@use './button/button-types.scss' as buttonTypes;
@use './mixins.scss' as mixins;

.app-dashBlock {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  border-radius: var(--app-border-radius);
  border: var(--app-border);
  border-radius: var(--app-border-radius);
  background-color: var(--app-color-white);

  .app-dashBlock-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 56px;
    border-bottom: var(--app-border);
    padding: 0 15px;
    flex: 0 0 auto;

    &.-dragHandle {
      cursor: move;
    }

    .app-dashBlock-headerLeft {
      display: flex;
      align-items: center;

      > :not(:first-child) {
        margin-left: 10px;
      }
      report-thumbnail {
        --reportThumbnail-size: 40px;
      }
      .app-dashBlock-header-title {
        font-size: var(--app-font-size-large);
        font-weight: var(--app-font-weight-bolder);
      }
      button.app-dashBlock-header-title {
        @include buttonTypes.button-type-text;
        --button-iconSize: 16px;
        background-color: transparent !important;
      }
      .app-dashBlock-header-description {
        font-size: var(--app-font-size-normal);
        font-weight: var(--app-font-weight-normal);
      }
    }
    .app-dashBlock-headerRight {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      > :not(:first-child) {
        margin-left: 15px;
      }
      .app-remove {
        --appRemove-size: 26px;
      }
      .app-formItem {
        align-items: flex-end;

        .app-formItem-value {
          font-size: var(--app-font-size-larger);
        }
      }
    }
  }
  .app-dashBlock-content {
    width: 100%;
    overflow: hidden;
    position: relative;
    min-height: 0;
    flex: 1 1 auto;
  }

  @include mixins.is-mobile {
    border-left: 0;
    border-right: 0;
    border-radius: 0;
  }
}


i.app-remove {
  --appRemove-size: 20px;
  height: var(--appRemove-size);
  width: var(--appRemove-size);
  line-height: var(--appRemove-size);
  border-radius: var(--appRemove-size);
  transition: background-color var(--app-transition-longer), color var(--app-transition-longer);
  background-color: transparent;
  color: var(--app-color-grey-dark);
  cursor: pointer;
  text-align: center;
  font-size: calc(var(--appRemove-size) * 0.8);
  flex: 0 0 auto;

  &:hover {
    background-color: var(--app-color-grey-translucent);
  }
  &.-shaded {
    background-color: var(--app-color-grey-translucent-dark);
    color: var(--app-color-white);
  }
  &.-white {
    background-color: var(--app-color-white);
    color: var(--app-color-dark);
    box-shadow: var(--app-box-shadow-dark);

    &:hover {
      background-color: var(--app-color-grey-very-light);
    }
  }
  &:before {
    text-align: center;
    width: var(--appRemove-size);
    height: var(--appRemove-size);
    line-height: var(--appRemove-size);
  }
}

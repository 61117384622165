.app-approvalProject {
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 20px;
    border-bottom: var(--app-border-light);
  }

  .app-approvalProject-header {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding-bottom: 15px;

    .app-approvalProject-projectName {
      font-size: var(--app-font-size-larger);
      margin-bottom: 5px;
    }
    .app-approvalProject-clientName {
      color: var(--app-color-grey-dark);
    }
  }
}

.app-approvalProject-unitGroups {
  padding: 25px 15px;

  .app-approvalProject-unitGroup {
    .app-approvalProject-unitGroup-header {
      display: flex;
      align-items: center;
      font-weight: var(--app-font-weight-normal);
      text-transform: capitalize;
      width: 100%;
    }
    .app-approvalProject-unitGroup-segments {
      width: 100%;
      padding: 10px 0;

      .app-approvalProject-unitGroup-segment {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 5px 0;

        .-segment-date {
          width: 25%;
          display: flex;
          align-items: center;
        }
        .-segment-details {
          width: 50%;
          display: flex;
          align-items: center;
          min-height: 24px;

          item-bullet {
            margin-right: 10px;
            height: auto;
            align-self: stretch;
          }
          .-segment-detailsInner {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            > div {
              display: flex;
              align-items: center;

              > :not(:first-child) {
                margin-left: 5px;
              }
              &:nth-child(1) {
                font-weight: var(--app-font-weight-normal);

                > i {
                  position: relative;
                  font-size: var(--app-font-size-normal);
                  color: var(--app-color-grey-dark);
                }
              }
              &:nth-child(2) {
              }

              &.-segment-error {
                color: var(--app-color-red);
              }

            }
          }
        }
        .-segment-units {
          width: 25%;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          segment-value {
            --unit-color: var(--app-color-grey-dark);
          }
        }
      }
    }
    .app-approvalProject-unitGroup-footer {
      display: flex;
      align-items: center;
      font-weight: var(--app-font-weight-normal);
      justify-content: flex-end;
      width: 100%;

      segment-value {
        --unit-color: var(--app-color-grey-dark);
        --value-weight: 500;
      }
    }
  }
}

.app-approvalProject-approvers,
.app-approvalProject-attachments {
  width: 100%;
  padding: 0 15px 15px 15px;

  .app-approvalProject-approvers-header,
  .app-approvalProject-attachments-header {
    width: 100%;
    display: flex;
    align-items: center;
    height: 40px;

    > div {
      font-weight: var(--app-font-weight-normal);
    }
  }
  .app-approvalProject-approvers-item,
  .app-approvalProject-attachments-item {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px 0;
  }
  .app-approvalProject-approvers-empty,
  .app-approvalProject-attachments-empty {
    text-align: center;
    width: 100%;
    margin: 20px 0;
    color: var(--app-color-grey-dark);
  }
}

.app-approvalProject-approvers {
  .app-approvalProject-approvers-item {
    approval-user-status-icon {
      margin-right: 10px;
    }
    .-approver-details {
      .-approver-name {
      }
      .-approver-reason {
        color: var(--app-color-grey-dark);
        display: flex;
        align-items: center;

        > i {
          margin-right: 5px;
        }
        > div {
          padding-right: 25px;
        }
      }
    }
    .-approver-response {
      margin-left: auto;
      color: var(--app-color-grey-dark);
      flex: 0 0 auto;

      &.-declined {
        color: var(--app-color-red);
      }
      &.-approved {
        color: var(--app-color-green);
      }
    }
  }
}

.app-approvalProject-attachments {
  .app-approvalProject-attachments-item {
    .-attachment-name {
      > a {
        height: 22px;
      }
      > div {
        color: var(--app-color-grey-dark);
      }
    }
    .-attachment-date {
      margin-left: auto;
      color: var(--app-color-grey-dark);
      flex: 0 0 auto;
    }
  }
}

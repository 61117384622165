@use './mixins.scss' as mixins;

.app-tabs,
app-tabs {
  --appTabs-height: 40px;
  display: flex;
  position: relative;
  justify-content: var(--theme-appTabs-justifyContent);
  width: 100%;
  height: var(--appTabs-height);
  overflow: hidden;
  overflow-x: auto;
  border-bottom: var(--theme-appTabs-borderBottom);
  flex: 0 0 auto;
  left: 0;

  &::-webkit-scrollbar {
    display: none;
  }

  .app-tabs-tab {
    padding: 0;
    min-width: 0;
    background-color: transparent;
    border-radius: var(--app-border-radius) var(--app-border-radius) 0 0;
    position: relative;
    user-select: none;
    transition: background-color var(--app-transition);
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.2;
    flex: 0 0 auto;

    @include mixins.is-mobile {
      flex: 1 0 auto;
    }

    .app-tabs-tabLabel {
      text-align: center;
    }
    .app-tabs-tabHighlighter {
      height: 4px;
      border-radius: 5px 5px 0 0;
      background-color: var(--theme-color-accent);
      position: absolute;
      left: 0;
      right: 0;
      bottom: -4px;
      transition: bottom var(--app-transition);
    }

    &:not(:last-child) {
      margin-right: 30px;

      @include mixins.is-mobile {
        margin-right: 15px;
      }
    }
    &:not(.-disabled) {
      cursor: pointer;
    }
    &:hover:not(.-disabled) {
      background-color: var(--theme-appTabs-background-hover);
    }
    &.-selected {
      color: var(--theme-appTabs-selected-color);
      font-weight: var(--app-font-weight-normal);

      .app-tabs-tabHighlighter {
        bottom: 0;
      }
    }
    &:not(.-selected):not(.-disabled) {
      color: var(--theme-appTabs-color);
    }
    &.-disabled {
      color: var(--app-color-grey);
      pointer-events: none;
    }
  }
}

body {
  &.-theme-invoxy {
    --theme-appTabs-borderBottom: none;
    --theme-appTabs-justifyContent: flex-start;
    --theme-appTabs-background-hover: var(--app-color-grey-translucent);
    --theme-appTabs-color: var(--app-color-dark);
    --theme-appTabs-selected-color: var(--app-color-dark);
  }
  &.-theme-karmly-light,
  &.-theme-karmly-dark,
  &.-theme-flexitime {
    --theme-appTabs-borderBottom: var(--app-border);
    --theme-appTabs-justifyContent: flex-start;
    --theme-appTabs-background-hover: transparent;
    --theme-appTabs-color: var(--app-color-grey-very-dark);
    --theme-appTabs-selected-color: var(--app-color-dark);
  }
}

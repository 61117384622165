@mixin is-mobile {
  @media (max-width: 850px) {
    @content;
  }
}
@mixin is-desktop {
  @media (min-width: 851px) {
    @content;
  }
}
@mixin is-small-desktop {
  @media (min-width: 851px) and (max-width: 1200px) {
    @content;
  }
}

@mixin mobile-only {
  @include is-mobile {
    display: flex !important;
  }
  @include is-desktop {
    display: none !important;
  }
}
@mixin desktop-only {
  @include is-mobile {
    display: none !important;
  }
  @include is-desktop {
    display: flex !important;
  }
}

@mixin mobile-fullWidth {
  @include is-mobile {
    max-width: 100vw;
    margin: 0;
  }
}

@mixin grid {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}

@mixin grid-small {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

@mixin user-select-disabled {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ and Edge */
  user-select: none; /* Standard syntax */
}

@mixin inputFocusBoxShadow {
  outline: none !important;
  box-shadow: var(--theme-input-focus-boxShadow) !important;
}
@mixin inputFocusBorderBottom {
  outline: none !important;
  border-bottom: var(--theme-input-focus-border) !important;
}
@mixin inputNoFocus {
  outline: none !important;
  box-shadow: none !important;
}

@mixin overflowEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin inputBorderElement {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  pointer-events: none;
}

@mixin app-card {
  background-color: var(--app-color-grey-very-light);
  border: var(--app-border-light);
  border-radius: var(--app-border-radius);
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.05);
  overflow: hidden;

  > i.ion-ios-arrow-forward {
    color: var(--app-color-grey);
    text-align: center;
    font-size: 1.4em;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}

@mixin ph-slideup-overlay {
  width: 100%;
  height: 100%;
  z-index: 1100;
  position: absolute;
  top: 0;
  left: 0;
  transition: background-color var(--app-transition);

  &.-overlayVisible {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
@mixin ph-slideup-container {
  width: 100%;
  height: 0;
  max-height: calc(100% - 200px);
  z-index: 101;
  background-color: var(--app-color-grey-very-light);
  position: absolute;
  bottom: 0;
  transition: height var(--app-transition);
}

@mixin ph-alert-btn {
  --button-height: 50px !important;
}

@mixin ph-textHeader {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: var(--app-font-size-larger);
  background-color: var(--app-color-grey-very-light);
}

@mixin ph-workLeaveSelector {
  height: 140px;
  padding: 30px 30px 20px 30px;
  color: var(--app-color-white);
  font-size: 26px;
  line-height: 80px;
  font-weight: var(--app-font-weight-normal);
  overflow: hidden;
  position: relative;
  background-color: var(--app-color-grey-very-dark);
  transition: background-color 0.5s ease-out;

  > div {
    width: 100%;
    height: 20px;
    line-height: 20px;
    font-size: var(--app-font-size-normal);
    float: left;
    overflow: hidden;
  }

  ion-select {
    width: 100%;
    max-width: 100%;
    float: left;
    position: relative;
    height: 60px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
    padding: 0;

    .select-text {
      line-height: 60px;
    }
    .select-icon {
      text-align: center;
      margin-top: 20px;
      position: absolute;
      top: 50%;
      --padding-top: 20px;

      .select-icon-inner {
        left: 0;
        color: var(--app-color-white);
      }
    }
  }

  i {
    height: 140px;
    line-height: 140px;
    width: 60px;
    background-color: rgba(0, 0, 0, 0.1);
    top: 0;
    right: 0;
    color: var(--app-color-white);
    font-weight: var(--app-font-weight-normal);
    text-align: center;
    position: absolute;
  }
}

@mixin ph-periodSelector {
  display: block;
  height: 60px;
  line-height: 60px;
  width: 100%;
  text-align: center;
  font-size: var(--app-font-size-larger);
  background-color: var(--app-color-grey-very-light);
  padding: 0 10px;

  > button {
    --button-height: 60px;
    --icon-size: 24px;
    margin: 0;
    background-color: transparent !important;

    &:first-child {
      float: left;
    }
    &:last-child {
      float: right;
    }
  }

  > div {
    float: left;
    width: calc(100% - 120px);

    > span:nth-child(2) {
      color: var(--app-color-grey-dark);
    }
  }
}

@mixin ph-groupedListContainer {
  background-color: var(--app-color-white);
  overflow: hidden;
  width: 100%;
  margin-bottom: 5px;
  border-top: var(--app-border-light);
  border-bottom: var(--app-border-light);
}

@mixin ph-workLeaveDeleteBtn {
  color: var(--app-color-red);
  border: none;
  margin-top: 50px;
}

@mixin ph-emptyMessage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  color: var(--app-color-grey);
  text-align: center;

  i {
    font-size: 50px;
    margin-bottom: 15px;
  }
  & > div {
    font-size: var(--app-font-size-larger);
  }
}

@mixin app-page {
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
  display: block;
  position: relative;
  overflow: auto;
  // background-color: var(--app-color-grey-very-light);
}

@mixin app-modal {
  height: 100%;
  display: block;
  position: relative;
  overflow: auto;
  background-color: var(--app-color-grey-very-light);

  @include is-desktop {
    border-radius: 5px;
  }
  @include is-mobile {
  }
}

@mixin ph-ionContentWithHeader {
  --ion-background-color: var(--app-color-grey-very-light);
  min-height: calc(100% - 56px);
  max-height: calc(100% - 56px);
}

@mixin hideScrollBar {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  scrollbar-height: none;
}

@mixin verticalScrollBar {
  &::-webkit-scrollbar-track {
    border-radius: var(--theme-scrollbar-width);
    background-color: var(--theme-color-scrollbar-background);
  }

  &::-webkit-scrollbar {
    width: var(--theme-scrollbar-width);
    border-radius: var(--theme-scrollbar-width);
    background-color: var(--theme-color-scrollbar-background);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: var(--theme-scrollbar-width);
    background-color: var(--theme-color-scrollbar);
  }

}

@mixin horizontalScrollBar {
  &::-webkit-scrollbar-track {
    border-radius: var(--theme-scrollbar-width);
    background-color: var(--theme-color-scrollbar-background);
  }

  &::-webkit-scrollbar {
    height: var(--theme-scrollbar-width);
    border-radius: var(--theme-scrollbar-width);
    background-color: var(--theme-color-scrollbar-background);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: var(--theme-scrollbar-width);
    background-color: var(--theme-color-scrollbar);
  }
}

@mixin app-infiniteScrollOuter {
  @include is-desktop {
    @include verticalScrollBar;
  }
  height: 100%;
  max-height: 100%;
  width: 100%;
  position: relative;
  overflow-y: var(--app-overflow-overlay);
  overflow-x: hidden;

  > app-spinner {
    z-index: 10;
    position: fixed;
  }
}

@mixin app-infiniteScrollInner {
  width: 100%;
  min-height: 100%;
  position: relative;
  z-index: 1;

  &.-loading {
    @include disableWhileLoading;
  }
}
@mixin disableWhileLoading {
  opacity: 0.7;
  pointer-events: none;
  user-select: none;
}

@use './button-mixins.scss' as buttonMixins;

// karmly
@mixin button-color-karmly {
  @include buttonMixins.buttonStandard(var(--app-color-white), var(--app-color-karmly), var(--app-color-karmly-dark));
}
@mixin button-color-karmly-clear {
  @include buttonMixins.buttonClear(var(--app-color-karmly), var(--app-color-karmly-dark));
  &:disabled {
    background-color: transparent !important;
  }
}
@mixin button-color-karmly-clear-grey-border {
  @include buttonMixins.buttonClearGreyBorder(var(--app-color-karmly), var(--app-color-karmly-dark));
}
@mixin button-color-karmly-hoverFill {
  @include buttonMixins.buttonHoverFill(var(--app-color-karmly), var(--app-color-karmly-translucent));
}
@mixin button-color-karmly-translucent {
  @include buttonMixins.buttonTranslucent(
    var(--app-color-karmly),
    var(--app-color-karmly-translucent),
    var(--app-color-karmly-translucent-light)
  );
}
@mixin button-color-karmly-outline {
  @include buttonMixins.buttonOutline(
    var(--app-color-karmly),
    var(--app-color-karmly-translucent),
    var(--app-color-karmly-translucent-light)
  );
}

// flexitime-yellow
@mixin button-color-flexitime-yellow {
  @include buttonMixins.buttonStandard(
    var(--app-color-flexitime-blue),
    var(--app-color-flexitime-yellow),
    var(--app-color-flexitime-yellow-dark)
  );
}
@mixin button-color-flexitime-yellow-clear {
  @include buttonMixins.buttonClear(var(--app-color-flexitime-yellow), var(--app-color-flexitime-yellow-dark));
  &:disabled {
    background-color: transparent !important;
  }
}
@mixin button-color-flexitime-yellow-clear-grey-border {
  @include buttonMixins.buttonClearGreyBorder(var(--app-color-flexitime-yellow), var(--app-color-flexitime-yellow-dark));
}
@mixin button-color-flexitime-yellow-hoverFill {
  @include buttonMixins.buttonHoverFill(var(--app-color-flexitime-yellow), var(--app-color-flexitime-yellow-translucent));
}
@mixin button-color-flexitime-yellow-translucent {
  @include buttonMixins.buttonTranslucent(
    var(--app-color-flexitime-yellow),
    var(--app-color-flexitime-yellow-translucent),
    var(--app-color-flexitime-yellow-translucent-light)
  );
}
@mixin button-color-flexitime-yellow-outline {
  @include buttonMixins.buttonOutline(
    var(--app-color-flexitime-yellow),
    var(--app-color-flexitime-yellow-translucent),
    var(--app-color-flexitime-yellow-translucent-light)
  );
}

// flexitime-blue
@mixin button-color-flexitime-blue {
  @include buttonMixins.buttonStandard(var(--app-color-white), var(--app-color-flexitime-blue), var(--app-color-flexitime-blue-dark));
}
@mixin button-color-flexitime-blue-clear {
  @include buttonMixins.buttonClear(var(--app-color-flexitime-blue), var(--app-color-flexitime-blue-dark));
  &:disabled {
    background-color: transparent !important;
  }
}
@mixin button-color-flexitime-blue-clear-grey-border {
  @include buttonMixins.buttonClearGreyBorder(var(--app-color-flexitime-blue), var(--app-color-flexitime-blue-dark));
}
@mixin button-color-flexitime-blue-hoverFill {
  @include buttonMixins.buttonHoverFill(var(--app-color-flexitime-blue), var(--app-color-flexitime-blue-translucent));
}
@mixin button-color-flexitime-blue-translucent {
  @include buttonMixins.buttonTranslucent(
    var(--app-color-flexitime-blue),
    var(--app-color-flexitime-blue-translucent),
    var(--app-color-flexitime-blue-translucent-light)
  );
}
@mixin button-color-flexitime-blue-outline {
  @include buttonMixins.buttonOutline(
    var(--app-color-flexitime-blue),
    var(--app-color-flexitime-blue-translucent),
    var(--app-color-flexitime-blue-translucent-light)
  );
}

// link
@mixin button-color-link {
  @include buttonMixins.buttonStandard(var(--app-color-white), var(--theme-color-link), var(--theme-color-link-dark));
}
@mixin button-color-link-clear {
  @include buttonMixins.buttonClear(var(--theme-color-link), var(--theme-color-link-dark));
  &:disabled {
    background-color: transparent !important;
  }
}
@mixin button-color-link-clear-grey-border {
  @include buttonMixins.buttonClearGreyBorder(var(--theme-color-link), var(--theme-color-link-dark));
}
@mixin button-color-link-hoverFill {
  @include buttonMixins.buttonHoverFill(var(--theme-color-link), var(--theme-color-link-translucent));
}
@mixin button-color-link-translucent {
  @include buttonMixins.buttonTranslucent(
    var(--theme-color-link),
    var(--theme-color-link-translucent),
    var(--theme-color-link-translucent-light)
  );
}
@mixin button-color-link-outline {
  @include buttonMixins.buttonOutline(
    var(--theme-color-link),
    var(--theme-color-link-translucent),
    var(--theme-color-link-translucent-light)
  );
}

// green
@mixin button-color-green {
  @include buttonMixins.buttonStandard(var(--app-color-white), var(--app-color-green), var(--app-color-green-dark));
}
@mixin button-color-green-clear {
  @include buttonMixins.buttonClear(var(--app-color-green), var(--app-color-green-dark));
  &:disabled {
    background-color: transparent !important;
  }
}
@mixin button-color-green-clear-grey-border {
  @include buttonMixins.buttonClearGreyBorder(var(--app-color-green), var(--app-color-green-dark));
}
@mixin button-color-green-hoverFill {
  @include buttonMixins.buttonHoverFill(var(--app-color-green), var(--app-color-green-translucent));
}
@mixin button-color-green-translucent {
  @include buttonMixins.buttonTranslucent(
    var(--app-color-green),
    var(--app-color-green-translucent),
    var(--app-color-green-translucent-light)
  );
}
@mixin button-color-green-outline {
  @include buttonMixins.buttonOutline(var(--app-color-green), var(--app-color-green-translucent), var(--app-color-green-translucent-light));
}

// blue
@mixin button-color-blue {
  @include buttonMixins.buttonStandard(var(--app-color-white), var(--app-color-blue), var(--app-color-blue-dark));
}
@mixin button-color-blue-clear {
  @include buttonMixins.buttonClear(var(--app-color-blue), var(--app-color-blue-dark));
  &:disabled {
    background-color: transparent !important;
  }
}
@mixin button-color-blue-clear-grey-border {
  @include buttonMixins.buttonClearGreyBorder(var(--app-color-blue), var(--app-color-blue-dark));
}
@mixin button-color-blue-hoverFill {
  @include buttonMixins.buttonHoverFill(var(--app-color-blue), var(--app-color-blue-translucent));
}
@mixin button-color-blue-translucent {
  @include buttonMixins.buttonTranslucent(
    var(--app-color-blue),
    var(--app-color-blue-translucent),
    var(--app-color-blue-translucent-light)
  );
}
@mixin button-color-blue-outline {
  @include buttonMixins.buttonOutline(var(--app-color-blue), var(--app-color-blue-translucent), var(--app-color-blue-translucent-light));
}

// payhero
@mixin button-color-payhero {
  @include buttonMixins.buttonStandard(var(--app-color-white), var(--app-color-payhero), var(--app-color-payhero-dark));
}
@mixin button-color-payhero-clear {
  @include buttonMixins.buttonClear(var(--app-color-payhero), var(--app-color-payhero-dark));
  &:disabled {
    background-color: transparent !important;
  }
}
@mixin button-color-payhero-clear-grey-border {
  @include buttonMixins.buttonClearGreyBorder(var(--app-color-payhero), var(--app-color-payhero-dark));
}
@mixin button-color-payhero-hoverFill {
  @include buttonMixins.buttonHoverFill(var(--app-color-payhero), var(--app-color-payhero-translucent));
}
@mixin button-color-payhero-translucent {
  @include buttonMixins.buttonTranslucent(
    var(--app-color-payhero),
    var(--app-color-payhero-translucent),
    var(--app-color-payhero-translucent-light)
  );
}
@mixin button-color-payhero-outline {
  @include buttonMixins.buttonOutline(
    var(--app-color-payhero),
    var(--app-color-payhero-translucent),
    var(--app-color-payhero-translucent-light)
  );
}

// droppah
@mixin button-color-droppah {
  @include buttonMixins.buttonStandard(var(--app-color-white), var(--app-color-droppah-purple), var(--app-color-droppah-purple-dark));
}
@mixin button-color-droppah-clear {
  @include buttonMixins.buttonClear(var(--app-color-droppah-purple), var(--app-color-droppah-purple-dark));
  &:disabled {
    background-color: transparent !important;
  }
}
@mixin button-color-droppah-clear-grey-border {
  @include buttonMixins.buttonClearGreyBorder(var(--app-color-droppah-purple), var(--app-color-droppah-purple-dark));
}
@mixin button-color-droppah-hoverFill {
  @include buttonMixins.buttonHoverFill(var(--app-color-droppah-purple), var(--app-color-droppah-purple-translucent));
}
@mixin button-color-droppah-translucent {
  @include buttonMixins.buttonTranslucent(
    var(--app-color-droppah-purple),
    var(--app-color-droppah-purple-translucent),
    var(--app-color-droppah-purple-translucent-light)
  );
}
@mixin button-color-droppah-outline {
  @include buttonMixins.buttonOutline(
    var(--app-color-droppah-purple),
    var(--app-color-droppah-purple-translucent),
    var(--app-color-droppah-purple-translucent-light)
  );
}

// invoxy
@mixin button-color-invoxy {
  @include buttonMixins.buttonStandard(var(--app-color-white), var(--app-color-invoxy), var(--app-color-invoxy-dark));
}
@mixin button-color-invoxy-clear {
  @include buttonMixins.buttonClear(var(--app-color-invoxy), var(--app-color-invoxy-dark));
  &:disabled {
    background-color: transparent !important;
  }
}
@mixin button-color-invoxy-clear-grey-border {
  @include buttonMixins.buttonClearGreyBorder(var(--app-color-invoxy), var(--app-color-invoxy-dark));
}
@mixin button-color-invoxy-hoverFill {
  @include buttonMixins.buttonHoverFill(var(--app-color-invoxy), var(--app-color-invoxy-translucent));
}
@mixin button-color-invoxy-translucent {
  @include buttonMixins.buttonTranslucent(
    var(--app-color-invoxy),
    var(--app-color-invoxy-translucent),
    var(--app-color-invoxy-translucent-light)
  );
}
@mixin button-color-invoxy-outline {
  @include buttonMixins.buttonOutline(
    var(--app-color-invoxy),
    var(--app-color-invoxy-translucent),
    var(--app-color-invoxy-translucent-light)
  );
}

// red
@mixin button-color-red {
  @include buttonMixins.buttonStandard(var(--app-color-white), var(--app-color-red), var(--app-color-red-dark));
}
@mixin button-color-red-clear {
  @include buttonMixins.buttonClear(var(--app-color-red), var(--app-color-red-dark));
  &:disabled {
    background-color: transparent !important;
  }
}
@mixin button-color-red-clear-grey-border {
  @include buttonMixins.buttonClearGreyBorder(var(--app-color-red), var(--app-color-red-dark));
}
@mixin button-color-red-hoverFill {
  @include buttonMixins.buttonHoverFill(var(--app-color-red), var(--app-color-red-translucent));
}
@mixin button-color-red-translucent {
  @include buttonMixins.buttonTranslucent(var(--app-color-red), var(--app-color-red-translucent), var(--app-color-red-translucent-light));
}
@mixin button-color-red-outline {
  @include buttonMixins.buttonOutline(var(--app-color-red), var(--app-color-red-translucent), var(--app-color-red-translucent-light));
}

// yellow
@mixin button-color-yellow {
  @include buttonMixins.buttonStandard(var(--app-color-white), var(--app-color-yellow), var(--app-color-yellow-dark));
}
@mixin button-color-yellow-clear {
  @include buttonMixins.buttonClear(var(--app-color-yellow), var(--app-color-yellow-dark));
  &:disabled {
    background-color: transparent !important;
  }
}
@mixin button-color-yellow-clear-grey-border {
  @include buttonMixins.buttonClearGreyBorder(var(--app-color-yellow), var(--app-color-yellow-dark));
}
@mixin button-color-yellow-hoverFill {
  @include buttonMixins.buttonHoverFill(var(--app-color-yellow), var(--app-color-yellow-translucent));
}
@mixin button-color-yellow-translucent {
  @include buttonMixins.buttonTranslucent(
    var(--app-color-yellow),
    var(--app-color-yellow-translucent),
    var(--app-color-yellow-translucent-light)
  );
}
@mixin button-color-yellow-outline {
  @include buttonMixins.buttonOutline(
    var(--app-color-yellow),
    var(--app-color-yellow-translucent),
    var(--app-color-yellow-translucent-light)
  );
}

// grey
@mixin button-color-grey {
  @include buttonMixins.buttonStandard(var(--app-color-white), var(--app-color-grey-dark), var(--app-color-grey-very-dark));
}
@mixin button-color-grey-clear {
  @include buttonMixins.buttonClear(var(--app-color-grey-dark), var(--app-color-grey-very-dark));
  &:disabled {
    background-color: transparent !important;
  }
}
@mixin button-color-grey-clear-grey-border {
  @include buttonMixins.buttonClearGreyBorder(var(--app-color-grey-dark), var(--app-color-grey-very-dark));
}
@mixin button-color-grey-hoverFill {
  @include buttonMixins.buttonHoverFill(var(--app-color-grey-dark), var(--app-color-grey-translucent));
}
@mixin button-color-grey-translucent {
  @include buttonMixins.buttonTranslucent(
    var(--app-color-grey-dark),
    var(--app-color-grey-translucent),
    var(--app-color-grey-translucent-light)
  );
}
@mixin button-color-grey-outline {
  @include buttonMixins.buttonOutline(
    var(--app-color-grey-dark),
    var(--app-color-grey-translucent),
    var(--app-color-grey-translucent-light)
  );
}

// white
@mixin button-color-white {
  @include buttonMixins.buttonStandard(var(--app-color-dark), var(--app-color-white), var(--app-color-white));
}
@mixin button-color-white-clear {
  @include buttonMixins.buttonClear(var(--app-color-white), var(--app-color-grey-very-light));
  &:disabled {
    background-color: transparent !important;
  }
}
@mixin button-color-white-clear-grey-border {
  @include buttonMixins.buttonClearGreyBorder(var(--app-color-white), var(--app-color-grey-very-light));
}
@mixin button-color-white-hoverFill {
  @include buttonMixins.buttonHoverFill(var(--app-color-dark), var(--app-color-grey-translucent));
}
@mixin button-color-white-translucent {
  @include buttonMixins.buttonTranslucent(
    var(--app-color-dark),
    var(--app-color-grey-translucent),
    var(--app-color-grey-translucent-light)
  );
}
@mixin button-color-white-outline {
  @include buttonMixins.buttonOutline(var(--app-color-dark), var(--app-color-white), var(--app-color-grey-very-light));
  border: var(--app-border) !important;
}

// black
@mixin button-color-black {
  @include buttonMixins.buttonStandard(var(--app-color-white), var(--app-color-black), var(--app-color-dark));
}
@mixin button-color-black-clear {
  @include buttonMixins.buttonClear(var(--app-color-white), var(--app-color-grey-very-light));
  &:disabled {
    background-color: transparent !important;
  }
}
@mixin button-color-black-clear-grey-border {
  @include buttonMixins.buttonClearGreyBorder(var(--app-color-white), var(--app-color-grey-very-light));
}
@mixin button-color-black-hoverFill {
  @include buttonMixins.buttonHoverFill(var(--app-color-white), var(--app-color-dark));
}
@mixin button-color-black-translucent {
  @include buttonMixins.buttonTranslucent(var(--app-color-white), var(--app-color-black), var(--app-color-dark));
}
@mixin button-color-black-outline {
  @include buttonMixins.buttonOutline(var(--app-color-white), var(--app-color-black), var(--app-color-dark));
  border: var(--app-border) !important;
}

// default
@mixin button-color-default {
  @include buttonMixins.buttonStandard(var(--app-color-dark), var(--app-color-grey-light), var(--app-color-grey));
}
@mixin button-color-default-clear {
  @include buttonMixins.buttonClear(var(--app-color-dark), var(--app-color-grey-very-dark));
  &:disabled {
    background-color: transparent !important;
  }
}
@mixin button-color-default-clear-grey-border {
  @include buttonMixins.buttonClearGreyBorder(var(--app-color-dark), var(--app-color-grey-very-dark));
}
@mixin button-color-default-hoverFill {
  @include buttonMixins.buttonHoverFill(var(--app-color-dark), var(--app-color-grey-translucent));
}
@mixin button-color-default-translucent {
  @include buttonMixins.buttonTranslucent(
    var(--app-color-dark),
    var(--app-color-grey-translucent),
    var(--app-color-grey-translucent-light)
  );
}
@mixin button-color-default-outline {
  @include buttonMixins.buttonOutline(var(--app-color-dark), var(--app-color-grey-light), var(--app-color-grey-translucent-light));
  border-color: var(--app-color-grey) !important;
}

@mixin button-color-droppah-dark {
  @include buttonMixins.buttonStandard(var(--app-color-white), var(--droppah-color-background-light), var(--droppah-color-background-light));
}

body {
  button,
  a {
    // karmly
    &.-color-karmly {
      @include button-color-karmly;
    }
    &.-color-karmly-clear {
      @include button-color-karmly-clear;
    }
    &.-color-karmly-clear-grey-border {
      @include button-color-karmly-clear-grey-border;
    }
    &.-color-karmly-hoverFill {
      @include button-color-karmly-hoverFill;
    }
    &.-color-karmly-translucent {
      @include button-color-karmly-translucent;
    }
    &.-color-karmly-outline {
      @include button-color-karmly-outline;
    }

    // flexitime-yellow
    &.-color-flexitime-yellow {
      @include button-color-flexitime-yellow;
    }
    &.-color-flexitime-yellow-clear {
      @include button-color-flexitime-yellow-clear;
    }
    &.-color-flexitime-yellow-clear-grey-border {
      @include button-color-flexitime-yellow-clear-grey-border;
    }
    &.-color-flexitime-yellow-hoverFill {
      @include button-color-flexitime-yellow-hoverFill;
    }
    &.-color-flexitime-yellow-translucent {
      @include button-color-flexitime-yellow-translucent;
    }
    &.-color-flexitime-yellow-outline {
      @include button-color-flexitime-yellow-outline;
    }

    // flexitime-blue
    &.-color-flexitime-blue {
      @include button-color-flexitime-blue;
    }
    &.-color-flexitime-blue-clear {
      @include button-color-flexitime-blue-clear;
    }
    &.-color-flexitime-blue-clear-grey-border {
      @include button-color-flexitime-blue-clear-grey-border;
    }
    &.-color-flexitime-blue-hoverFill {
      @include button-color-flexitime-blue-hoverFill;
    }
    &.-color-flexitime-blue-translucent {
      @include button-color-flexitime-blue-translucent;
    }
    &.-color-flexitime-blue-outline {
      @include button-color-flexitime-blue-outline;
    }

    // link
    &.-color-link {
      @include button-color-link;
    }
    &.-color-link-clear {
      @include button-color-link-clear;
    }
    &.-color-link-clear-grey-border {
      @include button-color-link-clear-grey-border;
    }
    &.-color-link-hoverFill {
      @include button-color-link-hoverFill;
    }
    &.-color-link-translucent {
      @include button-color-link-translucent;
    }
    &.-color-link-outline {
      @include button-color-link-outline;
    }

    // green
    &.-color-green {
      @include button-color-green;
    }
    &.-color-green-clear {
      @include button-color-green-clear;
    }
    &.-color-green-clear-grey-border {
      @include button-color-green-clear-grey-border;
    }
    &.-color-green-hoverFill {
      @include button-color-green-hoverFill;
    }
    &.-color-green-translucent {
      @include button-color-green-translucent;
    }
    &.-color-green-outline {
      @include button-color-green-outline;
    }

    // blue
    &.-color-blue {
      @include button-color-blue;
    }
    &.-color-blue-clear {
      @include button-color-blue-clear;
    }
    &.-color-blue-clear-grey-border {
      @include button-color-blue-clear-grey-border;
    }
    &.-color-blue-hoverFill {
      @include button-color-blue-hoverFill;
    }
    &.-color-blue-translucent {
      @include button-color-blue-translucent;
    }
    &.-color-blue-outline {
      @include button-color-blue-outline;
    }

    // payhero
    &.-color-payhero {
      @include button-color-payhero;
    }
    &.-color-payhero-clear {
      @include button-color-payhero-clear;
    }
    &.-color-payhero-clear-grey-border {
      @include button-color-payhero-clear-grey-border;
    }
    &.-color-payhero-hoverFill {
      @include button-color-payhero-hoverFill;
    }
    &.-color-payhero-translucent {
      @include button-color-payhero-translucent;
    }
    &.-color-payhero-outline {
      @include button-color-payhero-outline;
    }

    // droppah
    &.-color-droppah {
      @include button-color-droppah;
    }
    &.-color-droppah-clear {
      @include button-color-droppah-clear;
    }
    &.-color-droppah-clear-grey-border {
      @include button-color-droppah-clear-grey-border;
    }
    &.-color-droppah-hoverFill {
      @include button-color-droppah-hoverFill;
    }
    &.-color-droppah-translucent {
      @include button-color-droppah-translucent;
    }
    &.-color-droppah-outline {
      @include button-color-droppah-outline;
    }

    &.-color-droppah-dark {
      @include button-color-droppah-dark;
    }

    // invoxy
    &.-color-invoxy {
      @include button-color-invoxy;
    }
    &.-color-invoxy-clear {
      @include button-color-invoxy-clear;
    }
    &.-color-invoxy-clear-grey-border {
      @include button-color-invoxy-clear-grey-border;
    }
    &.-color-invoxy-hoverFill {
      @include button-color-invoxy-hoverFill;
    }
    &.-color-invoxy-translucent {
      @include button-color-invoxy-translucent;
    }
    &.-color-invoxy-outline {
      @include button-color-invoxy-outline;
    }

    // red
    &.-color-red {
      @include button-color-red;
    }
    &.-color-red-clear {
      @include button-color-red-clear;
    }
    &.-color-red-clear-grey-border {
      @include button-color-red-clear-grey-border;
    }
    &.-color-red-hoverFill {
      @include button-color-red-hoverFill;
    }
    &.-color-red-translucent {
      @include button-color-red-translucent;
    }
    &.-color-red-outline {
      @include button-color-red-outline;
    }

    // yellow
    &.-color-yellow {
      @include button-color-yellow;
    }
    &.-color-yellow-clear {
      @include button-color-yellow-clear;
    }
    &.-color-yellow-clear-grey-border {
      @include button-color-yellow-clear-grey-border;
    }
    &.-color-yellow-hoverFill {
      @include button-color-yellow-hoverFill;
    }
    &.-color-yellow-translucent {
      @include button-color-yellow-translucent;
    }
    &.-color-yellow-outline {
      @include button-color-yellow-outline;
    }

    // grey
    &.-color-grey {
      @include button-color-grey;
    }
    &.-color-grey-clear {
      @include button-color-grey-clear;
    }
    &.-color-grey-clear-grey-border {
      @include button-color-grey-clear-grey-border;
    }
    &.-color-grey-hoverFill {
      @include button-color-grey-hoverFill;
    }
    &.-color-grey-translucent {
      @include button-color-grey-translucent;
    }
    &.-color-grey-outline {
      @include button-color-grey-outline;
    }

    // white
    &.-color-white {
      @include button-color-white;
    }
    &.-color-white-clear {
      @include button-color-white-clear;
    }
    &.-color-white-clear-grey-border {
      @include button-color-white-clear-grey-border;
    }
    &.-color-white-hoverFill {
      @include button-color-white-hoverFill;
    }
    &.-color-white-translucent {
      @include button-color-white-translucent;
    }
    &.-color-white-outline {
      @include button-color-white-outline;
    }

    // black
    &.-color-black {
      @include button-color-black;
    }
    &.-color-black-clear {
      @include button-color-black-clear;
    }
    &.-color-black-clear-grey-border {
      @include button-color-black-clear-grey-border;
    }
    &.-color-black-hoverFill {
      @include button-color-black-hoverFill;
    }
    &.-color-black-translucent {
      @include button-color-black-translucent;
    }
    &.-color-black-outline {
      @include button-color-black-outline;
    }

    // default
    &.-color-default {
      @include button-color-default;
    }
    &.-color-default-clear {
      @include button-color-default-clear;
    }
    &.-color-default-clear-grey-border {
      @include button-color-default-clear-grey-border;
    }
    &.-color-default-hoverFill {
      @include button-color-default-hoverFill;
    }
    &.-color-default-translucent {
      @include button-color-default-translucent;
    }
    &.-color-default-outline {
      @include button-color-default-outline;
    }
  }

  // Theme specific colours

  &.-theme-invoxy {
    button,
    a {
      // success
      &.-color-success {
        @include button-color-green;
      }
      &.-color-success-clear {
        @include button-color-green-clear;
      }
      &.-color-success-clear-grey-border {
        @include button-color-green-clear-grey-border;
      }
      &.-color-success-hoverFill {
        @include button-color-green-hoverFill;
      }
      &.-color-success-translucent {
        @include button-color-green-translucent;
      }
      &.-color-success-outline {
        @include button-color-green-outline;
      }

      // product
      &.-color-product {
        @include button-color-invoxy;
      }
      &.-color-product-clear {
        @include button-color-invoxy-clear;
      }
      &.-color-product-clear-grey-border {
        @include button-color-invoxy-clear-grey-border;
      }
      &.-color-product-hoverFill {
        @include button-color-invoxy-hoverFill;
      }
      &.-color-product-translucent {
        @include button-color-invoxy-translucent;
      }
      &.-color-product-outline {
        @include button-color-invoxy-outline;
      }

      // accent
      &.-color-accent {
        @include button-color-invoxy;
      }
      &.-color-accent-clear {
        @include button-color-invoxy-clear;
      }
      &.-color-accent-clear-grey-border {
        @include button-color-invoxy-clear-grey-border;
      }
      &.-color-accent-hoverFill {
        @include button-color-invoxy-hoverFill;
      }
      &.-color-accent-translucent {
        @include button-color-invoxy-translucent;
      }
      &.-color-accent-outline {
        @include button-color-invoxy-outline;
      }

      // primary
      &.-color-primary {
        @include button-color-flexitime-blue;
      }
      &.-color-primary-clear {
        @include button-color-flexitime-blue-clear;
      }
      &.-color-primary-clear-grey-border {
        @include button-color-flexitime-blue-clear-grey-border;
      }
      &.-color-primary-hoverFill {
        @include button-color-flexitime-blue-hoverFill;
      }
      &.-color-primary-translucent {
        @include button-color-flexitime-blue-translucent;
      }
      &.-color-primary-outline {
        @include button-color-flexitime-blue-outline;
      }
    }
  }

  &.-theme-karmly-light,
  &.-theme-karmly-dark {
    button,
    a {
      // success
      &.-color-success {
        @include button-color-karmly;
      }
      &.-color-success-clear {
        @include button-color-karmly-clear;
      }
      &.-color-success-clear-grey-border {
        @include button-color-karmly-clear-grey-border;
      }
      &.-color-success-hoverFill {
        @include button-color-karmly-hoverFill;
      }
      &.-color-success-translucent {
        @include button-color-karmly-translucent;
      }
      &.-color-success-outline {
        @include button-color-karmly-outline;
      }

      // product
      &.-color-product {
        @include button-color-karmly;
      }
      &.-color-product-clear {
        @include button-color-karmly-clear;
      }
      &.-color-product-clear-grey-border {
        @include button-color-karmly-clear-grey-border;
      }
      &.-color-product-hoverFill {
        @include button-color-karmly-hoverFill;
      }
      &.-color-product-translucent {
        @include button-color-karmly-translucent;
      }
      &.-color-product-outline {
        @include button-color-karmly-outline;
      }

      // accent
      &.-color-accent {
        @include button-color-karmly;
      }
      &.-color-accent-clear {
        @include button-color-karmly-clear;
      }
      &.-color-accent-clear-grey-border {
        @include button-color-karmly-clear-grey-border;
      }
      &.-color-accent-hoverFill {
        @include button-color-karmly-hoverFill;
      }
      &.-color-accent-translucent {
        @include button-color-karmly-translucent;
      }
      &.-color-accent-outline {
        @include button-color-karmly-outline;
      }

      // primary
      &.-color-primary {
        @include button-color-flexitime-blue;
      }
      &.-color-primary-clear {
        @include button-color-flexitime-blue-clear;
      }
      &.-color-primary-clear-grey-border {
        @include button-color-flexitime-blue-clear-grey-border;
      }
      &.-color-primary-hoverFill {
        @include button-color-flexitime-blue-hoverFill;
      }
      &.-color-primary-translucent {
        @include button-color-flexitime-blue-translucent;
      }
      &.-color-primary-outline {
        @include button-color-flexitime-blue-outline;
      }
    }
  }

  &.-theme-droppah {
    button,
    a {
      // success
      &.-color-success {
        @include button-color-droppah;
      }
      &.-color-success-clear {
        @include button-color-droppah-clear;
      }
      &.-color-success-clear-grey-border {
        @include button-color-droppah-clear-grey-border;
      }
      &.-color-success-hoverFill {
        @include button-color-droppah-hoverFill;
      }
      &.-color-success-translucent {
        @include button-color-droppah-translucent;
      }
      &.-color-success-outline {
        @include button-color-droppah-outline;
      }

      // product
      &.-color-product {
        @include button-color-droppah;
      }
      &.-color-product-clear {
        @include button-color-droppah-clear;
      }
      &.-color-product-clear-grey-border {
        @include button-color-droppah-clear-grey-border;
      }
      &.-color-product-hoverFill {
        @include button-color-droppah-hoverFill;
      }
      &.-color-product-translucent {
        @include button-color-droppah-translucent;
      }
      &.-color-product-outline {
        @include button-color-droppah-outline;
      }

      // accent
      &.-color-accent {
        @include button-color-droppah;
      }
      &.-color-accent-clear {
        @include button-color-droppah-clear;
      }
      &.-color-accent-clear-grey-border {
        @include button-color-droppah-clear-grey-border;
      }
      &.-color-accent-hoverFill {
        @include button-color-droppah-hoverFill;
      }
      &.-color-accent-translucent {
        @include button-color-droppah-translucent;
      }
      &.-color-accent-outline {
        @include button-color-droppah-outline;
      }

      // primary
      &.-color-primary {
        @include button-color-droppah;
      }
      &.-color-primary-clear {
        @include button-color-droppah-clear;
      }
      &.-color-primary-clear-grey-border {
        @include button-color-droppah-clear-grey-border;
      }
      &.-color-primary-hoverFill {
        @include button-color-droppah-hoverFill;
      }
      &.-color-primary-translucent {
        @include button-color-droppah-translucent;
      }
      &.-color-primary-outline {
        @include button-color-droppah-outline;
      }
    }
  }
  &.-theme-flexitime {
    button,
    a {
      // success
      &.-color-success {
        @include button-color-flexitime-yellow;
      }
      &.-color-success-clear {
        @include button-color-flexitime-yellow-clear;
      }
      &.-color-success-clear-grey-border {
        @include button-color-flexitime-yellow-clear-grey-border;
      }
      &.-color-success-hoverFill {
        @include button-color-flexitime-yellow-hoverFill;
      }
      &.-color-success-translucent {
        @include button-color-flexitime-yellow-translucent;
      }
      &.-color-success-outline {
        @include button-color-flexitime-yellow-outline;
      }

      // product
      &.-color-product {
        @include button-color-flexitime-blue;
      }
      &.-color-product-clear {
        @include button-color-flexitime-blue-clear;
      }
      &.-color-product-clear-grey-border {
        @include button-color-flexitime-blue-clear-grey-border;
      }
      &.-color-product-hoverFill {
        @include button-color-flexitime-blue-hoverFill;
      }
      &.-color-product-translucent {
        @include button-color-flexitime-blue-translucent;
      }
      &.-color-product-outline {
        @include button-color-flexitime-blue-outline;
      }

      // accent
      &.-color-accent {
        @include button-color-flexitime-yellow;
      }
      &.-color-accent-clear {
        @include button-color-flexitime-yellow-clear;
      }
      &.-color-accent-clear-grey-border {
        @include button-color-flexitime-yellow-clear-grey-border;
      }
      &.-color-accent-hoverFill {
        @include button-color-flexitime-yellow-hoverFill;
      }
      &.-color-accent-translucent {
        @include button-color-flexitime-yellow-translucent;
      }
      &.-color-accent-outline {
        @include button-color-flexitime-yellow-outline;
      }

      // primary
      &.-color-primary {
        @include button-color-flexitime-blue;
      }
      &.-color-primary-clear {
        @include button-color-flexitime-blue-clear;
      }
      &.-color-primary-clear-grey-border {
        @include button-color-flexitime-blue-clear-grey-border;
      }
      &.-color-primary-hoverFill {
        @include button-color-flexitime-blue-hoverFill;
      }
      &.-color-primary-translucent {
        @include button-color-flexitime-blue-translucent;
      }
      &.-color-primary-outline {
        @include button-color-flexitime-blue-outline;
      }
    }
  }

}

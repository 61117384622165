.app-label-container {
  --label-height: 22px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: var(--label-height);
  overflow: hidden;
  max-width: 100%;
  position: relative;

  .app-label {
    flex: 0 0 auto;
    height: var(--label-height);
    display: flex;
    align-items: center;
    color: var(--app-color-white);
    border-radius: var(--app-border-radius);
    padding: 0 8px;
    font-size: var(--app-font-size-normal);
    transition: box-shadow var(--app-transition);
    white-space: nowrap;

    &:not(:first-child) {
      margin-left: 5px;
    }
    .app-remove {
      --appRemove-size: var(--label-height);
      margin-left: 3px;
      color: var(--app-color-white)
    }
  }
}
@use "../../../flexitime-library/src/styles/button/button-types.scss" as
  buttonTypes;
@use "../../../flexitime-library/src/styles/mixins.scss" as mixins;

.app-formItem-container {
  @include mixins.grid;
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  min-height: 0;
  border-radius: var(--theme-formItem-container-borderRadius);
  border: var(--theme-formItem-container-border);
  padding: var(--theme-formItem-container-padding);
  background-color: var(--theme-formItem-container-background);
}

.app-formItem-row {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 15px;
}

.app-formItem-column {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
}

.app-formItem-container,
.app-formItem-column {
  > :not(:last-child):not(.app-formItem-column) {
    margin-bottom: var(--theme-formItem-marginBottom);
  }
  @include mixins.is-mobile {
    > :last-child:not(.app-formItem-column) {
      margin-bottom: var(--theme-formItem-marginBottom);
    }
  }
  > .app-buttonRow {
    padding-left: var(--theme-formItem-horizontalPadding);
    padding-right: var(--theme-formItem-horizontalPadding);
  }
  > app-list {
    width: calc(100% - (var(--theme-formItem-horizontalPadding) * 2));
    margin-left: var(--theme-formItem-horizontalPadding);
    margin-right: var(--theme-formItem-horizontalPadding);
  }
}

.app-formItem {
  width: 100%;
  padding: 0 var(--theme-formItem-horizontalPadding);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 0;
  position: relative;

  &.-width-50 {
    width: 50%;
  }
  &.-width-25 {
    width: 25%;
  }
  &.-width-75 {
    width: 75%;
  }
  &.-noPadding {
    --theme-formItem-horizontalPadding: 0;
    padding: 0;
  }
  &.-noLabel {
    height: var(--app-formItem-height);
  }
  &.-alignRight {
    .app-formItem-label {
      justify-content: flex-end;
      text-align: end;
    }
    .app-formItem-value {
      justify-content: flex-end;
      text-align: end;
    }
  }

  button.app-formItem-inputButton {
    @include buttonTypes.button-type-dropdown;
    --button-iconSize: 16px;
    width: 100%;
    padding: 0 !important;
    border-radius: 0;
    background-color: transparent;
    text-transform: capitalize;
    border-bottom: var(--app-border);
    color: var(--app-color-black);
    font-weight: var(--app-font-weight-normal);
    position: relative;
  }
  .app-formItem-incrementButtons {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    > button {
      --button-height: 60px;
      --button-iconSize: 60px;

      > i.ion-ios-add {
        color: var(--app-color-green);
      }
      > i.ion-ios-remove {
        color: var(--app-color-grey-dark);
      }
    }
  }

  > app-list {
    margin-bottom: 10px;
  }

  &.-capitaliseLabel {
    .app-formItem-label {
      text-transform: capitalize;
    }
  }
  &.-detailBelow {
    --theme-formItem-marginBottom: 0;
  }
}

.app-formItem-label {
  color: var(--theme-formItem-label-color);
  height: var(--theme-formItem-label-height);
  line-height: var(--theme-formItem-label-height);
  font-size: var(--app-font-size-normal);
  padding-left: 0;
  font-weight: var(--theme-formItem-label-fontWeight);
  display: flex;
  align-items: center;
  //text-transform: capitalize;
  position: relative;
  overflow: hidden;
  max-width: 100%;

  > span {
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  > div {
    display: flex;
    align-items: center;

    > :not(:last-child) {
      margin-right: 8px;
    }
  }
  > .-alignRight {
    margin-left: auto;
  }
  > :not(:last-child) {
    margin-right: 4px;
  }
}
.app-formItem-errorLabel {
  color: var(--app-color-red);
  font-weight: var(--app-font-weight-normal);
  line-height: 20px;
  opacity: 0;
  height: 0;
  margin-top: 0;
  transition: all var(--app-transition);

  &:first-letter {
    text-transform: capitalize;
  }

  &.-errorVisible {
    opacity: 1;
    height: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.app-formItem-inputContainer {
  height: var(--app-formItem-height);
  width: 100%;
  padding: var(--theme-formItem-inputContainer-padding);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  transition: all var(--app-transition);
  border-radius: var(--theme-formItem-inputContainer-borderRadius);
  background-color: var(--theme-formItem-inputContainer-background);

  &.-disabled {
    background-color: var(--theme-formItem-inputContainer-disabled-background);
    color: var(--theme-color-input-disabled-color);

    .input-group {
      > .input-group-addon {
        color: var(--theme-color-input-disabled-color);
        font-weight: var(--app-font-weight-normal);
      }
    }
    .app-formItem-inputContainer-border {
      border-color: var(--app-color-border-light);
    }
  }
  &.-word-break {
    word-break: break-all;
  }
  &.-readonly {
    background-color: var(--theme-formItem-inputContainer-readonly-background);
    color: var(--theme-color-input-readonly-color);

    .input-group {
      > .input-group-addon {
        color: var(--theme-color-input-readonly-color);
        font-weight: var(--app-font-weight-normal);
      }
    }
    .app-formItem-inputContainer-border {
      border-color: var(--app-color-border-light);
    }
  }
  &:not(.-disabled):not(.-readonly) {
    &:focus-within,
    &.-mockFocus {
      .app-formItem-inputContainer-border {
        box-shadow: var(--theme-input-focus-boxShadow);
        border-bottom: var(--theme-input-focus-border);
        border-top: var(--theme-formItem-inputContainer-focus-borderTop);
        border-bottom: var(--theme-formItem-inputContainer-focus-borderBottom);
        border-left: var(--theme-formItem-inputContainer-focus-borderLeft);
        border-right: var(--theme-formItem-inputContainer-focus-borderRight);
      }
    }
  }
  .app-formItem-inputContainer-border {
    margin: 0 !important;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    transition: box-shadow var(--app-transition);
    border-top: var(--theme-formItem-inputContainer-borderTop);
    border-bottom: var(--theme-formItem-inputContainer-borderBottom);
    border-left: var(--theme-formItem-inputContainer-borderLeft);
    border-right: var(--theme-formItem-inputContainer-borderRight);
    border-radius: var(--theme-formItem-inputContainer-borderRadius);
    pointer-events: none;
    z-index: 100;
  }

  .rx-container {
    min-height: 34px;
    margin: 0;
    border: none !important;

    .rx-editor {
      padding-left: 0;
      padding-right: 0;

      > p:not(:first-child) {
        margin-top: 5px;
      }
    }
  }

  input,
  input:focus {
    background-color: transparent;
  }

  > input:first-child,
  .input-group > input:first-child,
  .input-group > .input-group-addon:first-child {
    padding-left: var(--theme-formItem-value-input-firstChild-paddingLeft);
  }
  > input:last-child,
  .input-group > input:last-child,
  .input-group > .input-group-addon:last-child {
    padding-right: var(--theme-formItem-value-input-lastChild-paddingRight);
  }
  > list-drop-down:first-child {
    .ldd-button {
      padding-left: var(--theme-formItem-value-input-firstChild-paddingLeft);
    }
  }
  > list-drop-down:last-child {
    .ldd-button {
      padding-right: var(--theme-formItem-value-input-lastChild-paddingRight);
    }
  }

  .input-group,
  input,
  button,
  day-dropdown button,
  duration-input,
  duration-input input,
  unit-input,
  unit-input input,
  date-picker,
  time-picker,
  list-drop-down,
  list-drop-down button.ldd-button {
    border: none !important;
    box-shadow: none !important;
    width: 100%;
  }
  app-toggle {
    align-self: center;
  }
  button:disabled,
  day-dropdown button:disabled,
  list-drop-down button.ldd-button:disabled {
    background-color: var(--theme-color-input-disabled-background) !important;
  }

  > list-drop-down,
  > .input-group > list-drop-down {
    .ldd-button {
      border: none !important;
    }
  }
  .input-group {
    > input,
    > button,
    > day-dropdown button {
      box-shadow: none !important;
      border: none !important;
    }
    > button,
    > list-drop-down,
    > day-dropdown {
      &:first-child {
        border-right: var(--theme-formItem-inputContainer-borderLeft);
      }
      &:last-child {
        border-left: var(--theme-formItem-inputContainer-borderRight);
      }
    }
    > .input-group-addon,
    > .input-group-value {
      background-color: var(--theme-formItem-inputContainer-inputGroupAddon-backgroundColor);
      border-top: none;
      border-bottom: none;
      padding: 0 10px;

      &:first-child {
        border-right: var(--theme-formItem-inputContainer-borderLeft);
        border-left: none;
      }
      &:last-child {
        border-left: var(--theme-formItem-inputContainer-borderRight);
        border-right: none;
      }
    }
  }
  date-picker {
    --datePicker-border: none;
    --datePicker-borderBottom: none;

    & > .input-group:focus-within {
      box-shadow: none !important;
    }
  }
  time-picker {
    --timePicker-border: none;
    --timePicker-borderBottom: none;

    .ngb-tp-input {
      box-shadow: none !important;
    }
  }
  address-input {
    --addressInput-border: none;
    --addressInput-borderBottom: none;

    & > input {
      box-shadow: none !important;
      padding-left: 0;
    }
  }
  input,
  date-picker,
  time-picker,
  address-input,
  duration-input,
  unit-input {
    width: 100%;
  }
}

.app-formItem-value {
  min-height: var(--app-formItem-height);
  padding: 0;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  font-weight: var(--theme-formItem-value-weight);
  overflow: hidden;

  &.-column {
    flex-direction: column;
    align-items: flex-start;

    > :not(:last-child) {
      margin-bottom: 3px;
    }
  }
  &:not(.-column) {
    > :not(:first-child) {
      margin-left: 5px;
    }
  }

  &.-sm {
    > input {
      max-width: 130px;
    }
  }

  > .app-formItem-valueColour {
    height: calc(var(--app-formItem-height) - 16px);
    width: 4px;
    border-radius: 10px;
    margin-right: 8px;
    flex: 0 0 auto;
  }
  > .app-formItem-units {
    font-weight: var(--app-font-weight-normal);
    margin-left: 10px;
    white-space: nowrap;
  }
  > app-checkbox {
    margin-right: 10px;
  }
  > input,
  > .input-group,
  > time-picker,
  > date-picker,
  > address-input,
  > list-drop-down {
    width: 100%;
    height: var(--app-formItem-height);
  }
  > input {
    border: var(--theme-formItem-value-input-border);
    border-bottom: var(--theme-formItem-value-input-borderBottom);
    border-radius: var(--theme-formItem-value-input-borderRadius);
  }
  > input,
  > .input-group > input {
    clip-path: var(--theme-formItem-value-input-clipPath);
  }
  > .input-group {
    > .input-group-addon {
      background-color: var(--theme-formItem-value-inputGroupAddon-backgroundColor);
    }
  }
  > time-picker {
    --timePicker-border: var(--theme-formItem-value-input-border);
    --timePicker-borderBottom: var(--theme-formItem-value-input-borderBottom);
    --timePicker-borderRadius: var(--theme-formItem-value-input-borderRadius);
    --timePicker-clipPath: var(--theme-formItem-value-input-clipPath);
  }
  > date-picker {
    --datePicker-border: var(--theme-formItem-value-input-border);
    --datePicker-borderBottom: var(--theme-formItem-value-input-borderBottom);
    --datePicker-borderRadius: var(--theme-formItem-value-input-borderRadius);
    --datePicker-clipPath: var(--theme-formItem-value-input-clipPath);
  }
  > address-input {
    --addressInput-border: var(--theme-formItem-value-input-border);
    --addressInput-borderBottom: var(--theme-formItem-value-input-borderBottom);
    --addressInput-borderRadius: var(--theme-formItem-value-input-borderRadius);
    --addressInput-clipPath: var(--theme-formItem-value-input-clipPath);
  }
  > list-drop-down,
  > .input-group > list-drop-down {
    --listDropDown-button-size: var(--app-formItem-height);
  }
  > span {
    font-weight: var(--app-font-weight-normal);
  }
}

.app-formItem-detailBelow {
  padding: 10px calc(var(--theme-formItem-horizontalPadding) + 2px);
  font-size: 10px;
  display: inline;
  color: var(--theme-formItem-label-color);

  i {
    font-size: 15px;
    padding: 1px 6px 0 0;
  }
  button {
    --button-fontSize: 10px;
    text-decoration: underline;
    display: inline;
  }
}

@include mixins.is-mobile {
  .app-formItem {
    &.-width-50 {
      width: 100%;
    }
    &.-width-25 {
      width: 100%;
    }
  }
}

body {
  &.-theme-invoxy {
    --theme-formItem-container-padding: 0;
    --theme-formItem-container-background: transparent;
    --theme-formItem-container-borderRadius: 0;
    --theme-formItem-container-border: none;

    --theme-formItem-marginBottom: 15px;
    --theme-formItem-horizontalPadding: 15px;

    --theme-formItem-inputContainer-padding: 0;
    --theme-formItem-inputContainer-borderTop: var(--app-border);
    --theme-formItem-inputContainer-borderBottom: var(--app-border);
    --theme-formItem-inputContainer-borderLeft: var(--app-border);
    --theme-formItem-inputContainer-borderRight: var(--app-border);
    --theme-formItem-inputContainer-borderRadius: var(--app-border-radius);
    --theme-formItem-inputContainer-background: var(--app-color-white);
    --theme-formItem-inputContainer-disabled-background: var(--app-color-grey-very-light);
    --theme-formItem-inputContainer-readonly-background: var(--app-color-grey-very-light);

    --theme-formItem-inputContainer-focus-borderTop: var(--theme-input-focus-border);
    --theme-formItem-inputContainer-focus-borderBottom: var(--theme-input-focus-border);
    --theme-formItem-inputContainer-focus-borderLeft: var(--theme-input-focus-border);
    --theme-formItem-inputContainer-focus-borderRight: var(--theme-input-focus-border);

    --theme-formItem-inputContainer-inputGroupAddon-backgroundColor: var(--app-color-grey-light);

    --theme-formItem-label-height: 30px;
    --theme-formItem-label-color: var(--app-color-grey-very-dark);
    --theme-formItem-label-fontWeight: var(--app-font-weight-normal);

    --theme-formItem-value-weight: var(--app-font-weight-normal);

    --theme-formItem-value-input-border: var(--app-border);
    --theme-formItem-value-input-borderBottom: var(--app-border);
    --theme-formItem-value-input-borderRadius: var(--app-border-radius);
    --theme-formItem-value-input-clipPath: none;

    --theme-formItem-value-input-firstChild-paddingLeft: 10px;
    --theme-formItem-value-input-lastChild-paddingRight: 10px;

    --theme-formItem-value-inputGroupAddon-backgroundColor: var(--app-color-grey-light);
  }
  &.-theme-karmly-light,
  &.-theme-karmly-dark,
  &.-theme-flexitime {
    --theme-formItem-container-padding: 30px 15px;
    --theme-formItem-container-background: var(--app-color-white);
    --theme-formItem-container-borderRadius: var(--app-border-radius);
    --theme-formItem-container-border: var(--app-border);

    --theme-formItem-marginBottom: 28px;
    --theme-formItem-horizontalPadding: 15px;

    --theme-formItem-inputContainer-padding: 0;
    --theme-formItem-inputContainer-borderTop: none;
    --theme-formItem-inputContainer-borderBottom: var(--app-border);
    --theme-formItem-inputContainer-borderLeft: none;
    --theme-formItem-inputContainer-borderRight: none;
    --theme-formItem-inputContainer-borderRadius: 0;
    --theme-formItem-inputContainer-background: transparent;
    --theme-formItem-inputContainer-disabled-background: transparent;
    --theme-formItem-inputContainer-readonly-background: transparent;

    --theme-formItem-inputContainer-focus-borderTop: none;
    --theme-formItem-inputContainer-focus-borderBottom: var(--theme-input-focus-border);
    --theme-formItem-inputContainer-focus-borderLeft: none;
    --theme-formItem-inputContainer-focus-borderRight: none;

    --theme-formItem-inputContainer-inputGroupAddon-backgroundColor: transparent;

    --theme-formItem-label-height: 20px;
    --theme-formItem-label-color: var(--app-color-grey-very-dark);
    --theme-formItem-label-fontWeight: var(--app-font-weight-normal);

    --theme-formItem-value-weight: var(--app-font-weight-normal);

    --theme-formItem-value-input-border: none;
    --theme-formItem-value-input-borderBottom: var(--app-border);
    --theme-formItem-value-input-borderRadius: 0;
    --theme-formItem-value-input-clipPath: inset(2px 1px -15px 1px);

    --theme-formItem-value-input-firstChild-paddingLeft: 2px;
    --theme-formItem-value-input-lastChild-paddingRight: 2px;

    --theme-formItem-value-inputGroupAddon-backgroundColor: transparent;

    .app-formItem-value {
      @mixin borderBottomOnly {
        border: none !important;
        border-bottom: var(--app-border) !important;
        border-radius: 0 !important;
      }

      > .input-group {
        > input,
        > .input-group-addon,
        > list-drop-down > div > .ldd-button.-color-white-outline {
          @include borderBottomOnly;
        }
      }
      > list-drop-down > div > .ldd-button.-color-white-outline {
        @include borderBottomOnly;
      }
    }

    @include mixins.is-mobile {
      --theme-formItem-container-padding: 15px 0;
    }
  }
  &.-theme-droppah {
    --theme-formItem-container-padding: 8px 0px;
    --theme-formItem-container-background: var(--app-color-black);
    --theme-formItem-container-borderRadius: var(--app-border-radius);
    --theme-formItem-container-border: none;

    --theme-formItem-marginBottom: 8px;
    --theme-formItem-horizontalPadding: 10px;

    --theme-formItem-inputContainer-padding: 0px 16px;
    --theme-formItem-inputContainer-borderTop: none;
    --theme-formItem-inputContainer-borderBottom: none;
    --theme-formItem-inputContainer-borderLeft: none;
    --theme-formItem-inputContainer-borderRight: none;
    --theme-formItem-inputContainer-borderRadius: var(--app-border-radius);
    --theme-formItem-inputContainer-background: var(--droppah-color-background-light);
    --theme-formItem-inputContainer-disabled-background: var(--droppah-color-background-light);
    --theme-formItem-inputContainer-readonly-background: var(--droppah-color-background-light);

    --theme-formItem-inputContainer-focus-borderTop: var(--theme-input-focus-border);
    --theme-formItem-inputContainer-focus-borderBottom: var(--theme-input-focus-border);
    --theme-formItem-inputContainer-focus-borderLeft: var(--theme-input-focus-border);
    --theme-formItem-inputContainer-focus-borderRight: var(--theme-input-focus-border);

    --theme-formItem-inputContainer-inputGroupAddon-backgroundColor: transparent;

    --theme-formItem-label-height: 34px;
    --theme-formItem-label-color: var(--app-color-white);
    --theme-formItem-label-fontWeight: var(--app-font-weight-bold);

    --theme-formItem-value-weight: var(--app-font-weight-normal);

    --theme-formItem-value-input-border: none;
    --theme-formItem-value-input-borderBottom: none;
    --theme-formItem-value-input-borderRadius: var(--app-border-radius);
    --theme-formItem-value-input-clipPath: inset(2px 1px -15px 1px);

    --theme-formItem-value-input-firstChild-paddingLeft: 0px;
    --theme-formItem-value-input-lastChild-paddingRight: 0px;

    --theme-formItem-value-inputGroupAddon-backgroundColor: transparent;

    .app-formItem-container,
    .app-formItem-column {
      @include mixins.is-mobile {
        > :last-child:not(.app-formItem-column) {
          margin-bottom: 0;
        }
      }
    }
  }
}

@use '../../../flexitime-library/src/styles/mixins.scss' as mixins;

.app-nav {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
}
.app-navHeader,
.app-navToolbar {
  --app-nav-rowHeight: 34px;
  --app-nav-rowComponentHeight: var(--app-nav-rowHeight);

  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: var(--app-border);
  padding: 0 20px;

  @include mixins.is-mobile {
    padding: 0 10px;
  }

  &.-grid {
    .app-navRow,
    .app-tabs,
    app-tabs {
      @include mixins.grid;
    }
  }

  .app-tabs,
  app-tabs {
    border-bottom: none;
  }

  .app-navRow {
    flex: 0 0 auto;
    width: 100%;
    min-height: 34px;
    display: flex;
    justify-content: space-between;

    .app-nav-graphic {
      height: 40px;
    }
    .app-nav-icon {
      font-size: 24px;
      color: var(--theme-color-accent);
    }

    .app-navRow-left,
    .app-navRow-right,
    .app-navRow-center {
      display: flex;
      align-items: center;
      position: relative;
      overflow: hidden;
      min-width: 0;

      > back-button {
        @include mixins.is-desktop {
          &:not(:last-child) ~ * {
            margin-left: 20px;
          }
        }
      }
      > :not(:first-child) {
        margin-left: 10px;
      }
      > .app-remove {
        --appRemove-size: 28px;
      }
    }
    .app-navRow-left {
      justify-content: flex-start;
      flex: 0 0 auto;
      margin-right: 15px;
    }
    .app-navRow-right {
      justify-content: flex-end;
      flex: 0 1 auto;
      margin-left: auto;
      width: 50%;
    }
    .app-navRow-center {
      justify-content: center;
      flex: 0 1 auto;
      margin: 0 auto;
    }
    .app-navRow-logo {
      height: 36px;
    }

    @include mixins.is-mobile {
      .app-navRow-left,
      .app-navRow-right,
      .app-navRow-center {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  thumbnail-graphic:not(:last-child) {
    margin-right: 10px;
  }
  .app-nav-errorMessage {
    color: var(--app-color-red);
    font-weight: var(--app-font-weight-normal);
  }
  mega-search,
  app-search {
    flex: 0 1 auto;
    width: 300px;

    @include mixins.is-mobile {
      flex: 1 1 auto;
    }
  }
  .app-nav-title {
    font-size: var(--app-font-size-larger);
    font-weight: var(--app-font-weight-bolder);
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 0 0 auto;

    @include mixins.is-mobile {
      font-size: var(--app-font-size-large);
      flex: 1 1 auto;
    }

    .app-nav-titleRow {
      display: flex;
      align-items: center;
      position: relative;

      > :not(:first-child):not(span) {
        margin-left: 10px;
      }
      > i {
        color: var(--app-color-grey-dark);
      }
    }
    .app-nav-titleDescription {
      font-size: var(--app-font-size-normal);
      color: var(--app-color-grey-very-dark);
      font-weight: var(--app-font-weight-normal);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: flex;
      align-items: center;

      > i,
      > item-bullet {
        margin-right: 5px;
      }
      &.-error {
        color: var(--app-color-red);
      }
    }
    app-form-item {
      padding: 0;

      ::ng-deep {
        input {
          height: 40px;
        }
      }
    }
    .app-nav-titleInput {
      height: 40px;
    }
  }
}

.app-navHeader {
  min-height: var(--theme-app-navHeader-minHeight);
  padding-top: 10px;
  justify-content: flex-start;

  .app-navRow {
    min-height: var(--theme-app-navRow-minHeight);
    transition: padding-left var(--app-transition);

    .app-navRow-left {
      width: auto;
      flex: 0 1 auto;
      margin-right: 10px;
    }
    .app-navRow-right {
      width: auto;
      flex: 0 0 auto;
    }

    @include mixins.is-mobile {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}

.app-navToolbar {
  --app-navToolbarHeight: 55px;
  height: var(--app-navToolbarHeight);
  justify-content: center;

  .app-navRow {
    .app-tabs,
    app-tabs {
      height: calc(var(--app-navToolbarHeight) - 1px);
      flex: 1 1 auto;
    }

    @include mixins.is-mobile {
      flex-wrap: wrap;

      .app-navRow-left,
      .app-navRow-right,
      .app-navRow-center {
        width: 100%;
        flex: 1 1 auto;
      }

      week-selector {
        flex: 1 1 auto;
      }
    }
  }

  &.-searchToolbar {
    padding: 0;
    border-bottom: 0;

    app-search {
      padding: 0 10px;
      height: 100%;
      width: 100%;
    }
    mega-search {
      --megaSearch-height: 100%;
      width: 100%;
    }
  }
}

.app-navWindow {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-y: var(--app-overflow-overlay);
  position: relative;
  flex: 1 1 auto;
  padding: 0 20px;
  background-color: var(--theme-app-navWindow-background);

  &.-sidePanel {
    display: flex;
    padding-right: 0;
  }

  .app-navSidePanel {
    @include mixins.desktop-only;
    flex: 0 0 auto;
    width: 0;
    overflow: hidden;
    height: 100%;
    margin-left: 30px;
    border-left: var(--app-border);
    transition: width var(--app-transition);

    &.-visible {
      width: 400px;
    }
  }

  &.-loading {
    opacity: 0.3;
  }
  &.-horizontalScroll {
    padding: 0;

    .app-navContent {
      @include mixins.horizontalScrollBar;
      padding: 0;
      position: relative;
      width: auto;
      height: auto;
      overflow-x: auto;
    }
  }
  &:not(.-horizontalScroll) {
    .app-navContent {
      @include mixins.grid;
    }
  }

  .app-navContent {
    --app-content-padding: 20px;
    height: 100%;
    width: 100%;
    padding: var(--app-content-padding) 0;
    display: block;
    min-height: 100%;
    position: relative;

    @include mixins.is-mobile {
      > app-list.-listTallerThanScrollContainer:last-child {
        .app-list-item:last-child {
          margin-bottom: 70px;
        }
      }
    }
  }
}

@include mixins.is-mobile {
  .app-navMenu-mobileMenuIcon ~ .app-navMenu-content {
    .app-nav {
      .app-navHeader {
        .app-navRow:first-child {
          padding-left: 40px;
        }
      }
    }
  }

  .app-navMenu-content:not(.-nonResponsive) {
    .app-navWindow {
      padding-left: 0;
      padding-right: 0;
    }
    .app-navContent {
      padding: 15px 0 0 0;

      .app-buttonRow {
        padding-left: 10px;
        padding-right: 10px;
      }
      > app-list {
        .app-list-scrollOuter {
          border-radius: 0;
          border-left: none;
          border-right: none;
        }

        .app-list-empty {
          border-radius: 0;
          border-left: none;
          border-right: none;
        }
      }
      .app-formItem-container {
        border-radius: 0;
        border-left: none;
        border-right: none;
      }
    }
  }
  .app-nav > .app-navWindow {
    padding-left: 0;
    padding-right: 0;
  }
}

body {
  &.-theme-invoxy {
    --theme-app-navWindow-background: var(--app-color-white);
    --theme-app-navRow-minHeight: 40px;
    --theme-app-navHeader-minHeight: 61px;
  }
  &.-theme-karmly-light {
    --theme-app-navWindow-background: var(--app-color-grey-very-light);
    --theme-app-navRow-minHeight: 40px;
    --theme-app-navHeader-minHeight: 61px;
  }
  &.-theme-karmly-dark {
    --theme-app-navWindow-background: var(--app-color-white);
    --theme-app-navRow-minHeight: 40px;
    --theme-app-navHeader-minHeight: 61px;
  }
  &.-theme-droppah {
    --theme-app-nav-padding: 12px;

    --theme-app-navWindow-background: transparent;
    --theme-app-navRow-minHeight: var(--droppah-losenge-height-small);
    --theme-app-navHeader-minHeight: calc(var(--droppah-losenge-height-small) + 2 * var(--droppah-border-width));

    .app-navHeader,
    .app-navToolbar {
      border-radius: var(--app-border-radius);
      background-color: var(--app-color-black);
      border: none;
      border: var(--droppah-border);
      margin-top: calc(var(--theme-app-nav-padding) - var(--droppah-border-width) + env(safe-area-inset-top));
      margin-left: calc(var(--theme-app-nav-padding) - var(--droppah-border-width));
      margin-right: calc(var(--theme-app-nav-padding) - var(--droppah-border-width));
      width: calc(100% - (var(--theme-app-nav-padding) * 2) + (var(--droppah-border-width) * 2));
      padding: var(--droppah-border-width);
      z-index: 1000;
      max-width: var(--droppah-content-maxWidth);
      @media only screen and (min-width: 620px) {
        margin-left: calc(50vw - 300px);
      }

      .app-navRow-left {
        flex: 1 1 auto;
      }
      .app-nav-title {
        padding-left: calc(var(--droppah-header-spacing) - var(--droppah-border-width));
      }
    }
    .app-navWindow {
      padding-top: 40px;
      margin-top: -30px;

      .app-navContent {
        max-width: var(--droppah-content-maxWidth);
        padding: 0 var(--theme-app-nav-padding) 70px var(--theme-app-nav-padding);
      }
    }
    .app-nav-icon {
      cursor: pointer;
      font-size: 20px;
    }
  }
  &.-theme-flexitime {
    --theme-app-navWindow-background: var(--app-color-grey-very-light);
    --theme-app-navRow-minHeight: 40px;
    --theme-app-navHeader-minHeight: 61px;
  }
}

body {
  &.-theme-invoxy {
    --theme-color-scrollbar-background: var(--app-color-grey-light);
    --theme-color-scrollbar: var(--app-color-grey);
    --theme-scrollbar-width: 8px;

    --theme-color-accent: var(--app-color-invoxy);
    --theme-color-accent-dark: var(--app-color-invoxy-dark);
    --theme-color-accent-translucent: var(--app-color-invoxy-translucent);
    --theme-color-accent-translucent-light: var(--app-color-invoxy-translucent-light);

    --theme-color-success: var(--app-color-green);
    --theme-color-success-dark: var(--app-color-green-dark);
    --theme-color-success-translucent: var(--app-color-green-translucent);
    --theme-color-success-translucent-light: var(--app-color-green-translucent-light);

    --theme-color-primary: var(--app-color-flexitime-blue);
    --theme-color-primary-dark: var(--app-color-flexitime-blue-dark);
    --theme-color-primary-translucent: var(--app-color-flexitime-blue-translucent);
    --theme-color-primary-translucent-light: var(--app-color-flexitime-blue-translucent-light);

    --theme-color-link: var(--app-color-mid-blue);
    --theme-color-link-dark: var(--app-color-mid-blue-dark);
    --theme-color-link-translucent: var(--app-color-mid-blue-translucent);
    --theme-color-link-translucent-light: var(--app-color-mid-blue-translucent-light);

    --theme-color-input-disabled-background: var(--app-color-grey-very-light);
    --theme-color-input-disabled-color: var(--app-color-grey-very-dark);

    --theme-color-input-readonly-background: var(--app-color-grey-very-light);
    --theme-color-input-readonly-color: var(--app-color-grey-very-dark);

    --theme-input-focus-boxShadow: 0 0 0 0.2rem var(--theme-input-focus-boxShadow-color);
    --theme-input-focus-boxShadow-color: rgb(0 123 255 / 25%);

    --theme-input-border-radius: var(--app-border-radius);

    --theme-input-focus-border-width: 1px;
    --theme-input-focus-border-color: var(--app-color-border);
    --theme-input-focus-border: var(--theme-input-focus-border-width) solid var(--theme-input-focus-border-color);
  }
  &.-theme-karmly-light,
  &.-theme-karmly-dark {
    --theme-color-scrollbar-background: var(--app-color-grey-light);
    --theme-color-scrollbar: var(--app-color-grey);
    --theme-scrollbar-width: 8px;

    --theme-color-accent: var(--app-color-karmly);
    --theme-color-accent-dark: var(--app-color-karmly-dark);
    --theme-color-accent-translucent: var(--app-color-karmly-translucent);
    --theme-color-accent-translucent-light: var(--app-color-karmly-translucent-light);

    --theme-color-success: var(--app-color-karmly);
    --theme-color-success-dark: var(--app-color-karmly-dark);
    --theme-color-success-translucent: var(--app-color-karmly-translucent);
    --theme-color-success-translucent-light: var(--app-color-karmly-translucent-light);

    --theme-color-primary: var(--app-color-flexitime-blue);
    --theme-color-primary-dark: var(--app-color-flexitime-blue-dark);
    --theme-color-primary-translucent: var(--app-color-flexitime-blue-translucent);
    --theme-color-primary-translucent-light: var(--app-color-flexitime-blue-translucent-light);

    --theme-color-link: var(--app-color-flexitime-blue);
    --theme-color-link-dark: var(--app-color-flexitime-blue-dark);
    --theme-color-link-translucent: var(--app-color-flexitime-blue-translucent);
    --theme-color-link-translucent-light: var(--app-color-flexitime-blue-translucent-light);

    --theme-color-input-disabled-background: transparent;
    --theme-color-input-disabled-color: var(--app-color-grey-dark);

    --theme-color-input-readonly-background: transparent;
    --theme-color-input-readonly-color: var(--app-color-grey-very-dark);

    --theme-input-focus-boxShadow: none;
    --theme-input-focus-boxShadow-color: transparent;

    --theme-input-border-radius: 0;

    --theme-input-focus-border-width: 2px;
    --theme-input-focus-border-color: var(--theme-color-primary);
    --theme-input-focus-border: var(--theme-input-focus-border-width) solid var(--theme-input-focus-border-color);
  }


  &.-theme-droppah {

    --theme-color-scrollbar-background: var(--droppah-color-background-dark);
    --theme-color-scrollbar: var(--droppah-color-background-light);
    --theme-scrollbar-width: var(--droppah-border-width);

    --theme-color-accent: var(--app-color-droppah-purple);
    --theme-color-accent-dark: var(--app-color-droppah-purple-dark);
    --theme-color-accent-translucent: var(--app-color-droppah-purple-translucent);
    --theme-color-accent-translucent-light: var(--app-color-droppah-purple-translucent-light);

    --theme-color-success: var(--app-color-droppah-purple);
    --theme-color-success-dark: var(--app-color-droppah-purple-dark);
    --theme-color-success-translucent: var(--app-color-droppah-purple-translucent);
    --theme-color-success-translucent-light: var(--app-color-droppah-purple-translucent-light);

    --theme-color-primary: var(--app-color-droppah-purple);
    --theme-color-primary-dark: var(--app-color-droppah-purple-dark);
    --theme-color-primary-translucent: var(--app-color-droppah-purple-translucent);
    --theme-color-primary-translucent-light: var(--app-color-droppah-purple-translucent-light);

    --theme-color-link: var(--app-color-droppah-purple);
    --theme-color-link-dark: var(--app-color-droppah-purple-dark);
    --theme-color-link-translucent: var(--app-color-droppah-purple-translucent);
    --theme-color-link-translucent-light: var(--app-color-droppah-purple-translucent-light);

    --theme-color-input-disabled-background: transparent;
    --theme-color-input-disabled-color: var(--app-color-grey-dark);

    --theme-input-focus-boxShadow: none;
    --theme-input-focus-boxShadow-color: transparent;

    --theme-input-border-width: 2px;
    --theme-input-focus-border-color: var(--theme-color-primary);
    --theme-input-focus-border: var(--theme-input-border-width) solid var(--theme-input-focus-border-color);
  }

  &.-theme-flexitime {
    --theme-color-scrollbar-background: var(--app-color-grey-light);
    --theme-color-scrollbar: var(--app-color-grey);
    --theme-scrollbar-width: 8px;

    --theme-color-accent: var(--app-color-flexitime-yellow);
    --theme-color-accent-dark: var(--app-color-flexitime-yellow-dark);
    --theme-color-accent-translucent: var(--app-color-flexitime-yellow-translucent);
    --theme-color-accent-translucent-light: var(--app-color-flexitime-yellow-translucent-light);

    --theme-color-success: var(--app-color-flexitime-yellow);
    --theme-color-success-dark: var(--app-color-flexitime-yellow-dark);
    --theme-color-success-translucent: var(--app-color-flexitime-yellow-translucent);
    --theme-color-success-translucent-light: var(--app-color-flexitime-yellow-translucent-light);

    --theme-color-primary: var(--app-color-flexitime-blue);
    --theme-color-primary-dark: var(--app-color-flexitime-blue-dark);
    --theme-color-primary-translucent: var(--app-color-flexitime-blue-translucent);
    --theme-color-primary-translucent-light: var(--app-color-flexitime-blue-translucent-light);

    --theme-color-link: var(--app-color-flexitime-blue);
    --theme-color-link-dark: var(--app-color-flexitime-blue-dark);
    --theme-color-link-translucent: var(--app-color-flexitime-blue-translucent);
    --theme-color-link-translucent-light: var(--app-color-flexitime-blue-translucent-light);

    --theme-color-input-disabled-background: transparent;
    --theme-color-input-disabled-color: var(--app-color-grey-dark);

    --theme-color-input-readonly-background: transparent;
    --theme-color-input-readonly-color: var(--app-color-grey-very-dark);

    --theme-input-focus-boxShadow: none;
    --theme-input-focus-boxShadow-color: transparent;

    --theme-input-border-radius: 0;

    --theme-input-focus-border-width: 2px;
    --theme-input-focus-border-color: var(--theme-color-primary);
    --theme-input-focus-border: var(--theme-input-focus-border-width) solid var(--theme-input-focus-border-color);
  }
}

@use './mixins.scss' as mixins;

@keyframes notifyIcon {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(1.2);
  }
  66% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

:root {
  // Colour variables
  --app-color-payhero: #00adef;
  --app-color-payhero-dark: #0094d6;
  --app-color-payhero-translucent: #00adef20;
  --app-color-payhero-translucent-light: #00adef40;

  --app-color-invoxy: #eb6426;
  --app-color-invoxy-dark: #d14f13;
  --app-color-invoxy-translucent: #eb642620;
  --app-color-invoxy-translucent-light: #eb642640;

  --app-color-karmly: #ef7812;
  --app-color-karmly-dark: #cb6813;
  --app-color-karmly-translucent: #ef781220;
  --app-color-karmly-translucent-light: #ef781240;

  --app-color-droppah-light: #d0e6c8;
  --app-color-droppah: #459915;
  --app-color-droppah-dark: #397d12;
  --app-color-droppah-translucent: #45991520;
  --app-color-droppah-translucent-light: #45991540;

  --app-color-droppah-purple: #763eff;
  --app-color-droppah-purple-dark: #3b266f;
  --app-color-droppah-purple-translucent: #281a4a20;
  --app-color-droppah-purple-translucent-light: #281a4a40;

  --app-color-flexitime-blue-light: #ccdae0;
  --app-color-flexitime-blue: #004763;
  --app-color-flexitime-blue-dark: #002b3b;
  --app-color-flexitime-blue-translucent: #00476320;
  --app-color-flexitime-blue-translucent-light: #00476340;

  --app-color-flexitime-yellow: #ffad3d;
  --app-color-flexitime-yellow-dark: #d89232;
  --app-color-flexitime-yellow-translucent: #ffad3d20;
  --app-color-flexitime-yellow-translucent-light: #ffad3d40;

  --app-color-blue: #337ab7;
  --app-color-blue-dark: #1a619e;
  --app-color-blue-translucent: #337ab720;
  --app-color-blue-translucent-light: #337ab740;

  --app-color-mid-blue: #118be0;
  --app-color-mid-blue-dark: #1383d3;
  --app-color-mid-blue-translucent: #118be010;
  --app-color-mid-blue-translucent-light: #118be030;

  --app-color-green: #5eb22e;
  --app-color-green-dark: #459915;
  --app-color-green-translucent: #5eb22e20;
  --app-color-green-translucent-light: #5eb22e40;

  --app-color-red: #c03a36;
  --app-color-red-dark: #b22e2e;
  --app-color-red-translucent: #d9534f20;
  --app-color-red-translucent-light: #d9534f40;

  --app-color-yellow: #fdd835;
  --app-color-yellow-dark: #e4bf1c;
  --app-color-yellow-translucent: #fdd83520;
  --app-color-yellow-translucent-light: #fdd83540;

  --app-color-pending: #d2b023;
  --app-color-pending-light: #fef9e6;

  --app-color-grey-very-dark: #777777;
  --app-color-grey-dark: #999999;
  --app-color-grey: #dddddd;
  --app-color-grey-light: #eeeeee;
  --app-color-grey-very-light: #f9f9f9;

  --app-color-grey-translucent-dark: #77777780;
  --app-color-grey-translucent-light: #cccccc80;
  --app-color-grey-translucent: #cccccc40;

  --app-color-dark: #333;
  --app-color-black: #000000;
  --app-color-white: #ffffff;

  --app-color-white-translucent: #ffffffaa;

  // --app-color-link: #118be0;
  // --app-color-link-dark: #1383d3;
  // --app-color-link-translucent: #118be010;
  // --app-color-link-translucent-light: #118be030;

  --app-color-error-background: #b22e2ebf;
  --app-color-error-border: #b22e2ebf;

  --app-color-info-background: #00adef1a;
  --app-color-info-border: #00adef80;

  --app-color-warning-background: #f1ca031a;
  --app-color-warning-border: #f1ca0380;

  --app-color-leave-light: #f5d4d3;
  --app-color-leave: #ef5350;

  --app-font-size-small: 10px;
  --app-font-size-normal: 12px;
  --app-font-size-large: 14px;
  --app-font-size-larger: 16px;
  --app-font-size-largest: 22px;

  --app-font-weight-skinny: 200;
  --app-font-weight-thin: 400;
  --app-font-weight-normal: 500;
  --app-font-weight-bold: 600;
  --app-font-weight-bolder: 700;

  // Border variables
  --app-color-border: var(--app-color-grey);
  --app-color-border-light: var(--app-color-grey-light);
  --app-color-border-dark: var(--app-color-dark);

  --app-border: 1px solid var(--app-color-border);
  --app-border-dashed: 1px dashed var(--app-color-border);

  --app-border-light: 1px solid var(--app-color-border-light);
  --app-border-dashed-light: 1px dashed var(--app-color-border-light);

  --app-border-dark: 1px solid var(--app-color-border-dark);
  --app-border-dashed-dark: 1px dashed var(--app-color-border-dark);

  --app-border-radius: 5px;
  --app-border-radius-large: 8px;
  --app-border-radius-larger: 20px;
  // Use as a box-shadow
  --app-border-inset: inset 0 0 0 1px var(--app-color-border);
  --app-border-light-inset: inset 0 0 0 1px var(--app-color-border-light);

  // Misc variables
  --app-transition-shorter: 0.1s ease;
  --app-transition: 0.2s ease;
  --app-transition-longer: 0.3s ease;
  --app-transition-longest: 0.5s ease;

  --app-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  --app-box-shadow-medium: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  --app-box-shadow-dark: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  --app-box-shadow-inset: inset 0 0 10px 0 rgba(0, 0, 0, 0.05);
  --app-box-shadow-dark-inset: inset 0 0 10px 0 rgba(0, 0, 0, 0.2);
  --app-text-shadow-small: 0px 0px 5px rgba(100, 100, 100, 1);
  --app-text-shadow: 0px 0px 10px rgba(100, 100, 100, 1);

  --app-navMenu-width: 260px;

  --app-input-height: 34px;
  --app-input-focus: 0 0 0 0.2rem rgb(0 123 255 / 25%);

  --app-formItem-height: var(--app-input-height);

  // Variables for handling css values with a fallback for browsers with limited support
  --app-overflow-overlay: overlay;
  @supports not (overflow: overlay) {
    --app-overflow-overlay: auto;
  }

  @include mixins.is-mobile {
    // TODO: find a solution to this causing issues with fixed height app-list items
    // --app-input-height: 44px;
  }

  // DROPPAH mobile new styles

  --droppah-losenge-height: 60px;
  --droppah-losenge-height-small: 45px;

  --droppah-header-spacing: 20px;

  --droppah-border-radius-container: calc(var(--app-border-radius) - var(--droppah-border-width)/2);

  --droppah-border-width: 5px;
  --droppah-border: var(--droppah-border-width) var(--droppah-color-background-light) solid;
  --droppah-border-dark: var(--droppah-border-width) var(--droppah-color-background-dark) solid;

  // Colours
  --droppah-color-background-light: #202020;
  --droppah-color-background-mid: #0e0e0e;
  --droppah-color-background-dark: var(--app-color-black);

  --droppah-color-dark-grey: #252525;
  --droppah-color-grey: #8d8d8d;

  --droppah-color-orange: #ffa571;
  --droppah-color-green: #84ca80;
  --droppah-color-green-dark: #2b3d2a;

  --droppah-color-stone: #EBEAE4;

  --droppah-color-red: #c21313;

  --droppah-content-maxWidth: 600px;
}

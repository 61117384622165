@use '../styles/mixins.scss' as mixins;
@use '../styles/button/button.scss' as buttons;

// Bootstrap variable overrides
:root {
  --primary: var(--app-color-blue);
}

.bg-primary {
  background-color: var(--theme-color-link) !important;
}

.btn {
  border-radius: var(--app-border-radius);

  &:focus {
    box-shadow: none;
  }

  &.btn-left {
    text-align: left;
    padding: 6px 18px;

    &:after {
      position: absolute;
      right: 18px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.btn-outline-secondary {
  color: var(--app-color-dark);
  background-color: var(--app-color-white);
  border: var(--app-border-light);

  &:hover {
    background-color: var(--app-color-grey-very-light);
  }
}

@mixin inputGroupAddonFirst {
  border-top-left-radius: var(--app-border-radius) !important;
  border-bottom-left-radius: var(--app-border-radius) !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

@mixin inputGroupAddonLast {
  border-top-right-radius: var(--app-border-radius) !important;
  border-bottom-right-radius: var(--app-border-radius) !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

@mixin inputGroupAddonMiddle {
  border-radius: 0 !important;
}

.input-group {
  height: var(--app-input-height);
  flex-wrap: nowrap;

  .input-group-addon,
  button.input-group-addon {
    --button-height: var(--app-input-height);
    height: var(--app-input-height);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    border: var(--app-border);
    background-color: var(--app-color-grey-light);
    font-weight: var(--app-font-weight-normal);

    &.-stretch {
      width: 100%;

      &:first-child {
        justify-content: flex-start;
      }
      &:last-child {
        justify-content: flex-end;
      }
    }

    &:first-child {
      @include inputGroupAddonFirst;
    }
    &:last-child {
      @include inputGroupAddonLast;
    }
    &:not(:last-child):not(:first-child) {
      @include inputGroupAddonMiddle;
    }
  }
  .input-group-value {
    flex: 1 1 auto;
    height: var(--app-input-height);
    line-height: var(--app-input-height);
    font-weight: var(--app-font-weight-normal);
  }
  .input-group-value {
    @include mixins.overflowEllipsis;
    min-width: 0;
    flex: 1 1 auto;
  }
  .form-control {
    height: var(--app-input-height);
  }
  list-drop-down {
    width: auto !important;

    &:not(:last-child):not(:first-child) {
      width: 100%;
    }
    &:first-child {
      .ldd-button {
        @include inputGroupAddonFirst;
        --listDropDown-button-fontWeight: 500 !important;
      }
    }
    &:last-child {
      .ldd-button {
        @include inputGroupAddonLast;
        --listDropDown-button-fontWeight: 500 !important;
      }
    }
    &:not(:last-child):not(:first-child) {
      .ldd-button {
        @include inputGroupAddonMiddle;
      }
    }
  }
  > :not(:first-child) {
    border-left: none;
  }
  > :not(:last-child) {
    border-right: none;
  }
}

input.form-control {
  border: var(--app-border);

  &:disabled {
    border: var(--app-border-light);
  }
}

.dropdown-toggle::after {
  display: none !important;
}

body > .dropdown,
body > .dropup {
  @include mixins.is-mobile {
    width: 100vw;
    max-height: calc(100% - 20px);
    top: auto;
    bottom: 0px;
    left: 0;
    transform: none;
    top: 0;
    bottom: auto;
    display: flex;
  }

  @include mixins.is-desktop {
    > .dropdown-menu {
      margin: 8px 0;
    }
  }
}

.dropdown,
.dropup {
  will-change: auto !important;

  > .btn {
    position: relative;
    width: 100%;

    &.dropdown-toggle:focus {
      box-shadow: none;
    }
    &:not(.btn-left) {
      &:after {
        display: none;
      }
    }
  }
}
.dropdown-menu {
  min-width: 80px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2);
  padding: 0;
  border: none;
  overflow: hidden;
  border-radius: var(--app-border-radius);

  .dropdown-item {
    height: 34px;
    line-height: 26px;
    font-size: var(--app-font-size-normal);
  }
}
.dropdown-item {
  &:focus,
  &:active {
    color: var(--app-color-dark);
    background-color: var(--app-color-white);
  }
  &:hover {
    color: var(--app-color-dark);

    @include mixins.is-mobile {
      background-color: var(--app-color-white);
    }
    @include mixins.is-desktop {
      background-color: var(--app-color-grey-very-light);
    }
  }
}

.modal-content {
  border: none;
  border-radius: var(--app-border-radius);
}

.modal {
  .modal-dialog {
    @include mixins.is-desktop {
      max-width: 700px;
    }
    &.modal-sm {
      @include mixins.is-desktop {
        max-width: 550px;
      }
    }
    &.modal-lg {
      @include mixins.is-desktop {
        max-width: 800px;
      }
    }
    &.modal-xl {
      @include mixins.is-desktop {
        max-width: 1000px;
      }
    }
    &:not(.modal-mobile-sm) {
      @include mixins.is-mobile {
        width: 100%;
        height: 100%;
        max-width: 100%;
        min-width: 100%;
        max-height: 100%;
        min-height: 100%;
        margin: 0;

        > .modal-content {
          width: 100%;
          height: 100%;
          border-radius: 0;

          .app-modal {
            border-radius: 0;
          }
        }
      }
    }
  }
}

.dropdown-menu[data-bs-popper].app-datepicker-dropdown {
  position: fixed !important;
  width: auto;
  padding: 0;
  border: none;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  overflow: visible;
  max-height: calc(100vh - 100px);
  overflow-y: var(--app-overflow-overlay);
  box-shadow: none;
  background-color: transparent;

  .app-week-relation-buttons {
    width: 400px;
    max-width: calc(100vw - 20px);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .app-week-relation-button {
      max-width: calc(33vw - 16px);
      cursor: pointer;
      width: 125px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--app-colow-white);
      border-radius: var(--app-border-radius);
    }
  }

  > ngb-datepicker {
    font-weight: var(--app-font-weight-normal);
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2);
  }

  @include mixins.is-mobile {
    width: calc(100vw - 20px);
  }
}

ngb-datepicker.dropdown-menu {
  position: fixed !important;
  transform: translate(-50%, -50%) !important;
  left: 50% !important;
  top: 50% !important;

  @include mixins.is-mobile {
    width: calc(100vw - 20px);
  }
}

ngb-popover-window {
  > .arrow {
    display: none !important;
  }
}

ngb-datepicker {
  background-color: var(--app-color-grey-very-light);
  border-radius: var(--app-border-radius);
  border: none;
  display: flex;
  flex-direction: column;

  @include mixins.is-mobile {
    width: calc(100vw - 20px);

    .ngb-dp-months {
      flex-direction: column;
      overflow-y: auto;
    }
  }

  .ngb-dp-header {
    padding: 15px;
    background-color: var(--app-color-grey-very-light);
  }
  .ngb-dp-months {
    position: relative;

    .ngb-dp-month {
      @include mixins.app-card;
      flex: 0 0 auto;
      background-color: transparent;
      display: block;
      margin: 0 auto;
      box-shadow: none;
      border: none;
      border-radius: 0 0 5px 5px;

      .ngb-dp-month-name {
        height: 40px;
        line-height: 40px;
      }
    }
  }
}

ngb-datepicker-navigation {
  .ngb-dp-arrow {
    width: 34px;
    height: 34px;

    button.ngb-dp-arrow-btn {
      width: 34px;
      min-width: 0;
      height: 34px;
      padding: 0;
      margin: 0;
      background-color: var(--app-color-white);
      border: var(--app-border);
      border-radius: var(--app-border-radius);
      color: var(--app-color-dark);

      &:focus {
        outline: 0;
      }

      > span.ngb-dp-navigation-chevron {
        font-size: var(--app-font-size-small);
        margin: 0;
        position: relative;
      }
    }

    &:not(.right) {
      span.ngb-dp-navigation-chevron {
        top: 0;
        left: 1px;
      }
    }
    &.right {
      span.ngb-dp-navigation-chevron {
        top: -1px;
        left: -1px;
      }
    }
  }
}

ngb-datepicker-navigation-select {
  > .form-select {
    flex: 0 0 auto !important;
    padding: 0 15px !important;
    font-size: var(--app-font-size-normal) !important;
    height: 34px !important;
    width: 120px;
    margin: 0 5px;
    font-weight: var(--app-font-weight-normal);
    border-radius: 5px;
    border-color: var(--app-color-border);
    cursor: pointer;
  }
}

ngb-datepicker-month {
  padding: 15px;
  flex: 0 0 auto;
  background-color: var(--app-color-white);

  .ngb-dp-week {
    padding: 0 !important;
    --ngbDpDay-size: 50px;
    --ngbDpDay-padding: 5px;

    &.ngb-dp-weekdays {
      border: none;
      background-color: transparent;

      .ngb-dp-weekday {
        width: var(--ngbDpDay-size);
        height: var(--ngbDpDay-size);
        line-height: var(--ngbDpDay-size);
        font-weight: var(--app-font-weight-normal);
        font-style: inherit;
        color: var(--app-color-dark);
        font-size: var(--app-font-size-normal);
      }
    }

    .ngb-dp-day {
      width: var(--ngbDpDay-size);
      height: var(--ngbDpDay-size);
      display: flex;
      justify-content: center;
      align-items: center;

      > div {
        --ngbDpDay-inner-size: calc(var(--ngbDpDay-size) - (var(--ngbDpDay-padding) * 2));
        width: var(--ngbDpDay-inner-size);
        height: var(--ngbDpDay-inner-size);
        line-height: var(--ngbDpDay-inner-size);
        border-radius: var(--ngbDpDay-inner-size);
        font-size: var(--app-font-size-normal);
        text-align: center;

        &.active,
        &:hover {
          background-color: var(--theme-color-link-translucent);
        }
        &.bg-primary {
          background-color: var(--theme-color-link);
          color: var(--app-color-white);
        }
      }
    }
  }
}

ngb-tooltip-window {
  opacity: 1 !important;

  .tooltip-inner {
    padding: 12px 15px;
    font-size: var(--app-font-size-normal);
    max-width: 500px;
  }
  &.tooltipPopover {
    transition: none;

    > .arrow {
      display: none;
    }
  }
  &.tooltipPopover-light {
    > .tooltip-inner {
      background-color: var(--app-color-white);
      box-shadow: var(--app-box-shadow-dark);
      color: var(--pp-color-dark);

      .tooltipPopover-buttonRow {
        width: 100%;
        margin-top: 10px;
        display: flex;

        > button {
          min-width: 250px;
          width: 100%;
          --button-height: 26px;
        }
      }
    }
  }
  &.workloadTooltips {
    position: relative;
    width: 120px;

    > .tooltip-inner {
      width: 100%;
    }
  }
  &.-verboseTooltip {
    @include mixins.is_mobile {
      max-width: min(calc(100% - 140px), 500px) !important;
    }
  }
}

// Modal backdrop
app-backdrop.-modalBackdrop {
  z-index: 1050;
}
.offcanvas-backdrop.-modalBackdrop,
.modal-backdrop {
  z-index: 1050 !important;
}

// Dropdown backdrop
app-backdrop:not(.-modalBackdrop) {
  z-index: 1060;
}
.offcanvas-backdrop:not(.-modalBackdrop) {
  z-index: 1060 !important;
}

// Modal
lib-expanding-canvas-wrapper {
  z-index: 1051;
}
.modal,
.offcanvas.-modal {
  z-index: 1051 !important;
}

// Dropdown
.offcanvas:not(.-modal),
// Only want to apply to dropdowns that're appended to body
body > .dropdown,
body > .dropup {
  z-index: 1061 !important;
}

.offcanvas {
  transition: transform var(--app-transition);
  overflow: hidden;
  display: flex;
  align-items: stretch;
  overflow: hidden;

  &.offcanvas-top,
  &.offcanvas-bottom {
    @include mixins.is-mobile {
      max-height: calc(100% - 50px);

      &.-fullHeight {
        height: calc(100% - 50px);
      }
    }
  }
  &.offcanvas-start,
  &.offcanvas-end {
    @include mixins.is-mobile {
      max-width: calc(100% - 50px);
    }
  }

  &.-modal {
    height: auto;
  }
  > lib-offcanvas-wrapper {
    > * {
      width: 100%;
    }
  }
}
.offcanvas-top {
  bottom: auto;
}
.offcanvas-bottom {
  top: auto;
}
.offcanvas-top,
.offcanvas-bottom {
  height: auto;
}
.offcanvas-start,
.offcanvas-end {
  width: auto;
}

.offcanvas-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--app-border-radius-larger);
  border-bottom-right-radius: var(--app-border-radius-larger);
}
.offcanvas-bottom {
  border-top-left-radius: var(--app-border-radius-larger);
  border-top-right-radius: var(--app-border-radius-larger);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.offcanvas-start {
  border-top-left-radius: 0;
  border-top-right-radius: var(--app-border-radius-larger);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: var(--app-border-radius-larger);
}
.offcanvas-end {
  border-top-left-radius: var(--app-border-radius-larger);
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--app-border-radius-larger);
  border-bottom-right-radius: 0;
}

body {
  &.-theme-droppah {
    .offcanvas-top,
    .offcanvas-bottom {
      max-width: var(--droppah-content-maxWidth);
      margin: 0 auto;
    }
  }
}

@use '../../../styles/mixins.scss' as mixins;
@use '../../../styles/button/button-types.scss' as buttonTypes;

:host {
  --listDropDown-button-size: 34px;
  --listDropDown-button-iconSize: 20px;
  --listDropDown-button-fontSize: 1rem;
  --listDropDown-button-fontWeight: var(--app-font-weight-normal);
  --listDropDown-button-disabled-fontWeight: var(--app-font-weight-normal);
  --listDropDown-button-justifyContent: center;
  --listDropdown-button-minWidth: 100px;
  --listDropdown-button-icon-margin: 8px;

  .-alignLeft {
    margin-right: var(--listDropdown-button-icon-margin) !important;
  }

  position: relative;
  display: block;
  line-height: 20px;
  margin-right: 0;
  height: var(--listDropDown-button-size);

  &.-floatOnMobile {
    @include mixins.is-mobile {
      --listDropDown-button-size: 44px;
    }

    @include buttonTypes.button-floatOnMobile;
    --button-height: var(--listDropDown-button-size);
  }
  &.-floatLeftOnMobile {
    @include mixins.is-mobile {
      --listDropDown-button-size: 44px;
    }

    @include buttonTypes.button-floatLeftOnMobile;
    --listDropDown-button-size: 44px;
    --button-height: var(--listDropDown-button-size);
  }

  &.-buttonEllipsisDisabled {
    .ldd-container {
      > button {
        overflow-x: auto;
        overflow-y: hidden;
        text-overflow: unset;
        white-space: normal;

        > span {
          overflow-x: auto;
          overflow-y: hidden;
          text-overflow: unset;
          white-space: normal;
        }
      }
    }
  }
  .ldd-container {
    width: 100%;

    button {
      --button-height: var(--listDropDown-button-size);
      --button-fontSize: var(--listDropDown-button-fontSize);
      --button-iconSize: var(--listDropDown-button-iconSize);
      font-weight: var(--listDropDown-button-fontWeight);
      justify-content: var(--listDropDown-button-justifyContent);

      > * {
        font-weight: var(--listDropDown-button-fontWeight);
      }

      &:disabled {
        font-weight: var(--listDropDown-button-disabled-fontWeight);

        > * {
          font-weight: var(--listDropDown-button-disabled-fontWeight);
        }
      }
      &.-invalidField {
        border-right: 1px solid var(--app-color-red) !important;
      }
      &:not(.-type-icon):not(.-type-icon-round) {
        width: 100%;
        min-width: var(--listDropdown-button-minWidth);
      }

      &.-type-dropdown {
        --button-iconSize: 1.3rem;

        &:disabled {
          .button-icon {
            display: none;
          }
          archived-icon {
            padding-top: 2px;
          }
          .completedIcon {
            color: var(--app-color-green);
          }
          tooltip-popover {
            pointer-events: all;
          }
        }
        > .button-icon {
          &.-alignRight {
            margin-left: auto;
          }
        }
      }

      .-colorPrefix {
        margin: var(--listDropDown-icon-margin) 0;
        border-radius: 5px;
        width: 5px;
        height: calc(var(--listDropDown-button-size) - 18px);
      }
      .-textPrefix {
        margin-right: 10px;
        width: auto;
        height: var(--listDropDown-button-size);
        line-height: var(--listDropDown-button-size);
        font-weight: var(--app-font-weight-bold);
      }
      .-title {
        flex: 1 1 auto;

        .-description {
          display: block;
          text-align: left;
          color: var(--app-color-grey-dark);
          font-size: var(--app-font-size-small);
          font-weight: var(--app-font-weight-normal);
        }
      }
      .-titlePlaceholder {
        color: var(--app-color-grey-dark) !important;
        font-weight: var(--app-font-weight-normal);
      }
    }
  }
}

::ng-deep {
  .ldd-listContainer {
    @include mixins.verticalScrollBar;
    padding: 0;
    border: none;
    background-color: var(--theme-listDropdown-listContainer-backgroundColor);
    min-width: 220px;
    --listDropDown-iconSize: 18px;

    @include mixins.is-mobile {
      --listDropDown-iconSize: 24px;
    }

    .ldd-listContainerInner {
      display: flex;
      flex-direction: column;

      @include mixins.is-desktop {
        max-height: min(600px, 50vh);
      }

      .ldd-header {
        flex: 0 0 auto;
        width: 100%;
        overflow: hidden;
        padding: var(--theme-listDropdown-header-padding);

        .app-formItem-inputContainer {
          padding: var(--theme-listDropdown-header-inputContainer-padding);
          min-height: var(--theme-listDropdown-header-inputContainer-minHeight);

          app-search {
            flex: 1 1 auto;
          }
        }

        .ldd-back {
          --button-height: 30px;
          --button-iconSize: 14px;
          flex-shrink: 0;
          margin-right: 7px;
        }
        .ldd-nestedListParent {
          @include mixins.overflowEllipsis;
          flex-shrink: 1;
          min-width: 0;
          font-size: var(--app-font-size-normal);
          font-weight: var(--app-font-weight-bold);
        }
      }

      .ldd-multiselect-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px;

        .ldd-multiselect-count {
          color: var(--app-color-grey-dark);
          user-select: none;
        }
        .ldd-multiselect-toggleAll {
          > button {
            --button-height: 20px;
          }
        }
      }

      .ldd-multiselect-footer {
        padding: 10px 5px;

        > button {
          --button-height: 28px;
          width: 100%;
        }
        @include mixins.is-mobile {
          padding: 10px 10px;

          > button {
            --button-height: 40px;
          }
        }
      }

      .ldd-list {
        width: 100%;
        overflow-y: auto;
        flex: 1 1 auto;
        min-height: 0;
        padding: 10px 0;
      }
      .ldd-divider {
        height: 1px;
        border-bottom: var(--app-border-light);
        margin: 10px 0;
        padding: 0;
        position: relative;
        user-select: none;

        &.-customDivider {
          height: 26px;
          display: flex;
          align-items: center;
          padding: 0 15px;
          font-size: var(--app-font-size-small);
          color: var(--app-color-grey-dark);
          margin: 8px 0 5px 0;

          item-bullet {
            --theme-itemBullet-dot-size: 8px;
            margin-right: 5px;
          }

          &:first-child {
            margin-top: 0;
          }
        }

        &:first-child:not(.-customDivider),
        &:last-child,
        & + .ldd-divider:not(.-customDivider) {
          display: none;
        }
      }

      .ldd-item {
        height: auto;
        min-height: 34px;
        padding: 4px 5px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        user-select: none;
        color: var(--theme-listDropdown-item-color);
        background-color: var(--theme-listDropdown-item-backgroundColor);
        font-size: var(--app-font-size-normal);
        font-weight: var(--app-font-weight-normal);
        display: flex;
        justify-content: space-between;

        &.-multiSelectItemToggled {
          .ldd-itemInner {
            background-color: var(--theme-color-primary-translucent);
          }
        }
        &.-itemSelected {
          background-color: var(--theme-listDropdown-item-selected-backgroundColor) !important;

          .itemName {
            color: var(--theme-listDropdown-item-selected-color) !important;
          }
        }
        &.-itemHighlighted {
          background-color: var(--theme-listDropdown-item-highlighted-backgroundColor) !important;
        }
        &.-itemDisabled {
          pointer-events: none;

          .itemName {
            color: var(--app-color-grey-dark) !important;
          }
          tooltip-popover {
            pointer-events: all;
          }
          &:hover {
            background-color: transparent;
          }
        }
        &.-dynamicItemCreator {
          color: var(--theme-color-link);
          font-weight: var(--app-font-weight-bold);
        }

        .ldd-itemInner {
          display: flex;
          justify-content: space-between;
          width: 100%;
          min-height: 20px;
          padding: 0 10px;
          border-radius: var(--app-border-radius);
        }

        .ldd-itemLeft {
          flex: 1 1 auto;
          width: 100%;
        }

        .ldd-itemRight {
          flex: 0 0 auto;
        }

        .ldd-itemLeft,
        .ldd-itemRight {
          display: flex;
          align-items: center;
          min-height: 20px;
        }

        .ldd-itemLeft {
          item-bullet {
            margin: 3px 10px 3px 0;
            height: calc(100% - 6px);
          }
          product-icon {
            margin-right: 10px;
            --productIcon-size: 18px;
          }
          .itemIcon {
            font-size: var(--listDropDown-iconSize);
            color: var(--app-color-dark);
            margin-right: 10px;
          }

          .ldd-itemLeftInner {
            display: flex;
            flex-direction: column;
            overflow: hidden;
            flex: 1 1 auto;
            position: relative;

            .itemName {
              max-width: 100%;
              font-size: var(--app-font-size-normal);
              font-weight: var(--app-font-weight-normal);
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .itemDescription {
              color: var(--app-color-grey-dark);
              font-size: var(--app-font-size-small);
              line-height: 1.3rem;
              max-width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              display: flex;

              .itemDescription-itemDescriptionBullet {
                width: 10px !important;
                height: 10px !important;
                margin-right: 6px;
              }
            }
          }
        }
        .ldd-itemRight {
          i.ion-ios-arrow-forward {
            color: var(--app-color-grey-dark);
            font-size: var(--app-font-size-normal);
          }
          .itemIcon {
            font-size: var(--listDropDown-iconSize);
            color: var(--app-color-dark);

            &.-multiSelectToggledIcon {
              color: var(--theme-color-primary);
              font-size: 22px;
            }
          }
        }
      }

      .ldd-empty {
        line-height: 34px;
        padding: 0 15px;
        margin: 0 10px;
        position: relative;
        text-align: center;
        font-size: var(--app-font-size-normal);
        color: var(--app-color-grey);
      }
      .ldd-listDescription {
        text-align: center;
        color: var(--app-color-grey-dark);
        font-size: var(--app-font-size-small);
        font-weight: var(--app-font-weight-thin);
        margin: 5px 10px 10px 10px;
      }

      @include mixins.is-mobile {
        max-height: calc(100vh - 50px);

        .ldd-item {
          padding: 4px 10px;

          .ldd-itemInner {
            padding: 8px 15px;
          }

          &:hover,
          &:active,
          &:focus {
            background-color: transparent;
          }
        }
        .ldd-divider {
          &.-customDivider {
            padding: 0 20px;
          }
        }
      }
      @include mixins.is-desktop {
        .ldd-item {
          &:hover {
            background-color: var(--app-color-grey-light);
          }
        }
      }
    }
  }
}

.-user-icon {
  margin-right: 10px;
  height: 22px;
}

body {
  &.-theme-invoxy {
    --theme-listDropdown-header-padding: 10px;
    --theme-listDropdown-header-inputContainer-padding: 0;
    --theme-listDropdown-header-inputContainer-minHeight: 34px;

    --theme-listDropdown-listContainer-backgroundColor: var(--app-color-white);

    --theme-listDropdown-item-color: var(--app-color-dark);
    --theme-listDropdown-item-backgroundColor: transparent;

    --theme-listDropdown-item-selected-backgroundColor: var(--app-color-grey-light);
    --theme-listDropdown-item-highlighted-backgroundColor: var(--app-color-grey-light);
    --theme-listDropdown-item-selected-color: var(--theme-color-primary);
  }
  &.-theme-karmly-light,
  &.-theme-karmly-dark,
  &.-theme-flexitime {
    --theme-listDropdown-header-inputContainer-minHewight: 10px;
    --theme-listDropdown-header-inputContainer-padding: 10px;
    --theme-listDropdown-header-inputContainer-minHeight: 54px;

    --theme-listDropdown-listContainer-backgroundColor: var(--app-color-white);

    --theme-listDropdown-item-color: var(--app-color-dark);
    --theme-listDropdown-item-backgroundColor: transparent;

    --theme-listDropdown-item-selected-backgroundColor: var(--app-color-grey-light);
    --theme-listDropdown-item-highlighted-backgroundColor: var(--app-color-grey-light);
    --theme-listDropdown-item-selected-color: var(--theme-color-primary);
  }
  &.-theme-droppah {
    --theme-listDropdown-header-inputContainer-minHewight: 10px;
    --theme-listDropdown-header-inputContainer-padding: 10px;
    --theme-listDropdown-header-inputContainer-minHeight: 54px;

    --theme-listDropdown-listContainer-backgroundColor: var(--droppah-color-background-light);

    --theme-listDropdown-item-color: var(--app-color-white);
    --theme-listDropdown-item-backgroundColor: var(--app-color-black);

    --theme-listDropdown-item-selected-backgroundColor: var(--theme-color-primary);
    --theme-listDropdown-item-highlighted-backgroundColor: var(--app-color-grey-light);
    --theme-listDropdown-item-selected-color: var(--app-color-white);

    .ldd-listContainer {
      .ldd-listContainerInner {
        .ldd-list {
          padding: 0;
        }
        .ldd-divider {
          border-bottom: none;
          margin: 8px 0;
        }
        .ldd-item {
          min-height: var(--droppah-losenge-height);
          border-radius: var(--app-border-radius);

          & + .ldd-item {
            margin-top: 6px;
          }

          &.-itemDisabled {
            .itemName {
              color: var(--app-color-grey-very-dark) !important;
            }
          }
        }
        @include mixins.is-mobile {
          .ldd-item {
            &:hover,
            &:active,
            &:focus {
              background-color: var(--app-color-black);
            }
          }
        }
        @include mixins.is-desktop {
          .ldd-item {
            &:hover {
              background-color: var(--theme-color-primary);
            }
          }
        }
      }
    }
  }
}

@use '../mixins.scss' as mixins;

@mixin button-floatOnMobile {
  @include mixins.is-mobile {
    --button-height: 44px !important;
    margin: 0 !important;
    position: fixed;
    bottom: calc(15px + var(--app-navMenu-footerHeight) + env(safe-area-inset-bottom));
    border-radius: var(--app-border-radius);
    right: 20px;
    z-index: 1050;
  }
}

@mixin button-floatLeftOnMobile {
  @include button-floatOnMobile;
  @include mixins.is-mobile {
    margin: 0 !important;
    right: auto;
    left: 20px;
  }
}

@mixin button-type-default {
  width: 100%;
}

@mixin button-type-icon {
  width: var(--button-height) !important;
  min-width: var(--button-height);
  padding: 0;

  > i {
    margin: 0 !important;
  }
}

@mixin button-type-icon-round {
  @include button-type-icon;
  border-radius: var(--button-height) !important;
}

@mixin button-type-text {
  padding: 0;
  min-width: 0;
  text-align: left;
  justify-content: flex-start;
}

@mixin button-type-dropdown {
  --button-iconSize: 1.3rem;
  min-width: 120px;
  justify-content: space-between !important;
  padding: 0 10px;

  > span {
    text-align: left;
  }
  > i,
  > .button-animatedIcon > i {
    min-width: 12px;
  }
  > i,
  > .button-animatedIcon {
    margin-left: auto;

    &:not(:last-child) {
      margin-right: 8px;
    }
    &.-alignLeft {
      margin-left: 0;
    }
  }
  &:disabled {
    > i,
    > .button-animatedIcon > i {
      display: none;
    }
  }
}

@mixin button-type-dropdown-round {
  @include button-type-dropdown;
  border-radius: var(--button-height) !important;
  > span{
    padding-left: 10px;
  }
}

@mixin button-type-round {
  @include button-type-default;
  min-height: var(--droppah-losenge-height-small);
  border-radius: var(--button-height) !important;
}

@mixin button-type-colourpicker {
  width: var(--button-height);
  min-width: var(--button-height);
  padding: 5px;

  > .buttonColor {
    width: 100%;
    height: calc(var(--button-height) - 10px - 2px);
    border-radius: var(--app-border-radius);
  }
}

body {
  button,
  a {
    &.-floatOnMobile {
      @include button-floatOnMobile;
    }
    &.-floatLeftOnMobile {
      @include button-floatLeftOnMobile;
    }
    &.-type-default {
      @include button-type-default;
    }
    &.-type-icon {
      @include button-type-icon;
    }
    &.-type-icon-round {
      @include button-type-icon-round;
    }
    &.-type-text {
      @include button-type-text;
    }
    &.-type-dropdown {
      @include button-type-dropdown;
    }
    &.-type-colourpicker {
      @include button-type-colourpicker;
    }
    &.-type-dropdown-round {
      @include button-type-dropdown-round;
    }
    &.-type-round {
      @include button-type-round;
    }
  }
}

/* reduce editor padding */
.rx-editor {
    padding: 6px 12px;
}

/* Set Font Sizes */
.rx-content,
.rx-content p {
    font-size: 12px;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Tahoma, sans-serif;
}

/* Set container borders */
.rx-container {
    position: relative;
    width: 100%;
}

.rx-container-invoice-body,
.rx-container-invoice-line {
    line-height: initial;
    border: 1px solid #fff;
    transition: border 0.3s;
    border-radius: 2px;
}

.rx-container-invoice-body:hover,
.rx-container-invoice-line:hover {
    border: 1px solid #eee;
}

.rx-in-focus.rx-container-invoice-body,
.rx-in-focus.rx-container-invoice-line {
    border: 1px solid #ccc;
}

.rx-container-invoice-body td,
.rx-container-invoice-body th {
    border: 1px dashed #ddd;
}

/* Set invoice line sizing */
.rx-container-invoice-line {
    margin: 5px 0;
}

.rx-container-invoice-line p {
    font-size: 12px;
}

.rx-container-invoice-line .rx-variable {
    position: relative;
    top: -1px;
}

/* Floating toolbar */
.rx-editor-disabled {
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    z-index: 1;
    background-color: none;
    top: 0;
    left: 0;
}

.rx-toolbar-container {
    margin-top: -50px;
    border: 1px solid rgba(0,11,29,.1);
    border-radius: 2px;
}

.rx-in-blur .rx-toolbar-container {
    display: none;
}

.rx-in-focus .rx-toolbar-container {
    display: flex;
}

.rx-in-focus .rx-editor-container {
    margin-top: 8px;
}

/* SVG Stroke */
.rx-button-toolbar svg.toolbar-svg-stroke {
    stroke: rgba(0,11,29,.8);
}

.rx-button-toolbar:hover svg.toolbar-svg-stroke,
.rx-button-toolbar.toggled svg.toolbar-svg-stroke {
    stroke: #000b1d;
}

/* Variable placeholder styling */
.rx-variable {
    font-family: SFMono-Regular, Consolas, "Liberation Mono", Menlo, Courier, monospace;
    background-color: rgba(178, 204, 253, 0.4);
    display: inline-block;
    border-radius: 16px;
    font-size: 0.85em;
    color: #000B1D;
    cursor: pointer;
    line-height: 1;
    min-height: initial;
    padding: 4px 12px;
    white-space: nowrap;
    text-transform: none;
    font-weight: normal;
    user-select: none;
}
  
.rx-variable:hover,
.rx-variable.rx-block-focus {
    background-color: #B2CCFD;
    outline: none !important;
}

/* Text alignment */
.text-left,
td.text-left {
    text-align: left;
}

.text-center,
td.text-center {
    text-align: center;
}

.text-right,
td.text-right {
    text-align: right;
}

.text-justify,
td.text-justify {
    text-align: justify;
}
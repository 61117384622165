@use './mixins.scss' as mixins;

.app-invoice-container {
  display: block;
  position: relative;
  width: 100%;
  padding: 50px;
  border-radius: var(--app-border-radius);
  box-shadow: var(--app-box-shadow-dark);
  min-height: 100px;
  background-color: var(--app-color-white);
  max-width: 1000px;
  margin: 0 auto;

  @include mixins.is-mobile {
    padding: 25px 0;
    border-radius: 0;

    .app-invoice-header,
    .app-invoice-subHeader,
    .app-invoice-footer {
      padding: 0 10px;
    }
  }

  .app-invoice-header {
    width: 100%;
    min-height: 60px;
    margin-bottom: 5px;
  }
  .app-invoice-subHeader {
    width: 100%;
    min-height: 120px;
    margin-bottom: 20px;
  }
  .app-invoice-content {
    .app-invoice-contentHeader > .-headerCol,
    .app-invoice-contentLines > .app-invoice-contentLine > .app-invoice-lineRow > .app-invoice-lineCol {
      &.-description {
        flex: 1 1 auto;
        width: 40%;
      }
      &.-quantity,
      &.-rate {
        flex: 0 0 auto;
        padding-left: 15px;
        width: 110px;
        justify-content: flex-end;
      }
      &.-amount {
        flex: 0 0 auto;
        padding-left: 15px;
        width: 120px;
        justify-content: flex-end;
      }
    }

    .app-invoice-contentHeader {
      width: 100%;
      display: flex;
      align-items: center;
      height: 40px;
      border-bottom: 2px solid var(--app-color-dark);

      .-headerCol {
        font-weight: var(--app-font-weight-normal);
        flex: 0 0 auto;
        display: flex;
        align-items: center;
      }
    }
    .app-invoice-contentLines {
      width: 100%;

      .app-invoice-contentLine {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 70px;

        &:not(.-subtotalLine) {
          border-bottom: var(--app-border);
        }

        &:hover {
          .app-invoice-lineRow {
            &.-projectSelect {
              height: var(--invoice-lineRow-projectSelect-height-open);
            }
          }
        }

        .app-invoice-lineIcon {
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          position: relative;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);

          > i {
            font-size: 18px;
            color: var(--app-color-grey-very-dark);
          }

          & ~ .app-invoice-lineRow {
            padding-left: 20px;
          }
        }

        .app-invoice-lineRow {
          display: flex;
          align-items: center;

          &.-projectSelect {
            --invoice-lineRow-projectSelect-height-open: 24px;

            height: 0px;
            overflow: hidden;
            transition: height var(--app-transition);
            font-size: var(--app-font-size-small);

            &.-lockedOpen {
              height: var(--invoice-lineRow-projectSelect-height-open) !important;
            }
            &.-lockedClosed {
              height: 0px !important;
            }

            .app-invoice-lineCol {
              min-height: var(--invoice-lineRow-projectSelect-height-open);
              margin-bottom: 5px;

              list-drop-down {
                --listDropDown-button-size: var(--invoice-lineRow-projectSelect-height-open);
                --listDropDown-button-iconSize: 14px;

                .button-animatedIcon {
                  margin-right: 3px;
                }
              }
              .app-invoice-line-selectedProject {
                height: var(--invoice-lineRow-projectSelect-height-open);
                display: flex;
                align-items: center;
              }
            }
          }

          .app-invoice-lineCol {
            flex: 0 0 auto;
            min-height: 40px;
            display: flex;
            align-items: center;

            &.-description {
              .app-formItem-inputContainer {
                width: 100%;
                height: auto;

                .rx-container-invoice-line {
                  min-height: 34px;
                  margin: 0;
                  border: none !important;

                  .rx-editor {
                    padding-left: 0;
                    padding-right: 0;

                    > p:not(:first-child) {
                      margin-top: 5px;
                    }
                  }
                }
              }
            }
            &.-quantity,
            &.-rate,
            &.-amount {
              .app-formItem-inputContainer {
                width: 100%;

                input {
                  text-align: right;
                }
                .app-invoice-lineCol-value {
                  margin-left: auto;
                }
              }
            }

            textarea {
              height: var(--app-input-height);
              width: 100%;
            }
            .app-invoice-lineCol-icon {
              min-width: 20px;
              font-size: var(--app-font-size-larger);
            }

            .app-invoice-lineCol-inputContainer {
              height: 100%;
              display: flex;
              align-items: center;

              > .input-group,
              > input {
                width: 120px;
              }
            }
            .-label {
              font-weight: var(--app-font-weight-normal);
              margin-right: 10px;
              flex: 0 0 auto;
            }
          }

          > .app-remove {
            position: absolute;
            right: -28px;
            top: 50%;
            transform: translateY(-50%);
          }

          .app-invoice-subtotal-header {
            width: 100%;
            padding: 15px 0;
            display: flex;
            align-items: center;
            border-bottom: 1px solid var(--app-color-dark);

            .app-invoice-subtotal-headerCol {
              display: flex;
              align-items: center;
              height: 40px;
              width: 50%;

              &:last-child {
                justify-content: flex-end;
              }

              textarea {
                width: 100%;
              }
              .-label {
                flex: 0 0 auto;
                font-weight: var(--app-font-weight-normal);
                margin-right: 10px;
              }
              .-filterDropdown,
              .-filterInput {
                flex: 0 0 180px;

                &:not(:last-child) {
                  margin-right: 10px;
                }
              }
            }
          }

          .app-invoice-subtotal-contentLines {
            padding-left: 20px;
          }
          .app-invoice-subtotal-footer {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            .app-invoice-subtotal-footer-left {
              display: flex;
              align-items: center;
              margin-right: auto;
              flex: 0 0 60%;
              padding: 10px 0 10px 20px;
              height: 54px;

              list-drop-down {
                --listDropdown-button-minWidth: 50px;
              }
            }
            .app-invoice-subtotal-footer-right {
              display: flex;
              align-items: center;
              margin-left: auto;
              flex: 0 0 40%;
              justify-content: flex-end;
              padding: 10px 0;
              border-bottom: 1px solid var(--app-color-dark);
              height: 55px;
              font-weight: var(--app-font-weight-normal);

              .app-invoice-subtotal-footer-subtotalValue {
                flex: 0 0 120px;
                text-align: end;
              }
            }

            .app-remove {
              margin-left: 8px;
            }
          }
        }
        .app-formItem-errorLabel {
          text-align: end;
        }
      }
    }
    .app-invoice-contentTotals {
      display: flex;
      margin-bottom: 10px;

      .app-invoice-contentTotalsLeft {
        display: flex;
        margin-right: auto;
        flex: 0 0 60%;
        padding-top: 15px;

        @include mixins.is-mobile {
          flex: 0 0 30%;
          padding-left: 10px;
        }

        list-drop-down {
          --listDropdown-button-minWidth: 50px;
        }
      }
      .app-invoice-contentTotalsRight {
        display: flex;
        margin-left: auto;
        flex: 0 0 40%;
        flex-direction: column;

        @include mixins.is-mobile {
          flex: 0 0 70%;
          padding-right: 20px;
        }

        .-contentTotals-subtotal,
        .-contentTotals-tax,
        .-contentTotals-total {
          width: 100%;
          display: flex;
          align-items: center;
          font-weight: var(--app-font-weight-normal);

          > * {
            flex: 0 0 50%;
            text-align: end;
          }
        }

        .-contentTotals-subtotal,
        .-contentTotals-tax {
          height: 50px;

          @include mixins.is-mobile {
            height: 40px;
          }

          &:not(:last-child):first-child {
            margin-top: 10px;
          }
        }
        .-contentTotals-total {
          font-size: var(--app-font-size-larger);
          height: 80px;

          @include mixins.is-mobile {
            height: 60px;
          }

          &:not(:first-child) {
            border-top: 2px solid var(--app-color-dark);
            margin-top: 10px;
          }
        }
      }
    }
  }
  .app-invoice-footer {
    margin-bottom: 30px;
  }
  .app-invoice-addTimesheets {
    height: 80px;
    width: 100%;
    display: flex;
    border: var(--app-border);
    border-radius: var(--app-border-radius);

    .app-invoice-addTimesheets-icon {
      flex: 0 0 40px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      color: var(--app-color-grey-dark);
    }
    .app-invoice-addTimesheets-checkbox {
      flex: 1 1 auto;
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 30px;
      border-left: var(--app-border);
      background-color: var(--app-color-grey-very-light);
    }
  }
}

body {
  &.-theme-invoxy {
  }
  &.-theme-karmly-light,
  &.-theme-karmly-dark {
  }
}

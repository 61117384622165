@use '../../../styles/mixins.scss' as mixins;

:host {
  --megaSearch-height: 34px;
  height: var(--megaSearch-height);
  width: 260px;
  position: relative;
  display: block;
  flex: 1 1 auto;

  .megaSearch-container {
    width: 100%;
    height: 100%;
    border-radius: var(--app-border-radius);

    .megaSearch-inputContainer {
      --app-formItem-height: var(--megaSearch-height);
    }
  }

  @include mixins.is-mobile {
    --megaSearch-height: 44px;
    min-width: 0;
    width: auto;
  }
}

.megaSearch-inputContainer {
  @include mixins.is-mobile {
    --app-formItem-height: 44px;
  }

  app-search {
    flex: 1 1 auto;
  }

  .megaSearch-selectedSearchPropertyCount {
    flex: 0 0 auto;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    background-color: var(--theme-color-success);
    color: var(--app-color-white);
    font-size: var(--app-font-size);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
  }
}

.megaSearch-dropdown {
  background-color: var(--app-color-white);
  min-width: 260px;
  max-width: 600px;
  padding: 10px 0;
  overflow-y: auto;

  @include mixins.is-desktop {
    position: relative;
  }
  @include mixins.is-mobile {
    min-width: 150px;
  }

  // ngb class
  &.show {
    display: flex;
    flex-direction: column;
  }

  .megaSearch-dropdown-contents {
    .megaSearch-dropdown-header {
      padding: 0 15px;
      width: 100%;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--app-color-grey-dark);
      font-size: var(--app-font-size);
      margin-bottom: 5px;

      > button {
        --button-fontSize: 0.9rem;
      }
    }
    .megaSearch-dropdown-empty {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: var(--app-font-size);
      color: var(--app-color-grey);
    }
    .megaSearch-dropdown-spacer {
      margin: 5px 0;
      width: 100%;
      height: 1px;
      border-top: var(--app-border);
    }

    .megaSearch-dropdown-properties {
      width: 100%;
      display: flex;
      flex-direction: column;

      .megaSearch-dropdown-property {
        height: 30px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
        font-size: var(--app-font-size);

        .-itemValue {
          flex: 1 1 100%;
        }
        .-propertyLabel {
          height: 20px;
          line-height: 20px;
          flex: 0 0 auto;
          max-width: 50%;
          text-align: center;
          padding: 0 10px;
          border-radius: 10px;
          background-color: var(--app-color-grey-light);
          margin-left: 5px;
        }
        > div {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        > i.app-remove {
          --appRemove-size: 20px;
          flex: 0 0 auto;
          margin-left: 3px;
        }
      }

      &.-filtered {
        .megaSearch-dropdown-property {
          cursor: pointer;

          &:hover {
            background-color: var(--app-color-grey-very-light);
          }
          &.-highlighted {
            background-color: var(--theme-color-link-translucent);

            .-propertyLabel {
              background-color: var(--app-color-white);
            }
          }
        }
      }
      &.-selected {
        .megaSearch-dropdown-property {
          .-clearSelectedProperty {
            flex: 0 0 auto;
            width: 20px;
            height: 20px;
            border-radius: 10px;
            margin-left: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--app-color-white);
            cursor: pointer;
            transition: background-color var(--app-transition);

            &:hover {
              background-color: var(--app-color-grey-light);
            }
          }
        }
      }
    }
  }

  @include mixins.is-mobile {
    .megaSearch-inputContainer {
      padding-bottom: 10px;
    }
    .megaSearch-dropdown-contents {
      overflow-y: auto;
      padding-top: 5px;

      .megaSearch-dropdown-properties {
        .megaSearch-dropdown-property {
          height: 40px;
        }
      }
    }
  }
}

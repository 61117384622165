body {
  button,
  a {
    &.-size-btn-lg {
      --button-height: 40px !important;
    }
    &.-size-btn-sm {
      --button-height: 24px !important;
    }
    &.-size-icon-lg {
      --button-iconSize: 34px !important;
    }
    &.-size-icon-sm {
      --button-iconSize: 16px !important;
    }
    &.-size-text-lg {
      --button-fontSize: 22px !important;
    }
    &.-size-text-sm {
      --button-fontSize: 0.9rem !important;
    }
  }
}

@use './mixins.scss' as mixins;

.app-reportTile-container {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.app-reportTile {
  min-width: 240px;
  height: 200px;
  flex: 0 1 auto;
  width: calc((100% / 5) - 20px);
  padding: 30px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  border: var(--app-border-light);
  border-radius: var(--app-border-radius);
  cursor: pointer;
  background-color: var(--app-color-white);
  transition: background-color var(--app-transition);
  box-shadow: var(--app-box-shadow);

  &:hover {
    background-color: var(--app-color-grey-very-light);
  }
  &.-smallTile {
    min-width: 170px;
    height: 160px;
    padding: 20px 10px;

    report-thumbnail {
      --reportThumbnail-size: 50px;
    }
  }

  report-thumbnail {
    margin-bottom: 25px;
  }
  .app-reportTile-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;

    .app-reportTile-details-title {
      @include mixins.overflowEllipsis;
      max-width: 100%;
    }
    .app-reportTile-details-type {
      @include mixins.overflowEllipsis;
      max-width: 100%;
      color: var(--app-color-grey-dark);
      text-transform: capitalize;
    }
  }
}

@include mixins.is-mobile {
  .app-reportTile-container {
    padding-left: 10px;
  }
  .app-reportTile {
    height: 180px;
    width: calc(50% - 10px);
    min-width: calc(50% - 10px);
    margin: 0 10px 10px 0;
    padding: 20px 15px;

    &:not(:last-child) {
      border-bottom: var(--app-border);
    }
  }
}

@use '../styles/mixins.scss' as mixins;

.modal-dialog {
  @media (max-width: 900px) {
    max-width: calc(100% - 12px) !important;
  }

  &:not(.modal-mobile-sm) {
    .app-modal-container {
      @include mixins.is-mobile {
        max-height: 100vh;
        height: 100vh;
      }
    }
  }
}

.app-modal-container {
  position: relative;
  background-color: var(--app-color-white);
  border-radius: var(--app-border-radius);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: calc(100vh - 50px);
  width: 100%;

  .dp-modal-close {
    position: absolute;
    right: 30px;
    top: 10px;
    padding: 10px;
  }
  .app-navHeader {
    padding: 10px 20px 0 20px;
  }
  .app-modal-close {
    position: absolute;
    top: 10px;
    right: 14px;
    z-index: 10;

    i {
      font-size: 2.5rem;
    }
  }
  .app-modal-headerIcon {
    font-size: 50px;
    color: var(--app-color-grey);
  }

  .app-modal-content {
    background-color: var(--theme-modal-content-background);
    width: 100%;
    font-size: var(--app-font-size-normal);
    padding: 20px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow-y: var(--app-overflow-overlay);

    &.-no-padding {
      padding: 0;
    }
    &.-contentGrey {
      --theme-modal-content-background: var(--app-color-grey-very-light);
      --theme-modal-contentInner-background: transparent;
    }

    .app-modal-contentInner {
      background-color: var(--theme-modal-contentInner-background);
      border: var(--theme-modal-contentInner-border);
      width: 100%;
      border-radius: var(--app-border-radius);
      padding: var(--theme-modal-contentInner-padding);
      display: flex;
      flex-direction: column;

      &.-contentCentered {
        align-items: center;
      }
      &.-mobile-padding {
        padding-left: 20px;
        padding-right: 20px;
      }

      > .app-edit-content {
        border: none;
      }
      > .app-list-empty {
        border: none;
      }

      .app-buttonRow {
        @include mixins.is-mobile {
          padding: 10px;
        }
      }
    }

    .app-modal-contentInfo {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: auto;
      text-align: center;
      width: 100%;

      > div:not(:first-child) {
        margin-top: 20px;
      }
    }

    .app-edit-content {
      width: 100%;
      padding: 0;
      margin: 0;
    }

    .app-modal-notes {
      padding: 15px 10px 10px 10px;
      width: 100%;

      .app-modal-notesHeader {
        cursor: pointer;
        color: var(--theme-color-link);
        user-select: none;

        &.-disabled {
          color: var(--app-color-grey-dark);
          font-weight: var(--app-font-weight-normal);
        }
      }
      .app-modal-notesHeader-km {
        color: var(--app-color-grey-very-dark);
      }
      .app-modal-textarea {
        min-height: 0;
        height: 0;
        padding: 0 10px;
        border: 1px solid transparent;

        &.-notesOpen {
          height: 120px;
          padding: 10px;
          border: var(--app-border);
          margin-top: 7px;
          background-color: var(--app-color-white);
        }
        &:focus {
          box-shadow: var(--theme-input-focus-boxShadow);
          border: var(--theme-input-focus-border);
        }
      }
    }
    .app-modal-textarea {
      @include mixins.verticalScrollBar;
      width: 100%;
      padding: 10px;
      margin-top: 0;
      max-width: 100%;
      max-height: 300px;
      border-radius: var(--app-border-radius);
      transition: padding var(--app-transition), margin-top var(--app-transition), background-color var(--app-transition);
      background-color: transparent;
    }
  }

  .app-modal-btnFooter {
    background-color: var(--theme-modal-content-background);
    border-top: var(--theme-modal-btnFooter-border);
    border-radius: 0 0 5px 5px;
    width: 100%;
    padding: 20px;
    padding-bottom: calc(20px + env(safe-area-inset-bottom));
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;

    .app-modal-btnFooterCenter {
      margin: 0 auto;

      > :not(:first-child) {
        margin-left: 10px;
      }
    }
    .app-modal-btnFooterLeft {
      margin-right: auto;

      > * {
        margin-right: 10px;
      }
    }
    .app-modal-btnFooterRight {
      margin-left: auto;

      > * {
        margin-left: 10px;
      }
    }
    .app-modal-btnFooterCenter,
    .app-modal-btnFooterRight,
    .app-modal-btnFooterLeft {
      position: relative;
      display: flex;
      align-items: center;

      app-spinner {
        position: relative;
        top: auto;
        transform: none;
        left: auto;
      }
    }
  }

  .ph-errorMessage {
    border-radius: 0;
    margin-bottom: 0;
  }

  @include mixins.is-mobile {
    height: 100%;
    border-radius: 0;

    .app-modal-content {
      padding: 20px 10px;

      .app-modal-contentInner {
        // padding: 20px 10px;
        border-radius: 0;
      }
    }
  }
}

body {
  &.-theme-invoxy {
    --theme-modal-header-background: var(--app-color-grey-light);
    --theme-modal-header-borderBottom: none;

    --theme-modal-content-background: var(--app-color-white);

    --theme-modal-contentInner-background: var(--app-color-grey-very-light);
    --theme-modal-contentInner-border: var(--app-border-light);
    --theme-modal-contentInner-padding: 20px;

    --theme-modal-btnFooter-border: none;

    @include mixins.is-mobile {
      --theme-modal-contentInner-padding: 20px 10px;
    }
  }
  &.-theme-karmly-light,
  &.-theme-karmly-dark,
  &.-theme-flexitime {
    --theme-modal-header-background: var(--app-color-white);
    --theme-modal-header-borderBottom: var(--app-border);

    --theme-modal-content-background: var(--app-color-white);

    --theme-modal-contentInner-background: var(--app-color-white);
    --theme-modal-contentInner-border: none;
    --theme-modal-contentInner-padding: 0;

    --theme-modal-btnFooter-border: var(--app-border);

    .app-modal-content {
      .app-formItem-container {
        margin-bottom: 0;
        padding: 0;
        border: none;
        border-radius: 0;
      }
    }
  }
  &.-theme-droppah {
    --theme-modal-contentInner-padding: var(--droppah-border-width);

    --theme-modal-content-background: transparent;

    --theme-modal-contentInner-background: transparent;

    .app-modal-container {
      .app-modal-content {
        padding: 0;
      }
    }
  }
}

$font-path: "/assets/fonts";

@font-face {
  font-family: "Work Sans";
  src: url($font-path + "/Work_Sans/WorkSans-Light.ttf");
  font-weight: 200;
}

@font-face {
  font-family: "Work Sans";
  src: url($font-path + "/Work_Sans/WorkSans-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Work Sans";
  src: url($font-path + "/Work_Sans/WorkSans-SemiBold.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Work Sans";
  src: url($font-path + "/Work_Sans/WorkSans-Bold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Work Sans";
  src: url($font-path + "/Work_Sans/WorkSans-ExtraBold.ttf");
  font-weight: 800;
}

* {
  -webkit-font-smoothing: antialiased !important;
  font-family: "Work Sans", sans-serif !important;
}

.app-payrollActionBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  height: 67px;
  padding: 15px;
  border-radius: 2px;
  transition: all 0.25s;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.-changes {
    background-color: var(--app-color-yellow-translucent);
    border: solid 1px var(--app-color-yellow);
  }

  &.-noChanges {
    background-color: var(--app-color-payhero-translucent);
    border: solid 1px var(--app-color-payhero);
  }

  > div {
    margin: 0;
    width: 100%;
    text-align: center;
  }
}

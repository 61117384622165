@use 'styles/bootstrapOverrides.scss';

@use 'styles/mixins.scss';
@use 'styles/variables.scss';
@use 'styles/theme.scss';
@use 'styles/classModifiers.scss';
@use 'styles/globalComponentClasses.scss';

@use 'styles/button/button-mixins.scss';
@use 'styles/button/button-colors.scss';
@use 'styles/button/button-types.scss';
@use 'styles/button/button-sizes.scss';
@use 'styles/button/button.scss';

@use 'styles/app-nav.scss';
@use 'styles/app-summaryItem.scss';
@use 'styles/app-remove.scss';
@use 'styles/app-tabs.scss';
@use 'styles/app-edit.scss';
@use 'styles/app-label.scss';
@use 'styles/app-modal.scss';
@use 'styles/app-payrollActionBar.scss';
@use 'styles/app-formItem.scss';
@use 'styles/app-approvalProject.scss';
@use 'styles/app-invoiceTemplate.scss';
@use 'styles/app-reportTile.scss';
@use 'styles/app-list.scss';
@use 'styles/app-dashBlock.scss';

// Components containing themed elements that're appended to body must also be imported here
@use 'lib/lib-components/list-drop-down/list-drop-down.component.scss';

// For debuging layout issues
// * {
//   background-color: rgba(70, 130, 180, 0.05) !important;
// }

html {
  height: 100%;
  width: 100%;
  font-size: var(--app-font-size-normal);
  font-weight: var(--app-font-weight-normal);
}
body {
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0;
  color: var(--app-color-dark);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--app-font-size-normal);
  font-weight: var(--app-font-weight-normal);

  *:focus {
    outline: none !important;
  }
}

:root {
  min-height: 100%;
  width: 100%;
  position: relative;
}

::-webkit-scrollbar {
  width: 7px;
  background: #f9f9f9;
}

::-webkit-scrollbar-thumb {
  background-color: #dddddd;
  border-radius: 0 !important;
}

p {
  margin: 0 0 0 0;
}

input {
  border: var(--app-border);
  color: var(--app-color-dark);
}
// Hide number input arrows
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
input::placeholder {
  color: var(--app-color-grey) !important;
}

.form-control {
  height: var(--app-formItem-height);
  color: var(--app-color-dark);
  font-weight: var(--app-font-weight-normal);
}
input,
textarea,
.form-control {
  &:disabled {
    background-color: var(--theme-color-input-disabled-background);
    color: var(--theme-color-input-disabled-color);
  }
}
input,
textarea,
.form-control {
  &[readonly] {
    background-color: var(--theme-color-input-readonly-background);
    color: var(--theme-color-input-readonly-color);
  }
}

b,
strong {
  font-weight: var(--app-font-weight-bold);
}

input,
select,
textarea {
  font-weight: var(--app-font-weight-normal);

  &:not(:disabled):not(&[readonly]):focus {
    border: var(--theme-input-focus-border);
    box-shadow: var(--theme-input-focus-boxShadow);
  }
}

input[type='search'] {
  &::-webkit-search-cancel-button {
    display: none;
  }
}

li {
  list-style-type: none;
}

div.-bold,
span.-bold {
  font-weight: var(--app-font-weight-bold);
}

div.-label,
span.-label {
  color: var(--app-color-grey-dark);
  font-weight: var(--app-font-weight-normal);
}
div.-labelError,
span.-labelError {
  color: var(--app-color-red);
  font-weight: var(--app-font-weight-normal);
}
div.-highlighted,
span.-highlighted {
  color: var(--theme-color-link);
  font-weight: var(--app-font-weight-normal);
}
div.-capitalise,
span.-capitalise {
  text-transform: capitalize;
}

textarea,
textarea.form-control {
  width: 100%;
  min-height: 130px;
  max-height: 200px;
  padding: 10px 12px;
  background-color: transparent;
  border: var(--app-border);
  border-radius: 5px;
  transition: all 0.25s;

  &:disabled {
    border: var(--app-border-light);
  }
}

.app-splash-logo {
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.app-warningPanel {
  background-color: var(--app-color-warning-background);
  border: 1px solid var(--app-color-warning-border);
  border-radius: var(--app-border-radius);
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .app-warningPanel-row {
    display: flex;
    align-items: center;
    text-align: center;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    > span {
      display: contents;
    }
  }
}

.app-footer {
  display: block;
  transition: all 0.25s;
  margin: 10px auto 30px auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  .app-footer-fromFlexitime {
    width: 90%;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    &.-cursor {
      cursor: pointer;
    }
    > img {
      max-width: 130px;
      margin: 0 7px;
    }
  }
}

.app-animatedTick {
  --animatedTick-size: 60px;
  width: var(--animatedTick-size);
  height: var(--animatedTick-size);

  img {
    max-width: var(--animatedTick-size);
    max-height: var(--animatedTick-size);
  }
}

.app-buttonRow {
  min-height: 54px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  position: relative;

  > .app-buttonRow-left {
    display: flex;
    align-items: center;
    position: relative;
    margin-right: auto;
    max-width: 100%;

    > :not(:first-child) {
      margin-left: 8px;
    }
    > * {
      max-width: 100%;
    }
  }
  > .app-buttonRow-right {
    display: flex;
    align-items: center;
    position: relative;
    margin-left: auto;
    max-width: 100%;

    > :not(:last-child) {
      margin-right: 8px;
    }
    > * {
      max-width: 100%;
    }
  }
  .app-buttonRow-title {
    font-size: var(--app-font-size-large);
    font-weight: var(--app-font-weight-normal);
  }
}

.app-invalidInput {
  border-color: var(--app-color-red);
}

// Google Address Autocomplete Dropdown
.pac-container {
  z-index: 1100;
}

// Slim image uploader
.slim-popover {
  button {
    min-width: 0 !important;
  }
  .slim-editor-utils-group {
    display: flex !important;
    justify-content: center !important;
  }
  .slim-editor-btn-group {
    .slim-editor-btn {
      height: auto !important;
    }
  }
}

// Redactor

.rx-toolbar,
.rx-topbar {
  .rx-button-icon {
    color: var(--theme-color-link);

    > svg {
      fill: var(--theme-color-link) !important;
      stroke: var(--theme-color-link) !important;
    }
  }
}
.rx-toolbar-container {
  box-shadow: var(--app-box-shadow);
  z-index: 200 !important;
}

.rx-variable {
  background-color: var(--app-color-karmly-translucent) !important;
}
.rx-popup {
  z-index: 1100 !important;
}

body button.-color-karmly,
body a.-color-karmly {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-karmly) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-karmly:active,
body a.-color-karmly:active {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-karmly) !important;
}
body button.-color-karmly:hover, body button.-color-karmly:focus,
body a.-color-karmly:hover,
body a.-color-karmly:focus {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-karmly) !important;
}
body button.-color-karmly:disabled,
body a.-color-karmly:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-karmly:disabled > i,
body a.-color-karmly:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-karmly.-readonly,
body a.-color-karmly.-readonly {
  pointer-events: none !important;
}
body button.-color-karmly-clear,
body a.-color-karmly-clear {
  color: var(--app-color-karmly) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-karmly-clear:active, body button.-color-karmly-clear:focus,
body a.-color-karmly-clear:active,
body a.-color-karmly-clear:focus {
  color: var(--app-color-karmly) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-karmly-clear:hover,
body a.-color-karmly-clear:hover {
  color: var(--app-color-karmly-dark) !important;
}
body button.-color-karmly-clear:disabled,
body a.-color-karmly-clear:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-karmly-clear:disabled > i,
body a.-color-karmly-clear:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-karmly-clear.-readonly,
body a.-color-karmly-clear.-readonly {
  pointer-events: none !important;
}
body button.-color-karmly-clear:disabled,
body a.-color-karmly-clear:disabled {
  background-color: transparent !important;
}
body button.-color-karmly-clear-grey-border,
body a.-color-karmly-clear-grey-border {
  color: var(--app-color-karmly) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
  border: var(--app-border);
  background-color: var(--app-color-white) !important;
}
body button.-color-karmly-clear-grey-border:active, body button.-color-karmly-clear-grey-border:focus,
body a.-color-karmly-clear-grey-border:active,
body a.-color-karmly-clear-grey-border:focus {
  color: var(--app-color-karmly) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-karmly-clear-grey-border:hover,
body a.-color-karmly-clear-grey-border:hover {
  color: var(--app-color-karmly-dark) !important;
}
body button.-color-karmly-clear-grey-border:disabled,
body a.-color-karmly-clear-grey-border:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-karmly-clear-grey-border:disabled > i,
body a.-color-karmly-clear-grey-border:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-karmly-clear-grey-border.-readonly,
body a.-color-karmly-clear-grey-border.-readonly {
  pointer-events: none !important;
}
body button.-color-karmly-clear-grey-border:active,
body a.-color-karmly-clear-grey-border:active {
  background-color: var(--app-color-grey-very-light) !important;
}
body button.-color-karmly-clear-grey-border:hover,
body a.-color-karmly-clear-grey-border:hover {
  background-color: var(--app-color-grey-very-light) !important;
}
body button.-color-karmly-hoverFill,
body a.-color-karmly-hoverFill {
  color: var(--app-color-karmly) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-karmly-hoverFill:active, body button.-color-karmly-hoverFill:focus,
body a.-color-karmly-hoverFill:active,
body a.-color-karmly-hoverFill:focus {
  color: var(--app-color-karmly) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-karmly-hoverFill:hover,
body a.-color-karmly-hoverFill:hover {
  color: var(--app-color-karmly) !important;
  background-color: var(--app-color-karmly-translucent) !important;
}
body button.-color-karmly-hoverFill:disabled,
body a.-color-karmly-hoverFill:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-karmly-hoverFill:disabled > i,
body a.-color-karmly-hoverFill:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-karmly-hoverFill.-readonly,
body a.-color-karmly-hoverFill.-readonly {
  pointer-events: none !important;
}
body button.-color-karmly-translucent,
body a.-color-karmly-translucent {
  color: var(--app-color-karmly) !important;
  background-color: var(--app-color-karmly-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-karmly-translucent:active, body button.-color-karmly-translucent:focus,
body a.-color-karmly-translucent:active,
body a.-color-karmly-translucent:focus {
  color: var(--app-color-karmly) !important;
  background-color: var(--app-color-karmly-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-karmly-translucent:hover,
body a.-color-karmly-translucent:hover {
  color: var(--app-color-karmly) !important;
  background-color: var(--app-color-karmly-translucent-light) !important;
}
body button.-color-karmly-translucent:disabled,
body a.-color-karmly-translucent:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-karmly-translucent:disabled > i,
body a.-color-karmly-translucent:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-karmly-translucent.-readonly,
body a.-color-karmly-translucent.-readonly {
  pointer-events: none !important;
}
body button.-color-karmly-outline,
body a.-color-karmly-outline {
  color: var(--app-color-karmly) !important;
  background-color: var(--app-color-karmly-translucent) !important;
  border: 1px solid var(--app-color-karmly);
  font-weight: var(--app-font-weight-normal);
}
body button.-color-karmly-outline:active, body button.-color-karmly-outline:focus,
body a.-color-karmly-outline:active,
body a.-color-karmly-outline:focus {
  color: var(--app-color-karmly) !important;
  background-color: var(--app-color-karmly-translucent) !important;
  border: 1px solid var(--app-color-karmly);
}
body button.-color-karmly-outline:hover,
body a.-color-karmly-outline:hover {
  color: var(--app-color-karmly) !important;
  background-color: var(--app-color-karmly-translucent-light) !important;
  border: 1px solid var(--app-color-karmly);
}
body button.-color-karmly-outline:disabled,
body a.-color-karmly-outline:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
  border: var(--app-border-light) !important;
}
body button.-color-karmly-outline:disabled > i,
body a.-color-karmly-outline:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-karmly-outline.-readonly,
body a.-color-karmly-outline.-readonly {
  pointer-events: none !important;
}
body button.-color-flexitime-yellow,
body a.-color-flexitime-yellow {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-yellow) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-flexitime-yellow:active,
body a.-color-flexitime-yellow:active {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-yellow) !important;
}
body button.-color-flexitime-yellow:hover, body button.-color-flexitime-yellow:focus,
body a.-color-flexitime-yellow:hover,
body a.-color-flexitime-yellow:focus {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-yellow) !important;
}
body button.-color-flexitime-yellow:disabled,
body a.-color-flexitime-yellow:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-flexitime-yellow:disabled > i,
body a.-color-flexitime-yellow:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-flexitime-yellow.-readonly,
body a.-color-flexitime-yellow.-readonly {
  pointer-events: none !important;
}
body button.-color-flexitime-yellow-clear,
body a.-color-flexitime-yellow-clear {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-flexitime-yellow-clear:active, body button.-color-flexitime-yellow-clear:focus,
body a.-color-flexitime-yellow-clear:active,
body a.-color-flexitime-yellow-clear:focus {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-flexitime-yellow-clear:hover,
body a.-color-flexitime-yellow-clear:hover {
  color: var(--app-color-flexitime-yellow-dark) !important;
}
body button.-color-flexitime-yellow-clear:disabled,
body a.-color-flexitime-yellow-clear:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-flexitime-yellow-clear:disabled > i,
body a.-color-flexitime-yellow-clear:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-flexitime-yellow-clear.-readonly,
body a.-color-flexitime-yellow-clear.-readonly {
  pointer-events: none !important;
}
body button.-color-flexitime-yellow-clear:disabled,
body a.-color-flexitime-yellow-clear:disabled {
  background-color: transparent !important;
}
body button.-color-flexitime-yellow-clear-grey-border,
body a.-color-flexitime-yellow-clear-grey-border {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
  border: var(--app-border);
  background-color: var(--app-color-white) !important;
}
body button.-color-flexitime-yellow-clear-grey-border:active, body button.-color-flexitime-yellow-clear-grey-border:focus,
body a.-color-flexitime-yellow-clear-grey-border:active,
body a.-color-flexitime-yellow-clear-grey-border:focus {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-flexitime-yellow-clear-grey-border:hover,
body a.-color-flexitime-yellow-clear-grey-border:hover {
  color: var(--app-color-flexitime-yellow-dark) !important;
}
body button.-color-flexitime-yellow-clear-grey-border:disabled,
body a.-color-flexitime-yellow-clear-grey-border:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-flexitime-yellow-clear-grey-border:disabled > i,
body a.-color-flexitime-yellow-clear-grey-border:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-flexitime-yellow-clear-grey-border.-readonly,
body a.-color-flexitime-yellow-clear-grey-border.-readonly {
  pointer-events: none !important;
}
body button.-color-flexitime-yellow-clear-grey-border:active,
body a.-color-flexitime-yellow-clear-grey-border:active {
  background-color: var(--app-color-grey-very-light) !important;
}
body button.-color-flexitime-yellow-clear-grey-border:hover,
body a.-color-flexitime-yellow-clear-grey-border:hover {
  background-color: var(--app-color-grey-very-light) !important;
}
body button.-color-flexitime-yellow-hoverFill,
body a.-color-flexitime-yellow-hoverFill {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-flexitime-yellow-hoverFill:active, body button.-color-flexitime-yellow-hoverFill:focus,
body a.-color-flexitime-yellow-hoverFill:active,
body a.-color-flexitime-yellow-hoverFill:focus {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-flexitime-yellow-hoverFill:hover,
body a.-color-flexitime-yellow-hoverFill:hover {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: var(--app-color-flexitime-yellow-translucent) !important;
}
body button.-color-flexitime-yellow-hoverFill:disabled,
body a.-color-flexitime-yellow-hoverFill:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-flexitime-yellow-hoverFill:disabled > i,
body a.-color-flexitime-yellow-hoverFill:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-flexitime-yellow-hoverFill.-readonly,
body a.-color-flexitime-yellow-hoverFill.-readonly {
  pointer-events: none !important;
}
body button.-color-flexitime-yellow-translucent,
body a.-color-flexitime-yellow-translucent {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: var(--app-color-flexitime-yellow-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-flexitime-yellow-translucent:active, body button.-color-flexitime-yellow-translucent:focus,
body a.-color-flexitime-yellow-translucent:active,
body a.-color-flexitime-yellow-translucent:focus {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: var(--app-color-flexitime-yellow-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-flexitime-yellow-translucent:hover,
body a.-color-flexitime-yellow-translucent:hover {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: var(--app-color-flexitime-yellow-translucent-light) !important;
}
body button.-color-flexitime-yellow-translucent:disabled,
body a.-color-flexitime-yellow-translucent:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-flexitime-yellow-translucent:disabled > i,
body a.-color-flexitime-yellow-translucent:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-flexitime-yellow-translucent.-readonly,
body a.-color-flexitime-yellow-translucent.-readonly {
  pointer-events: none !important;
}
body button.-color-flexitime-yellow-outline,
body a.-color-flexitime-yellow-outline {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: var(--app-color-flexitime-yellow-translucent) !important;
  border: 1px solid var(--app-color-flexitime-yellow);
  font-weight: var(--app-font-weight-normal);
}
body button.-color-flexitime-yellow-outline:active, body button.-color-flexitime-yellow-outline:focus,
body a.-color-flexitime-yellow-outline:active,
body a.-color-flexitime-yellow-outline:focus {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: var(--app-color-flexitime-yellow-translucent) !important;
  border: 1px solid var(--app-color-flexitime-yellow);
}
body button.-color-flexitime-yellow-outline:hover,
body a.-color-flexitime-yellow-outline:hover {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: var(--app-color-flexitime-yellow-translucent-light) !important;
  border: 1px solid var(--app-color-flexitime-yellow);
}
body button.-color-flexitime-yellow-outline:disabled,
body a.-color-flexitime-yellow-outline:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
  border: var(--app-border-light) !important;
}
body button.-color-flexitime-yellow-outline:disabled > i,
body a.-color-flexitime-yellow-outline:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-flexitime-yellow-outline.-readonly,
body a.-color-flexitime-yellow-outline.-readonly {
  pointer-events: none !important;
}
body button.-color-flexitime-blue,
body a.-color-flexitime-blue {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-flexitime-blue) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-flexitime-blue:active,
body a.-color-flexitime-blue:active {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-flexitime-blue) !important;
}
body button.-color-flexitime-blue:hover, body button.-color-flexitime-blue:focus,
body a.-color-flexitime-blue:hover,
body a.-color-flexitime-blue:focus {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-flexitime-blue) !important;
}
body button.-color-flexitime-blue:disabled,
body a.-color-flexitime-blue:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-flexitime-blue:disabled > i,
body a.-color-flexitime-blue:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-flexitime-blue.-readonly,
body a.-color-flexitime-blue.-readonly {
  pointer-events: none !important;
}
body button.-color-flexitime-blue-clear,
body a.-color-flexitime-blue-clear {
  color: var(--app-color-flexitime-blue) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-flexitime-blue-clear:active, body button.-color-flexitime-blue-clear:focus,
body a.-color-flexitime-blue-clear:active,
body a.-color-flexitime-blue-clear:focus {
  color: var(--app-color-flexitime-blue) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-flexitime-blue-clear:hover,
body a.-color-flexitime-blue-clear:hover {
  color: var(--app-color-flexitime-blue-dark) !important;
}
body button.-color-flexitime-blue-clear:disabled,
body a.-color-flexitime-blue-clear:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-flexitime-blue-clear:disabled > i,
body a.-color-flexitime-blue-clear:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-flexitime-blue-clear.-readonly,
body a.-color-flexitime-blue-clear.-readonly {
  pointer-events: none !important;
}
body button.-color-flexitime-blue-clear:disabled,
body a.-color-flexitime-blue-clear:disabled {
  background-color: transparent !important;
}
body button.-color-flexitime-blue-clear-grey-border,
body a.-color-flexitime-blue-clear-grey-border {
  color: var(--app-color-flexitime-blue) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
  border: var(--app-border);
  background-color: var(--app-color-white) !important;
}
body button.-color-flexitime-blue-clear-grey-border:active, body button.-color-flexitime-blue-clear-grey-border:focus,
body a.-color-flexitime-blue-clear-grey-border:active,
body a.-color-flexitime-blue-clear-grey-border:focus {
  color: var(--app-color-flexitime-blue) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-flexitime-blue-clear-grey-border:hover,
body a.-color-flexitime-blue-clear-grey-border:hover {
  color: var(--app-color-flexitime-blue-dark) !important;
}
body button.-color-flexitime-blue-clear-grey-border:disabled,
body a.-color-flexitime-blue-clear-grey-border:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-flexitime-blue-clear-grey-border:disabled > i,
body a.-color-flexitime-blue-clear-grey-border:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-flexitime-blue-clear-grey-border.-readonly,
body a.-color-flexitime-blue-clear-grey-border.-readonly {
  pointer-events: none !important;
}
body button.-color-flexitime-blue-clear-grey-border:active,
body a.-color-flexitime-blue-clear-grey-border:active {
  background-color: var(--app-color-grey-very-light) !important;
}
body button.-color-flexitime-blue-clear-grey-border:hover,
body a.-color-flexitime-blue-clear-grey-border:hover {
  background-color: var(--app-color-grey-very-light) !important;
}
body button.-color-flexitime-blue-hoverFill,
body a.-color-flexitime-blue-hoverFill {
  color: var(--app-color-flexitime-blue) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-flexitime-blue-hoverFill:active, body button.-color-flexitime-blue-hoverFill:focus,
body a.-color-flexitime-blue-hoverFill:active,
body a.-color-flexitime-blue-hoverFill:focus {
  color: var(--app-color-flexitime-blue) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-flexitime-blue-hoverFill:hover,
body a.-color-flexitime-blue-hoverFill:hover {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-blue-translucent) !important;
}
body button.-color-flexitime-blue-hoverFill:disabled,
body a.-color-flexitime-blue-hoverFill:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-flexitime-blue-hoverFill:disabled > i,
body a.-color-flexitime-blue-hoverFill:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-flexitime-blue-hoverFill.-readonly,
body a.-color-flexitime-blue-hoverFill.-readonly {
  pointer-events: none !important;
}
body button.-color-flexitime-blue-translucent,
body a.-color-flexitime-blue-translucent {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-blue-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-flexitime-blue-translucent:active, body button.-color-flexitime-blue-translucent:focus,
body a.-color-flexitime-blue-translucent:active,
body a.-color-flexitime-blue-translucent:focus {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-blue-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-flexitime-blue-translucent:hover,
body a.-color-flexitime-blue-translucent:hover {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-blue-translucent-light) !important;
}
body button.-color-flexitime-blue-translucent:disabled,
body a.-color-flexitime-blue-translucent:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-flexitime-blue-translucent:disabled > i,
body a.-color-flexitime-blue-translucent:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-flexitime-blue-translucent.-readonly,
body a.-color-flexitime-blue-translucent.-readonly {
  pointer-events: none !important;
}
body button.-color-flexitime-blue-outline,
body a.-color-flexitime-blue-outline {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-blue-translucent) !important;
  border: 1px solid var(--app-color-flexitime-blue);
  font-weight: var(--app-font-weight-normal);
}
body button.-color-flexitime-blue-outline:active, body button.-color-flexitime-blue-outline:focus,
body a.-color-flexitime-blue-outline:active,
body a.-color-flexitime-blue-outline:focus {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-blue-translucent) !important;
  border: 1px solid var(--app-color-flexitime-blue);
}
body button.-color-flexitime-blue-outline:hover,
body a.-color-flexitime-blue-outline:hover {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-blue-translucent-light) !important;
  border: 1px solid var(--app-color-flexitime-blue);
}
body button.-color-flexitime-blue-outline:disabled,
body a.-color-flexitime-blue-outline:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
  border: var(--app-border-light) !important;
}
body button.-color-flexitime-blue-outline:disabled > i,
body a.-color-flexitime-blue-outline:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-flexitime-blue-outline.-readonly,
body a.-color-flexitime-blue-outline.-readonly {
  pointer-events: none !important;
}
body button.-color-link,
body a.-color-link {
  color: var(--app-color-white) !important;
  background-color: var(--theme-color-link) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-link:active,
body a.-color-link:active {
  color: var(--app-color-white) !important;
  background-color: var(--theme-color-link) !important;
}
body button.-color-link:hover, body button.-color-link:focus,
body a.-color-link:hover,
body a.-color-link:focus {
  color: var(--app-color-white) !important;
  background-color: var(--theme-color-link) !important;
}
body button.-color-link:disabled,
body a.-color-link:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-link:disabled > i,
body a.-color-link:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-link.-readonly,
body a.-color-link.-readonly {
  pointer-events: none !important;
}
body button.-color-link-clear,
body a.-color-link-clear {
  color: var(--theme-color-link) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-link-clear:active, body button.-color-link-clear:focus,
body a.-color-link-clear:active,
body a.-color-link-clear:focus {
  color: var(--theme-color-link) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-link-clear:hover,
body a.-color-link-clear:hover {
  color: var(--theme-color-link-dark) !important;
}
body button.-color-link-clear:disabled,
body a.-color-link-clear:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-link-clear:disabled > i,
body a.-color-link-clear:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-link-clear.-readonly,
body a.-color-link-clear.-readonly {
  pointer-events: none !important;
}
body button.-color-link-clear:disabled,
body a.-color-link-clear:disabled {
  background-color: transparent !important;
}
body button.-color-link-clear-grey-border,
body a.-color-link-clear-grey-border {
  color: var(--theme-color-link) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
  border: var(--app-border);
  background-color: var(--app-color-white) !important;
}
body button.-color-link-clear-grey-border:active, body button.-color-link-clear-grey-border:focus,
body a.-color-link-clear-grey-border:active,
body a.-color-link-clear-grey-border:focus {
  color: var(--theme-color-link) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-link-clear-grey-border:hover,
body a.-color-link-clear-grey-border:hover {
  color: var(--theme-color-link-dark) !important;
}
body button.-color-link-clear-grey-border:disabled,
body a.-color-link-clear-grey-border:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-link-clear-grey-border:disabled > i,
body a.-color-link-clear-grey-border:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-link-clear-grey-border.-readonly,
body a.-color-link-clear-grey-border.-readonly {
  pointer-events: none !important;
}
body button.-color-link-clear-grey-border:active,
body a.-color-link-clear-grey-border:active {
  background-color: var(--app-color-grey-very-light) !important;
}
body button.-color-link-clear-grey-border:hover,
body a.-color-link-clear-grey-border:hover {
  background-color: var(--app-color-grey-very-light) !important;
}
body button.-color-link-hoverFill,
body a.-color-link-hoverFill {
  color: var(--theme-color-link) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-link-hoverFill:active, body button.-color-link-hoverFill:focus,
body a.-color-link-hoverFill:active,
body a.-color-link-hoverFill:focus {
  color: var(--theme-color-link) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-link-hoverFill:hover,
body a.-color-link-hoverFill:hover {
  color: var(--theme-color-link) !important;
  background-color: var(--theme-color-link-translucent) !important;
}
body button.-color-link-hoverFill:disabled,
body a.-color-link-hoverFill:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-link-hoverFill:disabled > i,
body a.-color-link-hoverFill:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-link-hoverFill.-readonly,
body a.-color-link-hoverFill.-readonly {
  pointer-events: none !important;
}
body button.-color-link-translucent,
body a.-color-link-translucent {
  color: var(--theme-color-link) !important;
  background-color: var(--theme-color-link-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-link-translucent:active, body button.-color-link-translucent:focus,
body a.-color-link-translucent:active,
body a.-color-link-translucent:focus {
  color: var(--theme-color-link) !important;
  background-color: var(--theme-color-link-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-link-translucent:hover,
body a.-color-link-translucent:hover {
  color: var(--theme-color-link) !important;
  background-color: var(--theme-color-link-translucent-light) !important;
}
body button.-color-link-translucent:disabled,
body a.-color-link-translucent:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-link-translucent:disabled > i,
body a.-color-link-translucent:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-link-translucent.-readonly,
body a.-color-link-translucent.-readonly {
  pointer-events: none !important;
}
body button.-color-link-outline,
body a.-color-link-outline {
  color: var(--theme-color-link) !important;
  background-color: var(--theme-color-link-translucent) !important;
  border: 1px solid var(--theme-color-link);
  font-weight: var(--app-font-weight-normal);
}
body button.-color-link-outline:active, body button.-color-link-outline:focus,
body a.-color-link-outline:active,
body a.-color-link-outline:focus {
  color: var(--theme-color-link) !important;
  background-color: var(--theme-color-link-translucent) !important;
  border: 1px solid var(--theme-color-link);
}
body button.-color-link-outline:hover,
body a.-color-link-outline:hover {
  color: var(--theme-color-link) !important;
  background-color: var(--theme-color-link-translucent-light) !important;
  border: 1px solid var(--theme-color-link);
}
body button.-color-link-outline:disabled,
body a.-color-link-outline:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
  border: var(--app-border-light) !important;
}
body button.-color-link-outline:disabled > i,
body a.-color-link-outline:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-link-outline.-readonly,
body a.-color-link-outline.-readonly {
  pointer-events: none !important;
}
body button.-color-green,
body a.-color-green {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-green) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-green:active,
body a.-color-green:active {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-green) !important;
}
body button.-color-green:hover, body button.-color-green:focus,
body a.-color-green:hover,
body a.-color-green:focus {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-green) !important;
}
body button.-color-green:disabled,
body a.-color-green:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-green:disabled > i,
body a.-color-green:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-green.-readonly,
body a.-color-green.-readonly {
  pointer-events: none !important;
}
body button.-color-green-clear,
body a.-color-green-clear {
  color: var(--app-color-green) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-green-clear:active, body button.-color-green-clear:focus,
body a.-color-green-clear:active,
body a.-color-green-clear:focus {
  color: var(--app-color-green) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-green-clear:hover,
body a.-color-green-clear:hover {
  color: var(--app-color-green-dark) !important;
}
body button.-color-green-clear:disabled,
body a.-color-green-clear:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-green-clear:disabled > i,
body a.-color-green-clear:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-green-clear.-readonly,
body a.-color-green-clear.-readonly {
  pointer-events: none !important;
}
body button.-color-green-clear:disabled,
body a.-color-green-clear:disabled {
  background-color: transparent !important;
}
body button.-color-green-clear-grey-border,
body a.-color-green-clear-grey-border {
  color: var(--app-color-green) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
  border: var(--app-border);
  background-color: var(--app-color-white) !important;
}
body button.-color-green-clear-grey-border:active, body button.-color-green-clear-grey-border:focus,
body a.-color-green-clear-grey-border:active,
body a.-color-green-clear-grey-border:focus {
  color: var(--app-color-green) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-green-clear-grey-border:hover,
body a.-color-green-clear-grey-border:hover {
  color: var(--app-color-green-dark) !important;
}
body button.-color-green-clear-grey-border:disabled,
body a.-color-green-clear-grey-border:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-green-clear-grey-border:disabled > i,
body a.-color-green-clear-grey-border:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-green-clear-grey-border.-readonly,
body a.-color-green-clear-grey-border.-readonly {
  pointer-events: none !important;
}
body button.-color-green-clear-grey-border:active,
body a.-color-green-clear-grey-border:active {
  background-color: var(--app-color-grey-very-light) !important;
}
body button.-color-green-clear-grey-border:hover,
body a.-color-green-clear-grey-border:hover {
  background-color: var(--app-color-grey-very-light) !important;
}
body button.-color-green-hoverFill,
body a.-color-green-hoverFill {
  color: var(--app-color-green) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-green-hoverFill:active, body button.-color-green-hoverFill:focus,
body a.-color-green-hoverFill:active,
body a.-color-green-hoverFill:focus {
  color: var(--app-color-green) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-green-hoverFill:hover,
body a.-color-green-hoverFill:hover {
  color: var(--app-color-green) !important;
  background-color: var(--app-color-green-translucent) !important;
}
body button.-color-green-hoverFill:disabled,
body a.-color-green-hoverFill:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-green-hoverFill:disabled > i,
body a.-color-green-hoverFill:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-green-hoverFill.-readonly,
body a.-color-green-hoverFill.-readonly {
  pointer-events: none !important;
}
body button.-color-green-translucent,
body a.-color-green-translucent {
  color: var(--app-color-green) !important;
  background-color: var(--app-color-green-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-green-translucent:active, body button.-color-green-translucent:focus,
body a.-color-green-translucent:active,
body a.-color-green-translucent:focus {
  color: var(--app-color-green) !important;
  background-color: var(--app-color-green-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-green-translucent:hover,
body a.-color-green-translucent:hover {
  color: var(--app-color-green) !important;
  background-color: var(--app-color-green-translucent-light) !important;
}
body button.-color-green-translucent:disabled,
body a.-color-green-translucent:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-green-translucent:disabled > i,
body a.-color-green-translucent:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-green-translucent.-readonly,
body a.-color-green-translucent.-readonly {
  pointer-events: none !important;
}
body button.-color-green-outline,
body a.-color-green-outline {
  color: var(--app-color-green) !important;
  background-color: var(--app-color-green-translucent) !important;
  border: 1px solid var(--app-color-green);
  font-weight: var(--app-font-weight-normal);
}
body button.-color-green-outline:active, body button.-color-green-outline:focus,
body a.-color-green-outline:active,
body a.-color-green-outline:focus {
  color: var(--app-color-green) !important;
  background-color: var(--app-color-green-translucent) !important;
  border: 1px solid var(--app-color-green);
}
body button.-color-green-outline:hover,
body a.-color-green-outline:hover {
  color: var(--app-color-green) !important;
  background-color: var(--app-color-green-translucent-light) !important;
  border: 1px solid var(--app-color-green);
}
body button.-color-green-outline:disabled,
body a.-color-green-outline:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
  border: var(--app-border-light) !important;
}
body button.-color-green-outline:disabled > i,
body a.-color-green-outline:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-green-outline.-readonly,
body a.-color-green-outline.-readonly {
  pointer-events: none !important;
}
body button.-color-blue,
body a.-color-blue {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-blue) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-blue:active,
body a.-color-blue:active {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-blue) !important;
}
body button.-color-blue:hover, body button.-color-blue:focus,
body a.-color-blue:hover,
body a.-color-blue:focus {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-blue) !important;
}
body button.-color-blue:disabled,
body a.-color-blue:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-blue:disabled > i,
body a.-color-blue:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-blue.-readonly,
body a.-color-blue.-readonly {
  pointer-events: none !important;
}
body button.-color-blue-clear,
body a.-color-blue-clear {
  color: var(--app-color-blue) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-blue-clear:active, body button.-color-blue-clear:focus,
body a.-color-blue-clear:active,
body a.-color-blue-clear:focus {
  color: var(--app-color-blue) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-blue-clear:hover,
body a.-color-blue-clear:hover {
  color: var(--app-color-blue-dark) !important;
}
body button.-color-blue-clear:disabled,
body a.-color-blue-clear:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-blue-clear:disabled > i,
body a.-color-blue-clear:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-blue-clear.-readonly,
body a.-color-blue-clear.-readonly {
  pointer-events: none !important;
}
body button.-color-blue-clear:disabled,
body a.-color-blue-clear:disabled {
  background-color: transparent !important;
}
body button.-color-blue-clear-grey-border,
body a.-color-blue-clear-grey-border {
  color: var(--app-color-blue) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
  border: var(--app-border);
  background-color: var(--app-color-white) !important;
}
body button.-color-blue-clear-grey-border:active, body button.-color-blue-clear-grey-border:focus,
body a.-color-blue-clear-grey-border:active,
body a.-color-blue-clear-grey-border:focus {
  color: var(--app-color-blue) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-blue-clear-grey-border:hover,
body a.-color-blue-clear-grey-border:hover {
  color: var(--app-color-blue-dark) !important;
}
body button.-color-blue-clear-grey-border:disabled,
body a.-color-blue-clear-grey-border:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-blue-clear-grey-border:disabled > i,
body a.-color-blue-clear-grey-border:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-blue-clear-grey-border.-readonly,
body a.-color-blue-clear-grey-border.-readonly {
  pointer-events: none !important;
}
body button.-color-blue-clear-grey-border:active,
body a.-color-blue-clear-grey-border:active {
  background-color: var(--app-color-grey-very-light) !important;
}
body button.-color-blue-clear-grey-border:hover,
body a.-color-blue-clear-grey-border:hover {
  background-color: var(--app-color-grey-very-light) !important;
}
body button.-color-blue-hoverFill,
body a.-color-blue-hoverFill {
  color: var(--app-color-blue) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-blue-hoverFill:active, body button.-color-blue-hoverFill:focus,
body a.-color-blue-hoverFill:active,
body a.-color-blue-hoverFill:focus {
  color: var(--app-color-blue) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-blue-hoverFill:hover,
body a.-color-blue-hoverFill:hover {
  color: var(--app-color-blue) !important;
  background-color: var(--app-color-blue-translucent) !important;
}
body button.-color-blue-hoverFill:disabled,
body a.-color-blue-hoverFill:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-blue-hoverFill:disabled > i,
body a.-color-blue-hoverFill:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-blue-hoverFill.-readonly,
body a.-color-blue-hoverFill.-readonly {
  pointer-events: none !important;
}
body button.-color-blue-translucent,
body a.-color-blue-translucent {
  color: var(--app-color-blue) !important;
  background-color: var(--app-color-blue-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-blue-translucent:active, body button.-color-blue-translucent:focus,
body a.-color-blue-translucent:active,
body a.-color-blue-translucent:focus {
  color: var(--app-color-blue) !important;
  background-color: var(--app-color-blue-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-blue-translucent:hover,
body a.-color-blue-translucent:hover {
  color: var(--app-color-blue) !important;
  background-color: var(--app-color-blue-translucent-light) !important;
}
body button.-color-blue-translucent:disabled,
body a.-color-blue-translucent:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-blue-translucent:disabled > i,
body a.-color-blue-translucent:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-blue-translucent.-readonly,
body a.-color-blue-translucent.-readonly {
  pointer-events: none !important;
}
body button.-color-blue-outline,
body a.-color-blue-outline {
  color: var(--app-color-blue) !important;
  background-color: var(--app-color-blue-translucent) !important;
  border: 1px solid var(--app-color-blue);
  font-weight: var(--app-font-weight-normal);
}
body button.-color-blue-outline:active, body button.-color-blue-outline:focus,
body a.-color-blue-outline:active,
body a.-color-blue-outline:focus {
  color: var(--app-color-blue) !important;
  background-color: var(--app-color-blue-translucent) !important;
  border: 1px solid var(--app-color-blue);
}
body button.-color-blue-outline:hover,
body a.-color-blue-outline:hover {
  color: var(--app-color-blue) !important;
  background-color: var(--app-color-blue-translucent-light) !important;
  border: 1px solid var(--app-color-blue);
}
body button.-color-blue-outline:disabled,
body a.-color-blue-outline:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
  border: var(--app-border-light) !important;
}
body button.-color-blue-outline:disabled > i,
body a.-color-blue-outline:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-blue-outline.-readonly,
body a.-color-blue-outline.-readonly {
  pointer-events: none !important;
}
body button.-color-payhero,
body a.-color-payhero {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-payhero) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-payhero:active,
body a.-color-payhero:active {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-payhero) !important;
}
body button.-color-payhero:hover, body button.-color-payhero:focus,
body a.-color-payhero:hover,
body a.-color-payhero:focus {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-payhero) !important;
}
body button.-color-payhero:disabled,
body a.-color-payhero:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-payhero:disabled > i,
body a.-color-payhero:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-payhero.-readonly,
body a.-color-payhero.-readonly {
  pointer-events: none !important;
}
body button.-color-payhero-clear,
body a.-color-payhero-clear {
  color: var(--app-color-payhero) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-payhero-clear:active, body button.-color-payhero-clear:focus,
body a.-color-payhero-clear:active,
body a.-color-payhero-clear:focus {
  color: var(--app-color-payhero) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-payhero-clear:hover,
body a.-color-payhero-clear:hover {
  color: var(--app-color-payhero-dark) !important;
}
body button.-color-payhero-clear:disabled,
body a.-color-payhero-clear:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-payhero-clear:disabled > i,
body a.-color-payhero-clear:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-payhero-clear.-readonly,
body a.-color-payhero-clear.-readonly {
  pointer-events: none !important;
}
body button.-color-payhero-clear:disabled,
body a.-color-payhero-clear:disabled {
  background-color: transparent !important;
}
body button.-color-payhero-clear-grey-border,
body a.-color-payhero-clear-grey-border {
  color: var(--app-color-payhero) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
  border: var(--app-border);
  background-color: var(--app-color-white) !important;
}
body button.-color-payhero-clear-grey-border:active, body button.-color-payhero-clear-grey-border:focus,
body a.-color-payhero-clear-grey-border:active,
body a.-color-payhero-clear-grey-border:focus {
  color: var(--app-color-payhero) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-payhero-clear-grey-border:hover,
body a.-color-payhero-clear-grey-border:hover {
  color: var(--app-color-payhero-dark) !important;
}
body button.-color-payhero-clear-grey-border:disabled,
body a.-color-payhero-clear-grey-border:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-payhero-clear-grey-border:disabled > i,
body a.-color-payhero-clear-grey-border:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-payhero-clear-grey-border.-readonly,
body a.-color-payhero-clear-grey-border.-readonly {
  pointer-events: none !important;
}
body button.-color-payhero-clear-grey-border:active,
body a.-color-payhero-clear-grey-border:active {
  background-color: var(--app-color-grey-very-light) !important;
}
body button.-color-payhero-clear-grey-border:hover,
body a.-color-payhero-clear-grey-border:hover {
  background-color: var(--app-color-grey-very-light) !important;
}
body button.-color-payhero-hoverFill,
body a.-color-payhero-hoverFill {
  color: var(--app-color-payhero) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-payhero-hoverFill:active, body button.-color-payhero-hoverFill:focus,
body a.-color-payhero-hoverFill:active,
body a.-color-payhero-hoverFill:focus {
  color: var(--app-color-payhero) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-payhero-hoverFill:hover,
body a.-color-payhero-hoverFill:hover {
  color: var(--app-color-payhero) !important;
  background-color: var(--app-color-payhero-translucent) !important;
}
body button.-color-payhero-hoverFill:disabled,
body a.-color-payhero-hoverFill:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-payhero-hoverFill:disabled > i,
body a.-color-payhero-hoverFill:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-payhero-hoverFill.-readonly,
body a.-color-payhero-hoverFill.-readonly {
  pointer-events: none !important;
}
body button.-color-payhero-translucent,
body a.-color-payhero-translucent {
  color: var(--app-color-payhero) !important;
  background-color: var(--app-color-payhero-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-payhero-translucent:active, body button.-color-payhero-translucent:focus,
body a.-color-payhero-translucent:active,
body a.-color-payhero-translucent:focus {
  color: var(--app-color-payhero) !important;
  background-color: var(--app-color-payhero-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-payhero-translucent:hover,
body a.-color-payhero-translucent:hover {
  color: var(--app-color-payhero) !important;
  background-color: var(--app-color-payhero-translucent-light) !important;
}
body button.-color-payhero-translucent:disabled,
body a.-color-payhero-translucent:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-payhero-translucent:disabled > i,
body a.-color-payhero-translucent:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-payhero-translucent.-readonly,
body a.-color-payhero-translucent.-readonly {
  pointer-events: none !important;
}
body button.-color-payhero-outline,
body a.-color-payhero-outline {
  color: var(--app-color-payhero) !important;
  background-color: var(--app-color-payhero-translucent) !important;
  border: 1px solid var(--app-color-payhero);
  font-weight: var(--app-font-weight-normal);
}
body button.-color-payhero-outline:active, body button.-color-payhero-outline:focus,
body a.-color-payhero-outline:active,
body a.-color-payhero-outline:focus {
  color: var(--app-color-payhero) !important;
  background-color: var(--app-color-payhero-translucent) !important;
  border: 1px solid var(--app-color-payhero);
}
body button.-color-payhero-outline:hover,
body a.-color-payhero-outline:hover {
  color: var(--app-color-payhero) !important;
  background-color: var(--app-color-payhero-translucent-light) !important;
  border: 1px solid var(--app-color-payhero);
}
body button.-color-payhero-outline:disabled,
body a.-color-payhero-outline:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
  border: var(--app-border-light) !important;
}
body button.-color-payhero-outline:disabled > i,
body a.-color-payhero-outline:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-payhero-outline.-readonly,
body a.-color-payhero-outline.-readonly {
  pointer-events: none !important;
}
body button.-color-droppah,
body a.-color-droppah {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-droppah-purple) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-droppah:active,
body a.-color-droppah:active {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-droppah-purple) !important;
}
body button.-color-droppah:hover, body button.-color-droppah:focus,
body a.-color-droppah:hover,
body a.-color-droppah:focus {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-droppah-purple) !important;
}
body button.-color-droppah:disabled,
body a.-color-droppah:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-droppah:disabled > i,
body a.-color-droppah:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-droppah.-readonly,
body a.-color-droppah.-readonly {
  pointer-events: none !important;
}
body button.-color-droppah-clear,
body a.-color-droppah-clear {
  color: var(--app-color-droppah-purple) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-droppah-clear:active, body button.-color-droppah-clear:focus,
body a.-color-droppah-clear:active,
body a.-color-droppah-clear:focus {
  color: var(--app-color-droppah-purple) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-droppah-clear:hover,
body a.-color-droppah-clear:hover {
  color: var(--app-color-droppah-purple-dark) !important;
}
body button.-color-droppah-clear:disabled,
body a.-color-droppah-clear:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-droppah-clear:disabled > i,
body a.-color-droppah-clear:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-droppah-clear.-readonly,
body a.-color-droppah-clear.-readonly {
  pointer-events: none !important;
}
body button.-color-droppah-clear:disabled,
body a.-color-droppah-clear:disabled {
  background-color: transparent !important;
}
body button.-color-droppah-clear-grey-border,
body a.-color-droppah-clear-grey-border {
  color: var(--app-color-droppah-purple) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
  border: var(--app-border);
  background-color: var(--app-color-white) !important;
}
body button.-color-droppah-clear-grey-border:active, body button.-color-droppah-clear-grey-border:focus,
body a.-color-droppah-clear-grey-border:active,
body a.-color-droppah-clear-grey-border:focus {
  color: var(--app-color-droppah-purple) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-droppah-clear-grey-border:hover,
body a.-color-droppah-clear-grey-border:hover {
  color: var(--app-color-droppah-purple-dark) !important;
}
body button.-color-droppah-clear-grey-border:disabled,
body a.-color-droppah-clear-grey-border:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-droppah-clear-grey-border:disabled > i,
body a.-color-droppah-clear-grey-border:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-droppah-clear-grey-border.-readonly,
body a.-color-droppah-clear-grey-border.-readonly {
  pointer-events: none !important;
}
body button.-color-droppah-clear-grey-border:active,
body a.-color-droppah-clear-grey-border:active {
  background-color: var(--app-color-grey-very-light) !important;
}
body button.-color-droppah-clear-grey-border:hover,
body a.-color-droppah-clear-grey-border:hover {
  background-color: var(--app-color-grey-very-light) !important;
}
body button.-color-droppah-hoverFill,
body a.-color-droppah-hoverFill {
  color: var(--app-color-droppah-purple) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-droppah-hoverFill:active, body button.-color-droppah-hoverFill:focus,
body a.-color-droppah-hoverFill:active,
body a.-color-droppah-hoverFill:focus {
  color: var(--app-color-droppah-purple) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-droppah-hoverFill:hover,
body a.-color-droppah-hoverFill:hover {
  color: var(--app-color-droppah-purple) !important;
  background-color: var(--app-color-droppah-purple-translucent) !important;
}
body button.-color-droppah-hoverFill:disabled,
body a.-color-droppah-hoverFill:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-droppah-hoverFill:disabled > i,
body a.-color-droppah-hoverFill:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-droppah-hoverFill.-readonly,
body a.-color-droppah-hoverFill.-readonly {
  pointer-events: none !important;
}
body button.-color-droppah-translucent,
body a.-color-droppah-translucent {
  color: var(--app-color-droppah-purple) !important;
  background-color: var(--app-color-droppah-purple-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-droppah-translucent:active, body button.-color-droppah-translucent:focus,
body a.-color-droppah-translucent:active,
body a.-color-droppah-translucent:focus {
  color: var(--app-color-droppah-purple) !important;
  background-color: var(--app-color-droppah-purple-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-droppah-translucent:hover,
body a.-color-droppah-translucent:hover {
  color: var(--app-color-droppah-purple) !important;
  background-color: var(--app-color-droppah-purple-translucent-light) !important;
}
body button.-color-droppah-translucent:disabled,
body a.-color-droppah-translucent:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-droppah-translucent:disabled > i,
body a.-color-droppah-translucent:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-droppah-translucent.-readonly,
body a.-color-droppah-translucent.-readonly {
  pointer-events: none !important;
}
body button.-color-droppah-outline,
body a.-color-droppah-outline {
  color: var(--app-color-droppah-purple) !important;
  background-color: var(--app-color-droppah-purple-translucent) !important;
  border: 1px solid var(--app-color-droppah-purple);
  font-weight: var(--app-font-weight-normal);
}
body button.-color-droppah-outline:active, body button.-color-droppah-outline:focus,
body a.-color-droppah-outline:active,
body a.-color-droppah-outline:focus {
  color: var(--app-color-droppah-purple) !important;
  background-color: var(--app-color-droppah-purple-translucent) !important;
  border: 1px solid var(--app-color-droppah-purple);
}
body button.-color-droppah-outline:hover,
body a.-color-droppah-outline:hover {
  color: var(--app-color-droppah-purple) !important;
  background-color: var(--app-color-droppah-purple-translucent-light) !important;
  border: 1px solid var(--app-color-droppah-purple);
}
body button.-color-droppah-outline:disabled,
body a.-color-droppah-outline:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
  border: var(--app-border-light) !important;
}
body button.-color-droppah-outline:disabled > i,
body a.-color-droppah-outline:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-droppah-outline.-readonly,
body a.-color-droppah-outline.-readonly {
  pointer-events: none !important;
}
body button.-color-droppah-dark,
body a.-color-droppah-dark {
  color: var(--app-color-white) !important;
  background-color: var(--droppah-color-background-light) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-droppah-dark:active,
body a.-color-droppah-dark:active {
  color: var(--app-color-white) !important;
  background-color: var(--droppah-color-background-light) !important;
}
body button.-color-droppah-dark:hover, body button.-color-droppah-dark:focus,
body a.-color-droppah-dark:hover,
body a.-color-droppah-dark:focus {
  color: var(--app-color-white) !important;
  background-color: var(--droppah-color-background-light) !important;
}
body button.-color-droppah-dark:disabled,
body a.-color-droppah-dark:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-droppah-dark:disabled > i,
body a.-color-droppah-dark:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-droppah-dark.-readonly,
body a.-color-droppah-dark.-readonly {
  pointer-events: none !important;
}
body button.-color-invoxy,
body a.-color-invoxy {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-invoxy) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-invoxy:active,
body a.-color-invoxy:active {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-invoxy) !important;
}
body button.-color-invoxy:hover, body button.-color-invoxy:focus,
body a.-color-invoxy:hover,
body a.-color-invoxy:focus {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-invoxy) !important;
}
body button.-color-invoxy:disabled,
body a.-color-invoxy:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-invoxy:disabled > i,
body a.-color-invoxy:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-invoxy.-readonly,
body a.-color-invoxy.-readonly {
  pointer-events: none !important;
}
body button.-color-invoxy-clear,
body a.-color-invoxy-clear {
  color: var(--app-color-invoxy) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-invoxy-clear:active, body button.-color-invoxy-clear:focus,
body a.-color-invoxy-clear:active,
body a.-color-invoxy-clear:focus {
  color: var(--app-color-invoxy) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-invoxy-clear:hover,
body a.-color-invoxy-clear:hover {
  color: var(--app-color-invoxy-dark) !important;
}
body button.-color-invoxy-clear:disabled,
body a.-color-invoxy-clear:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-invoxy-clear:disabled > i,
body a.-color-invoxy-clear:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-invoxy-clear.-readonly,
body a.-color-invoxy-clear.-readonly {
  pointer-events: none !important;
}
body button.-color-invoxy-clear:disabled,
body a.-color-invoxy-clear:disabled {
  background-color: transparent !important;
}
body button.-color-invoxy-clear-grey-border,
body a.-color-invoxy-clear-grey-border {
  color: var(--app-color-invoxy) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
  border: var(--app-border);
  background-color: var(--app-color-white) !important;
}
body button.-color-invoxy-clear-grey-border:active, body button.-color-invoxy-clear-grey-border:focus,
body a.-color-invoxy-clear-grey-border:active,
body a.-color-invoxy-clear-grey-border:focus {
  color: var(--app-color-invoxy) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-invoxy-clear-grey-border:hover,
body a.-color-invoxy-clear-grey-border:hover {
  color: var(--app-color-invoxy-dark) !important;
}
body button.-color-invoxy-clear-grey-border:disabled,
body a.-color-invoxy-clear-grey-border:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-invoxy-clear-grey-border:disabled > i,
body a.-color-invoxy-clear-grey-border:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-invoxy-clear-grey-border.-readonly,
body a.-color-invoxy-clear-grey-border.-readonly {
  pointer-events: none !important;
}
body button.-color-invoxy-clear-grey-border:active,
body a.-color-invoxy-clear-grey-border:active {
  background-color: var(--app-color-grey-very-light) !important;
}
body button.-color-invoxy-clear-grey-border:hover,
body a.-color-invoxy-clear-grey-border:hover {
  background-color: var(--app-color-grey-very-light) !important;
}
body button.-color-invoxy-hoverFill,
body a.-color-invoxy-hoverFill {
  color: var(--app-color-invoxy) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-invoxy-hoverFill:active, body button.-color-invoxy-hoverFill:focus,
body a.-color-invoxy-hoverFill:active,
body a.-color-invoxy-hoverFill:focus {
  color: var(--app-color-invoxy) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-invoxy-hoverFill:hover,
body a.-color-invoxy-hoverFill:hover {
  color: var(--app-color-invoxy) !important;
  background-color: var(--app-color-invoxy-translucent) !important;
}
body button.-color-invoxy-hoverFill:disabled,
body a.-color-invoxy-hoverFill:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-invoxy-hoverFill:disabled > i,
body a.-color-invoxy-hoverFill:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-invoxy-hoverFill.-readonly,
body a.-color-invoxy-hoverFill.-readonly {
  pointer-events: none !important;
}
body button.-color-invoxy-translucent,
body a.-color-invoxy-translucent {
  color: var(--app-color-invoxy) !important;
  background-color: var(--app-color-invoxy-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-invoxy-translucent:active, body button.-color-invoxy-translucent:focus,
body a.-color-invoxy-translucent:active,
body a.-color-invoxy-translucent:focus {
  color: var(--app-color-invoxy) !important;
  background-color: var(--app-color-invoxy-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-invoxy-translucent:hover,
body a.-color-invoxy-translucent:hover {
  color: var(--app-color-invoxy) !important;
  background-color: var(--app-color-invoxy-translucent-light) !important;
}
body button.-color-invoxy-translucent:disabled,
body a.-color-invoxy-translucent:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-invoxy-translucent:disabled > i,
body a.-color-invoxy-translucent:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-invoxy-translucent.-readonly,
body a.-color-invoxy-translucent.-readonly {
  pointer-events: none !important;
}
body button.-color-invoxy-outline,
body a.-color-invoxy-outline {
  color: var(--app-color-invoxy) !important;
  background-color: var(--app-color-invoxy-translucent) !important;
  border: 1px solid var(--app-color-invoxy);
  font-weight: var(--app-font-weight-normal);
}
body button.-color-invoxy-outline:active, body button.-color-invoxy-outline:focus,
body a.-color-invoxy-outline:active,
body a.-color-invoxy-outline:focus {
  color: var(--app-color-invoxy) !important;
  background-color: var(--app-color-invoxy-translucent) !important;
  border: 1px solid var(--app-color-invoxy);
}
body button.-color-invoxy-outline:hover,
body a.-color-invoxy-outline:hover {
  color: var(--app-color-invoxy) !important;
  background-color: var(--app-color-invoxy-translucent-light) !important;
  border: 1px solid var(--app-color-invoxy);
}
body button.-color-invoxy-outline:disabled,
body a.-color-invoxy-outline:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
  border: var(--app-border-light) !important;
}
body button.-color-invoxy-outline:disabled > i,
body a.-color-invoxy-outline:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-invoxy-outline.-readonly,
body a.-color-invoxy-outline.-readonly {
  pointer-events: none !important;
}
body button.-color-red,
body a.-color-red {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-red) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-red:active,
body a.-color-red:active {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-red) !important;
}
body button.-color-red:hover, body button.-color-red:focus,
body a.-color-red:hover,
body a.-color-red:focus {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-red) !important;
}
body button.-color-red:disabled,
body a.-color-red:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-red:disabled > i,
body a.-color-red:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-red.-readonly,
body a.-color-red.-readonly {
  pointer-events: none !important;
}
body button.-color-red-clear,
body a.-color-red-clear {
  color: var(--app-color-red) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-red-clear:active, body button.-color-red-clear:focus,
body a.-color-red-clear:active,
body a.-color-red-clear:focus {
  color: var(--app-color-red) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-red-clear:hover,
body a.-color-red-clear:hover {
  color: var(--app-color-red-dark) !important;
}
body button.-color-red-clear:disabled,
body a.-color-red-clear:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-red-clear:disabled > i,
body a.-color-red-clear:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-red-clear.-readonly,
body a.-color-red-clear.-readonly {
  pointer-events: none !important;
}
body button.-color-red-clear:disabled,
body a.-color-red-clear:disabled {
  background-color: transparent !important;
}
body button.-color-red-clear-grey-border,
body a.-color-red-clear-grey-border {
  color: var(--app-color-red) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
  border: var(--app-border);
  background-color: var(--app-color-white) !important;
}
body button.-color-red-clear-grey-border:active, body button.-color-red-clear-grey-border:focus,
body a.-color-red-clear-grey-border:active,
body a.-color-red-clear-grey-border:focus {
  color: var(--app-color-red) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-red-clear-grey-border:hover,
body a.-color-red-clear-grey-border:hover {
  color: var(--app-color-red-dark) !important;
}
body button.-color-red-clear-grey-border:disabled,
body a.-color-red-clear-grey-border:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-red-clear-grey-border:disabled > i,
body a.-color-red-clear-grey-border:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-red-clear-grey-border.-readonly,
body a.-color-red-clear-grey-border.-readonly {
  pointer-events: none !important;
}
body button.-color-red-clear-grey-border:active,
body a.-color-red-clear-grey-border:active {
  background-color: var(--app-color-grey-very-light) !important;
}
body button.-color-red-clear-grey-border:hover,
body a.-color-red-clear-grey-border:hover {
  background-color: var(--app-color-grey-very-light) !important;
}
body button.-color-red-hoverFill,
body a.-color-red-hoverFill {
  color: var(--app-color-red) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-red-hoverFill:active, body button.-color-red-hoverFill:focus,
body a.-color-red-hoverFill:active,
body a.-color-red-hoverFill:focus {
  color: var(--app-color-red) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-red-hoverFill:hover,
body a.-color-red-hoverFill:hover {
  color: var(--app-color-red) !important;
  background-color: var(--app-color-red-translucent) !important;
}
body button.-color-red-hoverFill:disabled,
body a.-color-red-hoverFill:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-red-hoverFill:disabled > i,
body a.-color-red-hoverFill:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-red-hoverFill.-readonly,
body a.-color-red-hoverFill.-readonly {
  pointer-events: none !important;
}
body button.-color-red-translucent,
body a.-color-red-translucent {
  color: var(--app-color-red) !important;
  background-color: var(--app-color-red-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-red-translucent:active, body button.-color-red-translucent:focus,
body a.-color-red-translucent:active,
body a.-color-red-translucent:focus {
  color: var(--app-color-red) !important;
  background-color: var(--app-color-red-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-red-translucent:hover,
body a.-color-red-translucent:hover {
  color: var(--app-color-red) !important;
  background-color: var(--app-color-red-translucent-light) !important;
}
body button.-color-red-translucent:disabled,
body a.-color-red-translucent:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-red-translucent:disabled > i,
body a.-color-red-translucent:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-red-translucent.-readonly,
body a.-color-red-translucent.-readonly {
  pointer-events: none !important;
}
body button.-color-red-outline,
body a.-color-red-outline {
  color: var(--app-color-red) !important;
  background-color: var(--app-color-red-translucent) !important;
  border: 1px solid var(--app-color-red);
  font-weight: var(--app-font-weight-normal);
}
body button.-color-red-outline:active, body button.-color-red-outline:focus,
body a.-color-red-outline:active,
body a.-color-red-outline:focus {
  color: var(--app-color-red) !important;
  background-color: var(--app-color-red-translucent) !important;
  border: 1px solid var(--app-color-red);
}
body button.-color-red-outline:hover,
body a.-color-red-outline:hover {
  color: var(--app-color-red) !important;
  background-color: var(--app-color-red-translucent-light) !important;
  border: 1px solid var(--app-color-red);
}
body button.-color-red-outline:disabled,
body a.-color-red-outline:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
  border: var(--app-border-light) !important;
}
body button.-color-red-outline:disabled > i,
body a.-color-red-outline:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-red-outline.-readonly,
body a.-color-red-outline.-readonly {
  pointer-events: none !important;
}
body button.-color-yellow,
body a.-color-yellow {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-yellow) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-yellow:active,
body a.-color-yellow:active {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-yellow) !important;
}
body button.-color-yellow:hover, body button.-color-yellow:focus,
body a.-color-yellow:hover,
body a.-color-yellow:focus {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-yellow) !important;
}
body button.-color-yellow:disabled,
body a.-color-yellow:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-yellow:disabled > i,
body a.-color-yellow:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-yellow.-readonly,
body a.-color-yellow.-readonly {
  pointer-events: none !important;
}
body button.-color-yellow-clear,
body a.-color-yellow-clear {
  color: var(--app-color-yellow) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-yellow-clear:active, body button.-color-yellow-clear:focus,
body a.-color-yellow-clear:active,
body a.-color-yellow-clear:focus {
  color: var(--app-color-yellow) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-yellow-clear:hover,
body a.-color-yellow-clear:hover {
  color: var(--app-color-yellow-dark) !important;
}
body button.-color-yellow-clear:disabled,
body a.-color-yellow-clear:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-yellow-clear:disabled > i,
body a.-color-yellow-clear:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-yellow-clear.-readonly,
body a.-color-yellow-clear.-readonly {
  pointer-events: none !important;
}
body button.-color-yellow-clear:disabled,
body a.-color-yellow-clear:disabled {
  background-color: transparent !important;
}
body button.-color-yellow-clear-grey-border,
body a.-color-yellow-clear-grey-border {
  color: var(--app-color-yellow) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
  border: var(--app-border);
  background-color: var(--app-color-white) !important;
}
body button.-color-yellow-clear-grey-border:active, body button.-color-yellow-clear-grey-border:focus,
body a.-color-yellow-clear-grey-border:active,
body a.-color-yellow-clear-grey-border:focus {
  color: var(--app-color-yellow) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-yellow-clear-grey-border:hover,
body a.-color-yellow-clear-grey-border:hover {
  color: var(--app-color-yellow-dark) !important;
}
body button.-color-yellow-clear-grey-border:disabled,
body a.-color-yellow-clear-grey-border:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-yellow-clear-grey-border:disabled > i,
body a.-color-yellow-clear-grey-border:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-yellow-clear-grey-border.-readonly,
body a.-color-yellow-clear-grey-border.-readonly {
  pointer-events: none !important;
}
body button.-color-yellow-clear-grey-border:active,
body a.-color-yellow-clear-grey-border:active {
  background-color: var(--app-color-grey-very-light) !important;
}
body button.-color-yellow-clear-grey-border:hover,
body a.-color-yellow-clear-grey-border:hover {
  background-color: var(--app-color-grey-very-light) !important;
}
body button.-color-yellow-hoverFill,
body a.-color-yellow-hoverFill {
  color: var(--app-color-yellow) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-yellow-hoverFill:active, body button.-color-yellow-hoverFill:focus,
body a.-color-yellow-hoverFill:active,
body a.-color-yellow-hoverFill:focus {
  color: var(--app-color-yellow) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-yellow-hoverFill:hover,
body a.-color-yellow-hoverFill:hover {
  color: var(--app-color-yellow) !important;
  background-color: var(--app-color-yellow-translucent) !important;
}
body button.-color-yellow-hoverFill:disabled,
body a.-color-yellow-hoverFill:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-yellow-hoverFill:disabled > i,
body a.-color-yellow-hoverFill:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-yellow-hoverFill.-readonly,
body a.-color-yellow-hoverFill.-readonly {
  pointer-events: none !important;
}
body button.-color-yellow-translucent,
body a.-color-yellow-translucent {
  color: var(--app-color-yellow) !important;
  background-color: var(--app-color-yellow-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-yellow-translucent:active, body button.-color-yellow-translucent:focus,
body a.-color-yellow-translucent:active,
body a.-color-yellow-translucent:focus {
  color: var(--app-color-yellow) !important;
  background-color: var(--app-color-yellow-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-yellow-translucent:hover,
body a.-color-yellow-translucent:hover {
  color: var(--app-color-yellow) !important;
  background-color: var(--app-color-yellow-translucent-light) !important;
}
body button.-color-yellow-translucent:disabled,
body a.-color-yellow-translucent:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-yellow-translucent:disabled > i,
body a.-color-yellow-translucent:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-yellow-translucent.-readonly,
body a.-color-yellow-translucent.-readonly {
  pointer-events: none !important;
}
body button.-color-yellow-outline,
body a.-color-yellow-outline {
  color: var(--app-color-yellow) !important;
  background-color: var(--app-color-yellow-translucent) !important;
  border: 1px solid var(--app-color-yellow);
  font-weight: var(--app-font-weight-normal);
}
body button.-color-yellow-outline:active, body button.-color-yellow-outline:focus,
body a.-color-yellow-outline:active,
body a.-color-yellow-outline:focus {
  color: var(--app-color-yellow) !important;
  background-color: var(--app-color-yellow-translucent) !important;
  border: 1px solid var(--app-color-yellow);
}
body button.-color-yellow-outline:hover,
body a.-color-yellow-outline:hover {
  color: var(--app-color-yellow) !important;
  background-color: var(--app-color-yellow-translucent-light) !important;
  border: 1px solid var(--app-color-yellow);
}
body button.-color-yellow-outline:disabled,
body a.-color-yellow-outline:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
  border: var(--app-border-light) !important;
}
body button.-color-yellow-outline:disabled > i,
body a.-color-yellow-outline:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-yellow-outline.-readonly,
body a.-color-yellow-outline.-readonly {
  pointer-events: none !important;
}
body button.-color-grey,
body a.-color-grey {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-grey-dark) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-grey:active,
body a.-color-grey:active {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-grey-dark) !important;
}
body button.-color-grey:hover, body button.-color-grey:focus,
body a.-color-grey:hover,
body a.-color-grey:focus {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-grey-dark) !important;
}
body button.-color-grey:disabled,
body a.-color-grey:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-grey:disabled > i,
body a.-color-grey:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-grey.-readonly,
body a.-color-grey.-readonly {
  pointer-events: none !important;
}
body button.-color-grey-clear,
body a.-color-grey-clear {
  color: var(--app-color-grey-dark) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-grey-clear:active, body button.-color-grey-clear:focus,
body a.-color-grey-clear:active,
body a.-color-grey-clear:focus {
  color: var(--app-color-grey-dark) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-grey-clear:hover,
body a.-color-grey-clear:hover {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-grey-clear:disabled,
body a.-color-grey-clear:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-grey-clear:disabled > i,
body a.-color-grey-clear:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-grey-clear.-readonly,
body a.-color-grey-clear.-readonly {
  pointer-events: none !important;
}
body button.-color-grey-clear:disabled,
body a.-color-grey-clear:disabled {
  background-color: transparent !important;
}
body button.-color-grey-clear-grey-border,
body a.-color-grey-clear-grey-border {
  color: var(--app-color-grey-dark) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
  border: var(--app-border);
  background-color: var(--app-color-white) !important;
}
body button.-color-grey-clear-grey-border:active, body button.-color-grey-clear-grey-border:focus,
body a.-color-grey-clear-grey-border:active,
body a.-color-grey-clear-grey-border:focus {
  color: var(--app-color-grey-dark) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-grey-clear-grey-border:hover,
body a.-color-grey-clear-grey-border:hover {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-grey-clear-grey-border:disabled,
body a.-color-grey-clear-grey-border:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-grey-clear-grey-border:disabled > i,
body a.-color-grey-clear-grey-border:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-grey-clear-grey-border.-readonly,
body a.-color-grey-clear-grey-border.-readonly {
  pointer-events: none !important;
}
body button.-color-grey-clear-grey-border:active,
body a.-color-grey-clear-grey-border:active {
  background-color: var(--app-color-grey-very-light) !important;
}
body button.-color-grey-clear-grey-border:hover,
body a.-color-grey-clear-grey-border:hover {
  background-color: var(--app-color-grey-very-light) !important;
}
body button.-color-grey-hoverFill,
body a.-color-grey-hoverFill {
  color: var(--app-color-grey-dark) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-grey-hoverFill:active, body button.-color-grey-hoverFill:focus,
body a.-color-grey-hoverFill:active,
body a.-color-grey-hoverFill:focus {
  color: var(--app-color-grey-dark) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-grey-hoverFill:hover,
body a.-color-grey-hoverFill:hover {
  color: var(--app-color-grey-dark) !important;
  background-color: var(--app-color-grey-translucent) !important;
}
body button.-color-grey-hoverFill:disabled,
body a.-color-grey-hoverFill:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-grey-hoverFill:disabled > i,
body a.-color-grey-hoverFill:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-grey-hoverFill.-readonly,
body a.-color-grey-hoverFill.-readonly {
  pointer-events: none !important;
}
body button.-color-grey-translucent,
body a.-color-grey-translucent {
  color: var(--app-color-grey-dark) !important;
  background-color: var(--app-color-grey-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-grey-translucent:active, body button.-color-grey-translucent:focus,
body a.-color-grey-translucent:active,
body a.-color-grey-translucent:focus {
  color: var(--app-color-grey-dark) !important;
  background-color: var(--app-color-grey-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-grey-translucent:hover,
body a.-color-grey-translucent:hover {
  color: var(--app-color-grey-dark) !important;
  background-color: var(--app-color-grey-translucent-light) !important;
}
body button.-color-grey-translucent:disabled,
body a.-color-grey-translucent:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-grey-translucent:disabled > i,
body a.-color-grey-translucent:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-grey-translucent.-readonly,
body a.-color-grey-translucent.-readonly {
  pointer-events: none !important;
}
body button.-color-grey-outline,
body a.-color-grey-outline {
  color: var(--app-color-grey-dark) !important;
  background-color: var(--app-color-grey-translucent) !important;
  border: 1px solid var(--app-color-grey-dark);
  font-weight: var(--app-font-weight-normal);
}
body button.-color-grey-outline:active, body button.-color-grey-outline:focus,
body a.-color-grey-outline:active,
body a.-color-grey-outline:focus {
  color: var(--app-color-grey-dark) !important;
  background-color: var(--app-color-grey-translucent) !important;
  border: 1px solid var(--app-color-grey-dark);
}
body button.-color-grey-outline:hover,
body a.-color-grey-outline:hover {
  color: var(--app-color-grey-dark) !important;
  background-color: var(--app-color-grey-translucent-light) !important;
  border: 1px solid var(--app-color-grey-dark);
}
body button.-color-grey-outline:disabled,
body a.-color-grey-outline:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
  border: var(--app-border-light) !important;
}
body button.-color-grey-outline:disabled > i,
body a.-color-grey-outline:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-grey-outline.-readonly,
body a.-color-grey-outline.-readonly {
  pointer-events: none !important;
}
body button.-color-white,
body a.-color-white {
  color: var(--app-color-dark) !important;
  background-color: var(--app-color-white) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-white:active,
body a.-color-white:active {
  color: var(--app-color-dark) !important;
  background-color: var(--app-color-white) !important;
}
body button.-color-white:hover, body button.-color-white:focus,
body a.-color-white:hover,
body a.-color-white:focus {
  color: var(--app-color-dark) !important;
  background-color: var(--app-color-white) !important;
}
body button.-color-white:disabled,
body a.-color-white:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-white:disabled > i,
body a.-color-white:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-white.-readonly,
body a.-color-white.-readonly {
  pointer-events: none !important;
}
body button.-color-white-clear,
body a.-color-white-clear {
  color: var(--app-color-white) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-white-clear:active, body button.-color-white-clear:focus,
body a.-color-white-clear:active,
body a.-color-white-clear:focus {
  color: var(--app-color-white) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-white-clear:hover,
body a.-color-white-clear:hover {
  color: var(--app-color-grey-very-light) !important;
}
body button.-color-white-clear:disabled,
body a.-color-white-clear:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-white-clear:disabled > i,
body a.-color-white-clear:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-white-clear.-readonly,
body a.-color-white-clear.-readonly {
  pointer-events: none !important;
}
body button.-color-white-clear:disabled,
body a.-color-white-clear:disabled {
  background-color: transparent !important;
}
body button.-color-white-clear-grey-border,
body a.-color-white-clear-grey-border {
  color: var(--app-color-white) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
  border: var(--app-border);
  background-color: var(--app-color-white) !important;
}
body button.-color-white-clear-grey-border:active, body button.-color-white-clear-grey-border:focus,
body a.-color-white-clear-grey-border:active,
body a.-color-white-clear-grey-border:focus {
  color: var(--app-color-white) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-white-clear-grey-border:hover,
body a.-color-white-clear-grey-border:hover {
  color: var(--app-color-grey-very-light) !important;
}
body button.-color-white-clear-grey-border:disabled,
body a.-color-white-clear-grey-border:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-white-clear-grey-border:disabled > i,
body a.-color-white-clear-grey-border:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-white-clear-grey-border.-readonly,
body a.-color-white-clear-grey-border.-readonly {
  pointer-events: none !important;
}
body button.-color-white-clear-grey-border:active,
body a.-color-white-clear-grey-border:active {
  background-color: var(--app-color-grey-very-light) !important;
}
body button.-color-white-clear-grey-border:hover,
body a.-color-white-clear-grey-border:hover {
  background-color: var(--app-color-grey-very-light) !important;
}
body button.-color-white-hoverFill,
body a.-color-white-hoverFill {
  color: var(--app-color-dark) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-white-hoverFill:active, body button.-color-white-hoverFill:focus,
body a.-color-white-hoverFill:active,
body a.-color-white-hoverFill:focus {
  color: var(--app-color-dark) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-white-hoverFill:hover,
body a.-color-white-hoverFill:hover {
  color: var(--app-color-dark) !important;
  background-color: var(--app-color-grey-translucent) !important;
}
body button.-color-white-hoverFill:disabled,
body a.-color-white-hoverFill:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-white-hoverFill:disabled > i,
body a.-color-white-hoverFill:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-white-hoverFill.-readonly,
body a.-color-white-hoverFill.-readonly {
  pointer-events: none !important;
}
body button.-color-white-translucent,
body a.-color-white-translucent {
  color: var(--app-color-dark) !important;
  background-color: var(--app-color-grey-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-white-translucent:active, body button.-color-white-translucent:focus,
body a.-color-white-translucent:active,
body a.-color-white-translucent:focus {
  color: var(--app-color-dark) !important;
  background-color: var(--app-color-grey-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-white-translucent:hover,
body a.-color-white-translucent:hover {
  color: var(--app-color-dark) !important;
  background-color: var(--app-color-grey-translucent-light) !important;
}
body button.-color-white-translucent:disabled,
body a.-color-white-translucent:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-white-translucent:disabled > i,
body a.-color-white-translucent:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-white-translucent.-readonly,
body a.-color-white-translucent.-readonly {
  pointer-events: none !important;
}
body button.-color-white-outline,
body a.-color-white-outline {
  color: var(--app-color-dark) !important;
  background-color: var(--app-color-white) !important;
  border: 1px solid var(--app-color-dark);
  font-weight: var(--app-font-weight-normal);
  border: var(--app-border) !important;
}
body button.-color-white-outline:active, body button.-color-white-outline:focus,
body a.-color-white-outline:active,
body a.-color-white-outline:focus {
  color: var(--app-color-dark) !important;
  background-color: var(--app-color-white) !important;
  border: 1px solid var(--app-color-dark);
}
body button.-color-white-outline:hover,
body a.-color-white-outline:hover {
  color: var(--app-color-dark) !important;
  background-color: var(--app-color-grey-very-light) !important;
  border: 1px solid var(--app-color-dark);
}
body button.-color-white-outline:disabled,
body a.-color-white-outline:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
  border: var(--app-border-light) !important;
}
body button.-color-white-outline:disabled > i,
body a.-color-white-outline:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-white-outline.-readonly,
body a.-color-white-outline.-readonly {
  pointer-events: none !important;
}
body button.-color-black,
body a.-color-black {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-black) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-black:active,
body a.-color-black:active {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-black) !important;
}
body button.-color-black:hover, body button.-color-black:focus,
body a.-color-black:hover,
body a.-color-black:focus {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-black) !important;
}
body button.-color-black:disabled,
body a.-color-black:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-black:disabled > i,
body a.-color-black:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-black.-readonly,
body a.-color-black.-readonly {
  pointer-events: none !important;
}
body button.-color-black-clear,
body a.-color-black-clear {
  color: var(--app-color-white) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-black-clear:active, body button.-color-black-clear:focus,
body a.-color-black-clear:active,
body a.-color-black-clear:focus {
  color: var(--app-color-white) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-black-clear:hover,
body a.-color-black-clear:hover {
  color: var(--app-color-grey-very-light) !important;
}
body button.-color-black-clear:disabled,
body a.-color-black-clear:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-black-clear:disabled > i,
body a.-color-black-clear:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-black-clear.-readonly,
body a.-color-black-clear.-readonly {
  pointer-events: none !important;
}
body button.-color-black-clear:disabled,
body a.-color-black-clear:disabled {
  background-color: transparent !important;
}
body button.-color-black-clear-grey-border,
body a.-color-black-clear-grey-border {
  color: var(--app-color-white) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
  border: var(--app-border);
  background-color: var(--app-color-white) !important;
}
body button.-color-black-clear-grey-border:active, body button.-color-black-clear-grey-border:focus,
body a.-color-black-clear-grey-border:active,
body a.-color-black-clear-grey-border:focus {
  color: var(--app-color-white) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-black-clear-grey-border:hover,
body a.-color-black-clear-grey-border:hover {
  color: var(--app-color-grey-very-light) !important;
}
body button.-color-black-clear-grey-border:disabled,
body a.-color-black-clear-grey-border:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-black-clear-grey-border:disabled > i,
body a.-color-black-clear-grey-border:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-black-clear-grey-border.-readonly,
body a.-color-black-clear-grey-border.-readonly {
  pointer-events: none !important;
}
body button.-color-black-clear-grey-border:active,
body a.-color-black-clear-grey-border:active {
  background-color: var(--app-color-grey-very-light) !important;
}
body button.-color-black-clear-grey-border:hover,
body a.-color-black-clear-grey-border:hover {
  background-color: var(--app-color-grey-very-light) !important;
}
body button.-color-black-hoverFill,
body a.-color-black-hoverFill {
  color: var(--app-color-white) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-black-hoverFill:active, body button.-color-black-hoverFill:focus,
body a.-color-black-hoverFill:active,
body a.-color-black-hoverFill:focus {
  color: var(--app-color-white) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-black-hoverFill:hover,
body a.-color-black-hoverFill:hover {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-dark) !important;
}
body button.-color-black-hoverFill:disabled,
body a.-color-black-hoverFill:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-black-hoverFill:disabled > i,
body a.-color-black-hoverFill:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-black-hoverFill.-readonly,
body a.-color-black-hoverFill.-readonly {
  pointer-events: none !important;
}
body button.-color-black-translucent,
body a.-color-black-translucent {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-black) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-black-translucent:active, body button.-color-black-translucent:focus,
body a.-color-black-translucent:active,
body a.-color-black-translucent:focus {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-black) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-black-translucent:hover,
body a.-color-black-translucent:hover {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-dark) !important;
}
body button.-color-black-translucent:disabled,
body a.-color-black-translucent:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-black-translucent:disabled > i,
body a.-color-black-translucent:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-black-translucent.-readonly,
body a.-color-black-translucent.-readonly {
  pointer-events: none !important;
}
body button.-color-black-outline,
body a.-color-black-outline {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-black) !important;
  border: 1px solid var(--app-color-white);
  font-weight: var(--app-font-weight-normal);
  border: var(--app-border) !important;
}
body button.-color-black-outline:active, body button.-color-black-outline:focus,
body a.-color-black-outline:active,
body a.-color-black-outline:focus {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-black) !important;
  border: 1px solid var(--app-color-white);
}
body button.-color-black-outline:hover,
body a.-color-black-outline:hover {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-dark) !important;
  border: 1px solid var(--app-color-white);
}
body button.-color-black-outline:disabled,
body a.-color-black-outline:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
  border: var(--app-border-light) !important;
}
body button.-color-black-outline:disabled > i,
body a.-color-black-outline:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-black-outline.-readonly,
body a.-color-black-outline.-readonly {
  pointer-events: none !important;
}
body button.-color-default,
body a.-color-default {
  color: var(--app-color-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-default:active,
body a.-color-default:active {
  color: var(--app-color-dark) !important;
  background-color: var(--app-color-grey-light) !important;
}
body button.-color-default:hover, body button.-color-default:focus,
body a.-color-default:hover,
body a.-color-default:focus {
  color: var(--app-color-dark) !important;
  background-color: var(--app-color-grey-light) !important;
}
body button.-color-default:disabled,
body a.-color-default:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-default:disabled > i,
body a.-color-default:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-default.-readonly,
body a.-color-default.-readonly {
  pointer-events: none !important;
}
body button.-color-default-clear,
body a.-color-default-clear {
  color: var(--app-color-dark) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-default-clear:active, body button.-color-default-clear:focus,
body a.-color-default-clear:active,
body a.-color-default-clear:focus {
  color: var(--app-color-dark) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-default-clear:hover,
body a.-color-default-clear:hover {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-default-clear:disabled,
body a.-color-default-clear:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-default-clear:disabled > i,
body a.-color-default-clear:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-default-clear.-readonly,
body a.-color-default-clear.-readonly {
  pointer-events: none !important;
}
body button.-color-default-clear:disabled,
body a.-color-default-clear:disabled {
  background-color: transparent !important;
}
body button.-color-default-clear-grey-border,
body a.-color-default-clear-grey-border {
  color: var(--app-color-dark) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
  border: var(--app-border);
  background-color: var(--app-color-white) !important;
}
body button.-color-default-clear-grey-border:active, body button.-color-default-clear-grey-border:focus,
body a.-color-default-clear-grey-border:active,
body a.-color-default-clear-grey-border:focus {
  color: var(--app-color-dark) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-default-clear-grey-border:hover,
body a.-color-default-clear-grey-border:hover {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-default-clear-grey-border:disabled,
body a.-color-default-clear-grey-border:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-default-clear-grey-border:disabled > i,
body a.-color-default-clear-grey-border:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-default-clear-grey-border.-readonly,
body a.-color-default-clear-grey-border.-readonly {
  pointer-events: none !important;
}
body button.-color-default-clear-grey-border:active,
body a.-color-default-clear-grey-border:active {
  background-color: var(--app-color-grey-very-light) !important;
}
body button.-color-default-clear-grey-border:hover,
body a.-color-default-clear-grey-border:hover {
  background-color: var(--app-color-grey-very-light) !important;
}
body button.-color-default-hoverFill,
body a.-color-default-hoverFill {
  color: var(--app-color-dark) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-default-hoverFill:active, body button.-color-default-hoverFill:focus,
body a.-color-default-hoverFill:active,
body a.-color-default-hoverFill:focus {
  color: var(--app-color-dark) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-default-hoverFill:hover,
body a.-color-default-hoverFill:hover {
  color: var(--app-color-dark) !important;
  background-color: var(--app-color-grey-translucent) !important;
}
body button.-color-default-hoverFill:disabled,
body a.-color-default-hoverFill:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-default-hoverFill:disabled > i,
body a.-color-default-hoverFill:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-default-hoverFill.-readonly,
body a.-color-default-hoverFill.-readonly {
  pointer-events: none !important;
}
body button.-color-default-translucent,
body a.-color-default-translucent {
  color: var(--app-color-dark) !important;
  background-color: var(--app-color-grey-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-default-translucent:active, body button.-color-default-translucent:focus,
body a.-color-default-translucent:active,
body a.-color-default-translucent:focus {
  color: var(--app-color-dark) !important;
  background-color: var(--app-color-grey-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body button.-color-default-translucent:hover,
body a.-color-default-translucent:hover {
  color: var(--app-color-dark) !important;
  background-color: var(--app-color-grey-translucent-light) !important;
}
body button.-color-default-translucent:disabled,
body a.-color-default-translucent:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body button.-color-default-translucent:disabled > i,
body a.-color-default-translucent:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-default-translucent.-readonly,
body a.-color-default-translucent.-readonly {
  pointer-events: none !important;
}
body button.-color-default-outline,
body a.-color-default-outline {
  color: var(--app-color-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border: 1px solid var(--app-color-dark);
  font-weight: var(--app-font-weight-normal);
  border-color: var(--app-color-grey) !important;
}
body button.-color-default-outline:active, body button.-color-default-outline:focus,
body a.-color-default-outline:active,
body a.-color-default-outline:focus {
  color: var(--app-color-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border: 1px solid var(--app-color-dark);
}
body button.-color-default-outline:hover,
body a.-color-default-outline:hover {
  color: var(--app-color-dark) !important;
  background-color: var(--app-color-grey-translucent-light) !important;
  border: 1px solid var(--app-color-dark);
}
body button.-color-default-outline:disabled,
body a.-color-default-outline:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
  border: var(--app-border-light) !important;
}
body button.-color-default-outline:disabled > i,
body a.-color-default-outline:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body button.-color-default-outline.-readonly,
body a.-color-default-outline.-readonly {
  pointer-events: none !important;
}
body.-theme-invoxy button.-color-success,
body.-theme-invoxy a.-color-success {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-green) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-invoxy button.-color-success:active,
body.-theme-invoxy a.-color-success:active {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-green) !important;
}
body.-theme-invoxy button.-color-success:hover, body.-theme-invoxy button.-color-success:focus,
body.-theme-invoxy a.-color-success:hover,
body.-theme-invoxy a.-color-success:focus {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-green) !important;
}
body.-theme-invoxy button.-color-success:disabled,
body.-theme-invoxy a.-color-success:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-invoxy button.-color-success:disabled > i,
body.-theme-invoxy a.-color-success:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-invoxy button.-color-success.-readonly,
body.-theme-invoxy a.-color-success.-readonly {
  pointer-events: none !important;
}
body.-theme-invoxy button.-color-success-clear,
body.-theme-invoxy a.-color-success-clear {
  color: var(--app-color-green) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-invoxy button.-color-success-clear:active, body.-theme-invoxy button.-color-success-clear:focus,
body.-theme-invoxy a.-color-success-clear:active,
body.-theme-invoxy a.-color-success-clear:focus {
  color: var(--app-color-green) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-invoxy button.-color-success-clear:hover,
body.-theme-invoxy a.-color-success-clear:hover {
  color: var(--app-color-green-dark) !important;
}
body.-theme-invoxy button.-color-success-clear:disabled,
body.-theme-invoxy a.-color-success-clear:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-invoxy button.-color-success-clear:disabled > i,
body.-theme-invoxy a.-color-success-clear:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-invoxy button.-color-success-clear.-readonly,
body.-theme-invoxy a.-color-success-clear.-readonly {
  pointer-events: none !important;
}
body.-theme-invoxy button.-color-success-clear:disabled,
body.-theme-invoxy a.-color-success-clear:disabled {
  background-color: transparent !important;
}
body.-theme-invoxy button.-color-success-clear-grey-border,
body.-theme-invoxy a.-color-success-clear-grey-border {
  color: var(--app-color-green) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
  border: var(--app-border);
  background-color: var(--app-color-white) !important;
}
body.-theme-invoxy button.-color-success-clear-grey-border:active, body.-theme-invoxy button.-color-success-clear-grey-border:focus,
body.-theme-invoxy a.-color-success-clear-grey-border:active,
body.-theme-invoxy a.-color-success-clear-grey-border:focus {
  color: var(--app-color-green) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-invoxy button.-color-success-clear-grey-border:hover,
body.-theme-invoxy a.-color-success-clear-grey-border:hover {
  color: var(--app-color-green-dark) !important;
}
body.-theme-invoxy button.-color-success-clear-grey-border:disabled,
body.-theme-invoxy a.-color-success-clear-grey-border:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-invoxy button.-color-success-clear-grey-border:disabled > i,
body.-theme-invoxy a.-color-success-clear-grey-border:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-invoxy button.-color-success-clear-grey-border.-readonly,
body.-theme-invoxy a.-color-success-clear-grey-border.-readonly {
  pointer-events: none !important;
}
body.-theme-invoxy button.-color-success-clear-grey-border:active,
body.-theme-invoxy a.-color-success-clear-grey-border:active {
  background-color: var(--app-color-grey-very-light) !important;
}
body.-theme-invoxy button.-color-success-clear-grey-border:hover,
body.-theme-invoxy a.-color-success-clear-grey-border:hover {
  background-color: var(--app-color-grey-very-light) !important;
}
body.-theme-invoxy button.-color-success-hoverFill,
body.-theme-invoxy a.-color-success-hoverFill {
  color: var(--app-color-green) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-invoxy button.-color-success-hoverFill:active, body.-theme-invoxy button.-color-success-hoverFill:focus,
body.-theme-invoxy a.-color-success-hoverFill:active,
body.-theme-invoxy a.-color-success-hoverFill:focus {
  color: var(--app-color-green) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-invoxy button.-color-success-hoverFill:hover,
body.-theme-invoxy a.-color-success-hoverFill:hover {
  color: var(--app-color-green) !important;
  background-color: var(--app-color-green-translucent) !important;
}
body.-theme-invoxy button.-color-success-hoverFill:disabled,
body.-theme-invoxy a.-color-success-hoverFill:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-invoxy button.-color-success-hoverFill:disabled > i,
body.-theme-invoxy a.-color-success-hoverFill:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-invoxy button.-color-success-hoverFill.-readonly,
body.-theme-invoxy a.-color-success-hoverFill.-readonly {
  pointer-events: none !important;
}
body.-theme-invoxy button.-color-success-translucent,
body.-theme-invoxy a.-color-success-translucent {
  color: var(--app-color-green) !important;
  background-color: var(--app-color-green-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-invoxy button.-color-success-translucent:active, body.-theme-invoxy button.-color-success-translucent:focus,
body.-theme-invoxy a.-color-success-translucent:active,
body.-theme-invoxy a.-color-success-translucent:focus {
  color: var(--app-color-green) !important;
  background-color: var(--app-color-green-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-invoxy button.-color-success-translucent:hover,
body.-theme-invoxy a.-color-success-translucent:hover {
  color: var(--app-color-green) !important;
  background-color: var(--app-color-green-translucent-light) !important;
}
body.-theme-invoxy button.-color-success-translucent:disabled,
body.-theme-invoxy a.-color-success-translucent:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-invoxy button.-color-success-translucent:disabled > i,
body.-theme-invoxy a.-color-success-translucent:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-invoxy button.-color-success-translucent.-readonly,
body.-theme-invoxy a.-color-success-translucent.-readonly {
  pointer-events: none !important;
}
body.-theme-invoxy button.-color-success-outline,
body.-theme-invoxy a.-color-success-outline {
  color: var(--app-color-green) !important;
  background-color: var(--app-color-green-translucent) !important;
  border: 1px solid var(--app-color-green);
  font-weight: var(--app-font-weight-normal);
}
body.-theme-invoxy button.-color-success-outline:active, body.-theme-invoxy button.-color-success-outline:focus,
body.-theme-invoxy a.-color-success-outline:active,
body.-theme-invoxy a.-color-success-outline:focus {
  color: var(--app-color-green) !important;
  background-color: var(--app-color-green-translucent) !important;
  border: 1px solid var(--app-color-green);
}
body.-theme-invoxy button.-color-success-outline:hover,
body.-theme-invoxy a.-color-success-outline:hover {
  color: var(--app-color-green) !important;
  background-color: var(--app-color-green-translucent-light) !important;
  border: 1px solid var(--app-color-green);
}
body.-theme-invoxy button.-color-success-outline:disabled,
body.-theme-invoxy a.-color-success-outline:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
  border: var(--app-border-light) !important;
}
body.-theme-invoxy button.-color-success-outline:disabled > i,
body.-theme-invoxy a.-color-success-outline:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-invoxy button.-color-success-outline.-readonly,
body.-theme-invoxy a.-color-success-outline.-readonly {
  pointer-events: none !important;
}
body.-theme-invoxy button.-color-product,
body.-theme-invoxy a.-color-product {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-invoxy) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-invoxy button.-color-product:active,
body.-theme-invoxy a.-color-product:active {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-invoxy) !important;
}
body.-theme-invoxy button.-color-product:hover, body.-theme-invoxy button.-color-product:focus,
body.-theme-invoxy a.-color-product:hover,
body.-theme-invoxy a.-color-product:focus {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-invoxy) !important;
}
body.-theme-invoxy button.-color-product:disabled,
body.-theme-invoxy a.-color-product:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-invoxy button.-color-product:disabled > i,
body.-theme-invoxy a.-color-product:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-invoxy button.-color-product.-readonly,
body.-theme-invoxy a.-color-product.-readonly {
  pointer-events: none !important;
}
body.-theme-invoxy button.-color-product-clear,
body.-theme-invoxy a.-color-product-clear {
  color: var(--app-color-invoxy) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-invoxy button.-color-product-clear:active, body.-theme-invoxy button.-color-product-clear:focus,
body.-theme-invoxy a.-color-product-clear:active,
body.-theme-invoxy a.-color-product-clear:focus {
  color: var(--app-color-invoxy) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-invoxy button.-color-product-clear:hover,
body.-theme-invoxy a.-color-product-clear:hover {
  color: var(--app-color-invoxy-dark) !important;
}
body.-theme-invoxy button.-color-product-clear:disabled,
body.-theme-invoxy a.-color-product-clear:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-invoxy button.-color-product-clear:disabled > i,
body.-theme-invoxy a.-color-product-clear:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-invoxy button.-color-product-clear.-readonly,
body.-theme-invoxy a.-color-product-clear.-readonly {
  pointer-events: none !important;
}
body.-theme-invoxy button.-color-product-clear:disabled,
body.-theme-invoxy a.-color-product-clear:disabled {
  background-color: transparent !important;
}
body.-theme-invoxy button.-color-product-clear-grey-border,
body.-theme-invoxy a.-color-product-clear-grey-border {
  color: var(--app-color-invoxy) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
  border: var(--app-border);
  background-color: var(--app-color-white) !important;
}
body.-theme-invoxy button.-color-product-clear-grey-border:active, body.-theme-invoxy button.-color-product-clear-grey-border:focus,
body.-theme-invoxy a.-color-product-clear-grey-border:active,
body.-theme-invoxy a.-color-product-clear-grey-border:focus {
  color: var(--app-color-invoxy) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-invoxy button.-color-product-clear-grey-border:hover,
body.-theme-invoxy a.-color-product-clear-grey-border:hover {
  color: var(--app-color-invoxy-dark) !important;
}
body.-theme-invoxy button.-color-product-clear-grey-border:disabled,
body.-theme-invoxy a.-color-product-clear-grey-border:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-invoxy button.-color-product-clear-grey-border:disabled > i,
body.-theme-invoxy a.-color-product-clear-grey-border:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-invoxy button.-color-product-clear-grey-border.-readonly,
body.-theme-invoxy a.-color-product-clear-grey-border.-readonly {
  pointer-events: none !important;
}
body.-theme-invoxy button.-color-product-clear-grey-border:active,
body.-theme-invoxy a.-color-product-clear-grey-border:active {
  background-color: var(--app-color-grey-very-light) !important;
}
body.-theme-invoxy button.-color-product-clear-grey-border:hover,
body.-theme-invoxy a.-color-product-clear-grey-border:hover {
  background-color: var(--app-color-grey-very-light) !important;
}
body.-theme-invoxy button.-color-product-hoverFill,
body.-theme-invoxy a.-color-product-hoverFill {
  color: var(--app-color-invoxy) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-invoxy button.-color-product-hoverFill:active, body.-theme-invoxy button.-color-product-hoverFill:focus,
body.-theme-invoxy a.-color-product-hoverFill:active,
body.-theme-invoxy a.-color-product-hoverFill:focus {
  color: var(--app-color-invoxy) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-invoxy button.-color-product-hoverFill:hover,
body.-theme-invoxy a.-color-product-hoverFill:hover {
  color: var(--app-color-invoxy) !important;
  background-color: var(--app-color-invoxy-translucent) !important;
}
body.-theme-invoxy button.-color-product-hoverFill:disabled,
body.-theme-invoxy a.-color-product-hoverFill:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-invoxy button.-color-product-hoverFill:disabled > i,
body.-theme-invoxy a.-color-product-hoverFill:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-invoxy button.-color-product-hoverFill.-readonly,
body.-theme-invoxy a.-color-product-hoverFill.-readonly {
  pointer-events: none !important;
}
body.-theme-invoxy button.-color-product-translucent,
body.-theme-invoxy a.-color-product-translucent {
  color: var(--app-color-invoxy) !important;
  background-color: var(--app-color-invoxy-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-invoxy button.-color-product-translucent:active, body.-theme-invoxy button.-color-product-translucent:focus,
body.-theme-invoxy a.-color-product-translucent:active,
body.-theme-invoxy a.-color-product-translucent:focus {
  color: var(--app-color-invoxy) !important;
  background-color: var(--app-color-invoxy-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-invoxy button.-color-product-translucent:hover,
body.-theme-invoxy a.-color-product-translucent:hover {
  color: var(--app-color-invoxy) !important;
  background-color: var(--app-color-invoxy-translucent-light) !important;
}
body.-theme-invoxy button.-color-product-translucent:disabled,
body.-theme-invoxy a.-color-product-translucent:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-invoxy button.-color-product-translucent:disabled > i,
body.-theme-invoxy a.-color-product-translucent:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-invoxy button.-color-product-translucent.-readonly,
body.-theme-invoxy a.-color-product-translucent.-readonly {
  pointer-events: none !important;
}
body.-theme-invoxy button.-color-product-outline,
body.-theme-invoxy a.-color-product-outline {
  color: var(--app-color-invoxy) !important;
  background-color: var(--app-color-invoxy-translucent) !important;
  border: 1px solid var(--app-color-invoxy);
  font-weight: var(--app-font-weight-normal);
}
body.-theme-invoxy button.-color-product-outline:active, body.-theme-invoxy button.-color-product-outline:focus,
body.-theme-invoxy a.-color-product-outline:active,
body.-theme-invoxy a.-color-product-outline:focus {
  color: var(--app-color-invoxy) !important;
  background-color: var(--app-color-invoxy-translucent) !important;
  border: 1px solid var(--app-color-invoxy);
}
body.-theme-invoxy button.-color-product-outline:hover,
body.-theme-invoxy a.-color-product-outline:hover {
  color: var(--app-color-invoxy) !important;
  background-color: var(--app-color-invoxy-translucent-light) !important;
  border: 1px solid var(--app-color-invoxy);
}
body.-theme-invoxy button.-color-product-outline:disabled,
body.-theme-invoxy a.-color-product-outline:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
  border: var(--app-border-light) !important;
}
body.-theme-invoxy button.-color-product-outline:disabled > i,
body.-theme-invoxy a.-color-product-outline:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-invoxy button.-color-product-outline.-readonly,
body.-theme-invoxy a.-color-product-outline.-readonly {
  pointer-events: none !important;
}
body.-theme-invoxy button.-color-accent,
body.-theme-invoxy a.-color-accent {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-invoxy) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-invoxy button.-color-accent:active,
body.-theme-invoxy a.-color-accent:active {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-invoxy) !important;
}
body.-theme-invoxy button.-color-accent:hover, body.-theme-invoxy button.-color-accent:focus,
body.-theme-invoxy a.-color-accent:hover,
body.-theme-invoxy a.-color-accent:focus {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-invoxy) !important;
}
body.-theme-invoxy button.-color-accent:disabled,
body.-theme-invoxy a.-color-accent:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-invoxy button.-color-accent:disabled > i,
body.-theme-invoxy a.-color-accent:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-invoxy button.-color-accent.-readonly,
body.-theme-invoxy a.-color-accent.-readonly {
  pointer-events: none !important;
}
body.-theme-invoxy button.-color-accent-clear,
body.-theme-invoxy a.-color-accent-clear {
  color: var(--app-color-invoxy) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-invoxy button.-color-accent-clear:active, body.-theme-invoxy button.-color-accent-clear:focus,
body.-theme-invoxy a.-color-accent-clear:active,
body.-theme-invoxy a.-color-accent-clear:focus {
  color: var(--app-color-invoxy) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-invoxy button.-color-accent-clear:hover,
body.-theme-invoxy a.-color-accent-clear:hover {
  color: var(--app-color-invoxy-dark) !important;
}
body.-theme-invoxy button.-color-accent-clear:disabled,
body.-theme-invoxy a.-color-accent-clear:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-invoxy button.-color-accent-clear:disabled > i,
body.-theme-invoxy a.-color-accent-clear:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-invoxy button.-color-accent-clear.-readonly,
body.-theme-invoxy a.-color-accent-clear.-readonly {
  pointer-events: none !important;
}
body.-theme-invoxy button.-color-accent-clear:disabled,
body.-theme-invoxy a.-color-accent-clear:disabled {
  background-color: transparent !important;
}
body.-theme-invoxy button.-color-accent-clear-grey-border,
body.-theme-invoxy a.-color-accent-clear-grey-border {
  color: var(--app-color-invoxy) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
  border: var(--app-border);
  background-color: var(--app-color-white) !important;
}
body.-theme-invoxy button.-color-accent-clear-grey-border:active, body.-theme-invoxy button.-color-accent-clear-grey-border:focus,
body.-theme-invoxy a.-color-accent-clear-grey-border:active,
body.-theme-invoxy a.-color-accent-clear-grey-border:focus {
  color: var(--app-color-invoxy) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-invoxy button.-color-accent-clear-grey-border:hover,
body.-theme-invoxy a.-color-accent-clear-grey-border:hover {
  color: var(--app-color-invoxy-dark) !important;
}
body.-theme-invoxy button.-color-accent-clear-grey-border:disabled,
body.-theme-invoxy a.-color-accent-clear-grey-border:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-invoxy button.-color-accent-clear-grey-border:disabled > i,
body.-theme-invoxy a.-color-accent-clear-grey-border:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-invoxy button.-color-accent-clear-grey-border.-readonly,
body.-theme-invoxy a.-color-accent-clear-grey-border.-readonly {
  pointer-events: none !important;
}
body.-theme-invoxy button.-color-accent-clear-grey-border:active,
body.-theme-invoxy a.-color-accent-clear-grey-border:active {
  background-color: var(--app-color-grey-very-light) !important;
}
body.-theme-invoxy button.-color-accent-clear-grey-border:hover,
body.-theme-invoxy a.-color-accent-clear-grey-border:hover {
  background-color: var(--app-color-grey-very-light) !important;
}
body.-theme-invoxy button.-color-accent-hoverFill,
body.-theme-invoxy a.-color-accent-hoverFill {
  color: var(--app-color-invoxy) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-invoxy button.-color-accent-hoverFill:active, body.-theme-invoxy button.-color-accent-hoverFill:focus,
body.-theme-invoxy a.-color-accent-hoverFill:active,
body.-theme-invoxy a.-color-accent-hoverFill:focus {
  color: var(--app-color-invoxy) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-invoxy button.-color-accent-hoverFill:hover,
body.-theme-invoxy a.-color-accent-hoverFill:hover {
  color: var(--app-color-invoxy) !important;
  background-color: var(--app-color-invoxy-translucent) !important;
}
body.-theme-invoxy button.-color-accent-hoverFill:disabled,
body.-theme-invoxy a.-color-accent-hoverFill:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-invoxy button.-color-accent-hoverFill:disabled > i,
body.-theme-invoxy a.-color-accent-hoverFill:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-invoxy button.-color-accent-hoverFill.-readonly,
body.-theme-invoxy a.-color-accent-hoverFill.-readonly {
  pointer-events: none !important;
}
body.-theme-invoxy button.-color-accent-translucent,
body.-theme-invoxy a.-color-accent-translucent {
  color: var(--app-color-invoxy) !important;
  background-color: var(--app-color-invoxy-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-invoxy button.-color-accent-translucent:active, body.-theme-invoxy button.-color-accent-translucent:focus,
body.-theme-invoxy a.-color-accent-translucent:active,
body.-theme-invoxy a.-color-accent-translucent:focus {
  color: var(--app-color-invoxy) !important;
  background-color: var(--app-color-invoxy-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-invoxy button.-color-accent-translucent:hover,
body.-theme-invoxy a.-color-accent-translucent:hover {
  color: var(--app-color-invoxy) !important;
  background-color: var(--app-color-invoxy-translucent-light) !important;
}
body.-theme-invoxy button.-color-accent-translucent:disabled,
body.-theme-invoxy a.-color-accent-translucent:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-invoxy button.-color-accent-translucent:disabled > i,
body.-theme-invoxy a.-color-accent-translucent:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-invoxy button.-color-accent-translucent.-readonly,
body.-theme-invoxy a.-color-accent-translucent.-readonly {
  pointer-events: none !important;
}
body.-theme-invoxy button.-color-accent-outline,
body.-theme-invoxy a.-color-accent-outline {
  color: var(--app-color-invoxy) !important;
  background-color: var(--app-color-invoxy-translucent) !important;
  border: 1px solid var(--app-color-invoxy);
  font-weight: var(--app-font-weight-normal);
}
body.-theme-invoxy button.-color-accent-outline:active, body.-theme-invoxy button.-color-accent-outline:focus,
body.-theme-invoxy a.-color-accent-outline:active,
body.-theme-invoxy a.-color-accent-outline:focus {
  color: var(--app-color-invoxy) !important;
  background-color: var(--app-color-invoxy-translucent) !important;
  border: 1px solid var(--app-color-invoxy);
}
body.-theme-invoxy button.-color-accent-outline:hover,
body.-theme-invoxy a.-color-accent-outline:hover {
  color: var(--app-color-invoxy) !important;
  background-color: var(--app-color-invoxy-translucent-light) !important;
  border: 1px solid var(--app-color-invoxy);
}
body.-theme-invoxy button.-color-accent-outline:disabled,
body.-theme-invoxy a.-color-accent-outline:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
  border: var(--app-border-light) !important;
}
body.-theme-invoxy button.-color-accent-outline:disabled > i,
body.-theme-invoxy a.-color-accent-outline:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-invoxy button.-color-accent-outline.-readonly,
body.-theme-invoxy a.-color-accent-outline.-readonly {
  pointer-events: none !important;
}
body.-theme-invoxy button.-color-primary,
body.-theme-invoxy a.-color-primary {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-flexitime-blue) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-invoxy button.-color-primary:active,
body.-theme-invoxy a.-color-primary:active {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-flexitime-blue) !important;
}
body.-theme-invoxy button.-color-primary:hover, body.-theme-invoxy button.-color-primary:focus,
body.-theme-invoxy a.-color-primary:hover,
body.-theme-invoxy a.-color-primary:focus {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-flexitime-blue) !important;
}
body.-theme-invoxy button.-color-primary:disabled,
body.-theme-invoxy a.-color-primary:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-invoxy button.-color-primary:disabled > i,
body.-theme-invoxy a.-color-primary:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-invoxy button.-color-primary.-readonly,
body.-theme-invoxy a.-color-primary.-readonly {
  pointer-events: none !important;
}
body.-theme-invoxy button.-color-primary-clear,
body.-theme-invoxy a.-color-primary-clear {
  color: var(--app-color-flexitime-blue) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-invoxy button.-color-primary-clear:active, body.-theme-invoxy button.-color-primary-clear:focus,
body.-theme-invoxy a.-color-primary-clear:active,
body.-theme-invoxy a.-color-primary-clear:focus {
  color: var(--app-color-flexitime-blue) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-invoxy button.-color-primary-clear:hover,
body.-theme-invoxy a.-color-primary-clear:hover {
  color: var(--app-color-flexitime-blue-dark) !important;
}
body.-theme-invoxy button.-color-primary-clear:disabled,
body.-theme-invoxy a.-color-primary-clear:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-invoxy button.-color-primary-clear:disabled > i,
body.-theme-invoxy a.-color-primary-clear:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-invoxy button.-color-primary-clear.-readonly,
body.-theme-invoxy a.-color-primary-clear.-readonly {
  pointer-events: none !important;
}
body.-theme-invoxy button.-color-primary-clear:disabled,
body.-theme-invoxy a.-color-primary-clear:disabled {
  background-color: transparent !important;
}
body.-theme-invoxy button.-color-primary-clear-grey-border,
body.-theme-invoxy a.-color-primary-clear-grey-border {
  color: var(--app-color-flexitime-blue) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
  border: var(--app-border);
  background-color: var(--app-color-white) !important;
}
body.-theme-invoxy button.-color-primary-clear-grey-border:active, body.-theme-invoxy button.-color-primary-clear-grey-border:focus,
body.-theme-invoxy a.-color-primary-clear-grey-border:active,
body.-theme-invoxy a.-color-primary-clear-grey-border:focus {
  color: var(--app-color-flexitime-blue) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-invoxy button.-color-primary-clear-grey-border:hover,
body.-theme-invoxy a.-color-primary-clear-grey-border:hover {
  color: var(--app-color-flexitime-blue-dark) !important;
}
body.-theme-invoxy button.-color-primary-clear-grey-border:disabled,
body.-theme-invoxy a.-color-primary-clear-grey-border:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-invoxy button.-color-primary-clear-grey-border:disabled > i,
body.-theme-invoxy a.-color-primary-clear-grey-border:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-invoxy button.-color-primary-clear-grey-border.-readonly,
body.-theme-invoxy a.-color-primary-clear-grey-border.-readonly {
  pointer-events: none !important;
}
body.-theme-invoxy button.-color-primary-clear-grey-border:active,
body.-theme-invoxy a.-color-primary-clear-grey-border:active {
  background-color: var(--app-color-grey-very-light) !important;
}
body.-theme-invoxy button.-color-primary-clear-grey-border:hover,
body.-theme-invoxy a.-color-primary-clear-grey-border:hover {
  background-color: var(--app-color-grey-very-light) !important;
}
body.-theme-invoxy button.-color-primary-hoverFill,
body.-theme-invoxy a.-color-primary-hoverFill {
  color: var(--app-color-flexitime-blue) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-invoxy button.-color-primary-hoverFill:active, body.-theme-invoxy button.-color-primary-hoverFill:focus,
body.-theme-invoxy a.-color-primary-hoverFill:active,
body.-theme-invoxy a.-color-primary-hoverFill:focus {
  color: var(--app-color-flexitime-blue) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-invoxy button.-color-primary-hoverFill:hover,
body.-theme-invoxy a.-color-primary-hoverFill:hover {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-blue-translucent) !important;
}
body.-theme-invoxy button.-color-primary-hoverFill:disabled,
body.-theme-invoxy a.-color-primary-hoverFill:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-invoxy button.-color-primary-hoverFill:disabled > i,
body.-theme-invoxy a.-color-primary-hoverFill:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-invoxy button.-color-primary-hoverFill.-readonly,
body.-theme-invoxy a.-color-primary-hoverFill.-readonly {
  pointer-events: none !important;
}
body.-theme-invoxy button.-color-primary-translucent,
body.-theme-invoxy a.-color-primary-translucent {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-blue-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-invoxy button.-color-primary-translucent:active, body.-theme-invoxy button.-color-primary-translucent:focus,
body.-theme-invoxy a.-color-primary-translucent:active,
body.-theme-invoxy a.-color-primary-translucent:focus {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-blue-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-invoxy button.-color-primary-translucent:hover,
body.-theme-invoxy a.-color-primary-translucent:hover {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-blue-translucent-light) !important;
}
body.-theme-invoxy button.-color-primary-translucent:disabled,
body.-theme-invoxy a.-color-primary-translucent:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-invoxy button.-color-primary-translucent:disabled > i,
body.-theme-invoxy a.-color-primary-translucent:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-invoxy button.-color-primary-translucent.-readonly,
body.-theme-invoxy a.-color-primary-translucent.-readonly {
  pointer-events: none !important;
}
body.-theme-invoxy button.-color-primary-outline,
body.-theme-invoxy a.-color-primary-outline {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-blue-translucent) !important;
  border: 1px solid var(--app-color-flexitime-blue);
  font-weight: var(--app-font-weight-normal);
}
body.-theme-invoxy button.-color-primary-outline:active, body.-theme-invoxy button.-color-primary-outline:focus,
body.-theme-invoxy a.-color-primary-outline:active,
body.-theme-invoxy a.-color-primary-outline:focus {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-blue-translucent) !important;
  border: 1px solid var(--app-color-flexitime-blue);
}
body.-theme-invoxy button.-color-primary-outline:hover,
body.-theme-invoxy a.-color-primary-outline:hover {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-blue-translucent-light) !important;
  border: 1px solid var(--app-color-flexitime-blue);
}
body.-theme-invoxy button.-color-primary-outline:disabled,
body.-theme-invoxy a.-color-primary-outline:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
  border: var(--app-border-light) !important;
}
body.-theme-invoxy button.-color-primary-outline:disabled > i,
body.-theme-invoxy a.-color-primary-outline:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-invoxy button.-color-primary-outline.-readonly,
body.-theme-invoxy a.-color-primary-outline.-readonly {
  pointer-events: none !important;
}
body.-theme-karmly-light button.-color-success,
body.-theme-karmly-light a.-color-success, body.-theme-karmly-dark button.-color-success,
body.-theme-karmly-dark a.-color-success {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-karmly) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-karmly-light button.-color-success:active,
body.-theme-karmly-light a.-color-success:active, body.-theme-karmly-dark button.-color-success:active,
body.-theme-karmly-dark a.-color-success:active {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-karmly) !important;
}
body.-theme-karmly-light button.-color-success:hover, body.-theme-karmly-light button.-color-success:focus,
body.-theme-karmly-light a.-color-success:hover,
body.-theme-karmly-light a.-color-success:focus, body.-theme-karmly-dark button.-color-success:hover, body.-theme-karmly-dark button.-color-success:focus,
body.-theme-karmly-dark a.-color-success:hover,
body.-theme-karmly-dark a.-color-success:focus {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-karmly) !important;
}
body.-theme-karmly-light button.-color-success:disabled,
body.-theme-karmly-light a.-color-success:disabled, body.-theme-karmly-dark button.-color-success:disabled,
body.-theme-karmly-dark a.-color-success:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-karmly-light button.-color-success:disabled > i,
body.-theme-karmly-light a.-color-success:disabled > i, body.-theme-karmly-dark button.-color-success:disabled > i,
body.-theme-karmly-dark a.-color-success:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-karmly-light button.-color-success.-readonly,
body.-theme-karmly-light a.-color-success.-readonly, body.-theme-karmly-dark button.-color-success.-readonly,
body.-theme-karmly-dark a.-color-success.-readonly {
  pointer-events: none !important;
}
body.-theme-karmly-light button.-color-success-clear,
body.-theme-karmly-light a.-color-success-clear, body.-theme-karmly-dark button.-color-success-clear,
body.-theme-karmly-dark a.-color-success-clear {
  color: var(--app-color-karmly) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-karmly-light button.-color-success-clear:active, body.-theme-karmly-light button.-color-success-clear:focus,
body.-theme-karmly-light a.-color-success-clear:active,
body.-theme-karmly-light a.-color-success-clear:focus, body.-theme-karmly-dark button.-color-success-clear:active, body.-theme-karmly-dark button.-color-success-clear:focus,
body.-theme-karmly-dark a.-color-success-clear:active,
body.-theme-karmly-dark a.-color-success-clear:focus {
  color: var(--app-color-karmly) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-karmly-light button.-color-success-clear:hover,
body.-theme-karmly-light a.-color-success-clear:hover, body.-theme-karmly-dark button.-color-success-clear:hover,
body.-theme-karmly-dark a.-color-success-clear:hover {
  color: var(--app-color-karmly-dark) !important;
}
body.-theme-karmly-light button.-color-success-clear:disabled,
body.-theme-karmly-light a.-color-success-clear:disabled, body.-theme-karmly-dark button.-color-success-clear:disabled,
body.-theme-karmly-dark a.-color-success-clear:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-karmly-light button.-color-success-clear:disabled > i,
body.-theme-karmly-light a.-color-success-clear:disabled > i, body.-theme-karmly-dark button.-color-success-clear:disabled > i,
body.-theme-karmly-dark a.-color-success-clear:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-karmly-light button.-color-success-clear.-readonly,
body.-theme-karmly-light a.-color-success-clear.-readonly, body.-theme-karmly-dark button.-color-success-clear.-readonly,
body.-theme-karmly-dark a.-color-success-clear.-readonly {
  pointer-events: none !important;
}
body.-theme-karmly-light button.-color-success-clear:disabled,
body.-theme-karmly-light a.-color-success-clear:disabled, body.-theme-karmly-dark button.-color-success-clear:disabled,
body.-theme-karmly-dark a.-color-success-clear:disabled {
  background-color: transparent !important;
}
body.-theme-karmly-light button.-color-success-clear-grey-border,
body.-theme-karmly-light a.-color-success-clear-grey-border, body.-theme-karmly-dark button.-color-success-clear-grey-border,
body.-theme-karmly-dark a.-color-success-clear-grey-border {
  color: var(--app-color-karmly) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
  border: var(--app-border);
  background-color: var(--app-color-white) !important;
}
body.-theme-karmly-light button.-color-success-clear-grey-border:active, body.-theme-karmly-light button.-color-success-clear-grey-border:focus,
body.-theme-karmly-light a.-color-success-clear-grey-border:active,
body.-theme-karmly-light a.-color-success-clear-grey-border:focus, body.-theme-karmly-dark button.-color-success-clear-grey-border:active, body.-theme-karmly-dark button.-color-success-clear-grey-border:focus,
body.-theme-karmly-dark a.-color-success-clear-grey-border:active,
body.-theme-karmly-dark a.-color-success-clear-grey-border:focus {
  color: var(--app-color-karmly) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-karmly-light button.-color-success-clear-grey-border:hover,
body.-theme-karmly-light a.-color-success-clear-grey-border:hover, body.-theme-karmly-dark button.-color-success-clear-grey-border:hover,
body.-theme-karmly-dark a.-color-success-clear-grey-border:hover {
  color: var(--app-color-karmly-dark) !important;
}
body.-theme-karmly-light button.-color-success-clear-grey-border:disabled,
body.-theme-karmly-light a.-color-success-clear-grey-border:disabled, body.-theme-karmly-dark button.-color-success-clear-grey-border:disabled,
body.-theme-karmly-dark a.-color-success-clear-grey-border:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-karmly-light button.-color-success-clear-grey-border:disabled > i,
body.-theme-karmly-light a.-color-success-clear-grey-border:disabled > i, body.-theme-karmly-dark button.-color-success-clear-grey-border:disabled > i,
body.-theme-karmly-dark a.-color-success-clear-grey-border:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-karmly-light button.-color-success-clear-grey-border.-readonly,
body.-theme-karmly-light a.-color-success-clear-grey-border.-readonly, body.-theme-karmly-dark button.-color-success-clear-grey-border.-readonly,
body.-theme-karmly-dark a.-color-success-clear-grey-border.-readonly {
  pointer-events: none !important;
}
body.-theme-karmly-light button.-color-success-clear-grey-border:active,
body.-theme-karmly-light a.-color-success-clear-grey-border:active, body.-theme-karmly-dark button.-color-success-clear-grey-border:active,
body.-theme-karmly-dark a.-color-success-clear-grey-border:active {
  background-color: var(--app-color-grey-very-light) !important;
}
body.-theme-karmly-light button.-color-success-clear-grey-border:hover,
body.-theme-karmly-light a.-color-success-clear-grey-border:hover, body.-theme-karmly-dark button.-color-success-clear-grey-border:hover,
body.-theme-karmly-dark a.-color-success-clear-grey-border:hover {
  background-color: var(--app-color-grey-very-light) !important;
}
body.-theme-karmly-light button.-color-success-hoverFill,
body.-theme-karmly-light a.-color-success-hoverFill, body.-theme-karmly-dark button.-color-success-hoverFill,
body.-theme-karmly-dark a.-color-success-hoverFill {
  color: var(--app-color-karmly) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-karmly-light button.-color-success-hoverFill:active, body.-theme-karmly-light button.-color-success-hoverFill:focus,
body.-theme-karmly-light a.-color-success-hoverFill:active,
body.-theme-karmly-light a.-color-success-hoverFill:focus, body.-theme-karmly-dark button.-color-success-hoverFill:active, body.-theme-karmly-dark button.-color-success-hoverFill:focus,
body.-theme-karmly-dark a.-color-success-hoverFill:active,
body.-theme-karmly-dark a.-color-success-hoverFill:focus {
  color: var(--app-color-karmly) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-karmly-light button.-color-success-hoverFill:hover,
body.-theme-karmly-light a.-color-success-hoverFill:hover, body.-theme-karmly-dark button.-color-success-hoverFill:hover,
body.-theme-karmly-dark a.-color-success-hoverFill:hover {
  color: var(--app-color-karmly) !important;
  background-color: var(--app-color-karmly-translucent) !important;
}
body.-theme-karmly-light button.-color-success-hoverFill:disabled,
body.-theme-karmly-light a.-color-success-hoverFill:disabled, body.-theme-karmly-dark button.-color-success-hoverFill:disabled,
body.-theme-karmly-dark a.-color-success-hoverFill:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-karmly-light button.-color-success-hoverFill:disabled > i,
body.-theme-karmly-light a.-color-success-hoverFill:disabled > i, body.-theme-karmly-dark button.-color-success-hoverFill:disabled > i,
body.-theme-karmly-dark a.-color-success-hoverFill:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-karmly-light button.-color-success-hoverFill.-readonly,
body.-theme-karmly-light a.-color-success-hoverFill.-readonly, body.-theme-karmly-dark button.-color-success-hoverFill.-readonly,
body.-theme-karmly-dark a.-color-success-hoverFill.-readonly {
  pointer-events: none !important;
}
body.-theme-karmly-light button.-color-success-translucent,
body.-theme-karmly-light a.-color-success-translucent, body.-theme-karmly-dark button.-color-success-translucent,
body.-theme-karmly-dark a.-color-success-translucent {
  color: var(--app-color-karmly) !important;
  background-color: var(--app-color-karmly-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-karmly-light button.-color-success-translucent:active, body.-theme-karmly-light button.-color-success-translucent:focus,
body.-theme-karmly-light a.-color-success-translucent:active,
body.-theme-karmly-light a.-color-success-translucent:focus, body.-theme-karmly-dark button.-color-success-translucent:active, body.-theme-karmly-dark button.-color-success-translucent:focus,
body.-theme-karmly-dark a.-color-success-translucent:active,
body.-theme-karmly-dark a.-color-success-translucent:focus {
  color: var(--app-color-karmly) !important;
  background-color: var(--app-color-karmly-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-karmly-light button.-color-success-translucent:hover,
body.-theme-karmly-light a.-color-success-translucent:hover, body.-theme-karmly-dark button.-color-success-translucent:hover,
body.-theme-karmly-dark a.-color-success-translucent:hover {
  color: var(--app-color-karmly) !important;
  background-color: var(--app-color-karmly-translucent-light) !important;
}
body.-theme-karmly-light button.-color-success-translucent:disabled,
body.-theme-karmly-light a.-color-success-translucent:disabled, body.-theme-karmly-dark button.-color-success-translucent:disabled,
body.-theme-karmly-dark a.-color-success-translucent:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-karmly-light button.-color-success-translucent:disabled > i,
body.-theme-karmly-light a.-color-success-translucent:disabled > i, body.-theme-karmly-dark button.-color-success-translucent:disabled > i,
body.-theme-karmly-dark a.-color-success-translucent:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-karmly-light button.-color-success-translucent.-readonly,
body.-theme-karmly-light a.-color-success-translucent.-readonly, body.-theme-karmly-dark button.-color-success-translucent.-readonly,
body.-theme-karmly-dark a.-color-success-translucent.-readonly {
  pointer-events: none !important;
}
body.-theme-karmly-light button.-color-success-outline,
body.-theme-karmly-light a.-color-success-outline, body.-theme-karmly-dark button.-color-success-outline,
body.-theme-karmly-dark a.-color-success-outline {
  color: var(--app-color-karmly) !important;
  background-color: var(--app-color-karmly-translucent) !important;
  border: 1px solid var(--app-color-karmly);
  font-weight: var(--app-font-weight-normal);
}
body.-theme-karmly-light button.-color-success-outline:active, body.-theme-karmly-light button.-color-success-outline:focus,
body.-theme-karmly-light a.-color-success-outline:active,
body.-theme-karmly-light a.-color-success-outline:focus, body.-theme-karmly-dark button.-color-success-outline:active, body.-theme-karmly-dark button.-color-success-outline:focus,
body.-theme-karmly-dark a.-color-success-outline:active,
body.-theme-karmly-dark a.-color-success-outline:focus {
  color: var(--app-color-karmly) !important;
  background-color: var(--app-color-karmly-translucent) !important;
  border: 1px solid var(--app-color-karmly);
}
body.-theme-karmly-light button.-color-success-outline:hover,
body.-theme-karmly-light a.-color-success-outline:hover, body.-theme-karmly-dark button.-color-success-outline:hover,
body.-theme-karmly-dark a.-color-success-outline:hover {
  color: var(--app-color-karmly) !important;
  background-color: var(--app-color-karmly-translucent-light) !important;
  border: 1px solid var(--app-color-karmly);
}
body.-theme-karmly-light button.-color-success-outline:disabled,
body.-theme-karmly-light a.-color-success-outline:disabled, body.-theme-karmly-dark button.-color-success-outline:disabled,
body.-theme-karmly-dark a.-color-success-outline:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
  border: var(--app-border-light) !important;
}
body.-theme-karmly-light button.-color-success-outline:disabled > i,
body.-theme-karmly-light a.-color-success-outline:disabled > i, body.-theme-karmly-dark button.-color-success-outline:disabled > i,
body.-theme-karmly-dark a.-color-success-outline:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-karmly-light button.-color-success-outline.-readonly,
body.-theme-karmly-light a.-color-success-outline.-readonly, body.-theme-karmly-dark button.-color-success-outline.-readonly,
body.-theme-karmly-dark a.-color-success-outline.-readonly {
  pointer-events: none !important;
}
body.-theme-karmly-light button.-color-product,
body.-theme-karmly-light a.-color-product, body.-theme-karmly-dark button.-color-product,
body.-theme-karmly-dark a.-color-product {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-karmly) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-karmly-light button.-color-product:active,
body.-theme-karmly-light a.-color-product:active, body.-theme-karmly-dark button.-color-product:active,
body.-theme-karmly-dark a.-color-product:active {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-karmly) !important;
}
body.-theme-karmly-light button.-color-product:hover, body.-theme-karmly-light button.-color-product:focus,
body.-theme-karmly-light a.-color-product:hover,
body.-theme-karmly-light a.-color-product:focus, body.-theme-karmly-dark button.-color-product:hover, body.-theme-karmly-dark button.-color-product:focus,
body.-theme-karmly-dark a.-color-product:hover,
body.-theme-karmly-dark a.-color-product:focus {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-karmly) !important;
}
body.-theme-karmly-light button.-color-product:disabled,
body.-theme-karmly-light a.-color-product:disabled, body.-theme-karmly-dark button.-color-product:disabled,
body.-theme-karmly-dark a.-color-product:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-karmly-light button.-color-product:disabled > i,
body.-theme-karmly-light a.-color-product:disabled > i, body.-theme-karmly-dark button.-color-product:disabled > i,
body.-theme-karmly-dark a.-color-product:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-karmly-light button.-color-product.-readonly,
body.-theme-karmly-light a.-color-product.-readonly, body.-theme-karmly-dark button.-color-product.-readonly,
body.-theme-karmly-dark a.-color-product.-readonly {
  pointer-events: none !important;
}
body.-theme-karmly-light button.-color-product-clear,
body.-theme-karmly-light a.-color-product-clear, body.-theme-karmly-dark button.-color-product-clear,
body.-theme-karmly-dark a.-color-product-clear {
  color: var(--app-color-karmly) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-karmly-light button.-color-product-clear:active, body.-theme-karmly-light button.-color-product-clear:focus,
body.-theme-karmly-light a.-color-product-clear:active,
body.-theme-karmly-light a.-color-product-clear:focus, body.-theme-karmly-dark button.-color-product-clear:active, body.-theme-karmly-dark button.-color-product-clear:focus,
body.-theme-karmly-dark a.-color-product-clear:active,
body.-theme-karmly-dark a.-color-product-clear:focus {
  color: var(--app-color-karmly) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-karmly-light button.-color-product-clear:hover,
body.-theme-karmly-light a.-color-product-clear:hover, body.-theme-karmly-dark button.-color-product-clear:hover,
body.-theme-karmly-dark a.-color-product-clear:hover {
  color: var(--app-color-karmly-dark) !important;
}
body.-theme-karmly-light button.-color-product-clear:disabled,
body.-theme-karmly-light a.-color-product-clear:disabled, body.-theme-karmly-dark button.-color-product-clear:disabled,
body.-theme-karmly-dark a.-color-product-clear:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-karmly-light button.-color-product-clear:disabled > i,
body.-theme-karmly-light a.-color-product-clear:disabled > i, body.-theme-karmly-dark button.-color-product-clear:disabled > i,
body.-theme-karmly-dark a.-color-product-clear:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-karmly-light button.-color-product-clear.-readonly,
body.-theme-karmly-light a.-color-product-clear.-readonly, body.-theme-karmly-dark button.-color-product-clear.-readonly,
body.-theme-karmly-dark a.-color-product-clear.-readonly {
  pointer-events: none !important;
}
body.-theme-karmly-light button.-color-product-clear:disabled,
body.-theme-karmly-light a.-color-product-clear:disabled, body.-theme-karmly-dark button.-color-product-clear:disabled,
body.-theme-karmly-dark a.-color-product-clear:disabled {
  background-color: transparent !important;
}
body.-theme-karmly-light button.-color-product-clear-grey-border,
body.-theme-karmly-light a.-color-product-clear-grey-border, body.-theme-karmly-dark button.-color-product-clear-grey-border,
body.-theme-karmly-dark a.-color-product-clear-grey-border {
  color: var(--app-color-karmly) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
  border: var(--app-border);
  background-color: var(--app-color-white) !important;
}
body.-theme-karmly-light button.-color-product-clear-grey-border:active, body.-theme-karmly-light button.-color-product-clear-grey-border:focus,
body.-theme-karmly-light a.-color-product-clear-grey-border:active,
body.-theme-karmly-light a.-color-product-clear-grey-border:focus, body.-theme-karmly-dark button.-color-product-clear-grey-border:active, body.-theme-karmly-dark button.-color-product-clear-grey-border:focus,
body.-theme-karmly-dark a.-color-product-clear-grey-border:active,
body.-theme-karmly-dark a.-color-product-clear-grey-border:focus {
  color: var(--app-color-karmly) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-karmly-light button.-color-product-clear-grey-border:hover,
body.-theme-karmly-light a.-color-product-clear-grey-border:hover, body.-theme-karmly-dark button.-color-product-clear-grey-border:hover,
body.-theme-karmly-dark a.-color-product-clear-grey-border:hover {
  color: var(--app-color-karmly-dark) !important;
}
body.-theme-karmly-light button.-color-product-clear-grey-border:disabled,
body.-theme-karmly-light a.-color-product-clear-grey-border:disabled, body.-theme-karmly-dark button.-color-product-clear-grey-border:disabled,
body.-theme-karmly-dark a.-color-product-clear-grey-border:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-karmly-light button.-color-product-clear-grey-border:disabled > i,
body.-theme-karmly-light a.-color-product-clear-grey-border:disabled > i, body.-theme-karmly-dark button.-color-product-clear-grey-border:disabled > i,
body.-theme-karmly-dark a.-color-product-clear-grey-border:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-karmly-light button.-color-product-clear-grey-border.-readonly,
body.-theme-karmly-light a.-color-product-clear-grey-border.-readonly, body.-theme-karmly-dark button.-color-product-clear-grey-border.-readonly,
body.-theme-karmly-dark a.-color-product-clear-grey-border.-readonly {
  pointer-events: none !important;
}
body.-theme-karmly-light button.-color-product-clear-grey-border:active,
body.-theme-karmly-light a.-color-product-clear-grey-border:active, body.-theme-karmly-dark button.-color-product-clear-grey-border:active,
body.-theme-karmly-dark a.-color-product-clear-grey-border:active {
  background-color: var(--app-color-grey-very-light) !important;
}
body.-theme-karmly-light button.-color-product-clear-grey-border:hover,
body.-theme-karmly-light a.-color-product-clear-grey-border:hover, body.-theme-karmly-dark button.-color-product-clear-grey-border:hover,
body.-theme-karmly-dark a.-color-product-clear-grey-border:hover {
  background-color: var(--app-color-grey-very-light) !important;
}
body.-theme-karmly-light button.-color-product-hoverFill,
body.-theme-karmly-light a.-color-product-hoverFill, body.-theme-karmly-dark button.-color-product-hoverFill,
body.-theme-karmly-dark a.-color-product-hoverFill {
  color: var(--app-color-karmly) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-karmly-light button.-color-product-hoverFill:active, body.-theme-karmly-light button.-color-product-hoverFill:focus,
body.-theme-karmly-light a.-color-product-hoverFill:active,
body.-theme-karmly-light a.-color-product-hoverFill:focus, body.-theme-karmly-dark button.-color-product-hoverFill:active, body.-theme-karmly-dark button.-color-product-hoverFill:focus,
body.-theme-karmly-dark a.-color-product-hoverFill:active,
body.-theme-karmly-dark a.-color-product-hoverFill:focus {
  color: var(--app-color-karmly) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-karmly-light button.-color-product-hoverFill:hover,
body.-theme-karmly-light a.-color-product-hoverFill:hover, body.-theme-karmly-dark button.-color-product-hoverFill:hover,
body.-theme-karmly-dark a.-color-product-hoverFill:hover {
  color: var(--app-color-karmly) !important;
  background-color: var(--app-color-karmly-translucent) !important;
}
body.-theme-karmly-light button.-color-product-hoverFill:disabled,
body.-theme-karmly-light a.-color-product-hoverFill:disabled, body.-theme-karmly-dark button.-color-product-hoverFill:disabled,
body.-theme-karmly-dark a.-color-product-hoverFill:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-karmly-light button.-color-product-hoverFill:disabled > i,
body.-theme-karmly-light a.-color-product-hoverFill:disabled > i, body.-theme-karmly-dark button.-color-product-hoverFill:disabled > i,
body.-theme-karmly-dark a.-color-product-hoverFill:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-karmly-light button.-color-product-hoverFill.-readonly,
body.-theme-karmly-light a.-color-product-hoverFill.-readonly, body.-theme-karmly-dark button.-color-product-hoverFill.-readonly,
body.-theme-karmly-dark a.-color-product-hoverFill.-readonly {
  pointer-events: none !important;
}
body.-theme-karmly-light button.-color-product-translucent,
body.-theme-karmly-light a.-color-product-translucent, body.-theme-karmly-dark button.-color-product-translucent,
body.-theme-karmly-dark a.-color-product-translucent {
  color: var(--app-color-karmly) !important;
  background-color: var(--app-color-karmly-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-karmly-light button.-color-product-translucent:active, body.-theme-karmly-light button.-color-product-translucent:focus,
body.-theme-karmly-light a.-color-product-translucent:active,
body.-theme-karmly-light a.-color-product-translucent:focus, body.-theme-karmly-dark button.-color-product-translucent:active, body.-theme-karmly-dark button.-color-product-translucent:focus,
body.-theme-karmly-dark a.-color-product-translucent:active,
body.-theme-karmly-dark a.-color-product-translucent:focus {
  color: var(--app-color-karmly) !important;
  background-color: var(--app-color-karmly-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-karmly-light button.-color-product-translucent:hover,
body.-theme-karmly-light a.-color-product-translucent:hover, body.-theme-karmly-dark button.-color-product-translucent:hover,
body.-theme-karmly-dark a.-color-product-translucent:hover {
  color: var(--app-color-karmly) !important;
  background-color: var(--app-color-karmly-translucent-light) !important;
}
body.-theme-karmly-light button.-color-product-translucent:disabled,
body.-theme-karmly-light a.-color-product-translucent:disabled, body.-theme-karmly-dark button.-color-product-translucent:disabled,
body.-theme-karmly-dark a.-color-product-translucent:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-karmly-light button.-color-product-translucent:disabled > i,
body.-theme-karmly-light a.-color-product-translucent:disabled > i, body.-theme-karmly-dark button.-color-product-translucent:disabled > i,
body.-theme-karmly-dark a.-color-product-translucent:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-karmly-light button.-color-product-translucent.-readonly,
body.-theme-karmly-light a.-color-product-translucent.-readonly, body.-theme-karmly-dark button.-color-product-translucent.-readonly,
body.-theme-karmly-dark a.-color-product-translucent.-readonly {
  pointer-events: none !important;
}
body.-theme-karmly-light button.-color-product-outline,
body.-theme-karmly-light a.-color-product-outline, body.-theme-karmly-dark button.-color-product-outline,
body.-theme-karmly-dark a.-color-product-outline {
  color: var(--app-color-karmly) !important;
  background-color: var(--app-color-karmly-translucent) !important;
  border: 1px solid var(--app-color-karmly);
  font-weight: var(--app-font-weight-normal);
}
body.-theme-karmly-light button.-color-product-outline:active, body.-theme-karmly-light button.-color-product-outline:focus,
body.-theme-karmly-light a.-color-product-outline:active,
body.-theme-karmly-light a.-color-product-outline:focus, body.-theme-karmly-dark button.-color-product-outline:active, body.-theme-karmly-dark button.-color-product-outline:focus,
body.-theme-karmly-dark a.-color-product-outline:active,
body.-theme-karmly-dark a.-color-product-outline:focus {
  color: var(--app-color-karmly) !important;
  background-color: var(--app-color-karmly-translucent) !important;
  border: 1px solid var(--app-color-karmly);
}
body.-theme-karmly-light button.-color-product-outline:hover,
body.-theme-karmly-light a.-color-product-outline:hover, body.-theme-karmly-dark button.-color-product-outline:hover,
body.-theme-karmly-dark a.-color-product-outline:hover {
  color: var(--app-color-karmly) !important;
  background-color: var(--app-color-karmly-translucent-light) !important;
  border: 1px solid var(--app-color-karmly);
}
body.-theme-karmly-light button.-color-product-outline:disabled,
body.-theme-karmly-light a.-color-product-outline:disabled, body.-theme-karmly-dark button.-color-product-outline:disabled,
body.-theme-karmly-dark a.-color-product-outline:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
  border: var(--app-border-light) !important;
}
body.-theme-karmly-light button.-color-product-outline:disabled > i,
body.-theme-karmly-light a.-color-product-outline:disabled > i, body.-theme-karmly-dark button.-color-product-outline:disabled > i,
body.-theme-karmly-dark a.-color-product-outline:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-karmly-light button.-color-product-outline.-readonly,
body.-theme-karmly-light a.-color-product-outline.-readonly, body.-theme-karmly-dark button.-color-product-outline.-readonly,
body.-theme-karmly-dark a.-color-product-outline.-readonly {
  pointer-events: none !important;
}
body.-theme-karmly-light button.-color-accent,
body.-theme-karmly-light a.-color-accent, body.-theme-karmly-dark button.-color-accent,
body.-theme-karmly-dark a.-color-accent {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-karmly) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-karmly-light button.-color-accent:active,
body.-theme-karmly-light a.-color-accent:active, body.-theme-karmly-dark button.-color-accent:active,
body.-theme-karmly-dark a.-color-accent:active {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-karmly) !important;
}
body.-theme-karmly-light button.-color-accent:hover, body.-theme-karmly-light button.-color-accent:focus,
body.-theme-karmly-light a.-color-accent:hover,
body.-theme-karmly-light a.-color-accent:focus, body.-theme-karmly-dark button.-color-accent:hover, body.-theme-karmly-dark button.-color-accent:focus,
body.-theme-karmly-dark a.-color-accent:hover,
body.-theme-karmly-dark a.-color-accent:focus {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-karmly) !important;
}
body.-theme-karmly-light button.-color-accent:disabled,
body.-theme-karmly-light a.-color-accent:disabled, body.-theme-karmly-dark button.-color-accent:disabled,
body.-theme-karmly-dark a.-color-accent:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-karmly-light button.-color-accent:disabled > i,
body.-theme-karmly-light a.-color-accent:disabled > i, body.-theme-karmly-dark button.-color-accent:disabled > i,
body.-theme-karmly-dark a.-color-accent:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-karmly-light button.-color-accent.-readonly,
body.-theme-karmly-light a.-color-accent.-readonly, body.-theme-karmly-dark button.-color-accent.-readonly,
body.-theme-karmly-dark a.-color-accent.-readonly {
  pointer-events: none !important;
}
body.-theme-karmly-light button.-color-accent-clear,
body.-theme-karmly-light a.-color-accent-clear, body.-theme-karmly-dark button.-color-accent-clear,
body.-theme-karmly-dark a.-color-accent-clear {
  color: var(--app-color-karmly) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-karmly-light button.-color-accent-clear:active, body.-theme-karmly-light button.-color-accent-clear:focus,
body.-theme-karmly-light a.-color-accent-clear:active,
body.-theme-karmly-light a.-color-accent-clear:focus, body.-theme-karmly-dark button.-color-accent-clear:active, body.-theme-karmly-dark button.-color-accent-clear:focus,
body.-theme-karmly-dark a.-color-accent-clear:active,
body.-theme-karmly-dark a.-color-accent-clear:focus {
  color: var(--app-color-karmly) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-karmly-light button.-color-accent-clear:hover,
body.-theme-karmly-light a.-color-accent-clear:hover, body.-theme-karmly-dark button.-color-accent-clear:hover,
body.-theme-karmly-dark a.-color-accent-clear:hover {
  color: var(--app-color-karmly-dark) !important;
}
body.-theme-karmly-light button.-color-accent-clear:disabled,
body.-theme-karmly-light a.-color-accent-clear:disabled, body.-theme-karmly-dark button.-color-accent-clear:disabled,
body.-theme-karmly-dark a.-color-accent-clear:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-karmly-light button.-color-accent-clear:disabled > i,
body.-theme-karmly-light a.-color-accent-clear:disabled > i, body.-theme-karmly-dark button.-color-accent-clear:disabled > i,
body.-theme-karmly-dark a.-color-accent-clear:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-karmly-light button.-color-accent-clear.-readonly,
body.-theme-karmly-light a.-color-accent-clear.-readonly, body.-theme-karmly-dark button.-color-accent-clear.-readonly,
body.-theme-karmly-dark a.-color-accent-clear.-readonly {
  pointer-events: none !important;
}
body.-theme-karmly-light button.-color-accent-clear:disabled,
body.-theme-karmly-light a.-color-accent-clear:disabled, body.-theme-karmly-dark button.-color-accent-clear:disabled,
body.-theme-karmly-dark a.-color-accent-clear:disabled {
  background-color: transparent !important;
}
body.-theme-karmly-light button.-color-accent-clear-grey-border,
body.-theme-karmly-light a.-color-accent-clear-grey-border, body.-theme-karmly-dark button.-color-accent-clear-grey-border,
body.-theme-karmly-dark a.-color-accent-clear-grey-border {
  color: var(--app-color-karmly) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
  border: var(--app-border);
  background-color: var(--app-color-white) !important;
}
body.-theme-karmly-light button.-color-accent-clear-grey-border:active, body.-theme-karmly-light button.-color-accent-clear-grey-border:focus,
body.-theme-karmly-light a.-color-accent-clear-grey-border:active,
body.-theme-karmly-light a.-color-accent-clear-grey-border:focus, body.-theme-karmly-dark button.-color-accent-clear-grey-border:active, body.-theme-karmly-dark button.-color-accent-clear-grey-border:focus,
body.-theme-karmly-dark a.-color-accent-clear-grey-border:active,
body.-theme-karmly-dark a.-color-accent-clear-grey-border:focus {
  color: var(--app-color-karmly) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-karmly-light button.-color-accent-clear-grey-border:hover,
body.-theme-karmly-light a.-color-accent-clear-grey-border:hover, body.-theme-karmly-dark button.-color-accent-clear-grey-border:hover,
body.-theme-karmly-dark a.-color-accent-clear-grey-border:hover {
  color: var(--app-color-karmly-dark) !important;
}
body.-theme-karmly-light button.-color-accent-clear-grey-border:disabled,
body.-theme-karmly-light a.-color-accent-clear-grey-border:disabled, body.-theme-karmly-dark button.-color-accent-clear-grey-border:disabled,
body.-theme-karmly-dark a.-color-accent-clear-grey-border:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-karmly-light button.-color-accent-clear-grey-border:disabled > i,
body.-theme-karmly-light a.-color-accent-clear-grey-border:disabled > i, body.-theme-karmly-dark button.-color-accent-clear-grey-border:disabled > i,
body.-theme-karmly-dark a.-color-accent-clear-grey-border:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-karmly-light button.-color-accent-clear-grey-border.-readonly,
body.-theme-karmly-light a.-color-accent-clear-grey-border.-readonly, body.-theme-karmly-dark button.-color-accent-clear-grey-border.-readonly,
body.-theme-karmly-dark a.-color-accent-clear-grey-border.-readonly {
  pointer-events: none !important;
}
body.-theme-karmly-light button.-color-accent-clear-grey-border:active,
body.-theme-karmly-light a.-color-accent-clear-grey-border:active, body.-theme-karmly-dark button.-color-accent-clear-grey-border:active,
body.-theme-karmly-dark a.-color-accent-clear-grey-border:active {
  background-color: var(--app-color-grey-very-light) !important;
}
body.-theme-karmly-light button.-color-accent-clear-grey-border:hover,
body.-theme-karmly-light a.-color-accent-clear-grey-border:hover, body.-theme-karmly-dark button.-color-accent-clear-grey-border:hover,
body.-theme-karmly-dark a.-color-accent-clear-grey-border:hover {
  background-color: var(--app-color-grey-very-light) !important;
}
body.-theme-karmly-light button.-color-accent-hoverFill,
body.-theme-karmly-light a.-color-accent-hoverFill, body.-theme-karmly-dark button.-color-accent-hoverFill,
body.-theme-karmly-dark a.-color-accent-hoverFill {
  color: var(--app-color-karmly) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-karmly-light button.-color-accent-hoverFill:active, body.-theme-karmly-light button.-color-accent-hoverFill:focus,
body.-theme-karmly-light a.-color-accent-hoverFill:active,
body.-theme-karmly-light a.-color-accent-hoverFill:focus, body.-theme-karmly-dark button.-color-accent-hoverFill:active, body.-theme-karmly-dark button.-color-accent-hoverFill:focus,
body.-theme-karmly-dark a.-color-accent-hoverFill:active,
body.-theme-karmly-dark a.-color-accent-hoverFill:focus {
  color: var(--app-color-karmly) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-karmly-light button.-color-accent-hoverFill:hover,
body.-theme-karmly-light a.-color-accent-hoverFill:hover, body.-theme-karmly-dark button.-color-accent-hoverFill:hover,
body.-theme-karmly-dark a.-color-accent-hoverFill:hover {
  color: var(--app-color-karmly) !important;
  background-color: var(--app-color-karmly-translucent) !important;
}
body.-theme-karmly-light button.-color-accent-hoverFill:disabled,
body.-theme-karmly-light a.-color-accent-hoverFill:disabled, body.-theme-karmly-dark button.-color-accent-hoverFill:disabled,
body.-theme-karmly-dark a.-color-accent-hoverFill:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-karmly-light button.-color-accent-hoverFill:disabled > i,
body.-theme-karmly-light a.-color-accent-hoverFill:disabled > i, body.-theme-karmly-dark button.-color-accent-hoverFill:disabled > i,
body.-theme-karmly-dark a.-color-accent-hoverFill:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-karmly-light button.-color-accent-hoverFill.-readonly,
body.-theme-karmly-light a.-color-accent-hoverFill.-readonly, body.-theme-karmly-dark button.-color-accent-hoverFill.-readonly,
body.-theme-karmly-dark a.-color-accent-hoverFill.-readonly {
  pointer-events: none !important;
}
body.-theme-karmly-light button.-color-accent-translucent,
body.-theme-karmly-light a.-color-accent-translucent, body.-theme-karmly-dark button.-color-accent-translucent,
body.-theme-karmly-dark a.-color-accent-translucent {
  color: var(--app-color-karmly) !important;
  background-color: var(--app-color-karmly-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-karmly-light button.-color-accent-translucent:active, body.-theme-karmly-light button.-color-accent-translucent:focus,
body.-theme-karmly-light a.-color-accent-translucent:active,
body.-theme-karmly-light a.-color-accent-translucent:focus, body.-theme-karmly-dark button.-color-accent-translucent:active, body.-theme-karmly-dark button.-color-accent-translucent:focus,
body.-theme-karmly-dark a.-color-accent-translucent:active,
body.-theme-karmly-dark a.-color-accent-translucent:focus {
  color: var(--app-color-karmly) !important;
  background-color: var(--app-color-karmly-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-karmly-light button.-color-accent-translucent:hover,
body.-theme-karmly-light a.-color-accent-translucent:hover, body.-theme-karmly-dark button.-color-accent-translucent:hover,
body.-theme-karmly-dark a.-color-accent-translucent:hover {
  color: var(--app-color-karmly) !important;
  background-color: var(--app-color-karmly-translucent-light) !important;
}
body.-theme-karmly-light button.-color-accent-translucent:disabled,
body.-theme-karmly-light a.-color-accent-translucent:disabled, body.-theme-karmly-dark button.-color-accent-translucent:disabled,
body.-theme-karmly-dark a.-color-accent-translucent:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-karmly-light button.-color-accent-translucent:disabled > i,
body.-theme-karmly-light a.-color-accent-translucent:disabled > i, body.-theme-karmly-dark button.-color-accent-translucent:disabled > i,
body.-theme-karmly-dark a.-color-accent-translucent:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-karmly-light button.-color-accent-translucent.-readonly,
body.-theme-karmly-light a.-color-accent-translucent.-readonly, body.-theme-karmly-dark button.-color-accent-translucent.-readonly,
body.-theme-karmly-dark a.-color-accent-translucent.-readonly {
  pointer-events: none !important;
}
body.-theme-karmly-light button.-color-accent-outline,
body.-theme-karmly-light a.-color-accent-outline, body.-theme-karmly-dark button.-color-accent-outline,
body.-theme-karmly-dark a.-color-accent-outline {
  color: var(--app-color-karmly) !important;
  background-color: var(--app-color-karmly-translucent) !important;
  border: 1px solid var(--app-color-karmly);
  font-weight: var(--app-font-weight-normal);
}
body.-theme-karmly-light button.-color-accent-outline:active, body.-theme-karmly-light button.-color-accent-outline:focus,
body.-theme-karmly-light a.-color-accent-outline:active,
body.-theme-karmly-light a.-color-accent-outline:focus, body.-theme-karmly-dark button.-color-accent-outline:active, body.-theme-karmly-dark button.-color-accent-outline:focus,
body.-theme-karmly-dark a.-color-accent-outline:active,
body.-theme-karmly-dark a.-color-accent-outline:focus {
  color: var(--app-color-karmly) !important;
  background-color: var(--app-color-karmly-translucent) !important;
  border: 1px solid var(--app-color-karmly);
}
body.-theme-karmly-light button.-color-accent-outline:hover,
body.-theme-karmly-light a.-color-accent-outline:hover, body.-theme-karmly-dark button.-color-accent-outline:hover,
body.-theme-karmly-dark a.-color-accent-outline:hover {
  color: var(--app-color-karmly) !important;
  background-color: var(--app-color-karmly-translucent-light) !important;
  border: 1px solid var(--app-color-karmly);
}
body.-theme-karmly-light button.-color-accent-outline:disabled,
body.-theme-karmly-light a.-color-accent-outline:disabled, body.-theme-karmly-dark button.-color-accent-outline:disabled,
body.-theme-karmly-dark a.-color-accent-outline:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
  border: var(--app-border-light) !important;
}
body.-theme-karmly-light button.-color-accent-outline:disabled > i,
body.-theme-karmly-light a.-color-accent-outline:disabled > i, body.-theme-karmly-dark button.-color-accent-outline:disabled > i,
body.-theme-karmly-dark a.-color-accent-outline:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-karmly-light button.-color-accent-outline.-readonly,
body.-theme-karmly-light a.-color-accent-outline.-readonly, body.-theme-karmly-dark button.-color-accent-outline.-readonly,
body.-theme-karmly-dark a.-color-accent-outline.-readonly {
  pointer-events: none !important;
}
body.-theme-karmly-light button.-color-primary,
body.-theme-karmly-light a.-color-primary, body.-theme-karmly-dark button.-color-primary,
body.-theme-karmly-dark a.-color-primary {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-flexitime-blue) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-karmly-light button.-color-primary:active,
body.-theme-karmly-light a.-color-primary:active, body.-theme-karmly-dark button.-color-primary:active,
body.-theme-karmly-dark a.-color-primary:active {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-flexitime-blue) !important;
}
body.-theme-karmly-light button.-color-primary:hover, body.-theme-karmly-light button.-color-primary:focus,
body.-theme-karmly-light a.-color-primary:hover,
body.-theme-karmly-light a.-color-primary:focus, body.-theme-karmly-dark button.-color-primary:hover, body.-theme-karmly-dark button.-color-primary:focus,
body.-theme-karmly-dark a.-color-primary:hover,
body.-theme-karmly-dark a.-color-primary:focus {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-flexitime-blue) !important;
}
body.-theme-karmly-light button.-color-primary:disabled,
body.-theme-karmly-light a.-color-primary:disabled, body.-theme-karmly-dark button.-color-primary:disabled,
body.-theme-karmly-dark a.-color-primary:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-karmly-light button.-color-primary:disabled > i,
body.-theme-karmly-light a.-color-primary:disabled > i, body.-theme-karmly-dark button.-color-primary:disabled > i,
body.-theme-karmly-dark a.-color-primary:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-karmly-light button.-color-primary.-readonly,
body.-theme-karmly-light a.-color-primary.-readonly, body.-theme-karmly-dark button.-color-primary.-readonly,
body.-theme-karmly-dark a.-color-primary.-readonly {
  pointer-events: none !important;
}
body.-theme-karmly-light button.-color-primary-clear,
body.-theme-karmly-light a.-color-primary-clear, body.-theme-karmly-dark button.-color-primary-clear,
body.-theme-karmly-dark a.-color-primary-clear {
  color: var(--app-color-flexitime-blue) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-karmly-light button.-color-primary-clear:active, body.-theme-karmly-light button.-color-primary-clear:focus,
body.-theme-karmly-light a.-color-primary-clear:active,
body.-theme-karmly-light a.-color-primary-clear:focus, body.-theme-karmly-dark button.-color-primary-clear:active, body.-theme-karmly-dark button.-color-primary-clear:focus,
body.-theme-karmly-dark a.-color-primary-clear:active,
body.-theme-karmly-dark a.-color-primary-clear:focus {
  color: var(--app-color-flexitime-blue) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-karmly-light button.-color-primary-clear:hover,
body.-theme-karmly-light a.-color-primary-clear:hover, body.-theme-karmly-dark button.-color-primary-clear:hover,
body.-theme-karmly-dark a.-color-primary-clear:hover {
  color: var(--app-color-flexitime-blue-dark) !important;
}
body.-theme-karmly-light button.-color-primary-clear:disabled,
body.-theme-karmly-light a.-color-primary-clear:disabled, body.-theme-karmly-dark button.-color-primary-clear:disabled,
body.-theme-karmly-dark a.-color-primary-clear:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-karmly-light button.-color-primary-clear:disabled > i,
body.-theme-karmly-light a.-color-primary-clear:disabled > i, body.-theme-karmly-dark button.-color-primary-clear:disabled > i,
body.-theme-karmly-dark a.-color-primary-clear:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-karmly-light button.-color-primary-clear.-readonly,
body.-theme-karmly-light a.-color-primary-clear.-readonly, body.-theme-karmly-dark button.-color-primary-clear.-readonly,
body.-theme-karmly-dark a.-color-primary-clear.-readonly {
  pointer-events: none !important;
}
body.-theme-karmly-light button.-color-primary-clear:disabled,
body.-theme-karmly-light a.-color-primary-clear:disabled, body.-theme-karmly-dark button.-color-primary-clear:disabled,
body.-theme-karmly-dark a.-color-primary-clear:disabled {
  background-color: transparent !important;
}
body.-theme-karmly-light button.-color-primary-clear-grey-border,
body.-theme-karmly-light a.-color-primary-clear-grey-border, body.-theme-karmly-dark button.-color-primary-clear-grey-border,
body.-theme-karmly-dark a.-color-primary-clear-grey-border {
  color: var(--app-color-flexitime-blue) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
  border: var(--app-border);
  background-color: var(--app-color-white) !important;
}
body.-theme-karmly-light button.-color-primary-clear-grey-border:active, body.-theme-karmly-light button.-color-primary-clear-grey-border:focus,
body.-theme-karmly-light a.-color-primary-clear-grey-border:active,
body.-theme-karmly-light a.-color-primary-clear-grey-border:focus, body.-theme-karmly-dark button.-color-primary-clear-grey-border:active, body.-theme-karmly-dark button.-color-primary-clear-grey-border:focus,
body.-theme-karmly-dark a.-color-primary-clear-grey-border:active,
body.-theme-karmly-dark a.-color-primary-clear-grey-border:focus {
  color: var(--app-color-flexitime-blue) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-karmly-light button.-color-primary-clear-grey-border:hover,
body.-theme-karmly-light a.-color-primary-clear-grey-border:hover, body.-theme-karmly-dark button.-color-primary-clear-grey-border:hover,
body.-theme-karmly-dark a.-color-primary-clear-grey-border:hover {
  color: var(--app-color-flexitime-blue-dark) !important;
}
body.-theme-karmly-light button.-color-primary-clear-grey-border:disabled,
body.-theme-karmly-light a.-color-primary-clear-grey-border:disabled, body.-theme-karmly-dark button.-color-primary-clear-grey-border:disabled,
body.-theme-karmly-dark a.-color-primary-clear-grey-border:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-karmly-light button.-color-primary-clear-grey-border:disabled > i,
body.-theme-karmly-light a.-color-primary-clear-grey-border:disabled > i, body.-theme-karmly-dark button.-color-primary-clear-grey-border:disabled > i,
body.-theme-karmly-dark a.-color-primary-clear-grey-border:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-karmly-light button.-color-primary-clear-grey-border.-readonly,
body.-theme-karmly-light a.-color-primary-clear-grey-border.-readonly, body.-theme-karmly-dark button.-color-primary-clear-grey-border.-readonly,
body.-theme-karmly-dark a.-color-primary-clear-grey-border.-readonly {
  pointer-events: none !important;
}
body.-theme-karmly-light button.-color-primary-clear-grey-border:active,
body.-theme-karmly-light a.-color-primary-clear-grey-border:active, body.-theme-karmly-dark button.-color-primary-clear-grey-border:active,
body.-theme-karmly-dark a.-color-primary-clear-grey-border:active {
  background-color: var(--app-color-grey-very-light) !important;
}
body.-theme-karmly-light button.-color-primary-clear-grey-border:hover,
body.-theme-karmly-light a.-color-primary-clear-grey-border:hover, body.-theme-karmly-dark button.-color-primary-clear-grey-border:hover,
body.-theme-karmly-dark a.-color-primary-clear-grey-border:hover {
  background-color: var(--app-color-grey-very-light) !important;
}
body.-theme-karmly-light button.-color-primary-hoverFill,
body.-theme-karmly-light a.-color-primary-hoverFill, body.-theme-karmly-dark button.-color-primary-hoverFill,
body.-theme-karmly-dark a.-color-primary-hoverFill {
  color: var(--app-color-flexitime-blue) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-karmly-light button.-color-primary-hoverFill:active, body.-theme-karmly-light button.-color-primary-hoverFill:focus,
body.-theme-karmly-light a.-color-primary-hoverFill:active,
body.-theme-karmly-light a.-color-primary-hoverFill:focus, body.-theme-karmly-dark button.-color-primary-hoverFill:active, body.-theme-karmly-dark button.-color-primary-hoverFill:focus,
body.-theme-karmly-dark a.-color-primary-hoverFill:active,
body.-theme-karmly-dark a.-color-primary-hoverFill:focus {
  color: var(--app-color-flexitime-blue) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-karmly-light button.-color-primary-hoverFill:hover,
body.-theme-karmly-light a.-color-primary-hoverFill:hover, body.-theme-karmly-dark button.-color-primary-hoverFill:hover,
body.-theme-karmly-dark a.-color-primary-hoverFill:hover {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-blue-translucent) !important;
}
body.-theme-karmly-light button.-color-primary-hoverFill:disabled,
body.-theme-karmly-light a.-color-primary-hoverFill:disabled, body.-theme-karmly-dark button.-color-primary-hoverFill:disabled,
body.-theme-karmly-dark a.-color-primary-hoverFill:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-karmly-light button.-color-primary-hoverFill:disabled > i,
body.-theme-karmly-light a.-color-primary-hoverFill:disabled > i, body.-theme-karmly-dark button.-color-primary-hoverFill:disabled > i,
body.-theme-karmly-dark a.-color-primary-hoverFill:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-karmly-light button.-color-primary-hoverFill.-readonly,
body.-theme-karmly-light a.-color-primary-hoverFill.-readonly, body.-theme-karmly-dark button.-color-primary-hoverFill.-readonly,
body.-theme-karmly-dark a.-color-primary-hoverFill.-readonly {
  pointer-events: none !important;
}
body.-theme-karmly-light button.-color-primary-translucent,
body.-theme-karmly-light a.-color-primary-translucent, body.-theme-karmly-dark button.-color-primary-translucent,
body.-theme-karmly-dark a.-color-primary-translucent {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-blue-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-karmly-light button.-color-primary-translucent:active, body.-theme-karmly-light button.-color-primary-translucent:focus,
body.-theme-karmly-light a.-color-primary-translucent:active,
body.-theme-karmly-light a.-color-primary-translucent:focus, body.-theme-karmly-dark button.-color-primary-translucent:active, body.-theme-karmly-dark button.-color-primary-translucent:focus,
body.-theme-karmly-dark a.-color-primary-translucent:active,
body.-theme-karmly-dark a.-color-primary-translucent:focus {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-blue-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-karmly-light button.-color-primary-translucent:hover,
body.-theme-karmly-light a.-color-primary-translucent:hover, body.-theme-karmly-dark button.-color-primary-translucent:hover,
body.-theme-karmly-dark a.-color-primary-translucent:hover {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-blue-translucent-light) !important;
}
body.-theme-karmly-light button.-color-primary-translucent:disabled,
body.-theme-karmly-light a.-color-primary-translucent:disabled, body.-theme-karmly-dark button.-color-primary-translucent:disabled,
body.-theme-karmly-dark a.-color-primary-translucent:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-karmly-light button.-color-primary-translucent:disabled > i,
body.-theme-karmly-light a.-color-primary-translucent:disabled > i, body.-theme-karmly-dark button.-color-primary-translucent:disabled > i,
body.-theme-karmly-dark a.-color-primary-translucent:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-karmly-light button.-color-primary-translucent.-readonly,
body.-theme-karmly-light a.-color-primary-translucent.-readonly, body.-theme-karmly-dark button.-color-primary-translucent.-readonly,
body.-theme-karmly-dark a.-color-primary-translucent.-readonly {
  pointer-events: none !important;
}
body.-theme-karmly-light button.-color-primary-outline,
body.-theme-karmly-light a.-color-primary-outline, body.-theme-karmly-dark button.-color-primary-outline,
body.-theme-karmly-dark a.-color-primary-outline {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-blue-translucent) !important;
  border: 1px solid var(--app-color-flexitime-blue);
  font-weight: var(--app-font-weight-normal);
}
body.-theme-karmly-light button.-color-primary-outline:active, body.-theme-karmly-light button.-color-primary-outline:focus,
body.-theme-karmly-light a.-color-primary-outline:active,
body.-theme-karmly-light a.-color-primary-outline:focus, body.-theme-karmly-dark button.-color-primary-outline:active, body.-theme-karmly-dark button.-color-primary-outline:focus,
body.-theme-karmly-dark a.-color-primary-outline:active,
body.-theme-karmly-dark a.-color-primary-outline:focus {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-blue-translucent) !important;
  border: 1px solid var(--app-color-flexitime-blue);
}
body.-theme-karmly-light button.-color-primary-outline:hover,
body.-theme-karmly-light a.-color-primary-outline:hover, body.-theme-karmly-dark button.-color-primary-outline:hover,
body.-theme-karmly-dark a.-color-primary-outline:hover {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-blue-translucent-light) !important;
  border: 1px solid var(--app-color-flexitime-blue);
}
body.-theme-karmly-light button.-color-primary-outline:disabled,
body.-theme-karmly-light a.-color-primary-outline:disabled, body.-theme-karmly-dark button.-color-primary-outline:disabled,
body.-theme-karmly-dark a.-color-primary-outline:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
  border: var(--app-border-light) !important;
}
body.-theme-karmly-light button.-color-primary-outline:disabled > i,
body.-theme-karmly-light a.-color-primary-outline:disabled > i, body.-theme-karmly-dark button.-color-primary-outline:disabled > i,
body.-theme-karmly-dark a.-color-primary-outline:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-karmly-light button.-color-primary-outline.-readonly,
body.-theme-karmly-light a.-color-primary-outline.-readonly, body.-theme-karmly-dark button.-color-primary-outline.-readonly,
body.-theme-karmly-dark a.-color-primary-outline.-readonly {
  pointer-events: none !important;
}
body.-theme-droppah button.-color-success,
body.-theme-droppah a.-color-success {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-droppah-purple) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-droppah button.-color-success:active,
body.-theme-droppah a.-color-success:active {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-droppah-purple) !important;
}
body.-theme-droppah button.-color-success:hover, body.-theme-droppah button.-color-success:focus,
body.-theme-droppah a.-color-success:hover,
body.-theme-droppah a.-color-success:focus {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-droppah-purple) !important;
}
body.-theme-droppah button.-color-success:disabled,
body.-theme-droppah a.-color-success:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-droppah button.-color-success:disabled > i,
body.-theme-droppah a.-color-success:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-droppah button.-color-success.-readonly,
body.-theme-droppah a.-color-success.-readonly {
  pointer-events: none !important;
}
body.-theme-droppah button.-color-success-clear,
body.-theme-droppah a.-color-success-clear {
  color: var(--app-color-droppah-purple) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-droppah button.-color-success-clear:active, body.-theme-droppah button.-color-success-clear:focus,
body.-theme-droppah a.-color-success-clear:active,
body.-theme-droppah a.-color-success-clear:focus {
  color: var(--app-color-droppah-purple) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-droppah button.-color-success-clear:hover,
body.-theme-droppah a.-color-success-clear:hover {
  color: var(--app-color-droppah-purple-dark) !important;
}
body.-theme-droppah button.-color-success-clear:disabled,
body.-theme-droppah a.-color-success-clear:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-droppah button.-color-success-clear:disabled > i,
body.-theme-droppah a.-color-success-clear:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-droppah button.-color-success-clear.-readonly,
body.-theme-droppah a.-color-success-clear.-readonly {
  pointer-events: none !important;
}
body.-theme-droppah button.-color-success-clear:disabled,
body.-theme-droppah a.-color-success-clear:disabled {
  background-color: transparent !important;
}
body.-theme-droppah button.-color-success-clear-grey-border,
body.-theme-droppah a.-color-success-clear-grey-border {
  color: var(--app-color-droppah-purple) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
  border: var(--app-border);
  background-color: var(--app-color-white) !important;
}
body.-theme-droppah button.-color-success-clear-grey-border:active, body.-theme-droppah button.-color-success-clear-grey-border:focus,
body.-theme-droppah a.-color-success-clear-grey-border:active,
body.-theme-droppah a.-color-success-clear-grey-border:focus {
  color: var(--app-color-droppah-purple) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-droppah button.-color-success-clear-grey-border:hover,
body.-theme-droppah a.-color-success-clear-grey-border:hover {
  color: var(--app-color-droppah-purple-dark) !important;
}
body.-theme-droppah button.-color-success-clear-grey-border:disabled,
body.-theme-droppah a.-color-success-clear-grey-border:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-droppah button.-color-success-clear-grey-border:disabled > i,
body.-theme-droppah a.-color-success-clear-grey-border:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-droppah button.-color-success-clear-grey-border.-readonly,
body.-theme-droppah a.-color-success-clear-grey-border.-readonly {
  pointer-events: none !important;
}
body.-theme-droppah button.-color-success-clear-grey-border:active,
body.-theme-droppah a.-color-success-clear-grey-border:active {
  background-color: var(--app-color-grey-very-light) !important;
}
body.-theme-droppah button.-color-success-clear-grey-border:hover,
body.-theme-droppah a.-color-success-clear-grey-border:hover {
  background-color: var(--app-color-grey-very-light) !important;
}
body.-theme-droppah button.-color-success-hoverFill,
body.-theme-droppah a.-color-success-hoverFill {
  color: var(--app-color-droppah-purple) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-droppah button.-color-success-hoverFill:active, body.-theme-droppah button.-color-success-hoverFill:focus,
body.-theme-droppah a.-color-success-hoverFill:active,
body.-theme-droppah a.-color-success-hoverFill:focus {
  color: var(--app-color-droppah-purple) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-droppah button.-color-success-hoverFill:hover,
body.-theme-droppah a.-color-success-hoverFill:hover {
  color: var(--app-color-droppah-purple) !important;
  background-color: var(--app-color-droppah-purple-translucent) !important;
}
body.-theme-droppah button.-color-success-hoverFill:disabled,
body.-theme-droppah a.-color-success-hoverFill:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-droppah button.-color-success-hoverFill:disabled > i,
body.-theme-droppah a.-color-success-hoverFill:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-droppah button.-color-success-hoverFill.-readonly,
body.-theme-droppah a.-color-success-hoverFill.-readonly {
  pointer-events: none !important;
}
body.-theme-droppah button.-color-success-translucent,
body.-theme-droppah a.-color-success-translucent {
  color: var(--app-color-droppah-purple) !important;
  background-color: var(--app-color-droppah-purple-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-droppah button.-color-success-translucent:active, body.-theme-droppah button.-color-success-translucent:focus,
body.-theme-droppah a.-color-success-translucent:active,
body.-theme-droppah a.-color-success-translucent:focus {
  color: var(--app-color-droppah-purple) !important;
  background-color: var(--app-color-droppah-purple-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-droppah button.-color-success-translucent:hover,
body.-theme-droppah a.-color-success-translucent:hover {
  color: var(--app-color-droppah-purple) !important;
  background-color: var(--app-color-droppah-purple-translucent-light) !important;
}
body.-theme-droppah button.-color-success-translucent:disabled,
body.-theme-droppah a.-color-success-translucent:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-droppah button.-color-success-translucent:disabled > i,
body.-theme-droppah a.-color-success-translucent:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-droppah button.-color-success-translucent.-readonly,
body.-theme-droppah a.-color-success-translucent.-readonly {
  pointer-events: none !important;
}
body.-theme-droppah button.-color-success-outline,
body.-theme-droppah a.-color-success-outline {
  color: var(--app-color-droppah-purple) !important;
  background-color: var(--app-color-droppah-purple-translucent) !important;
  border: 1px solid var(--app-color-droppah-purple);
  font-weight: var(--app-font-weight-normal);
}
body.-theme-droppah button.-color-success-outline:active, body.-theme-droppah button.-color-success-outline:focus,
body.-theme-droppah a.-color-success-outline:active,
body.-theme-droppah a.-color-success-outline:focus {
  color: var(--app-color-droppah-purple) !important;
  background-color: var(--app-color-droppah-purple-translucent) !important;
  border: 1px solid var(--app-color-droppah-purple);
}
body.-theme-droppah button.-color-success-outline:hover,
body.-theme-droppah a.-color-success-outline:hover {
  color: var(--app-color-droppah-purple) !important;
  background-color: var(--app-color-droppah-purple-translucent-light) !important;
  border: 1px solid var(--app-color-droppah-purple);
}
body.-theme-droppah button.-color-success-outline:disabled,
body.-theme-droppah a.-color-success-outline:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
  border: var(--app-border-light) !important;
}
body.-theme-droppah button.-color-success-outline:disabled > i,
body.-theme-droppah a.-color-success-outline:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-droppah button.-color-success-outline.-readonly,
body.-theme-droppah a.-color-success-outline.-readonly {
  pointer-events: none !important;
}
body.-theme-droppah button.-color-product,
body.-theme-droppah a.-color-product {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-droppah-purple) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-droppah button.-color-product:active,
body.-theme-droppah a.-color-product:active {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-droppah-purple) !important;
}
body.-theme-droppah button.-color-product:hover, body.-theme-droppah button.-color-product:focus,
body.-theme-droppah a.-color-product:hover,
body.-theme-droppah a.-color-product:focus {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-droppah-purple) !important;
}
body.-theme-droppah button.-color-product:disabled,
body.-theme-droppah a.-color-product:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-droppah button.-color-product:disabled > i,
body.-theme-droppah a.-color-product:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-droppah button.-color-product.-readonly,
body.-theme-droppah a.-color-product.-readonly {
  pointer-events: none !important;
}
body.-theme-droppah button.-color-product-clear,
body.-theme-droppah a.-color-product-clear {
  color: var(--app-color-droppah-purple) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-droppah button.-color-product-clear:active, body.-theme-droppah button.-color-product-clear:focus,
body.-theme-droppah a.-color-product-clear:active,
body.-theme-droppah a.-color-product-clear:focus {
  color: var(--app-color-droppah-purple) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-droppah button.-color-product-clear:hover,
body.-theme-droppah a.-color-product-clear:hover {
  color: var(--app-color-droppah-purple-dark) !important;
}
body.-theme-droppah button.-color-product-clear:disabled,
body.-theme-droppah a.-color-product-clear:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-droppah button.-color-product-clear:disabled > i,
body.-theme-droppah a.-color-product-clear:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-droppah button.-color-product-clear.-readonly,
body.-theme-droppah a.-color-product-clear.-readonly {
  pointer-events: none !important;
}
body.-theme-droppah button.-color-product-clear:disabled,
body.-theme-droppah a.-color-product-clear:disabled {
  background-color: transparent !important;
}
body.-theme-droppah button.-color-product-clear-grey-border,
body.-theme-droppah a.-color-product-clear-grey-border {
  color: var(--app-color-droppah-purple) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
  border: var(--app-border);
  background-color: var(--app-color-white) !important;
}
body.-theme-droppah button.-color-product-clear-grey-border:active, body.-theme-droppah button.-color-product-clear-grey-border:focus,
body.-theme-droppah a.-color-product-clear-grey-border:active,
body.-theme-droppah a.-color-product-clear-grey-border:focus {
  color: var(--app-color-droppah-purple) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-droppah button.-color-product-clear-grey-border:hover,
body.-theme-droppah a.-color-product-clear-grey-border:hover {
  color: var(--app-color-droppah-purple-dark) !important;
}
body.-theme-droppah button.-color-product-clear-grey-border:disabled,
body.-theme-droppah a.-color-product-clear-grey-border:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-droppah button.-color-product-clear-grey-border:disabled > i,
body.-theme-droppah a.-color-product-clear-grey-border:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-droppah button.-color-product-clear-grey-border.-readonly,
body.-theme-droppah a.-color-product-clear-grey-border.-readonly {
  pointer-events: none !important;
}
body.-theme-droppah button.-color-product-clear-grey-border:active,
body.-theme-droppah a.-color-product-clear-grey-border:active {
  background-color: var(--app-color-grey-very-light) !important;
}
body.-theme-droppah button.-color-product-clear-grey-border:hover,
body.-theme-droppah a.-color-product-clear-grey-border:hover {
  background-color: var(--app-color-grey-very-light) !important;
}
body.-theme-droppah button.-color-product-hoverFill,
body.-theme-droppah a.-color-product-hoverFill {
  color: var(--app-color-droppah-purple) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-droppah button.-color-product-hoverFill:active, body.-theme-droppah button.-color-product-hoverFill:focus,
body.-theme-droppah a.-color-product-hoverFill:active,
body.-theme-droppah a.-color-product-hoverFill:focus {
  color: var(--app-color-droppah-purple) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-droppah button.-color-product-hoverFill:hover,
body.-theme-droppah a.-color-product-hoverFill:hover {
  color: var(--app-color-droppah-purple) !important;
  background-color: var(--app-color-droppah-purple-translucent) !important;
}
body.-theme-droppah button.-color-product-hoverFill:disabled,
body.-theme-droppah a.-color-product-hoverFill:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-droppah button.-color-product-hoverFill:disabled > i,
body.-theme-droppah a.-color-product-hoverFill:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-droppah button.-color-product-hoverFill.-readonly,
body.-theme-droppah a.-color-product-hoverFill.-readonly {
  pointer-events: none !important;
}
body.-theme-droppah button.-color-product-translucent,
body.-theme-droppah a.-color-product-translucent {
  color: var(--app-color-droppah-purple) !important;
  background-color: var(--app-color-droppah-purple-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-droppah button.-color-product-translucent:active, body.-theme-droppah button.-color-product-translucent:focus,
body.-theme-droppah a.-color-product-translucent:active,
body.-theme-droppah a.-color-product-translucent:focus {
  color: var(--app-color-droppah-purple) !important;
  background-color: var(--app-color-droppah-purple-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-droppah button.-color-product-translucent:hover,
body.-theme-droppah a.-color-product-translucent:hover {
  color: var(--app-color-droppah-purple) !important;
  background-color: var(--app-color-droppah-purple-translucent-light) !important;
}
body.-theme-droppah button.-color-product-translucent:disabled,
body.-theme-droppah a.-color-product-translucent:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-droppah button.-color-product-translucent:disabled > i,
body.-theme-droppah a.-color-product-translucent:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-droppah button.-color-product-translucent.-readonly,
body.-theme-droppah a.-color-product-translucent.-readonly {
  pointer-events: none !important;
}
body.-theme-droppah button.-color-product-outline,
body.-theme-droppah a.-color-product-outline {
  color: var(--app-color-droppah-purple) !important;
  background-color: var(--app-color-droppah-purple-translucent) !important;
  border: 1px solid var(--app-color-droppah-purple);
  font-weight: var(--app-font-weight-normal);
}
body.-theme-droppah button.-color-product-outline:active, body.-theme-droppah button.-color-product-outline:focus,
body.-theme-droppah a.-color-product-outline:active,
body.-theme-droppah a.-color-product-outline:focus {
  color: var(--app-color-droppah-purple) !important;
  background-color: var(--app-color-droppah-purple-translucent) !important;
  border: 1px solid var(--app-color-droppah-purple);
}
body.-theme-droppah button.-color-product-outline:hover,
body.-theme-droppah a.-color-product-outline:hover {
  color: var(--app-color-droppah-purple) !important;
  background-color: var(--app-color-droppah-purple-translucent-light) !important;
  border: 1px solid var(--app-color-droppah-purple);
}
body.-theme-droppah button.-color-product-outline:disabled,
body.-theme-droppah a.-color-product-outline:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
  border: var(--app-border-light) !important;
}
body.-theme-droppah button.-color-product-outline:disabled > i,
body.-theme-droppah a.-color-product-outline:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-droppah button.-color-product-outline.-readonly,
body.-theme-droppah a.-color-product-outline.-readonly {
  pointer-events: none !important;
}
body.-theme-droppah button.-color-accent,
body.-theme-droppah a.-color-accent {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-droppah-purple) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-droppah button.-color-accent:active,
body.-theme-droppah a.-color-accent:active {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-droppah-purple) !important;
}
body.-theme-droppah button.-color-accent:hover, body.-theme-droppah button.-color-accent:focus,
body.-theme-droppah a.-color-accent:hover,
body.-theme-droppah a.-color-accent:focus {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-droppah-purple) !important;
}
body.-theme-droppah button.-color-accent:disabled,
body.-theme-droppah a.-color-accent:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-droppah button.-color-accent:disabled > i,
body.-theme-droppah a.-color-accent:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-droppah button.-color-accent.-readonly,
body.-theme-droppah a.-color-accent.-readonly {
  pointer-events: none !important;
}
body.-theme-droppah button.-color-accent-clear,
body.-theme-droppah a.-color-accent-clear {
  color: var(--app-color-droppah-purple) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-droppah button.-color-accent-clear:active, body.-theme-droppah button.-color-accent-clear:focus,
body.-theme-droppah a.-color-accent-clear:active,
body.-theme-droppah a.-color-accent-clear:focus {
  color: var(--app-color-droppah-purple) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-droppah button.-color-accent-clear:hover,
body.-theme-droppah a.-color-accent-clear:hover {
  color: var(--app-color-droppah-purple-dark) !important;
}
body.-theme-droppah button.-color-accent-clear:disabled,
body.-theme-droppah a.-color-accent-clear:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-droppah button.-color-accent-clear:disabled > i,
body.-theme-droppah a.-color-accent-clear:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-droppah button.-color-accent-clear.-readonly,
body.-theme-droppah a.-color-accent-clear.-readonly {
  pointer-events: none !important;
}
body.-theme-droppah button.-color-accent-clear:disabled,
body.-theme-droppah a.-color-accent-clear:disabled {
  background-color: transparent !important;
}
body.-theme-droppah button.-color-accent-clear-grey-border,
body.-theme-droppah a.-color-accent-clear-grey-border {
  color: var(--app-color-droppah-purple) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
  border: var(--app-border);
  background-color: var(--app-color-white) !important;
}
body.-theme-droppah button.-color-accent-clear-grey-border:active, body.-theme-droppah button.-color-accent-clear-grey-border:focus,
body.-theme-droppah a.-color-accent-clear-grey-border:active,
body.-theme-droppah a.-color-accent-clear-grey-border:focus {
  color: var(--app-color-droppah-purple) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-droppah button.-color-accent-clear-grey-border:hover,
body.-theme-droppah a.-color-accent-clear-grey-border:hover {
  color: var(--app-color-droppah-purple-dark) !important;
}
body.-theme-droppah button.-color-accent-clear-grey-border:disabled,
body.-theme-droppah a.-color-accent-clear-grey-border:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-droppah button.-color-accent-clear-grey-border:disabled > i,
body.-theme-droppah a.-color-accent-clear-grey-border:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-droppah button.-color-accent-clear-grey-border.-readonly,
body.-theme-droppah a.-color-accent-clear-grey-border.-readonly {
  pointer-events: none !important;
}
body.-theme-droppah button.-color-accent-clear-grey-border:active,
body.-theme-droppah a.-color-accent-clear-grey-border:active {
  background-color: var(--app-color-grey-very-light) !important;
}
body.-theme-droppah button.-color-accent-clear-grey-border:hover,
body.-theme-droppah a.-color-accent-clear-grey-border:hover {
  background-color: var(--app-color-grey-very-light) !important;
}
body.-theme-droppah button.-color-accent-hoverFill,
body.-theme-droppah a.-color-accent-hoverFill {
  color: var(--app-color-droppah-purple) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-droppah button.-color-accent-hoverFill:active, body.-theme-droppah button.-color-accent-hoverFill:focus,
body.-theme-droppah a.-color-accent-hoverFill:active,
body.-theme-droppah a.-color-accent-hoverFill:focus {
  color: var(--app-color-droppah-purple) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-droppah button.-color-accent-hoverFill:hover,
body.-theme-droppah a.-color-accent-hoverFill:hover {
  color: var(--app-color-droppah-purple) !important;
  background-color: var(--app-color-droppah-purple-translucent) !important;
}
body.-theme-droppah button.-color-accent-hoverFill:disabled,
body.-theme-droppah a.-color-accent-hoverFill:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-droppah button.-color-accent-hoverFill:disabled > i,
body.-theme-droppah a.-color-accent-hoverFill:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-droppah button.-color-accent-hoverFill.-readonly,
body.-theme-droppah a.-color-accent-hoverFill.-readonly {
  pointer-events: none !important;
}
body.-theme-droppah button.-color-accent-translucent,
body.-theme-droppah a.-color-accent-translucent {
  color: var(--app-color-droppah-purple) !important;
  background-color: var(--app-color-droppah-purple-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-droppah button.-color-accent-translucent:active, body.-theme-droppah button.-color-accent-translucent:focus,
body.-theme-droppah a.-color-accent-translucent:active,
body.-theme-droppah a.-color-accent-translucent:focus {
  color: var(--app-color-droppah-purple) !important;
  background-color: var(--app-color-droppah-purple-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-droppah button.-color-accent-translucent:hover,
body.-theme-droppah a.-color-accent-translucent:hover {
  color: var(--app-color-droppah-purple) !important;
  background-color: var(--app-color-droppah-purple-translucent-light) !important;
}
body.-theme-droppah button.-color-accent-translucent:disabled,
body.-theme-droppah a.-color-accent-translucent:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-droppah button.-color-accent-translucent:disabled > i,
body.-theme-droppah a.-color-accent-translucent:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-droppah button.-color-accent-translucent.-readonly,
body.-theme-droppah a.-color-accent-translucent.-readonly {
  pointer-events: none !important;
}
body.-theme-droppah button.-color-accent-outline,
body.-theme-droppah a.-color-accent-outline {
  color: var(--app-color-droppah-purple) !important;
  background-color: var(--app-color-droppah-purple-translucent) !important;
  border: 1px solid var(--app-color-droppah-purple);
  font-weight: var(--app-font-weight-normal);
}
body.-theme-droppah button.-color-accent-outline:active, body.-theme-droppah button.-color-accent-outline:focus,
body.-theme-droppah a.-color-accent-outline:active,
body.-theme-droppah a.-color-accent-outline:focus {
  color: var(--app-color-droppah-purple) !important;
  background-color: var(--app-color-droppah-purple-translucent) !important;
  border: 1px solid var(--app-color-droppah-purple);
}
body.-theme-droppah button.-color-accent-outline:hover,
body.-theme-droppah a.-color-accent-outline:hover {
  color: var(--app-color-droppah-purple) !important;
  background-color: var(--app-color-droppah-purple-translucent-light) !important;
  border: 1px solid var(--app-color-droppah-purple);
}
body.-theme-droppah button.-color-accent-outline:disabled,
body.-theme-droppah a.-color-accent-outline:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
  border: var(--app-border-light) !important;
}
body.-theme-droppah button.-color-accent-outline:disabled > i,
body.-theme-droppah a.-color-accent-outline:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-droppah button.-color-accent-outline.-readonly,
body.-theme-droppah a.-color-accent-outline.-readonly {
  pointer-events: none !important;
}
body.-theme-droppah button.-color-primary,
body.-theme-droppah a.-color-primary {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-droppah-purple) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-droppah button.-color-primary:active,
body.-theme-droppah a.-color-primary:active {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-droppah-purple) !important;
}
body.-theme-droppah button.-color-primary:hover, body.-theme-droppah button.-color-primary:focus,
body.-theme-droppah a.-color-primary:hover,
body.-theme-droppah a.-color-primary:focus {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-droppah-purple) !important;
}
body.-theme-droppah button.-color-primary:disabled,
body.-theme-droppah a.-color-primary:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-droppah button.-color-primary:disabled > i,
body.-theme-droppah a.-color-primary:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-droppah button.-color-primary.-readonly,
body.-theme-droppah a.-color-primary.-readonly {
  pointer-events: none !important;
}
body.-theme-droppah button.-color-primary-clear,
body.-theme-droppah a.-color-primary-clear {
  color: var(--app-color-droppah-purple) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-droppah button.-color-primary-clear:active, body.-theme-droppah button.-color-primary-clear:focus,
body.-theme-droppah a.-color-primary-clear:active,
body.-theme-droppah a.-color-primary-clear:focus {
  color: var(--app-color-droppah-purple) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-droppah button.-color-primary-clear:hover,
body.-theme-droppah a.-color-primary-clear:hover {
  color: var(--app-color-droppah-purple-dark) !important;
}
body.-theme-droppah button.-color-primary-clear:disabled,
body.-theme-droppah a.-color-primary-clear:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-droppah button.-color-primary-clear:disabled > i,
body.-theme-droppah a.-color-primary-clear:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-droppah button.-color-primary-clear.-readonly,
body.-theme-droppah a.-color-primary-clear.-readonly {
  pointer-events: none !important;
}
body.-theme-droppah button.-color-primary-clear:disabled,
body.-theme-droppah a.-color-primary-clear:disabled {
  background-color: transparent !important;
}
body.-theme-droppah button.-color-primary-clear-grey-border,
body.-theme-droppah a.-color-primary-clear-grey-border {
  color: var(--app-color-droppah-purple) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
  border: var(--app-border);
  background-color: var(--app-color-white) !important;
}
body.-theme-droppah button.-color-primary-clear-grey-border:active, body.-theme-droppah button.-color-primary-clear-grey-border:focus,
body.-theme-droppah a.-color-primary-clear-grey-border:active,
body.-theme-droppah a.-color-primary-clear-grey-border:focus {
  color: var(--app-color-droppah-purple) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-droppah button.-color-primary-clear-grey-border:hover,
body.-theme-droppah a.-color-primary-clear-grey-border:hover {
  color: var(--app-color-droppah-purple-dark) !important;
}
body.-theme-droppah button.-color-primary-clear-grey-border:disabled,
body.-theme-droppah a.-color-primary-clear-grey-border:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-droppah button.-color-primary-clear-grey-border:disabled > i,
body.-theme-droppah a.-color-primary-clear-grey-border:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-droppah button.-color-primary-clear-grey-border.-readonly,
body.-theme-droppah a.-color-primary-clear-grey-border.-readonly {
  pointer-events: none !important;
}
body.-theme-droppah button.-color-primary-clear-grey-border:active,
body.-theme-droppah a.-color-primary-clear-grey-border:active {
  background-color: var(--app-color-grey-very-light) !important;
}
body.-theme-droppah button.-color-primary-clear-grey-border:hover,
body.-theme-droppah a.-color-primary-clear-grey-border:hover {
  background-color: var(--app-color-grey-very-light) !important;
}
body.-theme-droppah button.-color-primary-hoverFill,
body.-theme-droppah a.-color-primary-hoverFill {
  color: var(--app-color-droppah-purple) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-droppah button.-color-primary-hoverFill:active, body.-theme-droppah button.-color-primary-hoverFill:focus,
body.-theme-droppah a.-color-primary-hoverFill:active,
body.-theme-droppah a.-color-primary-hoverFill:focus {
  color: var(--app-color-droppah-purple) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-droppah button.-color-primary-hoverFill:hover,
body.-theme-droppah a.-color-primary-hoverFill:hover {
  color: var(--app-color-droppah-purple) !important;
  background-color: var(--app-color-droppah-purple-translucent) !important;
}
body.-theme-droppah button.-color-primary-hoverFill:disabled,
body.-theme-droppah a.-color-primary-hoverFill:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-droppah button.-color-primary-hoverFill:disabled > i,
body.-theme-droppah a.-color-primary-hoverFill:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-droppah button.-color-primary-hoverFill.-readonly,
body.-theme-droppah a.-color-primary-hoverFill.-readonly {
  pointer-events: none !important;
}
body.-theme-droppah button.-color-primary-translucent,
body.-theme-droppah a.-color-primary-translucent {
  color: var(--app-color-droppah-purple) !important;
  background-color: var(--app-color-droppah-purple-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-droppah button.-color-primary-translucent:active, body.-theme-droppah button.-color-primary-translucent:focus,
body.-theme-droppah a.-color-primary-translucent:active,
body.-theme-droppah a.-color-primary-translucent:focus {
  color: var(--app-color-droppah-purple) !important;
  background-color: var(--app-color-droppah-purple-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-droppah button.-color-primary-translucent:hover,
body.-theme-droppah a.-color-primary-translucent:hover {
  color: var(--app-color-droppah-purple) !important;
  background-color: var(--app-color-droppah-purple-translucent-light) !important;
}
body.-theme-droppah button.-color-primary-translucent:disabled,
body.-theme-droppah a.-color-primary-translucent:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-droppah button.-color-primary-translucent:disabled > i,
body.-theme-droppah a.-color-primary-translucent:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-droppah button.-color-primary-translucent.-readonly,
body.-theme-droppah a.-color-primary-translucent.-readonly {
  pointer-events: none !important;
}
body.-theme-droppah button.-color-primary-outline,
body.-theme-droppah a.-color-primary-outline {
  color: var(--app-color-droppah-purple) !important;
  background-color: var(--app-color-droppah-purple-translucent) !important;
  border: 1px solid var(--app-color-droppah-purple);
  font-weight: var(--app-font-weight-normal);
}
body.-theme-droppah button.-color-primary-outline:active, body.-theme-droppah button.-color-primary-outline:focus,
body.-theme-droppah a.-color-primary-outline:active,
body.-theme-droppah a.-color-primary-outline:focus {
  color: var(--app-color-droppah-purple) !important;
  background-color: var(--app-color-droppah-purple-translucent) !important;
  border: 1px solid var(--app-color-droppah-purple);
}
body.-theme-droppah button.-color-primary-outline:hover,
body.-theme-droppah a.-color-primary-outline:hover {
  color: var(--app-color-droppah-purple) !important;
  background-color: var(--app-color-droppah-purple-translucent-light) !important;
  border: 1px solid var(--app-color-droppah-purple);
}
body.-theme-droppah button.-color-primary-outline:disabled,
body.-theme-droppah a.-color-primary-outline:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
  border: var(--app-border-light) !important;
}
body.-theme-droppah button.-color-primary-outline:disabled > i,
body.-theme-droppah a.-color-primary-outline:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-droppah button.-color-primary-outline.-readonly,
body.-theme-droppah a.-color-primary-outline.-readonly {
  pointer-events: none !important;
}
body.-theme-flexitime button.-color-success,
body.-theme-flexitime a.-color-success {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-yellow) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-flexitime button.-color-success:active,
body.-theme-flexitime a.-color-success:active {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-yellow) !important;
}
body.-theme-flexitime button.-color-success:hover, body.-theme-flexitime button.-color-success:focus,
body.-theme-flexitime a.-color-success:hover,
body.-theme-flexitime a.-color-success:focus {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-yellow) !important;
}
body.-theme-flexitime button.-color-success:disabled,
body.-theme-flexitime a.-color-success:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-flexitime button.-color-success:disabled > i,
body.-theme-flexitime a.-color-success:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-flexitime button.-color-success.-readonly,
body.-theme-flexitime a.-color-success.-readonly {
  pointer-events: none !important;
}
body.-theme-flexitime button.-color-success-clear,
body.-theme-flexitime a.-color-success-clear {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-flexitime button.-color-success-clear:active, body.-theme-flexitime button.-color-success-clear:focus,
body.-theme-flexitime a.-color-success-clear:active,
body.-theme-flexitime a.-color-success-clear:focus {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-flexitime button.-color-success-clear:hover,
body.-theme-flexitime a.-color-success-clear:hover {
  color: var(--app-color-flexitime-yellow-dark) !important;
}
body.-theme-flexitime button.-color-success-clear:disabled,
body.-theme-flexitime a.-color-success-clear:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-flexitime button.-color-success-clear:disabled > i,
body.-theme-flexitime a.-color-success-clear:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-flexitime button.-color-success-clear.-readonly,
body.-theme-flexitime a.-color-success-clear.-readonly {
  pointer-events: none !important;
}
body.-theme-flexitime button.-color-success-clear:disabled,
body.-theme-flexitime a.-color-success-clear:disabled {
  background-color: transparent !important;
}
body.-theme-flexitime button.-color-success-clear-grey-border,
body.-theme-flexitime a.-color-success-clear-grey-border {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
  border: var(--app-border);
  background-color: var(--app-color-white) !important;
}
body.-theme-flexitime button.-color-success-clear-grey-border:active, body.-theme-flexitime button.-color-success-clear-grey-border:focus,
body.-theme-flexitime a.-color-success-clear-grey-border:active,
body.-theme-flexitime a.-color-success-clear-grey-border:focus {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-flexitime button.-color-success-clear-grey-border:hover,
body.-theme-flexitime a.-color-success-clear-grey-border:hover {
  color: var(--app-color-flexitime-yellow-dark) !important;
}
body.-theme-flexitime button.-color-success-clear-grey-border:disabled,
body.-theme-flexitime a.-color-success-clear-grey-border:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-flexitime button.-color-success-clear-grey-border:disabled > i,
body.-theme-flexitime a.-color-success-clear-grey-border:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-flexitime button.-color-success-clear-grey-border.-readonly,
body.-theme-flexitime a.-color-success-clear-grey-border.-readonly {
  pointer-events: none !important;
}
body.-theme-flexitime button.-color-success-clear-grey-border:active,
body.-theme-flexitime a.-color-success-clear-grey-border:active {
  background-color: var(--app-color-grey-very-light) !important;
}
body.-theme-flexitime button.-color-success-clear-grey-border:hover,
body.-theme-flexitime a.-color-success-clear-grey-border:hover {
  background-color: var(--app-color-grey-very-light) !important;
}
body.-theme-flexitime button.-color-success-hoverFill,
body.-theme-flexitime a.-color-success-hoverFill {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-flexitime button.-color-success-hoverFill:active, body.-theme-flexitime button.-color-success-hoverFill:focus,
body.-theme-flexitime a.-color-success-hoverFill:active,
body.-theme-flexitime a.-color-success-hoverFill:focus {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-flexitime button.-color-success-hoverFill:hover,
body.-theme-flexitime a.-color-success-hoverFill:hover {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: var(--app-color-flexitime-yellow-translucent) !important;
}
body.-theme-flexitime button.-color-success-hoverFill:disabled,
body.-theme-flexitime a.-color-success-hoverFill:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-flexitime button.-color-success-hoverFill:disabled > i,
body.-theme-flexitime a.-color-success-hoverFill:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-flexitime button.-color-success-hoverFill.-readonly,
body.-theme-flexitime a.-color-success-hoverFill.-readonly {
  pointer-events: none !important;
}
body.-theme-flexitime button.-color-success-translucent,
body.-theme-flexitime a.-color-success-translucent {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: var(--app-color-flexitime-yellow-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-flexitime button.-color-success-translucent:active, body.-theme-flexitime button.-color-success-translucent:focus,
body.-theme-flexitime a.-color-success-translucent:active,
body.-theme-flexitime a.-color-success-translucent:focus {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: var(--app-color-flexitime-yellow-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-flexitime button.-color-success-translucent:hover,
body.-theme-flexitime a.-color-success-translucent:hover {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: var(--app-color-flexitime-yellow-translucent-light) !important;
}
body.-theme-flexitime button.-color-success-translucent:disabled,
body.-theme-flexitime a.-color-success-translucent:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-flexitime button.-color-success-translucent:disabled > i,
body.-theme-flexitime a.-color-success-translucent:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-flexitime button.-color-success-translucent.-readonly,
body.-theme-flexitime a.-color-success-translucent.-readonly {
  pointer-events: none !important;
}
body.-theme-flexitime button.-color-success-outline,
body.-theme-flexitime a.-color-success-outline {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: var(--app-color-flexitime-yellow-translucent) !important;
  border: 1px solid var(--app-color-flexitime-yellow);
  font-weight: var(--app-font-weight-normal);
}
body.-theme-flexitime button.-color-success-outline:active, body.-theme-flexitime button.-color-success-outline:focus,
body.-theme-flexitime a.-color-success-outline:active,
body.-theme-flexitime a.-color-success-outline:focus {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: var(--app-color-flexitime-yellow-translucent) !important;
  border: 1px solid var(--app-color-flexitime-yellow);
}
body.-theme-flexitime button.-color-success-outline:hover,
body.-theme-flexitime a.-color-success-outline:hover {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: var(--app-color-flexitime-yellow-translucent-light) !important;
  border: 1px solid var(--app-color-flexitime-yellow);
}
body.-theme-flexitime button.-color-success-outline:disabled,
body.-theme-flexitime a.-color-success-outline:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
  border: var(--app-border-light) !important;
}
body.-theme-flexitime button.-color-success-outline:disabled > i,
body.-theme-flexitime a.-color-success-outline:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-flexitime button.-color-success-outline.-readonly,
body.-theme-flexitime a.-color-success-outline.-readonly {
  pointer-events: none !important;
}
body.-theme-flexitime button.-color-product,
body.-theme-flexitime a.-color-product {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-flexitime-blue) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-flexitime button.-color-product:active,
body.-theme-flexitime a.-color-product:active {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-flexitime-blue) !important;
}
body.-theme-flexitime button.-color-product:hover, body.-theme-flexitime button.-color-product:focus,
body.-theme-flexitime a.-color-product:hover,
body.-theme-flexitime a.-color-product:focus {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-flexitime-blue) !important;
}
body.-theme-flexitime button.-color-product:disabled,
body.-theme-flexitime a.-color-product:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-flexitime button.-color-product:disabled > i,
body.-theme-flexitime a.-color-product:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-flexitime button.-color-product.-readonly,
body.-theme-flexitime a.-color-product.-readonly {
  pointer-events: none !important;
}
body.-theme-flexitime button.-color-product-clear,
body.-theme-flexitime a.-color-product-clear {
  color: var(--app-color-flexitime-blue) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-flexitime button.-color-product-clear:active, body.-theme-flexitime button.-color-product-clear:focus,
body.-theme-flexitime a.-color-product-clear:active,
body.-theme-flexitime a.-color-product-clear:focus {
  color: var(--app-color-flexitime-blue) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-flexitime button.-color-product-clear:hover,
body.-theme-flexitime a.-color-product-clear:hover {
  color: var(--app-color-flexitime-blue-dark) !important;
}
body.-theme-flexitime button.-color-product-clear:disabled,
body.-theme-flexitime a.-color-product-clear:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-flexitime button.-color-product-clear:disabled > i,
body.-theme-flexitime a.-color-product-clear:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-flexitime button.-color-product-clear.-readonly,
body.-theme-flexitime a.-color-product-clear.-readonly {
  pointer-events: none !important;
}
body.-theme-flexitime button.-color-product-clear:disabled,
body.-theme-flexitime a.-color-product-clear:disabled {
  background-color: transparent !important;
}
body.-theme-flexitime button.-color-product-clear-grey-border,
body.-theme-flexitime a.-color-product-clear-grey-border {
  color: var(--app-color-flexitime-blue) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
  border: var(--app-border);
  background-color: var(--app-color-white) !important;
}
body.-theme-flexitime button.-color-product-clear-grey-border:active, body.-theme-flexitime button.-color-product-clear-grey-border:focus,
body.-theme-flexitime a.-color-product-clear-grey-border:active,
body.-theme-flexitime a.-color-product-clear-grey-border:focus {
  color: var(--app-color-flexitime-blue) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-flexitime button.-color-product-clear-grey-border:hover,
body.-theme-flexitime a.-color-product-clear-grey-border:hover {
  color: var(--app-color-flexitime-blue-dark) !important;
}
body.-theme-flexitime button.-color-product-clear-grey-border:disabled,
body.-theme-flexitime a.-color-product-clear-grey-border:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-flexitime button.-color-product-clear-grey-border:disabled > i,
body.-theme-flexitime a.-color-product-clear-grey-border:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-flexitime button.-color-product-clear-grey-border.-readonly,
body.-theme-flexitime a.-color-product-clear-grey-border.-readonly {
  pointer-events: none !important;
}
body.-theme-flexitime button.-color-product-clear-grey-border:active,
body.-theme-flexitime a.-color-product-clear-grey-border:active {
  background-color: var(--app-color-grey-very-light) !important;
}
body.-theme-flexitime button.-color-product-clear-grey-border:hover,
body.-theme-flexitime a.-color-product-clear-grey-border:hover {
  background-color: var(--app-color-grey-very-light) !important;
}
body.-theme-flexitime button.-color-product-hoverFill,
body.-theme-flexitime a.-color-product-hoverFill {
  color: var(--app-color-flexitime-blue) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-flexitime button.-color-product-hoverFill:active, body.-theme-flexitime button.-color-product-hoverFill:focus,
body.-theme-flexitime a.-color-product-hoverFill:active,
body.-theme-flexitime a.-color-product-hoverFill:focus {
  color: var(--app-color-flexitime-blue) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-flexitime button.-color-product-hoverFill:hover,
body.-theme-flexitime a.-color-product-hoverFill:hover {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-blue-translucent) !important;
}
body.-theme-flexitime button.-color-product-hoverFill:disabled,
body.-theme-flexitime a.-color-product-hoverFill:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-flexitime button.-color-product-hoverFill:disabled > i,
body.-theme-flexitime a.-color-product-hoverFill:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-flexitime button.-color-product-hoverFill.-readonly,
body.-theme-flexitime a.-color-product-hoverFill.-readonly {
  pointer-events: none !important;
}
body.-theme-flexitime button.-color-product-translucent,
body.-theme-flexitime a.-color-product-translucent {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-blue-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-flexitime button.-color-product-translucent:active, body.-theme-flexitime button.-color-product-translucent:focus,
body.-theme-flexitime a.-color-product-translucent:active,
body.-theme-flexitime a.-color-product-translucent:focus {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-blue-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-flexitime button.-color-product-translucent:hover,
body.-theme-flexitime a.-color-product-translucent:hover {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-blue-translucent-light) !important;
}
body.-theme-flexitime button.-color-product-translucent:disabled,
body.-theme-flexitime a.-color-product-translucent:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-flexitime button.-color-product-translucent:disabled > i,
body.-theme-flexitime a.-color-product-translucent:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-flexitime button.-color-product-translucent.-readonly,
body.-theme-flexitime a.-color-product-translucent.-readonly {
  pointer-events: none !important;
}
body.-theme-flexitime button.-color-product-outline,
body.-theme-flexitime a.-color-product-outline {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-blue-translucent) !important;
  border: 1px solid var(--app-color-flexitime-blue);
  font-weight: var(--app-font-weight-normal);
}
body.-theme-flexitime button.-color-product-outline:active, body.-theme-flexitime button.-color-product-outline:focus,
body.-theme-flexitime a.-color-product-outline:active,
body.-theme-flexitime a.-color-product-outline:focus {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-blue-translucent) !important;
  border: 1px solid var(--app-color-flexitime-blue);
}
body.-theme-flexitime button.-color-product-outline:hover,
body.-theme-flexitime a.-color-product-outline:hover {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-blue-translucent-light) !important;
  border: 1px solid var(--app-color-flexitime-blue);
}
body.-theme-flexitime button.-color-product-outline:disabled,
body.-theme-flexitime a.-color-product-outline:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
  border: var(--app-border-light) !important;
}
body.-theme-flexitime button.-color-product-outline:disabled > i,
body.-theme-flexitime a.-color-product-outline:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-flexitime button.-color-product-outline.-readonly,
body.-theme-flexitime a.-color-product-outline.-readonly {
  pointer-events: none !important;
}
body.-theme-flexitime button.-color-accent,
body.-theme-flexitime a.-color-accent {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-yellow) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-flexitime button.-color-accent:active,
body.-theme-flexitime a.-color-accent:active {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-yellow) !important;
}
body.-theme-flexitime button.-color-accent:hover, body.-theme-flexitime button.-color-accent:focus,
body.-theme-flexitime a.-color-accent:hover,
body.-theme-flexitime a.-color-accent:focus {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-yellow) !important;
}
body.-theme-flexitime button.-color-accent:disabled,
body.-theme-flexitime a.-color-accent:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-flexitime button.-color-accent:disabled > i,
body.-theme-flexitime a.-color-accent:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-flexitime button.-color-accent.-readonly,
body.-theme-flexitime a.-color-accent.-readonly {
  pointer-events: none !important;
}
body.-theme-flexitime button.-color-accent-clear,
body.-theme-flexitime a.-color-accent-clear {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-flexitime button.-color-accent-clear:active, body.-theme-flexitime button.-color-accent-clear:focus,
body.-theme-flexitime a.-color-accent-clear:active,
body.-theme-flexitime a.-color-accent-clear:focus {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-flexitime button.-color-accent-clear:hover,
body.-theme-flexitime a.-color-accent-clear:hover {
  color: var(--app-color-flexitime-yellow-dark) !important;
}
body.-theme-flexitime button.-color-accent-clear:disabled,
body.-theme-flexitime a.-color-accent-clear:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-flexitime button.-color-accent-clear:disabled > i,
body.-theme-flexitime a.-color-accent-clear:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-flexitime button.-color-accent-clear.-readonly,
body.-theme-flexitime a.-color-accent-clear.-readonly {
  pointer-events: none !important;
}
body.-theme-flexitime button.-color-accent-clear:disabled,
body.-theme-flexitime a.-color-accent-clear:disabled {
  background-color: transparent !important;
}
body.-theme-flexitime button.-color-accent-clear-grey-border,
body.-theme-flexitime a.-color-accent-clear-grey-border {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
  border: var(--app-border);
  background-color: var(--app-color-white) !important;
}
body.-theme-flexitime button.-color-accent-clear-grey-border:active, body.-theme-flexitime button.-color-accent-clear-grey-border:focus,
body.-theme-flexitime a.-color-accent-clear-grey-border:active,
body.-theme-flexitime a.-color-accent-clear-grey-border:focus {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-flexitime button.-color-accent-clear-grey-border:hover,
body.-theme-flexitime a.-color-accent-clear-grey-border:hover {
  color: var(--app-color-flexitime-yellow-dark) !important;
}
body.-theme-flexitime button.-color-accent-clear-grey-border:disabled,
body.-theme-flexitime a.-color-accent-clear-grey-border:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-flexitime button.-color-accent-clear-grey-border:disabled > i,
body.-theme-flexitime a.-color-accent-clear-grey-border:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-flexitime button.-color-accent-clear-grey-border.-readonly,
body.-theme-flexitime a.-color-accent-clear-grey-border.-readonly {
  pointer-events: none !important;
}
body.-theme-flexitime button.-color-accent-clear-grey-border:active,
body.-theme-flexitime a.-color-accent-clear-grey-border:active {
  background-color: var(--app-color-grey-very-light) !important;
}
body.-theme-flexitime button.-color-accent-clear-grey-border:hover,
body.-theme-flexitime a.-color-accent-clear-grey-border:hover {
  background-color: var(--app-color-grey-very-light) !important;
}
body.-theme-flexitime button.-color-accent-hoverFill,
body.-theme-flexitime a.-color-accent-hoverFill {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-flexitime button.-color-accent-hoverFill:active, body.-theme-flexitime button.-color-accent-hoverFill:focus,
body.-theme-flexitime a.-color-accent-hoverFill:active,
body.-theme-flexitime a.-color-accent-hoverFill:focus {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-flexitime button.-color-accent-hoverFill:hover,
body.-theme-flexitime a.-color-accent-hoverFill:hover {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: var(--app-color-flexitime-yellow-translucent) !important;
}
body.-theme-flexitime button.-color-accent-hoverFill:disabled,
body.-theme-flexitime a.-color-accent-hoverFill:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-flexitime button.-color-accent-hoverFill:disabled > i,
body.-theme-flexitime a.-color-accent-hoverFill:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-flexitime button.-color-accent-hoverFill.-readonly,
body.-theme-flexitime a.-color-accent-hoverFill.-readonly {
  pointer-events: none !important;
}
body.-theme-flexitime button.-color-accent-translucent,
body.-theme-flexitime a.-color-accent-translucent {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: var(--app-color-flexitime-yellow-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-flexitime button.-color-accent-translucent:active, body.-theme-flexitime button.-color-accent-translucent:focus,
body.-theme-flexitime a.-color-accent-translucent:active,
body.-theme-flexitime a.-color-accent-translucent:focus {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: var(--app-color-flexitime-yellow-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-flexitime button.-color-accent-translucent:hover,
body.-theme-flexitime a.-color-accent-translucent:hover {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: var(--app-color-flexitime-yellow-translucent-light) !important;
}
body.-theme-flexitime button.-color-accent-translucent:disabled,
body.-theme-flexitime a.-color-accent-translucent:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-flexitime button.-color-accent-translucent:disabled > i,
body.-theme-flexitime a.-color-accent-translucent:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-flexitime button.-color-accent-translucent.-readonly,
body.-theme-flexitime a.-color-accent-translucent.-readonly {
  pointer-events: none !important;
}
body.-theme-flexitime button.-color-accent-outline,
body.-theme-flexitime a.-color-accent-outline {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: var(--app-color-flexitime-yellow-translucent) !important;
  border: 1px solid var(--app-color-flexitime-yellow);
  font-weight: var(--app-font-weight-normal);
}
body.-theme-flexitime button.-color-accent-outline:active, body.-theme-flexitime button.-color-accent-outline:focus,
body.-theme-flexitime a.-color-accent-outline:active,
body.-theme-flexitime a.-color-accent-outline:focus {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: var(--app-color-flexitime-yellow-translucent) !important;
  border: 1px solid var(--app-color-flexitime-yellow);
}
body.-theme-flexitime button.-color-accent-outline:hover,
body.-theme-flexitime a.-color-accent-outline:hover {
  color: var(--app-color-flexitime-yellow) !important;
  background-color: var(--app-color-flexitime-yellow-translucent-light) !important;
  border: 1px solid var(--app-color-flexitime-yellow);
}
body.-theme-flexitime button.-color-accent-outline:disabled,
body.-theme-flexitime a.-color-accent-outline:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
  border: var(--app-border-light) !important;
}
body.-theme-flexitime button.-color-accent-outline:disabled > i,
body.-theme-flexitime a.-color-accent-outline:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-flexitime button.-color-accent-outline.-readonly,
body.-theme-flexitime a.-color-accent-outline.-readonly {
  pointer-events: none !important;
}
body.-theme-flexitime button.-color-primary,
body.-theme-flexitime a.-color-primary {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-flexitime-blue) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-flexitime button.-color-primary:active,
body.-theme-flexitime a.-color-primary:active {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-flexitime-blue) !important;
}
body.-theme-flexitime button.-color-primary:hover, body.-theme-flexitime button.-color-primary:focus,
body.-theme-flexitime a.-color-primary:hover,
body.-theme-flexitime a.-color-primary:focus {
  color: var(--app-color-white) !important;
  background-color: var(--app-color-flexitime-blue) !important;
}
body.-theme-flexitime button.-color-primary:disabled,
body.-theme-flexitime a.-color-primary:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-flexitime button.-color-primary:disabled > i,
body.-theme-flexitime a.-color-primary:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-flexitime button.-color-primary.-readonly,
body.-theme-flexitime a.-color-primary.-readonly {
  pointer-events: none !important;
}
body.-theme-flexitime button.-color-primary-clear,
body.-theme-flexitime a.-color-primary-clear {
  color: var(--app-color-flexitime-blue) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-flexitime button.-color-primary-clear:active, body.-theme-flexitime button.-color-primary-clear:focus,
body.-theme-flexitime a.-color-primary-clear:active,
body.-theme-flexitime a.-color-primary-clear:focus {
  color: var(--app-color-flexitime-blue) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-flexitime button.-color-primary-clear:hover,
body.-theme-flexitime a.-color-primary-clear:hover {
  color: var(--app-color-flexitime-blue-dark) !important;
}
body.-theme-flexitime button.-color-primary-clear:disabled,
body.-theme-flexitime a.-color-primary-clear:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-flexitime button.-color-primary-clear:disabled > i,
body.-theme-flexitime a.-color-primary-clear:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-flexitime button.-color-primary-clear.-readonly,
body.-theme-flexitime a.-color-primary-clear.-readonly {
  pointer-events: none !important;
}
body.-theme-flexitime button.-color-primary-clear:disabled,
body.-theme-flexitime a.-color-primary-clear:disabled {
  background-color: transparent !important;
}
body.-theme-flexitime button.-color-primary-clear-grey-border,
body.-theme-flexitime a.-color-primary-clear-grey-border {
  color: var(--app-color-flexitime-blue) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
  border: var(--app-border);
  background-color: var(--app-color-white) !important;
}
body.-theme-flexitime button.-color-primary-clear-grey-border:active, body.-theme-flexitime button.-color-primary-clear-grey-border:focus,
body.-theme-flexitime a.-color-primary-clear-grey-border:active,
body.-theme-flexitime a.-color-primary-clear-grey-border:focus {
  color: var(--app-color-flexitime-blue) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-flexitime button.-color-primary-clear-grey-border:hover,
body.-theme-flexitime a.-color-primary-clear-grey-border:hover {
  color: var(--app-color-flexitime-blue-dark) !important;
}
body.-theme-flexitime button.-color-primary-clear-grey-border:disabled,
body.-theme-flexitime a.-color-primary-clear-grey-border:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-flexitime button.-color-primary-clear-grey-border:disabled > i,
body.-theme-flexitime a.-color-primary-clear-grey-border:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-flexitime button.-color-primary-clear-grey-border.-readonly,
body.-theme-flexitime a.-color-primary-clear-grey-border.-readonly {
  pointer-events: none !important;
}
body.-theme-flexitime button.-color-primary-clear-grey-border:active,
body.-theme-flexitime a.-color-primary-clear-grey-border:active {
  background-color: var(--app-color-grey-very-light) !important;
}
body.-theme-flexitime button.-color-primary-clear-grey-border:hover,
body.-theme-flexitime a.-color-primary-clear-grey-border:hover {
  background-color: var(--app-color-grey-very-light) !important;
}
body.-theme-flexitime button.-color-primary-hoverFill,
body.-theme-flexitime a.-color-primary-hoverFill {
  color: var(--app-color-flexitime-blue) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-flexitime button.-color-primary-hoverFill:active, body.-theme-flexitime button.-color-primary-hoverFill:focus,
body.-theme-flexitime a.-color-primary-hoverFill:active,
body.-theme-flexitime a.-color-primary-hoverFill:focus {
  color: var(--app-color-flexitime-blue) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-flexitime button.-color-primary-hoverFill:hover,
body.-theme-flexitime a.-color-primary-hoverFill:hover {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-blue-translucent) !important;
}
body.-theme-flexitime button.-color-primary-hoverFill:disabled,
body.-theme-flexitime a.-color-primary-hoverFill:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-flexitime button.-color-primary-hoverFill:disabled > i,
body.-theme-flexitime a.-color-primary-hoverFill:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-flexitime button.-color-primary-hoverFill.-readonly,
body.-theme-flexitime a.-color-primary-hoverFill.-readonly {
  pointer-events: none !important;
}
body.-theme-flexitime button.-color-primary-translucent,
body.-theme-flexitime a.-color-primary-translucent {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-blue-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-flexitime button.-color-primary-translucent:active, body.-theme-flexitime button.-color-primary-translucent:focus,
body.-theme-flexitime a.-color-primary-translucent:active,
body.-theme-flexitime a.-color-primary-translucent:focus {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-blue-translucent) !important;
  font-weight: var(--app-font-weight-normal);
}
body.-theme-flexitime button.-color-primary-translucent:hover,
body.-theme-flexitime a.-color-primary-translucent:hover {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-blue-translucent-light) !important;
}
body.-theme-flexitime button.-color-primary-translucent:disabled,
body.-theme-flexitime a.-color-primary-translucent:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body.-theme-flexitime button.-color-primary-translucent:disabled > i,
body.-theme-flexitime a.-color-primary-translucent:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-flexitime button.-color-primary-translucent.-readonly,
body.-theme-flexitime a.-color-primary-translucent.-readonly {
  pointer-events: none !important;
}
body.-theme-flexitime button.-color-primary-outline,
body.-theme-flexitime a.-color-primary-outline {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-blue-translucent) !important;
  border: 1px solid var(--app-color-flexitime-blue);
  font-weight: var(--app-font-weight-normal);
}
body.-theme-flexitime button.-color-primary-outline:active, body.-theme-flexitime button.-color-primary-outline:focus,
body.-theme-flexitime a.-color-primary-outline:active,
body.-theme-flexitime a.-color-primary-outline:focus {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-blue-translucent) !important;
  border: 1px solid var(--app-color-flexitime-blue);
}
body.-theme-flexitime button.-color-primary-outline:hover,
body.-theme-flexitime a.-color-primary-outline:hover {
  color: var(--app-color-flexitime-blue) !important;
  background-color: var(--app-color-flexitime-blue-translucent-light) !important;
  border: 1px solid var(--app-color-flexitime-blue);
}
body.-theme-flexitime button.-color-primary-outline:disabled,
body.-theme-flexitime a.-color-primary-outline:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
  border: var(--app-border-light) !important;
}
body.-theme-flexitime button.-color-primary-outline:disabled > i,
body.-theme-flexitime a.-color-primary-outline:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body.-theme-flexitime button.-color-primary-outline.-readonly,
body.-theme-flexitime a.-color-primary-outline.-readonly {
  pointer-events: none !important;
}

@media (max-width: 850px) {
  body button.-floatOnMobile,
body a.-floatOnMobile {
    --button-height: 44px !important;
    margin: 0 !important;
    position: fixed;
    bottom: calc(15px + var(--app-navMenu-footerHeight) + env(safe-area-inset-bottom));
    border-radius: var(--app-border-radius);
    right: 20px;
    z-index: 1050;
  }
}
@media (max-width: 850px) {
  body button.-floatLeftOnMobile,
body a.-floatLeftOnMobile {
    --button-height: 44px !important;
    margin: 0 !important;
    position: fixed;
    bottom: calc(15px + var(--app-navMenu-footerHeight) + env(safe-area-inset-bottom));
    border-radius: var(--app-border-radius);
    right: 20px;
    z-index: 1050;
  }
}
@media (max-width: 850px) {
  body button.-floatLeftOnMobile,
body a.-floatLeftOnMobile {
    margin: 0 !important;
    right: auto;
    left: 20px;
  }
}
body button.-type-default,
body a.-type-default {
  width: 100%;
}
body button.-type-icon,
body a.-type-icon {
  width: var(--button-height) !important;
  min-width: var(--button-height);
  padding: 0;
}
body button.-type-icon > i,
body a.-type-icon > i {
  margin: 0 !important;
}
body button.-type-icon-round,
body a.-type-icon-round {
  width: var(--button-height) !important;
  min-width: var(--button-height);
  padding: 0;
  border-radius: var(--button-height) !important;
}
body button.-type-icon-round > i,
body a.-type-icon-round > i {
  margin: 0 !important;
}
body button.-type-text,
body a.-type-text {
  padding: 0;
  min-width: 0;
  text-align: left;
  justify-content: flex-start;
}
body button.-type-dropdown,
body a.-type-dropdown {
  --button-iconSize: 1.3rem;
  min-width: 120px;
  justify-content: space-between !important;
  padding: 0 10px;
}
body button.-type-dropdown > span,
body a.-type-dropdown > span {
  text-align: left;
}
body button.-type-dropdown > i,
body button.-type-dropdown > .button-animatedIcon > i,
body a.-type-dropdown > i,
body a.-type-dropdown > .button-animatedIcon > i {
  min-width: 12px;
}
body button.-type-dropdown > i,
body button.-type-dropdown > .button-animatedIcon,
body a.-type-dropdown > i,
body a.-type-dropdown > .button-animatedIcon {
  margin-left: auto;
}
body button.-type-dropdown > i:not(:last-child),
body button.-type-dropdown > .button-animatedIcon:not(:last-child),
body a.-type-dropdown > i:not(:last-child),
body a.-type-dropdown > .button-animatedIcon:not(:last-child) {
  margin-right: 8px;
}
body button.-type-dropdown > i.-alignLeft,
body button.-type-dropdown > .button-animatedIcon.-alignLeft,
body a.-type-dropdown > i.-alignLeft,
body a.-type-dropdown > .button-animatedIcon.-alignLeft {
  margin-left: 0;
}
body button.-type-dropdown:disabled > i,
body button.-type-dropdown:disabled > .button-animatedIcon > i,
body a.-type-dropdown:disabled > i,
body a.-type-dropdown:disabled > .button-animatedIcon > i {
  display: none;
}
body button.-type-colourpicker,
body a.-type-colourpicker {
  width: var(--button-height);
  min-width: var(--button-height);
  padding: 5px;
}
body button.-type-colourpicker > .buttonColor,
body a.-type-colourpicker > .buttonColor {
  width: 100%;
  height: calc(var(--button-height) - 10px - 2px);
  border-radius: var(--app-border-radius);
}
body button.-type-dropdown-round,
body a.-type-dropdown-round {
  --button-iconSize: 1.3rem;
  min-width: 120px;
  justify-content: space-between !important;
  padding: 0 10px;
  border-radius: var(--button-height) !important;
}
body button.-type-dropdown-round > span,
body a.-type-dropdown-round > span {
  text-align: left;
}
body button.-type-dropdown-round > i,
body button.-type-dropdown-round > .button-animatedIcon > i,
body a.-type-dropdown-round > i,
body a.-type-dropdown-round > .button-animatedIcon > i {
  min-width: 12px;
}
body button.-type-dropdown-round > i,
body button.-type-dropdown-round > .button-animatedIcon,
body a.-type-dropdown-round > i,
body a.-type-dropdown-round > .button-animatedIcon {
  margin-left: auto;
}
body button.-type-dropdown-round > i:not(:last-child),
body button.-type-dropdown-round > .button-animatedIcon:not(:last-child),
body a.-type-dropdown-round > i:not(:last-child),
body a.-type-dropdown-round > .button-animatedIcon:not(:last-child) {
  margin-right: 8px;
}
body button.-type-dropdown-round > i.-alignLeft,
body button.-type-dropdown-round > .button-animatedIcon.-alignLeft,
body a.-type-dropdown-round > i.-alignLeft,
body a.-type-dropdown-round > .button-animatedIcon.-alignLeft {
  margin-left: 0;
}
body button.-type-dropdown-round:disabled > i,
body button.-type-dropdown-round:disabled > .button-animatedIcon > i,
body a.-type-dropdown-round:disabled > i,
body a.-type-dropdown-round:disabled > .button-animatedIcon > i {
  display: none;
}
body button.-type-dropdown-round > span,
body a.-type-dropdown-round > span {
  padding-left: 10px;
}
body button.-type-round,
body a.-type-round {
  width: 100%;
  min-height: var(--droppah-losenge-height-small);
  border-radius: var(--button-height) !important;
}

body button,
body a {
  --button-height: 34px;
  --button-iconSize: 20px;
  --button-fontSize: 1rem;
  --button-minWidth: 100px;
  --button-padding: 15px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ and Edge */
  user-select: none; /* Standard syntax */
  border-radius: var(--app-border-radius);
  font-size: var(--button-fontSize);
  height: var(--button-height);
  min-width: var(--button-minWidth);
  max-width: 100%;
  color: var(--app-color-dark);
  border-radius: var(--app-border-radius);
  border: 1px solid transparent;
  text-transform: none;
  font-weight: var(--app-font-weight-normal);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 var(--button-padding);
  cursor: pointer;
  touch-action: manipulation;
  position: relative;
  white-space: nowrap;
}
@media (max-width: 850px) {
  body button,
body a {
    padding: 0 10px;
    min-width: 80px;
  }
}
body button > i,
body button > i.ion,
body button > .button-animatedIcon > i,
body a > i,
body a > i.ion,
body a > .button-animatedIcon > i {
  font-size: var(--button-iconSize);
  flex: 0 1 auto;
  transition: color var(--app-transition);
}
body button > .button-animatedIcon,
body a > .button-animatedIcon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--button-iconSize);
  height: var(--button-iconSize);
}
body button > .button-animatedIcon > i,
body a > .button-animatedIcon > i {
  transition: transform var(--app-transition-longer), opacity var(--app-transition-longer);
}
body button > .button-animatedIcon.-buttonIconAnimation-spinWhenActivated > i.-iconInactive, body button > .button-animatedIcon.-buttonIconAnimation-spinOnHover > i.-iconInactive,
body a > .button-animatedIcon.-buttonIconAnimation-spinWhenActivated > i.-iconInactive,
body a > .button-animatedIcon.-buttonIconAnimation-spinOnHover > i.-iconInactive {
  opacity: 1;
  transform: rotate(0deg);
}
body button > .button-animatedIcon.-buttonIconAnimation-spinWhenActivated > i.-iconActive, body button > .button-animatedIcon.-buttonIconAnimation-spinOnHover > i.-iconActive,
body a > .button-animatedIcon.-buttonIconAnimation-spinWhenActivated > i.-iconActive,
body a > .button-animatedIcon.-buttonIconAnimation-spinOnHover > i.-iconActive {
  opacity: 0;
  transform: rotate(-180deg);
}
body button > .button-animatedIcon.-buttonIconAnimation-spinWhenActivated.-activated > i.-iconInactive,
body a > .button-animatedIcon.-buttonIconAnimation-spinWhenActivated.-activated > i.-iconInactive {
  opacity: 0;
  transform: rotate(180deg);
}
body button > .button-animatedIcon.-buttonIconAnimation-spinWhenActivated.-activated > i.-iconActive,
body a > .button-animatedIcon.-buttonIconAnimation-spinWhenActivated.-activated > i.-iconActive {
  opacity: 1;
  transform: rotate(0);
}
body button > .button-animatedIcon.-buttonIconAnimation-spinOnHover:not(:disabled):hover > i.-iconInactive,
body a > .button-animatedIcon.-buttonIconAnimation-spinOnHover:not(:disabled):hover > i.-iconInactive {
  opacity: 0;
  transform: rotate(180deg);
}
body button > .button-animatedIcon.-buttonIconAnimation-spinOnHover:not(:disabled):hover > i.-iconActive,
body a > .button-animatedIcon.-buttonIconAnimation-spinOnHover:not(:disabled):hover > i.-iconActive {
  opacity: 1;
  transform: rotate(0);
}
body button > span,
body a > span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: normal;
  transition: color var(--app-transition);
}
body button > item-bullet,
body a > item-bullet {
  max-height: calc(100% - 10px);
  margin-top: 5px;
  margin-bottom: 5px;
}
body button.-centerAlign,
body a.-centerAlign {
  text-align: center;
}
body button.-centerTextAndIcon > .-title,
body a.-centerTextAndIcon > .-title {
  flex: 0 1 auto !important;
}
body button:disabled,
body a:disabled {
  pointer-events: none;
}
body button:disabled tooltip-popover,
body a:disabled tooltip-popover {
  pointer-events: all;
}
body button.btn,
body a.btn {
  border: none;
}
body button[ngbDropdownToggle]::after,
body a[ngbDropdownToggle]::after {
  display: none !important;
}
body button > :not(:last-child):not(tooltip-popover),
body a > :not(:last-child):not(tooltip-popover) {
  margin-right: 8px;
}
body a {
  padding: 0;
  min-width: 0;
  text-align: left;
  justify-content: flex-start;
  color: var(--theme-color-link) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body a:active, body a:focus {
  color: var(--theme-color-link) !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);
}
body a:hover {
  color: var(--theme-color-link-dark) !important;
}
body a:disabled {
  font-weight: var(--app-font-weight-normal);
  color: var(--app-color-grey-very-dark) !important;
  background-color: var(--app-color-grey-light) !important;
  border-color: transparent !important;
}
body a:disabled > i {
  color: var(--app-color-grey-very-dark) !important;
}
body a.-readonly {
  pointer-events: none !important;
}
body a:disabled {
  background-color: transparent !important;
}

body.-theme-droppah button,
body.-theme-droppah a {
  --button-height: var(--droppah-losenge-height-small);
}

:root {
  --primary: var(--app-color-blue);
}

.bg-primary {
  background-color: var(--theme-color-link) !important;
}

.btn {
  border-radius: var(--app-border-radius);
}
.btn:focus {
  box-shadow: none;
}
.btn.btn-left {
  text-align: left;
  padding: 6px 18px;
}
.btn.btn-left:after {
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
}

.btn-outline-secondary {
  color: var(--app-color-dark);
  background-color: var(--app-color-white);
  border: var(--app-border-light);
}
.btn-outline-secondary:hover {
  background-color: var(--app-color-grey-very-light);
}

.input-group {
  height: var(--app-input-height);
  flex-wrap: nowrap;
}
.input-group .input-group-addon,
.input-group button.input-group-addon {
  --button-height: var(--app-input-height);
  height: var(--app-input-height);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  border: var(--app-border);
  background-color: var(--app-color-grey-light);
  font-weight: var(--app-font-weight-normal);
}
.input-group .input-group-addon.-stretch,
.input-group button.input-group-addon.-stretch {
  width: 100%;
}
.input-group .input-group-addon.-stretch:first-child,
.input-group button.input-group-addon.-stretch:first-child {
  justify-content: flex-start;
}
.input-group .input-group-addon.-stretch:last-child,
.input-group button.input-group-addon.-stretch:last-child {
  justify-content: flex-end;
}
.input-group .input-group-addon:first-child,
.input-group button.input-group-addon:first-child {
  border-top-left-radius: var(--app-border-radius) !important;
  border-bottom-left-radius: var(--app-border-radius) !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.input-group .input-group-addon:last-child,
.input-group button.input-group-addon:last-child {
  border-top-right-radius: var(--app-border-radius) !important;
  border-bottom-right-radius: var(--app-border-radius) !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.input-group .input-group-addon:not(:last-child):not(:first-child),
.input-group button.input-group-addon:not(:last-child):not(:first-child) {
  border-radius: 0 !important;
}
.input-group .input-group-value {
  flex: 1 1 auto;
  height: var(--app-input-height);
  line-height: var(--app-input-height);
  font-weight: var(--app-font-weight-normal);
}
.input-group .input-group-value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
  flex: 1 1 auto;
}
.input-group .form-control {
  height: var(--app-input-height);
}
.input-group list-drop-down {
  width: auto !important;
}
.input-group list-drop-down:not(:last-child):not(:first-child) {
  width: 100%;
}
.input-group list-drop-down:first-child .ldd-button {
  border-top-left-radius: var(--app-border-radius) !important;
  border-bottom-left-radius: var(--app-border-radius) !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  --listDropDown-button-fontWeight: 500 !important;
}
.input-group list-drop-down:last-child .ldd-button {
  border-top-right-radius: var(--app-border-radius) !important;
  border-bottom-right-radius: var(--app-border-radius) !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  --listDropDown-button-fontWeight: 500 !important;
}
.input-group list-drop-down:not(:last-child):not(:first-child) .ldd-button {
  border-radius: 0 !important;
}
.input-group > :not(:first-child) {
  border-left: none;
}
.input-group > :not(:last-child) {
  border-right: none;
}

input.form-control {
  border: var(--app-border);
}
input.form-control:disabled {
  border: var(--app-border-light);
}

.dropdown-toggle::after {
  display: none !important;
}

@media (max-width: 850px) {
  body > .dropdown,
body > .dropup {
    width: 100vw;
    max-height: calc(100% - 20px);
    top: auto;
    bottom: 0px;
    left: 0;
    transform: none;
    top: 0;
    bottom: auto;
    display: flex;
  }
}
@media (min-width: 851px) {
  body > .dropdown > .dropdown-menu,
body > .dropup > .dropdown-menu {
    margin: 8px 0;
  }
}

.dropdown,
.dropup {
  will-change: auto !important;
}
.dropdown > .btn,
.dropup > .btn {
  position: relative;
  width: 100%;
}
.dropdown > .btn.dropdown-toggle:focus,
.dropup > .btn.dropdown-toggle:focus {
  box-shadow: none;
}
.dropdown > .btn:not(.btn-left):after,
.dropup > .btn:not(.btn-left):after {
  display: none;
}

.dropdown-menu {
  min-width: 80px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2);
  padding: 0;
  border: none;
  overflow: hidden;
  border-radius: var(--app-border-radius);
}
.dropdown-menu .dropdown-item {
  height: 34px;
  line-height: 26px;
  font-size: var(--app-font-size-normal);
}

.dropdown-item:focus, .dropdown-item:active {
  color: var(--app-color-dark);
  background-color: var(--app-color-white);
}
.dropdown-item:hover {
  color: var(--app-color-dark);
}
@media (max-width: 850px) {
  .dropdown-item:hover {
    background-color: var(--app-color-white);
  }
}
@media (min-width: 851px) {
  .dropdown-item:hover {
    background-color: var(--app-color-grey-very-light);
  }
}

.modal-content {
  border: none;
  border-radius: var(--app-border-radius);
}

@media (min-width: 851px) {
  .modal .modal-dialog {
    max-width: 700px;
  }
}
@media (min-width: 851px) {
  .modal .modal-dialog.modal-sm {
    max-width: 550px;
  }
}
@media (min-width: 851px) {
  .modal .modal-dialog.modal-lg {
    max-width: 800px;
  }
}
@media (min-width: 851px) {
  .modal .modal-dialog.modal-xl {
    max-width: 1000px;
  }
}
@media (max-width: 850px) {
  .modal .modal-dialog:not(.modal-mobile-sm) {
    width: 100%;
    height: 100%;
    max-width: 100%;
    min-width: 100%;
    max-height: 100%;
    min-height: 100%;
    margin: 0;
  }
  .modal .modal-dialog:not(.modal-mobile-sm) > .modal-content {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
  .modal .modal-dialog:not(.modal-mobile-sm) > .modal-content .app-modal {
    border-radius: 0;
  }
}

.dropdown-menu[data-bs-popper].app-datepicker-dropdown {
  position: fixed !important;
  width: auto;
  padding: 0;
  border: none;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  overflow: visible;
  max-height: calc(100vh - 100px);
  overflow-y: var(--app-overflow-overlay);
  box-shadow: none;
  background-color: transparent;
}
.dropdown-menu[data-bs-popper].app-datepicker-dropdown .app-week-relation-buttons {
  width: 400px;
  max-width: calc(100vw - 20px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dropdown-menu[data-bs-popper].app-datepicker-dropdown .app-week-relation-buttons .app-week-relation-button {
  max-width: calc(33vw - 16px);
  cursor: pointer;
  width: 125px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--app-colow-white);
  border-radius: var(--app-border-radius);
}
.dropdown-menu[data-bs-popper].app-datepicker-dropdown > ngb-datepicker {
  font-weight: var(--app-font-weight-normal);
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2);
}
@media (max-width: 850px) {
  .dropdown-menu[data-bs-popper].app-datepicker-dropdown {
    width: calc(100vw - 20px);
  }
}

ngb-datepicker.dropdown-menu {
  position: fixed !important;
  transform: translate(-50%, -50%) !important;
  left: 50% !important;
  top: 50% !important;
}
@media (max-width: 850px) {
  ngb-datepicker.dropdown-menu {
    width: calc(100vw - 20px);
  }
}

ngb-popover-window > .arrow {
  display: none !important;
}

ngb-datepicker {
  background-color: var(--app-color-grey-very-light);
  border-radius: var(--app-border-radius);
  border: none;
  display: flex;
  flex-direction: column;
}
@media (max-width: 850px) {
  ngb-datepicker {
    width: calc(100vw - 20px);
  }
  ngb-datepicker .ngb-dp-months {
    flex-direction: column;
    overflow-y: auto;
  }
}
ngb-datepicker .ngb-dp-header {
  padding: 15px;
  background-color: var(--app-color-grey-very-light);
}
ngb-datepicker .ngb-dp-months {
  position: relative;
}
ngb-datepicker .ngb-dp-months .ngb-dp-month {
  background-color: var(--app-color-grey-very-light);
  border: var(--app-border-light);
  border-radius: var(--app-border-radius);
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.05);
  overflow: hidden;
  flex: 0 0 auto;
  background-color: transparent;
  display: block;
  margin: 0 auto;
  box-shadow: none;
  border: none;
  border-radius: 0 0 5px 5px;
}
ngb-datepicker .ngb-dp-months .ngb-dp-month > i.ion-ios-arrow-forward {
  color: var(--app-color-grey);
  text-align: center;
  font-size: 1.4em;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}
ngb-datepicker .ngb-dp-months .ngb-dp-month .ngb-dp-month-name {
  height: 40px;
  line-height: 40px;
}

ngb-datepicker-navigation .ngb-dp-arrow {
  width: 34px;
  height: 34px;
}
ngb-datepicker-navigation .ngb-dp-arrow button.ngb-dp-arrow-btn {
  width: 34px;
  min-width: 0;
  height: 34px;
  padding: 0;
  margin: 0;
  background-color: var(--app-color-white);
  border: var(--app-border);
  border-radius: var(--app-border-radius);
  color: var(--app-color-dark);
}
ngb-datepicker-navigation .ngb-dp-arrow button.ngb-dp-arrow-btn:focus {
  outline: 0;
}
ngb-datepicker-navigation .ngb-dp-arrow button.ngb-dp-arrow-btn > span.ngb-dp-navigation-chevron {
  font-size: var(--app-font-size-small);
  margin: 0;
  position: relative;
}
ngb-datepicker-navigation .ngb-dp-arrow:not(.right) span.ngb-dp-navigation-chevron {
  top: 0;
  left: 1px;
}
ngb-datepicker-navigation .ngb-dp-arrow.right span.ngb-dp-navigation-chevron {
  top: -1px;
  left: -1px;
}

ngb-datepicker-navigation-select > .form-select {
  flex: 0 0 auto !important;
  padding: 0 15px !important;
  font-size: var(--app-font-size-normal) !important;
  height: 34px !important;
  width: 120px;
  margin: 0 5px;
  font-weight: var(--app-font-weight-normal);
  border-radius: 5px;
  border-color: var(--app-color-border);
  cursor: pointer;
}

ngb-datepicker-month {
  padding: 15px;
  flex: 0 0 auto;
  background-color: var(--app-color-white);
}
ngb-datepicker-month .ngb-dp-week {
  padding: 0 !important;
  --ngbDpDay-size: 50px;
  --ngbDpDay-padding: 5px;
}
ngb-datepicker-month .ngb-dp-week.ngb-dp-weekdays {
  border: none;
  background-color: transparent;
}
ngb-datepicker-month .ngb-dp-week.ngb-dp-weekdays .ngb-dp-weekday {
  width: var(--ngbDpDay-size);
  height: var(--ngbDpDay-size);
  line-height: var(--ngbDpDay-size);
  font-weight: var(--app-font-weight-normal);
  font-style: inherit;
  color: var(--app-color-dark);
  font-size: var(--app-font-size-normal);
}
ngb-datepicker-month .ngb-dp-week .ngb-dp-day {
  width: var(--ngbDpDay-size);
  height: var(--ngbDpDay-size);
  display: flex;
  justify-content: center;
  align-items: center;
}
ngb-datepicker-month .ngb-dp-week .ngb-dp-day > div {
  --ngbDpDay-inner-size: calc(var(--ngbDpDay-size) - (var(--ngbDpDay-padding) * 2));
  width: var(--ngbDpDay-inner-size);
  height: var(--ngbDpDay-inner-size);
  line-height: var(--ngbDpDay-inner-size);
  border-radius: var(--ngbDpDay-inner-size);
  font-size: var(--app-font-size-normal);
  text-align: center;
}
ngb-datepicker-month .ngb-dp-week .ngb-dp-day > div.active, ngb-datepicker-month .ngb-dp-week .ngb-dp-day > div:hover {
  background-color: var(--theme-color-link-translucent);
}
ngb-datepicker-month .ngb-dp-week .ngb-dp-day > div.bg-primary {
  background-color: var(--theme-color-link);
  color: var(--app-color-white);
}

ngb-tooltip-window {
  opacity: 1 !important;
}
ngb-tooltip-window .tooltip-inner {
  padding: 12px 15px;
  font-size: var(--app-font-size-normal);
  max-width: 500px;
}
ngb-tooltip-window.tooltipPopover {
  transition: none;
}
ngb-tooltip-window.tooltipPopover > .arrow {
  display: none;
}
ngb-tooltip-window.tooltipPopover-light > .tooltip-inner {
  background-color: var(--app-color-white);
  box-shadow: var(--app-box-shadow-dark);
  color: var(--pp-color-dark);
}
ngb-tooltip-window.tooltipPopover-light > .tooltip-inner .tooltipPopover-buttonRow {
  width: 100%;
  margin-top: 10px;
  display: flex;
}
ngb-tooltip-window.tooltipPopover-light > .tooltip-inner .tooltipPopover-buttonRow > button {
  min-width: 250px;
  width: 100%;
  --button-height: 26px;
}
ngb-tooltip-window.workloadTooltips {
  position: relative;
  width: 120px;
}
ngb-tooltip-window.workloadTooltips > .tooltip-inner {
  width: 100%;
}
@media (max-width: 850px) {
  ngb-tooltip-window.-verboseTooltip {
    max-width: min(100% - 140px, 500px) !important;
  }
}

app-backdrop.-modalBackdrop {
  z-index: 1050;
}

.offcanvas-backdrop.-modalBackdrop,
.modal-backdrop {
  z-index: 1050 !important;
}

app-backdrop:not(.-modalBackdrop) {
  z-index: 1060;
}

.offcanvas-backdrop:not(.-modalBackdrop) {
  z-index: 1060 !important;
}

lib-expanding-canvas-wrapper {
  z-index: 1051;
}

.modal,
.offcanvas.-modal {
  z-index: 1051 !important;
}

.offcanvas:not(.-modal),
body > .dropdown,
body > .dropup {
  z-index: 1061 !important;
}

.offcanvas {
  transition: transform var(--app-transition);
  overflow: hidden;
  display: flex;
  align-items: stretch;
  overflow: hidden;
}
@media (max-width: 850px) {
  .offcanvas.offcanvas-top, .offcanvas.offcanvas-bottom {
    max-height: calc(100% - 50px);
  }
  .offcanvas.offcanvas-top.-fullHeight, .offcanvas.offcanvas-bottom.-fullHeight {
    height: calc(100% - 50px);
  }
}
@media (max-width: 850px) {
  .offcanvas.offcanvas-start, .offcanvas.offcanvas-end {
    max-width: calc(100% - 50px);
  }
}
.offcanvas.-modal {
  height: auto;
}
.offcanvas > lib-offcanvas-wrapper > * {
  width: 100%;
}

.offcanvas-top {
  bottom: auto;
}

.offcanvas-bottom {
  top: auto;
}

.offcanvas-top,
.offcanvas-bottom {
  height: auto;
}

.offcanvas-start,
.offcanvas-end {
  width: auto;
}

.offcanvas-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--app-border-radius-larger);
  border-bottom-right-radius: var(--app-border-radius-larger);
}

.offcanvas-bottom {
  border-top-left-radius: var(--app-border-radius-larger);
  border-top-right-radius: var(--app-border-radius-larger);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.offcanvas-start {
  border-top-left-radius: 0;
  border-top-right-radius: var(--app-border-radius-larger);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: var(--app-border-radius-larger);
}

.offcanvas-end {
  border-top-left-radius: var(--app-border-radius-larger);
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--app-border-radius-larger);
  border-bottom-right-radius: 0;
}

body.-theme-droppah .offcanvas-top,
body.-theme-droppah .offcanvas-bottom {
  max-width: var(--droppah-content-maxWidth);
  margin: 0 auto;
}

@keyframes notifyIcon {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(1.2);
  }
  66% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
:root {
  --app-color-payhero: #00adef;
  --app-color-payhero-dark: #0094d6;
  --app-color-payhero-translucent: #00adef20;
  --app-color-payhero-translucent-light: #00adef40;
  --app-color-invoxy: #eb6426;
  --app-color-invoxy-dark: #d14f13;
  --app-color-invoxy-translucent: #eb642620;
  --app-color-invoxy-translucent-light: #eb642640;
  --app-color-karmly: #ef7812;
  --app-color-karmly-dark: #cb6813;
  --app-color-karmly-translucent: #ef781220;
  --app-color-karmly-translucent-light: #ef781240;
  --app-color-droppah-light: #d0e6c8;
  --app-color-droppah: #459915;
  --app-color-droppah-dark: #397d12;
  --app-color-droppah-translucent: #45991520;
  --app-color-droppah-translucent-light: #45991540;
  --app-color-droppah-purple: #763eff;
  --app-color-droppah-purple-dark: #3b266f;
  --app-color-droppah-purple-translucent: #281a4a20;
  --app-color-droppah-purple-translucent-light: #281a4a40;
  --app-color-flexitime-blue-light: #ccdae0;
  --app-color-flexitime-blue: #004763;
  --app-color-flexitime-blue-dark: #002b3b;
  --app-color-flexitime-blue-translucent: #00476320;
  --app-color-flexitime-blue-translucent-light: #00476340;
  --app-color-flexitime-yellow: #ffad3d;
  --app-color-flexitime-yellow-dark: #d89232;
  --app-color-flexitime-yellow-translucent: #ffad3d20;
  --app-color-flexitime-yellow-translucent-light: #ffad3d40;
  --app-color-blue: #337ab7;
  --app-color-blue-dark: #1a619e;
  --app-color-blue-translucent: #337ab720;
  --app-color-blue-translucent-light: #337ab740;
  --app-color-mid-blue: #118be0;
  --app-color-mid-blue-dark: #1383d3;
  --app-color-mid-blue-translucent: #118be010;
  --app-color-mid-blue-translucent-light: #118be030;
  --app-color-green: #5eb22e;
  --app-color-green-dark: #459915;
  --app-color-green-translucent: #5eb22e20;
  --app-color-green-translucent-light: #5eb22e40;
  --app-color-red: #c03a36;
  --app-color-red-dark: #b22e2e;
  --app-color-red-translucent: #d9534f20;
  --app-color-red-translucent-light: #d9534f40;
  --app-color-yellow: #fdd835;
  --app-color-yellow-dark: #e4bf1c;
  --app-color-yellow-translucent: #fdd83520;
  --app-color-yellow-translucent-light: #fdd83540;
  --app-color-pending: #d2b023;
  --app-color-pending-light: #fef9e6;
  --app-color-grey-very-dark: #777777;
  --app-color-grey-dark: #999999;
  --app-color-grey: #dddddd;
  --app-color-grey-light: #eeeeee;
  --app-color-grey-very-light: #f9f9f9;
  --app-color-grey-translucent-dark: #77777780;
  --app-color-grey-translucent-light: #cccccc80;
  --app-color-grey-translucent: #cccccc40;
  --app-color-dark: #333;
  --app-color-black: #000000;
  --app-color-white: #ffffff;
  --app-color-white-translucent: #ffffffaa;
  --app-color-error-background: #b22e2ebf;
  --app-color-error-border: #b22e2ebf;
  --app-color-info-background: #00adef1a;
  --app-color-info-border: #00adef80;
  --app-color-warning-background: #f1ca031a;
  --app-color-warning-border: #f1ca0380;
  --app-color-leave-light: #f5d4d3;
  --app-color-leave: #ef5350;
  --app-font-size-small: 10px;
  --app-font-size-normal: 12px;
  --app-font-size-large: 14px;
  --app-font-size-larger: 16px;
  --app-font-size-largest: 22px;
  --app-font-weight-skinny: 200;
  --app-font-weight-thin: 400;
  --app-font-weight-normal: 500;
  --app-font-weight-bold: 600;
  --app-font-weight-bolder: 700;
  --app-color-border: var(--app-color-grey);
  --app-color-border-light: var(--app-color-grey-light);
  --app-color-border-dark: var(--app-color-dark);
  --app-border: 1px solid var(--app-color-border);
  --app-border-dashed: 1px dashed var(--app-color-border);
  --app-border-light: 1px solid var(--app-color-border-light);
  --app-border-dashed-light: 1px dashed var(--app-color-border-light);
  --app-border-dark: 1px solid var(--app-color-border-dark);
  --app-border-dashed-dark: 1px dashed var(--app-color-border-dark);
  --app-border-radius: 5px;
  --app-border-radius-large: 8px;
  --app-border-radius-larger: 20px;
  --app-border-inset: inset 0 0 0 1px var(--app-color-border);
  --app-border-light-inset: inset 0 0 0 1px var(--app-color-border-light);
  --app-transition-shorter: 0.1s ease;
  --app-transition: 0.2s ease;
  --app-transition-longer: 0.3s ease;
  --app-transition-longest: 0.5s ease;
  --app-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  --app-box-shadow-medium: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  --app-box-shadow-dark: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  --app-box-shadow-inset: inset 0 0 10px 0 rgba(0, 0, 0, 0.05);
  --app-box-shadow-dark-inset: inset 0 0 10px 0 rgba(0, 0, 0, 0.2);
  --app-text-shadow-small: 0px 0px 5px rgba(100, 100, 100, 1);
  --app-text-shadow: 0px 0px 10px rgba(100, 100, 100, 1);
  --app-navMenu-width: 260px;
  --app-input-height: 34px;
  --app-input-focus: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  --app-formItem-height: var(--app-input-height);
  --app-overflow-overlay: overlay;
  --droppah-losenge-height: 60px;
  --droppah-losenge-height-small: 45px;
  --droppah-header-spacing: 20px;
  --droppah-border-radius-container: calc(var(--app-border-radius) - var(--droppah-border-width)/2);
  --droppah-border-width: 5px;
  --droppah-border: var(--droppah-border-width) var(--droppah-color-background-light) solid;
  --droppah-border-dark: var(--droppah-border-width) var(--droppah-color-background-dark) solid;
  --droppah-color-background-light: #202020;
  --droppah-color-background-mid: #0e0e0e;
  --droppah-color-background-dark: var(--app-color-black);
  --droppah-color-dark-grey: #252525;
  --droppah-color-grey: #8d8d8d;
  --droppah-color-orange: #ffa571;
  --droppah-color-green: #84ca80;
  --droppah-color-green-dark: #2b3d2a;
  --droppah-color-stone: #EBEAE4;
  --droppah-color-red: #c21313;
  --droppah-content-maxWidth: 600px;
}
@supports not (overflow: overlay) {
  :root {
    --app-overflow-overlay: auto;
  }
}
body.-theme-invoxy {
  --theme-color-scrollbar-background: var(--app-color-grey-light);
  --theme-color-scrollbar: var(--app-color-grey);
  --theme-scrollbar-width: 8px;
  --theme-color-accent: var(--app-color-invoxy);
  --theme-color-accent-dark: var(--app-color-invoxy-dark);
  --theme-color-accent-translucent: var(--app-color-invoxy-translucent);
  --theme-color-accent-translucent-light: var(--app-color-invoxy-translucent-light);
  --theme-color-success: var(--app-color-green);
  --theme-color-success-dark: var(--app-color-green-dark);
  --theme-color-success-translucent: var(--app-color-green-translucent);
  --theme-color-success-translucent-light: var(--app-color-green-translucent-light);
  --theme-color-primary: var(--app-color-flexitime-blue);
  --theme-color-primary-dark: var(--app-color-flexitime-blue-dark);
  --theme-color-primary-translucent: var(--app-color-flexitime-blue-translucent);
  --theme-color-primary-translucent-light: var(--app-color-flexitime-blue-translucent-light);
  --theme-color-link: var(--app-color-mid-blue);
  --theme-color-link-dark: var(--app-color-mid-blue-dark);
  --theme-color-link-translucent: var(--app-color-mid-blue-translucent);
  --theme-color-link-translucent-light: var(--app-color-mid-blue-translucent-light);
  --theme-color-input-disabled-background: var(--app-color-grey-very-light);
  --theme-color-input-disabled-color: var(--app-color-grey-very-dark);
  --theme-color-input-readonly-background: var(--app-color-grey-very-light);
  --theme-color-input-readonly-color: var(--app-color-grey-very-dark);
  --theme-input-focus-boxShadow: 0 0 0 0.2rem var(--theme-input-focus-boxShadow-color);
  --theme-input-focus-boxShadow-color: rgb(0 123 255 / 25%);
  --theme-input-border-radius: var(--app-border-radius);
  --theme-input-focus-border-width: 1px;
  --theme-input-focus-border-color: var(--app-color-border);
  --theme-input-focus-border: var(--theme-input-focus-border-width) solid var(--theme-input-focus-border-color);
}
body.-theme-karmly-light, body.-theme-karmly-dark {
  --theme-color-scrollbar-background: var(--app-color-grey-light);
  --theme-color-scrollbar: var(--app-color-grey);
  --theme-scrollbar-width: 8px;
  --theme-color-accent: var(--app-color-karmly);
  --theme-color-accent-dark: var(--app-color-karmly-dark);
  --theme-color-accent-translucent: var(--app-color-karmly-translucent);
  --theme-color-accent-translucent-light: var(--app-color-karmly-translucent-light);
  --theme-color-success: var(--app-color-karmly);
  --theme-color-success-dark: var(--app-color-karmly-dark);
  --theme-color-success-translucent: var(--app-color-karmly-translucent);
  --theme-color-success-translucent-light: var(--app-color-karmly-translucent-light);
  --theme-color-primary: var(--app-color-flexitime-blue);
  --theme-color-primary-dark: var(--app-color-flexitime-blue-dark);
  --theme-color-primary-translucent: var(--app-color-flexitime-blue-translucent);
  --theme-color-primary-translucent-light: var(--app-color-flexitime-blue-translucent-light);
  --theme-color-link: var(--app-color-flexitime-blue);
  --theme-color-link-dark: var(--app-color-flexitime-blue-dark);
  --theme-color-link-translucent: var(--app-color-flexitime-blue-translucent);
  --theme-color-link-translucent-light: var(--app-color-flexitime-blue-translucent-light);
  --theme-color-input-disabled-background: transparent;
  --theme-color-input-disabled-color: var(--app-color-grey-dark);
  --theme-color-input-readonly-background: transparent;
  --theme-color-input-readonly-color: var(--app-color-grey-very-dark);
  --theme-input-focus-boxShadow: none;
  --theme-input-focus-boxShadow-color: transparent;
  --theme-input-border-radius: 0;
  --theme-input-focus-border-width: 2px;
  --theme-input-focus-border-color: var(--theme-color-primary);
  --theme-input-focus-border: var(--theme-input-focus-border-width) solid var(--theme-input-focus-border-color);
}
body.-theme-droppah {
  --theme-color-scrollbar-background: var(--droppah-color-background-dark);
  --theme-color-scrollbar: var(--droppah-color-background-light);
  --theme-scrollbar-width: var(--droppah-border-width);
  --theme-color-accent: var(--app-color-droppah-purple);
  --theme-color-accent-dark: var(--app-color-droppah-purple-dark);
  --theme-color-accent-translucent: var(--app-color-droppah-purple-translucent);
  --theme-color-accent-translucent-light: var(--app-color-droppah-purple-translucent-light);
  --theme-color-success: var(--app-color-droppah-purple);
  --theme-color-success-dark: var(--app-color-droppah-purple-dark);
  --theme-color-success-translucent: var(--app-color-droppah-purple-translucent);
  --theme-color-success-translucent-light: var(--app-color-droppah-purple-translucent-light);
  --theme-color-primary: var(--app-color-droppah-purple);
  --theme-color-primary-dark: var(--app-color-droppah-purple-dark);
  --theme-color-primary-translucent: var(--app-color-droppah-purple-translucent);
  --theme-color-primary-translucent-light: var(--app-color-droppah-purple-translucent-light);
  --theme-color-link: var(--app-color-droppah-purple);
  --theme-color-link-dark: var(--app-color-droppah-purple-dark);
  --theme-color-link-translucent: var(--app-color-droppah-purple-translucent);
  --theme-color-link-translucent-light: var(--app-color-droppah-purple-translucent-light);
  --theme-color-input-disabled-background: transparent;
  --theme-color-input-disabled-color: var(--app-color-grey-dark);
  --theme-input-focus-boxShadow: none;
  --theme-input-focus-boxShadow-color: transparent;
  --theme-input-border-width: 2px;
  --theme-input-focus-border-color: var(--theme-color-primary);
  --theme-input-focus-border: var(--theme-input-border-width) solid var(--theme-input-focus-border-color);
}
body.-theme-flexitime {
  --theme-color-scrollbar-background: var(--app-color-grey-light);
  --theme-color-scrollbar: var(--app-color-grey);
  --theme-scrollbar-width: 8px;
  --theme-color-accent: var(--app-color-flexitime-yellow);
  --theme-color-accent-dark: var(--app-color-flexitime-yellow-dark);
  --theme-color-accent-translucent: var(--app-color-flexitime-yellow-translucent);
  --theme-color-accent-translucent-light: var(--app-color-flexitime-yellow-translucent-light);
  --theme-color-success: var(--app-color-flexitime-yellow);
  --theme-color-success-dark: var(--app-color-flexitime-yellow-dark);
  --theme-color-success-translucent: var(--app-color-flexitime-yellow-translucent);
  --theme-color-success-translucent-light: var(--app-color-flexitime-yellow-translucent-light);
  --theme-color-primary: var(--app-color-flexitime-blue);
  --theme-color-primary-dark: var(--app-color-flexitime-blue-dark);
  --theme-color-primary-translucent: var(--app-color-flexitime-blue-translucent);
  --theme-color-primary-translucent-light: var(--app-color-flexitime-blue-translucent-light);
  --theme-color-link: var(--app-color-flexitime-blue);
  --theme-color-link-dark: var(--app-color-flexitime-blue-dark);
  --theme-color-link-translucent: var(--app-color-flexitime-blue-translucent);
  --theme-color-link-translucent-light: var(--app-color-flexitime-blue-translucent-light);
  --theme-color-input-disabled-background: transparent;
  --theme-color-input-disabled-color: var(--app-color-grey-dark);
  --theme-color-input-readonly-background: transparent;
  --theme-color-input-readonly-color: var(--app-color-grey-very-dark);
  --theme-input-focus-boxShadow: none;
  --theme-input-focus-boxShadow-color: transparent;
  --theme-input-border-radius: 0;
  --theme-input-focus-border-width: 2px;
  --theme-input-focus-border-color: var(--theme-color-primary);
  --theme-input-focus-border: var(--theme-input-focus-border-width) solid var(--theme-input-focus-border-color);
}

@media (max-width: 850px) {
  .-mobile-only {
    display: flex !important;
  }
}
@media (min-width: 851px) {
  .-mobile-only {
    display: none !important;
  }
}

@media (max-width: 850px) {
  .-desktop-only {
    display: none !important;
  }
}
@media (min-width: 851px) {
  .-desktop-only {
    display: flex !important;
  }
}

:host {
  --megaSearch-height: 34px;
  height: var(--megaSearch-height);
  width: 260px;
  position: relative;
  display: block;
  flex: 1 1 auto;
}
:host .megaSearch-container {
  width: 100%;
  height: 100%;
  border-radius: var(--app-border-radius);
}
:host .megaSearch-container .megaSearch-inputContainer {
  --app-formItem-height: var(--megaSearch-height);
}
@media (max-width: 850px) {
  :host {
    --megaSearch-height: 44px;
    min-width: 0;
    width: auto;
  }
}

@media (max-width: 850px) {
  .megaSearch-inputContainer {
    --app-formItem-height: 44px;
  }
}
.megaSearch-inputContainer app-search {
  flex: 1 1 auto;
}
.megaSearch-inputContainer .megaSearch-selectedSearchPropertyCount {
  flex: 0 0 auto;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: var(--theme-color-success);
  color: var(--app-color-white);
  font-size: var(--app-font-size);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}

.megaSearch-dropdown {
  background-color: var(--app-color-white);
  min-width: 260px;
  max-width: 600px;
  padding: 10px 0;
  overflow-y: auto;
}
@media (min-width: 851px) {
  .megaSearch-dropdown {
    position: relative;
  }
}
@media (max-width: 850px) {
  .megaSearch-dropdown {
    min-width: 150px;
  }
}
.megaSearch-dropdown.show {
  display: flex;
  flex-direction: column;
}
.megaSearch-dropdown .megaSearch-dropdown-contents .megaSearch-dropdown-header {
  padding: 0 15px;
  width: 100%;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--app-color-grey-dark);
  font-size: var(--app-font-size);
  margin-bottom: 5px;
}
.megaSearch-dropdown .megaSearch-dropdown-contents .megaSearch-dropdown-header > button {
  --button-fontSize: 0.9rem;
}
.megaSearch-dropdown .megaSearch-dropdown-contents .megaSearch-dropdown-empty {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--app-font-size);
  color: var(--app-color-grey);
}
.megaSearch-dropdown .megaSearch-dropdown-contents .megaSearch-dropdown-spacer {
  margin: 5px 0;
  width: 100%;
  height: 1px;
  border-top: var(--app-border);
}
.megaSearch-dropdown .megaSearch-dropdown-contents .megaSearch-dropdown-properties {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.megaSearch-dropdown .megaSearch-dropdown-contents .megaSearch-dropdown-properties .megaSearch-dropdown-property {
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  font-size: var(--app-font-size);
}
.megaSearch-dropdown .megaSearch-dropdown-contents .megaSearch-dropdown-properties .megaSearch-dropdown-property .-itemValue {
  flex: 1 1 100%;
}
.megaSearch-dropdown .megaSearch-dropdown-contents .megaSearch-dropdown-properties .megaSearch-dropdown-property .-propertyLabel {
  height: 20px;
  line-height: 20px;
  flex: 0 0 auto;
  max-width: 50%;
  text-align: center;
  padding: 0 10px;
  border-radius: 10px;
  background-color: var(--app-color-grey-light);
  margin-left: 5px;
}
.megaSearch-dropdown .megaSearch-dropdown-contents .megaSearch-dropdown-properties .megaSearch-dropdown-property > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.megaSearch-dropdown .megaSearch-dropdown-contents .megaSearch-dropdown-properties .megaSearch-dropdown-property > i.app-remove {
  --appRemove-size: 20px;
  flex: 0 0 auto;
  margin-left: 3px;
}
.megaSearch-dropdown .megaSearch-dropdown-contents .megaSearch-dropdown-properties.-filtered .megaSearch-dropdown-property {
  cursor: pointer;
}
.megaSearch-dropdown .megaSearch-dropdown-contents .megaSearch-dropdown-properties.-filtered .megaSearch-dropdown-property:hover {
  background-color: var(--app-color-grey-very-light);
}
.megaSearch-dropdown .megaSearch-dropdown-contents .megaSearch-dropdown-properties.-filtered .megaSearch-dropdown-property.-highlighted {
  background-color: var(--theme-color-link-translucent);
}
.megaSearch-dropdown .megaSearch-dropdown-contents .megaSearch-dropdown-properties.-filtered .megaSearch-dropdown-property.-highlighted .-propertyLabel {
  background-color: var(--app-color-white);
}
.megaSearch-dropdown .megaSearch-dropdown-contents .megaSearch-dropdown-properties.-selected .megaSearch-dropdown-property .-clearSelectedProperty {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--app-color-white);
  cursor: pointer;
  transition: background-color var(--app-transition);
}
.megaSearch-dropdown .megaSearch-dropdown-contents .megaSearch-dropdown-properties.-selected .megaSearch-dropdown-property .-clearSelectedProperty:hover {
  background-color: var(--app-color-grey-light);
}
@media (max-width: 850px) {
  .megaSearch-dropdown .megaSearch-inputContainer {
    padding-bottom: 10px;
  }
  .megaSearch-dropdown .megaSearch-dropdown-contents {
    overflow-y: auto;
    padding-top: 5px;
  }
  .megaSearch-dropdown .megaSearch-dropdown-contents .megaSearch-dropdown-properties .megaSearch-dropdown-property {
    height: 40px;
  }
}

body.-theme-invoxy {
  --theme-appList-border: var(--app-border-light);
  --theme-appList-item-borderBottom: var(--app-border-light);
  --theme-appList-empty-border: none;
}
body.-theme-karmly-light, body.-theme-karmly-dark, body.-theme-flexitime {
  --theme-appList-border: var(--app-border);
  --theme-appList-item-borderBottom: var(--app-border);
  --theme-appList-empty-border: var(--app-border);
}

body button.-size-btn-lg,
body a.-size-btn-lg {
  --button-height: 40px !important;
}
body button.-size-btn-sm,
body a.-size-btn-sm {
  --button-height: 24px !important;
}
body button.-size-icon-lg,
body a.-size-icon-lg {
  --button-iconSize: 34px !important;
}
body button.-size-icon-sm,
body a.-size-icon-sm {
  --button-iconSize: 16px !important;
}
body button.-size-text-lg,
body a.-size-text-lg {
  --button-fontSize: 22px !important;
}
body button.-size-text-sm,
body a.-size-text-sm {
  --button-fontSize: 0.9rem !important;
}

.app-nav {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
}

.app-navHeader,
.app-navToolbar {
  --app-nav-rowHeight: 34px;
  --app-nav-rowComponentHeight: var(--app-nav-rowHeight);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: var(--app-border);
  padding: 0 20px;
}
@media (max-width: 850px) {
  .app-navHeader,
.app-navToolbar {
    padding: 0 10px;
  }
}
.app-navHeader.-grid .app-navRow,
.app-navHeader.-grid .app-tabs,
.app-navHeader.-grid app-tabs,
.app-navToolbar.-grid .app-navRow,
.app-navToolbar.-grid .app-tabs,
.app-navToolbar.-grid app-tabs {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}
.app-navHeader .app-tabs,
.app-navHeader app-tabs,
.app-navToolbar .app-tabs,
.app-navToolbar app-tabs {
  border-bottom: none;
}
.app-navHeader .app-navRow,
.app-navToolbar .app-navRow {
  flex: 0 0 auto;
  width: 100%;
  min-height: 34px;
  display: flex;
  justify-content: space-between;
}
.app-navHeader .app-navRow .app-nav-graphic,
.app-navToolbar .app-navRow .app-nav-graphic {
  height: 40px;
}
.app-navHeader .app-navRow .app-nav-icon,
.app-navToolbar .app-navRow .app-nav-icon {
  font-size: 24px;
  color: var(--theme-color-accent);
}
.app-navHeader .app-navRow .app-navRow-left,
.app-navHeader .app-navRow .app-navRow-right,
.app-navHeader .app-navRow .app-navRow-center,
.app-navToolbar .app-navRow .app-navRow-left,
.app-navToolbar .app-navRow .app-navRow-right,
.app-navToolbar .app-navRow .app-navRow-center {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  min-width: 0;
}
@media (min-width: 851px) {
  .app-navHeader .app-navRow .app-navRow-left > back-button:not(:last-child) ~ *,
.app-navHeader .app-navRow .app-navRow-right > back-button:not(:last-child) ~ *,
.app-navHeader .app-navRow .app-navRow-center > back-button:not(:last-child) ~ *,
.app-navToolbar .app-navRow .app-navRow-left > back-button:not(:last-child) ~ *,
.app-navToolbar .app-navRow .app-navRow-right > back-button:not(:last-child) ~ *,
.app-navToolbar .app-navRow .app-navRow-center > back-button:not(:last-child) ~ * {
    margin-left: 20px;
  }
}
.app-navHeader .app-navRow .app-navRow-left > :not(:first-child),
.app-navHeader .app-navRow .app-navRow-right > :not(:first-child),
.app-navHeader .app-navRow .app-navRow-center > :not(:first-child),
.app-navToolbar .app-navRow .app-navRow-left > :not(:first-child),
.app-navToolbar .app-navRow .app-navRow-right > :not(:first-child),
.app-navToolbar .app-navRow .app-navRow-center > :not(:first-child) {
  margin-left: 10px;
}
.app-navHeader .app-navRow .app-navRow-left > .app-remove,
.app-navHeader .app-navRow .app-navRow-right > .app-remove,
.app-navHeader .app-navRow .app-navRow-center > .app-remove,
.app-navToolbar .app-navRow .app-navRow-left > .app-remove,
.app-navToolbar .app-navRow .app-navRow-right > .app-remove,
.app-navToolbar .app-navRow .app-navRow-center > .app-remove {
  --appRemove-size: 28px;
}
.app-navHeader .app-navRow .app-navRow-left,
.app-navToolbar .app-navRow .app-navRow-left {
  justify-content: flex-start;
  flex: 0 0 auto;
  margin-right: 15px;
}
.app-navHeader .app-navRow .app-navRow-right,
.app-navToolbar .app-navRow .app-navRow-right {
  justify-content: flex-end;
  flex: 0 1 auto;
  margin-left: auto;
  width: 50%;
}
.app-navHeader .app-navRow .app-navRow-center,
.app-navToolbar .app-navRow .app-navRow-center {
  justify-content: center;
  flex: 0 1 auto;
  margin: 0 auto;
}
.app-navHeader .app-navRow .app-navRow-logo,
.app-navToolbar .app-navRow .app-navRow-logo {
  height: 36px;
}
@media (max-width: 850px) {
  .app-navHeader .app-navRow .app-navRow-left,
.app-navHeader .app-navRow .app-navRow-right,
.app-navHeader .app-navRow .app-navRow-center,
.app-navToolbar .app-navRow .app-navRow-left,
.app-navToolbar .app-navRow .app-navRow-right,
.app-navToolbar .app-navRow .app-navRow-center {
    margin-left: 0;
    margin-right: 0;
  }
}
.app-navHeader thumbnail-graphic:not(:last-child),
.app-navToolbar thumbnail-graphic:not(:last-child) {
  margin-right: 10px;
}
.app-navHeader .app-nav-errorMessage,
.app-navToolbar .app-nav-errorMessage {
  color: var(--app-color-red);
  font-weight: var(--app-font-weight-normal);
}
.app-navHeader mega-search,
.app-navHeader app-search,
.app-navToolbar mega-search,
.app-navToolbar app-search {
  flex: 0 1 auto;
  width: 300px;
}
@media (max-width: 850px) {
  .app-navHeader mega-search,
.app-navHeader app-search,
.app-navToolbar mega-search,
.app-navToolbar app-search {
    flex: 1 1 auto;
  }
}
.app-navHeader .app-nav-title,
.app-navToolbar .app-nav-title {
  font-size: var(--app-font-size-larger);
  font-weight: var(--app-font-weight-bolder);
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 auto;
}
@media (max-width: 850px) {
  .app-navHeader .app-nav-title,
.app-navToolbar .app-nav-title {
    font-size: var(--app-font-size-large);
    flex: 1 1 auto;
  }
}
.app-navHeader .app-nav-title .app-nav-titleRow,
.app-navToolbar .app-nav-title .app-nav-titleRow {
  display: flex;
  align-items: center;
  position: relative;
}
.app-navHeader .app-nav-title .app-nav-titleRow > :not(:first-child):not(span),
.app-navToolbar .app-nav-title .app-nav-titleRow > :not(:first-child):not(span) {
  margin-left: 10px;
}
.app-navHeader .app-nav-title .app-nav-titleRow > i,
.app-navToolbar .app-nav-title .app-nav-titleRow > i {
  color: var(--app-color-grey-dark);
}
.app-navHeader .app-nav-title .app-nav-titleDescription,
.app-navToolbar .app-nav-title .app-nav-titleDescription {
  font-size: var(--app-font-size-normal);
  color: var(--app-color-grey-very-dark);
  font-weight: var(--app-font-weight-normal);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.app-navHeader .app-nav-title .app-nav-titleDescription > i,
.app-navHeader .app-nav-title .app-nav-titleDescription > item-bullet,
.app-navToolbar .app-nav-title .app-nav-titleDescription > i,
.app-navToolbar .app-nav-title .app-nav-titleDescription > item-bullet {
  margin-right: 5px;
}
.app-navHeader .app-nav-title .app-nav-titleDescription.-error,
.app-navToolbar .app-nav-title .app-nav-titleDescription.-error {
  color: var(--app-color-red);
}
.app-navHeader .app-nav-title app-form-item,
.app-navToolbar .app-nav-title app-form-item {
  padding: 0;
}
.app-navHeader .app-nav-title app-form-item ::ng-deep input,
.app-navToolbar .app-nav-title app-form-item ::ng-deep input {
  height: 40px;
}
.app-navHeader .app-nav-title .app-nav-titleInput,
.app-navToolbar .app-nav-title .app-nav-titleInput {
  height: 40px;
}

.app-navHeader {
  min-height: var(--theme-app-navHeader-minHeight);
  padding-top: 10px;
  justify-content: flex-start;
}
.app-navHeader .app-navRow {
  min-height: var(--theme-app-navRow-minHeight);
  transition: padding-left var(--app-transition);
}
.app-navHeader .app-navRow .app-navRow-left {
  width: auto;
  flex: 0 1 auto;
  margin-right: 10px;
}
.app-navHeader .app-navRow .app-navRow-right {
  width: auto;
  flex: 0 0 auto;
}
@media (max-width: 850px) {
  .app-navHeader .app-navRow:not(:last-child) {
    margin-bottom: 10px;
  }
}

.app-navToolbar {
  --app-navToolbarHeight: 55px;
  height: var(--app-navToolbarHeight);
  justify-content: center;
}
.app-navToolbar .app-navRow .app-tabs,
.app-navToolbar .app-navRow app-tabs {
  height: calc(var(--app-navToolbarHeight) - 1px);
  flex: 1 1 auto;
}
@media (max-width: 850px) {
  .app-navToolbar .app-navRow {
    flex-wrap: wrap;
  }
  .app-navToolbar .app-navRow .app-navRow-left,
.app-navToolbar .app-navRow .app-navRow-right,
.app-navToolbar .app-navRow .app-navRow-center {
    width: 100%;
    flex: 1 1 auto;
  }
  .app-navToolbar .app-navRow week-selector {
    flex: 1 1 auto;
  }
}
.app-navToolbar.-searchToolbar {
  padding: 0;
  border-bottom: 0;
}
.app-navToolbar.-searchToolbar app-search {
  padding: 0 10px;
  height: 100%;
  width: 100%;
}
.app-navToolbar.-searchToolbar mega-search {
  --megaSearch-height: 100%;
  width: 100%;
}

.app-navWindow {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-y: var(--app-overflow-overlay);
  position: relative;
  flex: 1 1 auto;
  padding: 0 20px;
  background-color: var(--theme-app-navWindow-background);
}
.app-navWindow.-sidePanel {
  display: flex;
  padding-right: 0;
}
.app-navWindow .app-navSidePanel {
  flex: 0 0 auto;
  width: 0;
  overflow: hidden;
  height: 100%;
  margin-left: 30px;
  border-left: var(--app-border);
  transition: width var(--app-transition);
}
@media (max-width: 850px) {
  .app-navWindow .app-navSidePanel {
    display: none !important;
  }
}
@media (min-width: 851px) {
  .app-navWindow .app-navSidePanel {
    display: flex !important;
  }
}
.app-navWindow .app-navSidePanel.-visible {
  width: 400px;
}
.app-navWindow.-loading {
  opacity: 0.3;
}
.app-navWindow.-horizontalScroll {
  padding: 0;
}
.app-navWindow.-horizontalScroll .app-navContent {
  padding: 0;
  position: relative;
  width: auto;
  height: auto;
  overflow-x: auto;
}
.app-navWindow.-horizontalScroll .app-navContent::-webkit-scrollbar-track {
  border-radius: var(--theme-scrollbar-width);
  background-color: var(--theme-color-scrollbar-background);
}
.app-navWindow.-horizontalScroll .app-navContent::-webkit-scrollbar {
  height: var(--theme-scrollbar-width);
  border-radius: var(--theme-scrollbar-width);
  background-color: var(--theme-color-scrollbar-background);
}
.app-navWindow.-horizontalScroll .app-navContent::-webkit-scrollbar-thumb {
  border-radius: var(--theme-scrollbar-width);
  background-color: var(--theme-color-scrollbar);
}
.app-navWindow:not(.-horizontalScroll) .app-navContent {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}
.app-navWindow .app-navContent {
  --app-content-padding: 20px;
  height: 100%;
  width: 100%;
  padding: var(--app-content-padding) 0;
  display: block;
  min-height: 100%;
  position: relative;
}
@media (max-width: 850px) {
  .app-navWindow .app-navContent > app-list.-listTallerThanScrollContainer:last-child .app-list-item:last-child {
    margin-bottom: 70px;
  }
}

@media (max-width: 850px) {
  .app-navMenu-mobileMenuIcon ~ .app-navMenu-content .app-nav .app-navHeader .app-navRow:first-child {
    padding-left: 40px;
  }
  .app-navMenu-content:not(.-nonResponsive) .app-navWindow {
    padding-left: 0;
    padding-right: 0;
  }
  .app-navMenu-content:not(.-nonResponsive) .app-navContent {
    padding: 15px 0 0 0;
  }
  .app-navMenu-content:not(.-nonResponsive) .app-navContent .app-buttonRow {
    padding-left: 10px;
    padding-right: 10px;
  }
  .app-navMenu-content:not(.-nonResponsive) .app-navContent > app-list .app-list-scrollOuter {
    border-radius: 0;
    border-left: none;
    border-right: none;
  }
  .app-navMenu-content:not(.-nonResponsive) .app-navContent > app-list .app-list-empty {
    border-radius: 0;
    border-left: none;
    border-right: none;
  }
  .app-navMenu-content:not(.-nonResponsive) .app-navContent .app-formItem-container {
    border-radius: 0;
    border-left: none;
    border-right: none;
  }
  .app-nav > .app-navWindow {
    padding-left: 0;
    padding-right: 0;
  }
}
body.-theme-invoxy {
  --theme-app-navWindow-background: var(--app-color-white);
  --theme-app-navRow-minHeight: 40px;
  --theme-app-navHeader-minHeight: 61px;
}
body.-theme-karmly-light {
  --theme-app-navWindow-background: var(--app-color-grey-very-light);
  --theme-app-navRow-minHeight: 40px;
  --theme-app-navHeader-minHeight: 61px;
}
body.-theme-karmly-dark {
  --theme-app-navWindow-background: var(--app-color-white);
  --theme-app-navRow-minHeight: 40px;
  --theme-app-navHeader-minHeight: 61px;
}
body.-theme-droppah {
  --theme-app-nav-padding: 12px;
  --theme-app-navWindow-background: transparent;
  --theme-app-navRow-minHeight: var(--droppah-losenge-height-small);
  --theme-app-navHeader-minHeight: calc(var(--droppah-losenge-height-small) + 2 * var(--droppah-border-width));
}
body.-theme-droppah .app-navHeader,
body.-theme-droppah .app-navToolbar {
  border-radius: var(--app-border-radius);
  background-color: var(--app-color-black);
  border: none;
  border: var(--droppah-border);
  margin-top: calc(var(--theme-app-nav-padding) - var(--droppah-border-width) + env(safe-area-inset-top));
  margin-left: calc(var(--theme-app-nav-padding) - var(--droppah-border-width));
  margin-right: calc(var(--theme-app-nav-padding) - var(--droppah-border-width));
  width: calc(100% - var(--theme-app-nav-padding) * 2 + var(--droppah-border-width) * 2);
  padding: var(--droppah-border-width);
  z-index: 1000;
  max-width: var(--droppah-content-maxWidth);
}
@media only screen and (min-width: 620px) {
  body.-theme-droppah .app-navHeader,
body.-theme-droppah .app-navToolbar {
    margin-left: calc(50vw - 300px);
  }
}
body.-theme-droppah .app-navHeader .app-navRow-left,
body.-theme-droppah .app-navToolbar .app-navRow-left {
  flex: 1 1 auto;
}
body.-theme-droppah .app-navHeader .app-nav-title,
body.-theme-droppah .app-navToolbar .app-nav-title {
  padding-left: calc(var(--droppah-header-spacing) - var(--droppah-border-width));
}
body.-theme-droppah .app-navWindow {
  padding-top: 40px;
  margin-top: -30px;
}
body.-theme-droppah .app-navWindow .app-navContent {
  max-width: var(--droppah-content-maxWidth);
  padding: 0 var(--theme-app-nav-padding) 70px var(--theme-app-nav-padding);
}
body.-theme-droppah .app-nav-icon {
  cursor: pointer;
  font-size: 20px;
}
body.-theme-flexitime {
  --theme-app-navWindow-background: var(--app-color-grey-very-light);
  --theme-app-navRow-minHeight: 40px;
  --theme-app-navHeader-minHeight: 61px;
}

.app-summaryContainer {
  width: 100%;
  display: flex;
}
@media (max-width: 850px) {
  .app-summaryContainer {
    flex-wrap: wrap;
  }
  .app-summaryContainer .app-summaryItem {
    padding: 15px;
    width: 100%;
  }
  .app-summaryContainer .app-summaryItem:not(:first-child) {
    margin-left: 0;
  }
  .app-summaryContainer .app-summaryItem:not(:last-child) {
    margin-bottom: 10px;
  }
}

.app-summaryItem {
  flex: 1 1 auto;
  background-color: var(--app-color-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 15px 25px;
  height: 80px;
  transition: box-shadow var(--app-transition);
}
.app-summaryItem.-hoverable {
  cursor: pointer;
}
.app-summaryItem.-hoverable:hover {
  box-shadow: var(--app-box-shadow-dark);
}
.app-summaryItem.-warning {
  color: var(--app-color-red);
}
.app-summaryItem.-warning .app-summaryItem-label {
  color: var(--app-color-red);
}
.app-summaryItem .app-summaryItem-label {
  color: var(--app-color-grey-very-dark);
  display: flex;
  align-items: center;
}
.app-summaryItem .app-summaryItem-label .-alignRight {
  margin-left: auto;
}
.app-summaryItem .app-summaryItem-value {
  display: flex;
  align-items: center;
  font-size: var(--theme-summaryItem-value-fontSize);
  line-height: var(--theme-summaryItem-value-fontSize);
  height: 34px;
}
.app-summaryItem .app-summaryItem-value .-label {
  font-size: var(--app-font-size-larger);
  line-height: 20px;
}
.app-summaryItem .app-summaryItem-value .-valueSmall {
  font-size: var(--theme-summaryItem-value-fontSize-small);
  line-height: var(--theme-summaryItem-value-fontSize-small);
}
.app-summaryItem .app-summaryitem-selectIcon {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--app-color-grey-dark);
}

.app-window .app-summaryContainer,
.app-navContent .app-summaryContainer {
  margin-bottom: 15px;
}

body.-theme-invoxy {
  --theme-summaryItem-value-fontSize: 26px;
  --theme-summaryItem-value-fontSize-small: 18px;
}
body.-theme-karmly-light, body.-theme-karmly-dark, body.-theme-flexitime {
  --theme-summaryItem-value-fontSize: 20px;
  --theme-summaryItem-value-fontSize-small: 16px;
}

i.app-remove {
  --appRemove-size: 20px;
  height: var(--appRemove-size);
  width: var(--appRemove-size);
  line-height: var(--appRemove-size);
  border-radius: var(--appRemove-size);
  transition: background-color var(--app-transition-longer), color var(--app-transition-longer);
  background-color: transparent;
  color: var(--app-color-grey-dark);
  cursor: pointer;
  text-align: center;
  font-size: calc(var(--appRemove-size) * 0.8);
  flex: 0 0 auto;
}
i.app-remove:hover {
  background-color: var(--app-color-grey-translucent);
}
i.app-remove.-shaded {
  background-color: var(--app-color-grey-translucent-dark);
  color: var(--app-color-white);
}
i.app-remove.-white {
  background-color: var(--app-color-white);
  color: var(--app-color-dark);
  box-shadow: var(--app-box-shadow-dark);
}
i.app-remove.-white:hover {
  background-color: var(--app-color-grey-very-light);
}
i.app-remove:before {
  text-align: center;
  width: var(--appRemove-size);
  height: var(--appRemove-size);
  line-height: var(--appRemove-size);
}

.app-tabs,
app-tabs {
  --appTabs-height: 40px;
  display: flex;
  position: relative;
  justify-content: var(--theme-appTabs-justifyContent);
  width: 100%;
  height: var(--appTabs-height);
  overflow: hidden;
  overflow-x: auto;
  border-bottom: var(--theme-appTabs-borderBottom);
  flex: 0 0 auto;
  left: 0;
}
.app-tabs::-webkit-scrollbar,
app-tabs::-webkit-scrollbar {
  display: none;
}
.app-tabs .app-tabs-tab,
app-tabs .app-tabs-tab {
  padding: 0;
  min-width: 0;
  background-color: transparent;
  border-radius: var(--app-border-radius) var(--app-border-radius) 0 0;
  position: relative;
  user-select: none;
  transition: background-color var(--app-transition);
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.2;
  flex: 0 0 auto;
}
@media (max-width: 850px) {
  .app-tabs .app-tabs-tab,
app-tabs .app-tabs-tab {
    flex: 1 0 auto;
  }
}
.app-tabs .app-tabs-tab .app-tabs-tabLabel,
app-tabs .app-tabs-tab .app-tabs-tabLabel {
  text-align: center;
}
.app-tabs .app-tabs-tab .app-tabs-tabHighlighter,
app-tabs .app-tabs-tab .app-tabs-tabHighlighter {
  height: 4px;
  border-radius: 5px 5px 0 0;
  background-color: var(--theme-color-accent);
  position: absolute;
  left: 0;
  right: 0;
  bottom: -4px;
  transition: bottom var(--app-transition);
}
.app-tabs .app-tabs-tab:not(:last-child),
app-tabs .app-tabs-tab:not(:last-child) {
  margin-right: 30px;
}
@media (max-width: 850px) {
  .app-tabs .app-tabs-tab:not(:last-child),
app-tabs .app-tabs-tab:not(:last-child) {
    margin-right: 15px;
  }
}
.app-tabs .app-tabs-tab:not(.-disabled),
app-tabs .app-tabs-tab:not(.-disabled) {
  cursor: pointer;
}
.app-tabs .app-tabs-tab:hover:not(.-disabled),
app-tabs .app-tabs-tab:hover:not(.-disabled) {
  background-color: var(--theme-appTabs-background-hover);
}
.app-tabs .app-tabs-tab.-selected,
app-tabs .app-tabs-tab.-selected {
  color: var(--theme-appTabs-selected-color);
  font-weight: var(--app-font-weight-normal);
}
.app-tabs .app-tabs-tab.-selected .app-tabs-tabHighlighter,
app-tabs .app-tabs-tab.-selected .app-tabs-tabHighlighter {
  bottom: 0;
}
.app-tabs .app-tabs-tab:not(.-selected):not(.-disabled),
app-tabs .app-tabs-tab:not(.-selected):not(.-disabled) {
  color: var(--theme-appTabs-color);
}
.app-tabs .app-tabs-tab.-disabled,
app-tabs .app-tabs-tab.-disabled {
  color: var(--app-color-grey);
  pointer-events: none;
}

body.-theme-invoxy {
  --theme-appTabs-borderBottom: none;
  --theme-appTabs-justifyContent: flex-start;
  --theme-appTabs-background-hover: var(--app-color-grey-translucent);
  --theme-appTabs-color: var(--app-color-dark);
  --theme-appTabs-selected-color: var(--app-color-dark);
}
body.-theme-karmly-light, body.-theme-karmly-dark, body.-theme-flexitime {
  --theme-appTabs-borderBottom: var(--app-border);
  --theme-appTabs-justifyContent: flex-start;
  --theme-appTabs-background-hover: transparent;
  --theme-appTabs-color: var(--app-color-grey-very-dark);
  --theme-appTabs-selected-color: var(--app-color-dark);
}

.app-edit-content {
  border-radius: var(--app-border-radius);
  background-color: var(--theme-editContent-background);
  border: var(--app-border-light);
  padding: var(--theme-editContent-padding);
  margin: 0 0 20px 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.app-edit-content.-loading {
  opacity: 0.7;
  pointer-events: none;
}
.app-edit-content.-cols {
  flex-direction: row;
}
.app-edit-content.-cols .app-edit-contentCol {
  display: flex;
  flex-direction: column;
  width: 50%;
  flex: 0 0 auto;
}

.app-edit-row {
  width: 100%;
  min-height: 50px;
  padding: 8px 0;
  display: flex;
}
.app-edit-row .-divider {
  width: 100%;
  height: 1px;
  border-top: 1px solid #efefef;
  margin: 20px 0;
}
.app-edit-row .app-edit-col {
  display: flex;
  min-height: 34px;
  float: none;
}
.app-edit-row .app-edit-col.-column {
  flex-direction: column;
  align-items: flex-start;
}
.app-edit-row .app-edit-col.-column > :not(:first-child) {
  margin-left: 0;
}
.app-edit-row .app-edit-col.-alignRight {
  justify-content: flex-end;
}
.app-edit-row .app-edit-col.-alignVertical {
  display: block;
}
.app-edit-row .app-edit-col.-alignVertical > :not(:first-child) {
  margin-left: 0;
}
.app-edit-row .app-edit-col .app-edit-colHeader {
  height: 34px;
  display: flex;
  align-items: center;
}
.app-edit-row .app-edit-col .app-edit-colHeader.-colHeaderLarge {
  font-size: var(--app-font-size-large);
  font-weight: var(--app-font-weight-bold);
}
.app-edit-row .app-edit-col .app-edit-colValue {
  height: 34px;
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
}
.app-edit-row .app-edit-col .app-edit-colValue.-bold {
  font-weight: var(--app-font-weight-normal);
}
.app-edit-row .app-edit-col > label {
  margin-bottom: 0;
  line-height: 20px;
}
.app-edit-row .app-edit-col > :not(:first-child) {
  margin-left: 10px;
}
.app-edit-row .app-edit-col .rx-container {
  margin-left: 0;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.app-edit-row .app-edit-col .btn-link {
  padding: 0;
}
.app-edit-row .app-edit-col pending-field {
  height: 34px;
  position: absolute;
  right: 25px;
  z-index: 10;
}
.app-edit-row .app-edit-col > input {
  width: 100%;
  position: relative;
}
.app-edit-row .app-edit-col date-picker {
  width: 100%;
}
.app-edit-row .app-edit-col time-input {
  display: block;
  width: 140px;
}
.app-edit-row .app-edit-col list-drop-down {
  width: 100%;
}
.app-edit-row .app-edit-col list-drop-down button {
  width: 100%;
}
.app-edit-row .app-edit-col .form-group {
  margin-bottom: 0;
}
.app-edit-row .app-edit-col textarea {
  background-color: var(--app-color-white);
  min-height: 100px;
}
.app-edit-row .app-edit-col textarea:disabled {
  background-color: var(--theme-color-input-disabled-background);
}

body.-theme-invoxy {
  --theme-editContent-background: var(--app-color-grey-very-light);
  --theme-editContent-padding: 30px;
}
body.-theme-karmly-light {
  --theme-editContent-background: var(--app-color-white);
  --theme-editContent-padding: 30px 15px;
}
body.-theme-karmly-dark {
  --theme-editContent-background: var(--app-color-grey-very-light);
  --theme-editContent-padding: 30px 15px;
}

.app-label-container {
  --label-height: 22px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: var(--label-height);
  overflow: hidden;
  max-width: 100%;
  position: relative;
}
.app-label-container .app-label {
  flex: 0 0 auto;
  height: var(--label-height);
  display: flex;
  align-items: center;
  color: var(--app-color-white);
  border-radius: var(--app-border-radius);
  padding: 0 8px;
  font-size: var(--app-font-size-normal);
  transition: box-shadow var(--app-transition);
  white-space: nowrap;
}
.app-label-container .app-label:not(:first-child) {
  margin-left: 5px;
}
.app-label-container .app-label .app-remove {
  --appRemove-size: var(--label-height);
  margin-left: 3px;
  color: var(--app-color-white);
}

@media (max-width: 900px) {
  .modal-dialog {
    max-width: calc(100% - 12px) !important;
  }
}
@media (max-width: 850px) {
  .modal-dialog:not(.modal-mobile-sm) .app-modal-container {
    max-height: 100vh;
    height: 100vh;
  }
}

.app-modal-container {
  position: relative;
  background-color: var(--app-color-white);
  border-radius: var(--app-border-radius);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: calc(100vh - 50px);
  width: 100%;
}
.app-modal-container .dp-modal-close {
  position: absolute;
  right: 30px;
  top: 10px;
  padding: 10px;
}
.app-modal-container .app-navHeader {
  padding: 10px 20px 0 20px;
}
.app-modal-container .app-modal-close {
  position: absolute;
  top: 10px;
  right: 14px;
  z-index: 10;
}
.app-modal-container .app-modal-close i {
  font-size: 2.5rem;
}
.app-modal-container .app-modal-headerIcon {
  font-size: 50px;
  color: var(--app-color-grey);
}
.app-modal-container .app-modal-content {
  background-color: var(--theme-modal-content-background);
  width: 100%;
  font-size: var(--app-font-size-normal);
  padding: 20px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow-y: var(--app-overflow-overlay);
}
.app-modal-container .app-modal-content.-no-padding {
  padding: 0;
}
.app-modal-container .app-modal-content.-contentGrey {
  --theme-modal-content-background: var(--app-color-grey-very-light);
  --theme-modal-contentInner-background: transparent;
}
.app-modal-container .app-modal-content .app-modal-contentInner {
  background-color: var(--theme-modal-contentInner-background);
  border: var(--theme-modal-contentInner-border);
  width: 100%;
  border-radius: var(--app-border-radius);
  padding: var(--theme-modal-contentInner-padding);
  display: flex;
  flex-direction: column;
}
.app-modal-container .app-modal-content .app-modal-contentInner.-contentCentered {
  align-items: center;
}
.app-modal-container .app-modal-content .app-modal-contentInner.-mobile-padding {
  padding-left: 20px;
  padding-right: 20px;
}
.app-modal-container .app-modal-content .app-modal-contentInner > .app-edit-content {
  border: none;
}
.app-modal-container .app-modal-content .app-modal-contentInner > .app-list-empty {
  border: none;
}
@media (max-width: 850px) {
  .app-modal-container .app-modal-content .app-modal-contentInner .app-buttonRow {
    padding: 10px;
  }
}
.app-modal-container .app-modal-content .app-modal-contentInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  text-align: center;
  width: 100%;
}
.app-modal-container .app-modal-content .app-modal-contentInfo > div:not(:first-child) {
  margin-top: 20px;
}
.app-modal-container .app-modal-content .app-edit-content {
  width: 100%;
  padding: 0;
  margin: 0;
}
.app-modal-container .app-modal-content .app-modal-notes {
  padding: 15px 10px 10px 10px;
  width: 100%;
}
.app-modal-container .app-modal-content .app-modal-notes .app-modal-notesHeader {
  cursor: pointer;
  color: var(--theme-color-link);
  user-select: none;
}
.app-modal-container .app-modal-content .app-modal-notes .app-modal-notesHeader.-disabled {
  color: var(--app-color-grey-dark);
  font-weight: var(--app-font-weight-normal);
}
.app-modal-container .app-modal-content .app-modal-notes .app-modal-notesHeader-km {
  color: var(--app-color-grey-very-dark);
}
.app-modal-container .app-modal-content .app-modal-notes .app-modal-textarea {
  min-height: 0;
  height: 0;
  padding: 0 10px;
  border: 1px solid transparent;
}
.app-modal-container .app-modal-content .app-modal-notes .app-modal-textarea.-notesOpen {
  height: 120px;
  padding: 10px;
  border: var(--app-border);
  margin-top: 7px;
  background-color: var(--app-color-white);
}
.app-modal-container .app-modal-content .app-modal-notes .app-modal-textarea:focus {
  box-shadow: var(--theme-input-focus-boxShadow);
  border: var(--theme-input-focus-border);
}
.app-modal-container .app-modal-content .app-modal-textarea {
  width: 100%;
  padding: 10px;
  margin-top: 0;
  max-width: 100%;
  max-height: 300px;
  border-radius: var(--app-border-radius);
  transition: padding var(--app-transition), margin-top var(--app-transition), background-color var(--app-transition);
  background-color: transparent;
}
.app-modal-container .app-modal-content .app-modal-textarea::-webkit-scrollbar-track {
  border-radius: var(--theme-scrollbar-width);
  background-color: var(--theme-color-scrollbar-background);
}
.app-modal-container .app-modal-content .app-modal-textarea::-webkit-scrollbar {
  width: var(--theme-scrollbar-width);
  border-radius: var(--theme-scrollbar-width);
  background-color: var(--theme-color-scrollbar-background);
}
.app-modal-container .app-modal-content .app-modal-textarea::-webkit-scrollbar-thumb {
  border-radius: var(--theme-scrollbar-width);
  background-color: var(--theme-color-scrollbar);
}
.app-modal-container .app-modal-btnFooter {
  background-color: var(--theme-modal-content-background);
  border-top: var(--theme-modal-btnFooter-border);
  border-radius: 0 0 5px 5px;
  width: 100%;
  padding: 20px;
  padding-bottom: calc(20px + env(safe-area-inset-bottom));
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
}
.app-modal-container .app-modal-btnFooter .app-modal-btnFooterCenter {
  margin: 0 auto;
}
.app-modal-container .app-modal-btnFooter .app-modal-btnFooterCenter > :not(:first-child) {
  margin-left: 10px;
}
.app-modal-container .app-modal-btnFooter .app-modal-btnFooterLeft {
  margin-right: auto;
}
.app-modal-container .app-modal-btnFooter .app-modal-btnFooterLeft > * {
  margin-right: 10px;
}
.app-modal-container .app-modal-btnFooter .app-modal-btnFooterRight {
  margin-left: auto;
}
.app-modal-container .app-modal-btnFooter .app-modal-btnFooterRight > * {
  margin-left: 10px;
}
.app-modal-container .app-modal-btnFooter .app-modal-btnFooterCenter,
.app-modal-container .app-modal-btnFooter .app-modal-btnFooterRight,
.app-modal-container .app-modal-btnFooter .app-modal-btnFooterLeft {
  position: relative;
  display: flex;
  align-items: center;
}
.app-modal-container .app-modal-btnFooter .app-modal-btnFooterCenter app-spinner,
.app-modal-container .app-modal-btnFooter .app-modal-btnFooterRight app-spinner,
.app-modal-container .app-modal-btnFooter .app-modal-btnFooterLeft app-spinner {
  position: relative;
  top: auto;
  transform: none;
  left: auto;
}
.app-modal-container .ph-errorMessage {
  border-radius: 0;
  margin-bottom: 0;
}
@media (max-width: 850px) {
  .app-modal-container {
    height: 100%;
    border-radius: 0;
  }
  .app-modal-container .app-modal-content {
    padding: 20px 10px;
  }
  .app-modal-container .app-modal-content .app-modal-contentInner {
    border-radius: 0;
  }
}

body.-theme-invoxy {
  --theme-modal-header-background: var(--app-color-grey-light);
  --theme-modal-header-borderBottom: none;
  --theme-modal-content-background: var(--app-color-white);
  --theme-modal-contentInner-background: var(--app-color-grey-very-light);
  --theme-modal-contentInner-border: var(--app-border-light);
  --theme-modal-contentInner-padding: 20px;
  --theme-modal-btnFooter-border: none;
}
@media (max-width: 850px) {
  body.-theme-invoxy {
    --theme-modal-contentInner-padding: 20px 10px;
  }
}
body.-theme-karmly-light, body.-theme-karmly-dark, body.-theme-flexitime {
  --theme-modal-header-background: var(--app-color-white);
  --theme-modal-header-borderBottom: var(--app-border);
  --theme-modal-content-background: var(--app-color-white);
  --theme-modal-contentInner-background: var(--app-color-white);
  --theme-modal-contentInner-border: none;
  --theme-modal-contentInner-padding: 0;
  --theme-modal-btnFooter-border: var(--app-border);
}
body.-theme-karmly-light .app-modal-content .app-formItem-container, body.-theme-karmly-dark .app-modal-content .app-formItem-container, body.-theme-flexitime .app-modal-content .app-formItem-container {
  margin-bottom: 0;
  padding: 0;
  border: none;
  border-radius: 0;
}
body.-theme-droppah {
  --theme-modal-contentInner-padding: var(--droppah-border-width);
  --theme-modal-content-background: transparent;
  --theme-modal-contentInner-background: transparent;
}
body.-theme-droppah .app-modal-container .app-modal-content {
  padding: 0;
}

.app-payrollActionBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  height: 67px;
  padding: 15px;
  border-radius: 2px;
  transition: all 0.25s;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.app-payrollActionBar.-changes {
  background-color: var(--app-color-yellow-translucent);
  border: solid 1px var(--app-color-yellow);
}
.app-payrollActionBar.-noChanges {
  background-color: var(--app-color-payhero-translucent);
  border: solid 1px var(--app-color-payhero);
}
.app-payrollActionBar > div {
  margin: 0;
  width: 100%;
  text-align: center;
}

.app-formItem-container {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  min-height: 0;
  border-radius: var(--theme-formItem-container-borderRadius);
  border: var(--theme-formItem-container-border);
  padding: var(--theme-formItem-container-padding);
  background-color: var(--theme-formItem-container-background);
}

.app-formItem-row {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 15px;
}

.app-formItem-column {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
}

.app-formItem-container > :not(:last-child):not(.app-formItem-column),
.app-formItem-column > :not(:last-child):not(.app-formItem-column) {
  margin-bottom: var(--theme-formItem-marginBottom);
}
@media (max-width: 850px) {
  .app-formItem-container > :last-child:not(.app-formItem-column),
.app-formItem-column > :last-child:not(.app-formItem-column) {
    margin-bottom: var(--theme-formItem-marginBottom);
  }
}
.app-formItem-container > .app-buttonRow,
.app-formItem-column > .app-buttonRow {
  padding-left: var(--theme-formItem-horizontalPadding);
  padding-right: var(--theme-formItem-horizontalPadding);
}
.app-formItem-container > app-list,
.app-formItem-column > app-list {
  width: calc(100% - var(--theme-formItem-horizontalPadding) * 2);
  margin-left: var(--theme-formItem-horizontalPadding);
  margin-right: var(--theme-formItem-horizontalPadding);
}

.app-formItem {
  width: 100%;
  padding: 0 var(--theme-formItem-horizontalPadding);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 0;
  position: relative;
}
.app-formItem.-width-50 {
  width: 50%;
}
.app-formItem.-width-25 {
  width: 25%;
}
.app-formItem.-width-75 {
  width: 75%;
}
.app-formItem.-noPadding {
  --theme-formItem-horizontalPadding: 0;
  padding: 0;
}
.app-formItem.-noLabel {
  height: var(--app-formItem-height);
}
.app-formItem.-alignRight .app-formItem-label {
  justify-content: flex-end;
  text-align: end;
}
.app-formItem.-alignRight .app-formItem-value {
  justify-content: flex-end;
  text-align: end;
}
.app-formItem button.app-formItem-inputButton {
  --button-iconSize: 1.3rem;
  min-width: 120px;
  justify-content: space-between !important;
  padding: 0 10px;
  --button-iconSize: 16px;
  width: 100%;
  padding: 0 !important;
  border-radius: 0;
  background-color: transparent;
  text-transform: capitalize;
  border-bottom: var(--app-border);
  color: var(--app-color-black);
  font-weight: var(--app-font-weight-normal);
  position: relative;
}
.app-formItem button.app-formItem-inputButton > span {
  text-align: left;
}
.app-formItem button.app-formItem-inputButton > i,
.app-formItem button.app-formItem-inputButton > .button-animatedIcon > i {
  min-width: 12px;
}
.app-formItem button.app-formItem-inputButton > i,
.app-formItem button.app-formItem-inputButton > .button-animatedIcon {
  margin-left: auto;
}
.app-formItem button.app-formItem-inputButton > i:not(:last-child),
.app-formItem button.app-formItem-inputButton > .button-animatedIcon:not(:last-child) {
  margin-right: 8px;
}
.app-formItem button.app-formItem-inputButton > i.-alignLeft,
.app-formItem button.app-formItem-inputButton > .button-animatedIcon.-alignLeft {
  margin-left: 0;
}
.app-formItem button.app-formItem-inputButton:disabled > i,
.app-formItem button.app-formItem-inputButton:disabled > .button-animatedIcon > i {
  display: none;
}
.app-formItem .app-formItem-incrementButtons {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.app-formItem .app-formItem-incrementButtons > button {
  --button-height: 60px;
  --button-iconSize: 60px;
}
.app-formItem .app-formItem-incrementButtons > button > i.ion-ios-add {
  color: var(--app-color-green);
}
.app-formItem .app-formItem-incrementButtons > button > i.ion-ios-remove {
  color: var(--app-color-grey-dark);
}
.app-formItem > app-list {
  margin-bottom: 10px;
}
.app-formItem.-capitaliseLabel .app-formItem-label {
  text-transform: capitalize;
}
.app-formItem.-detailBelow {
  --theme-formItem-marginBottom: 0;
}

.app-formItem-label {
  color: var(--theme-formItem-label-color);
  height: var(--theme-formItem-label-height);
  line-height: var(--theme-formItem-label-height);
  font-size: var(--app-font-size-normal);
  padding-left: 0;
  font-weight: var(--theme-formItem-label-fontWeight);
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  max-width: 100%;
}
.app-formItem-label > span {
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.app-formItem-label > div {
  display: flex;
  align-items: center;
}
.app-formItem-label > div > :not(:last-child) {
  margin-right: 8px;
}
.app-formItem-label > .-alignRight {
  margin-left: auto;
}
.app-formItem-label > :not(:last-child) {
  margin-right: 4px;
}

.app-formItem-errorLabel {
  color: var(--app-color-red);
  font-weight: var(--app-font-weight-normal);
  line-height: 20px;
  opacity: 0;
  height: 0;
  margin-top: 0;
  transition: all var(--app-transition);
}
.app-formItem-errorLabel:first-letter {
  text-transform: capitalize;
}
.app-formItem-errorLabel.-errorVisible {
  opacity: 1;
  height: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.app-formItem-inputContainer {
  height: var(--app-formItem-height);
  width: 100%;
  padding: var(--theme-formItem-inputContainer-padding);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  transition: all var(--app-transition);
  border-radius: var(--theme-formItem-inputContainer-borderRadius);
  background-color: var(--theme-formItem-inputContainer-background);
}
.app-formItem-inputContainer.-disabled {
  background-color: var(--theme-formItem-inputContainer-disabled-background);
  color: var(--theme-color-input-disabled-color);
}
.app-formItem-inputContainer.-disabled .input-group > .input-group-addon {
  color: var(--theme-color-input-disabled-color);
  font-weight: var(--app-font-weight-normal);
}
.app-formItem-inputContainer.-disabled .app-formItem-inputContainer-border {
  border-color: var(--app-color-border-light);
}
.app-formItem-inputContainer.-word-break {
  word-break: break-all;
}
.app-formItem-inputContainer.-readonly {
  background-color: var(--theme-formItem-inputContainer-readonly-background);
  color: var(--theme-color-input-readonly-color);
}
.app-formItem-inputContainer.-readonly .input-group > .input-group-addon {
  color: var(--theme-color-input-readonly-color);
  font-weight: var(--app-font-weight-normal);
}
.app-formItem-inputContainer.-readonly .app-formItem-inputContainer-border {
  border-color: var(--app-color-border-light);
}
.app-formItem-inputContainer:not(.-disabled):not(.-readonly):focus-within .app-formItem-inputContainer-border, .app-formItem-inputContainer:not(.-disabled):not(.-readonly).-mockFocus .app-formItem-inputContainer-border {
  box-shadow: var(--theme-input-focus-boxShadow);
  border-bottom: var(--theme-input-focus-border);
  border-top: var(--theme-formItem-inputContainer-focus-borderTop);
  border-bottom: var(--theme-formItem-inputContainer-focus-borderBottom);
  border-left: var(--theme-formItem-inputContainer-focus-borderLeft);
  border-right: var(--theme-formItem-inputContainer-focus-borderRight);
}
.app-formItem-inputContainer .app-formItem-inputContainer-border {
  margin: 0 !important;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  transition: box-shadow var(--app-transition);
  border-top: var(--theme-formItem-inputContainer-borderTop);
  border-bottom: var(--theme-formItem-inputContainer-borderBottom);
  border-left: var(--theme-formItem-inputContainer-borderLeft);
  border-right: var(--theme-formItem-inputContainer-borderRight);
  border-radius: var(--theme-formItem-inputContainer-borderRadius);
  pointer-events: none;
  z-index: 100;
}
.app-formItem-inputContainer .rx-container {
  min-height: 34px;
  margin: 0;
  border: none !important;
}
.app-formItem-inputContainer .rx-container .rx-editor {
  padding-left: 0;
  padding-right: 0;
}
.app-formItem-inputContainer .rx-container .rx-editor > p:not(:first-child) {
  margin-top: 5px;
}
.app-formItem-inputContainer input,
.app-formItem-inputContainer input:focus {
  background-color: transparent;
}
.app-formItem-inputContainer > input:first-child,
.app-formItem-inputContainer .input-group > input:first-child,
.app-formItem-inputContainer .input-group > .input-group-addon:first-child {
  padding-left: var(--theme-formItem-value-input-firstChild-paddingLeft);
}
.app-formItem-inputContainer > input:last-child,
.app-formItem-inputContainer .input-group > input:last-child,
.app-formItem-inputContainer .input-group > .input-group-addon:last-child {
  padding-right: var(--theme-formItem-value-input-lastChild-paddingRight);
}
.app-formItem-inputContainer > list-drop-down:first-child .ldd-button {
  padding-left: var(--theme-formItem-value-input-firstChild-paddingLeft);
}
.app-formItem-inputContainer > list-drop-down:last-child .ldd-button {
  padding-right: var(--theme-formItem-value-input-lastChild-paddingRight);
}
.app-formItem-inputContainer .input-group,
.app-formItem-inputContainer input,
.app-formItem-inputContainer button,
.app-formItem-inputContainer day-dropdown button,
.app-formItem-inputContainer duration-input,
.app-formItem-inputContainer duration-input input,
.app-formItem-inputContainer unit-input,
.app-formItem-inputContainer unit-input input,
.app-formItem-inputContainer date-picker,
.app-formItem-inputContainer time-picker,
.app-formItem-inputContainer list-drop-down,
.app-formItem-inputContainer list-drop-down button.ldd-button {
  border: none !important;
  box-shadow: none !important;
  width: 100%;
}
.app-formItem-inputContainer app-toggle {
  align-self: center;
}
.app-formItem-inputContainer button:disabled,
.app-formItem-inputContainer day-dropdown button:disabled,
.app-formItem-inputContainer list-drop-down button.ldd-button:disabled {
  background-color: var(--theme-color-input-disabled-background) !important;
}
.app-formItem-inputContainer > list-drop-down .ldd-button,
.app-formItem-inputContainer > .input-group > list-drop-down .ldd-button {
  border: none !important;
}
.app-formItem-inputContainer .input-group > input,
.app-formItem-inputContainer .input-group > button,
.app-formItem-inputContainer .input-group > day-dropdown button {
  box-shadow: none !important;
  border: none !important;
}
.app-formItem-inputContainer .input-group > button:first-child,
.app-formItem-inputContainer .input-group > list-drop-down:first-child,
.app-formItem-inputContainer .input-group > day-dropdown:first-child {
  border-right: var(--theme-formItem-inputContainer-borderLeft);
}
.app-formItem-inputContainer .input-group > button:last-child,
.app-formItem-inputContainer .input-group > list-drop-down:last-child,
.app-formItem-inputContainer .input-group > day-dropdown:last-child {
  border-left: var(--theme-formItem-inputContainer-borderRight);
}
.app-formItem-inputContainer .input-group > .input-group-addon,
.app-formItem-inputContainer .input-group > .input-group-value {
  background-color: var(--theme-formItem-inputContainer-inputGroupAddon-backgroundColor);
  border-top: none;
  border-bottom: none;
  padding: 0 10px;
}
.app-formItem-inputContainer .input-group > .input-group-addon:first-child,
.app-formItem-inputContainer .input-group > .input-group-value:first-child {
  border-right: var(--theme-formItem-inputContainer-borderLeft);
  border-left: none;
}
.app-formItem-inputContainer .input-group > .input-group-addon:last-child,
.app-formItem-inputContainer .input-group > .input-group-value:last-child {
  border-left: var(--theme-formItem-inputContainer-borderRight);
  border-right: none;
}
.app-formItem-inputContainer date-picker {
  --datePicker-border: none;
  --datePicker-borderBottom: none;
}
.app-formItem-inputContainer date-picker > .input-group:focus-within {
  box-shadow: none !important;
}
.app-formItem-inputContainer time-picker {
  --timePicker-border: none;
  --timePicker-borderBottom: none;
}
.app-formItem-inputContainer time-picker .ngb-tp-input {
  box-shadow: none !important;
}
.app-formItem-inputContainer address-input {
  --addressInput-border: none;
  --addressInput-borderBottom: none;
}
.app-formItem-inputContainer address-input > input {
  box-shadow: none !important;
  padding-left: 0;
}
.app-formItem-inputContainer input,
.app-formItem-inputContainer date-picker,
.app-formItem-inputContainer time-picker,
.app-formItem-inputContainer address-input,
.app-formItem-inputContainer duration-input,
.app-formItem-inputContainer unit-input {
  width: 100%;
}

.app-formItem-value {
  min-height: var(--app-formItem-height);
  padding: 0;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  font-weight: var(--theme-formItem-value-weight);
  overflow: hidden;
}
.app-formItem-value.-column {
  flex-direction: column;
  align-items: flex-start;
}
.app-formItem-value.-column > :not(:last-child) {
  margin-bottom: 3px;
}
.app-formItem-value:not(.-column) > :not(:first-child) {
  margin-left: 5px;
}
.app-formItem-value.-sm > input {
  max-width: 130px;
}
.app-formItem-value > .app-formItem-valueColour {
  height: calc(var(--app-formItem-height) - 16px);
  width: 4px;
  border-radius: 10px;
  margin-right: 8px;
  flex: 0 0 auto;
}
.app-formItem-value > .app-formItem-units {
  font-weight: var(--app-font-weight-normal);
  margin-left: 10px;
  white-space: nowrap;
}
.app-formItem-value > app-checkbox {
  margin-right: 10px;
}
.app-formItem-value > input,
.app-formItem-value > .input-group,
.app-formItem-value > time-picker,
.app-formItem-value > date-picker,
.app-formItem-value > address-input,
.app-formItem-value > list-drop-down {
  width: 100%;
  height: var(--app-formItem-height);
}
.app-formItem-value > input {
  border: var(--theme-formItem-value-input-border);
  border-bottom: var(--theme-formItem-value-input-borderBottom);
  border-radius: var(--theme-formItem-value-input-borderRadius);
}
.app-formItem-value > input,
.app-formItem-value > .input-group > input {
  clip-path: var(--theme-formItem-value-input-clipPath);
}
.app-formItem-value > .input-group > .input-group-addon {
  background-color: var(--theme-formItem-value-inputGroupAddon-backgroundColor);
}
.app-formItem-value > time-picker {
  --timePicker-border: var(--theme-formItem-value-input-border);
  --timePicker-borderBottom: var(--theme-formItem-value-input-borderBottom);
  --timePicker-borderRadius: var(--theme-formItem-value-input-borderRadius);
  --timePicker-clipPath: var(--theme-formItem-value-input-clipPath);
}
.app-formItem-value > date-picker {
  --datePicker-border: var(--theme-formItem-value-input-border);
  --datePicker-borderBottom: var(--theme-formItem-value-input-borderBottom);
  --datePicker-borderRadius: var(--theme-formItem-value-input-borderRadius);
  --datePicker-clipPath: var(--theme-formItem-value-input-clipPath);
}
.app-formItem-value > address-input {
  --addressInput-border: var(--theme-formItem-value-input-border);
  --addressInput-borderBottom: var(--theme-formItem-value-input-borderBottom);
  --addressInput-borderRadius: var(--theme-formItem-value-input-borderRadius);
  --addressInput-clipPath: var(--theme-formItem-value-input-clipPath);
}
.app-formItem-value > list-drop-down,
.app-formItem-value > .input-group > list-drop-down {
  --listDropDown-button-size: var(--app-formItem-height);
}
.app-formItem-value > span {
  font-weight: var(--app-font-weight-normal);
}

.app-formItem-detailBelow {
  padding: 10px calc(var(--theme-formItem-horizontalPadding) + 2px);
  font-size: 10px;
  display: inline;
  color: var(--theme-formItem-label-color);
}
.app-formItem-detailBelow i {
  font-size: 15px;
  padding: 1px 6px 0 0;
}
.app-formItem-detailBelow button {
  --button-fontSize: 10px;
  text-decoration: underline;
  display: inline;
}

@media (max-width: 850px) {
  .app-formItem.-width-50 {
    width: 100%;
  }
  .app-formItem.-width-25 {
    width: 100%;
  }
}
body.-theme-invoxy {
  --theme-formItem-container-padding: 0;
  --theme-formItem-container-background: transparent;
  --theme-formItem-container-borderRadius: 0;
  --theme-formItem-container-border: none;
  --theme-formItem-marginBottom: 15px;
  --theme-formItem-horizontalPadding: 15px;
  --theme-formItem-inputContainer-padding: 0;
  --theme-formItem-inputContainer-borderTop: var(--app-border);
  --theme-formItem-inputContainer-borderBottom: var(--app-border);
  --theme-formItem-inputContainer-borderLeft: var(--app-border);
  --theme-formItem-inputContainer-borderRight: var(--app-border);
  --theme-formItem-inputContainer-borderRadius: var(--app-border-radius);
  --theme-formItem-inputContainer-background: var(--app-color-white);
  --theme-formItem-inputContainer-disabled-background: var(--app-color-grey-very-light);
  --theme-formItem-inputContainer-readonly-background: var(--app-color-grey-very-light);
  --theme-formItem-inputContainer-focus-borderTop: var(--theme-input-focus-border);
  --theme-formItem-inputContainer-focus-borderBottom: var(--theme-input-focus-border);
  --theme-formItem-inputContainer-focus-borderLeft: var(--theme-input-focus-border);
  --theme-formItem-inputContainer-focus-borderRight: var(--theme-input-focus-border);
  --theme-formItem-inputContainer-inputGroupAddon-backgroundColor: var(--app-color-grey-light);
  --theme-formItem-label-height: 30px;
  --theme-formItem-label-color: var(--app-color-grey-very-dark);
  --theme-formItem-label-fontWeight: var(--app-font-weight-normal);
  --theme-formItem-value-weight: var(--app-font-weight-normal);
  --theme-formItem-value-input-border: var(--app-border);
  --theme-formItem-value-input-borderBottom: var(--app-border);
  --theme-formItem-value-input-borderRadius: var(--app-border-radius);
  --theme-formItem-value-input-clipPath: none;
  --theme-formItem-value-input-firstChild-paddingLeft: 10px;
  --theme-formItem-value-input-lastChild-paddingRight: 10px;
  --theme-formItem-value-inputGroupAddon-backgroundColor: var(--app-color-grey-light);
}
body.-theme-karmly-light, body.-theme-karmly-dark, body.-theme-flexitime {
  --theme-formItem-container-padding: 30px 15px;
  --theme-formItem-container-background: var(--app-color-white);
  --theme-formItem-container-borderRadius: var(--app-border-radius);
  --theme-formItem-container-border: var(--app-border);
  --theme-formItem-marginBottom: 28px;
  --theme-formItem-horizontalPadding: 15px;
  --theme-formItem-inputContainer-padding: 0;
  --theme-formItem-inputContainer-borderTop: none;
  --theme-formItem-inputContainer-borderBottom: var(--app-border);
  --theme-formItem-inputContainer-borderLeft: none;
  --theme-formItem-inputContainer-borderRight: none;
  --theme-formItem-inputContainer-borderRadius: 0;
  --theme-formItem-inputContainer-background: transparent;
  --theme-formItem-inputContainer-disabled-background: transparent;
  --theme-formItem-inputContainer-readonly-background: transparent;
  --theme-formItem-inputContainer-focus-borderTop: none;
  --theme-formItem-inputContainer-focus-borderBottom: var(--theme-input-focus-border);
  --theme-formItem-inputContainer-focus-borderLeft: none;
  --theme-formItem-inputContainer-focus-borderRight: none;
  --theme-formItem-inputContainer-inputGroupAddon-backgroundColor: transparent;
  --theme-formItem-label-height: 20px;
  --theme-formItem-label-color: var(--app-color-grey-very-dark);
  --theme-formItem-label-fontWeight: var(--app-font-weight-normal);
  --theme-formItem-value-weight: var(--app-font-weight-normal);
  --theme-formItem-value-input-border: none;
  --theme-formItem-value-input-borderBottom: var(--app-border);
  --theme-formItem-value-input-borderRadius: 0;
  --theme-formItem-value-input-clipPath: inset(2px 1px -15px 1px);
  --theme-formItem-value-input-firstChild-paddingLeft: 2px;
  --theme-formItem-value-input-lastChild-paddingRight: 2px;
  --theme-formItem-value-inputGroupAddon-backgroundColor: transparent;
}
body.-theme-karmly-light .app-formItem-value > .input-group > input,
body.-theme-karmly-light .app-formItem-value > .input-group > .input-group-addon,
body.-theme-karmly-light .app-formItem-value > .input-group > list-drop-down > div > .ldd-button.-color-white-outline, body.-theme-karmly-dark .app-formItem-value > .input-group > input,
body.-theme-karmly-dark .app-formItem-value > .input-group > .input-group-addon,
body.-theme-karmly-dark .app-formItem-value > .input-group > list-drop-down > div > .ldd-button.-color-white-outline, body.-theme-flexitime .app-formItem-value > .input-group > input,
body.-theme-flexitime .app-formItem-value > .input-group > .input-group-addon,
body.-theme-flexitime .app-formItem-value > .input-group > list-drop-down > div > .ldd-button.-color-white-outline {
  border: none !important;
  border-bottom: var(--app-border) !important;
  border-radius: 0 !important;
}
body.-theme-karmly-light .app-formItem-value > list-drop-down > div > .ldd-button.-color-white-outline, body.-theme-karmly-dark .app-formItem-value > list-drop-down > div > .ldd-button.-color-white-outline, body.-theme-flexitime .app-formItem-value > list-drop-down > div > .ldd-button.-color-white-outline {
  border: none !important;
  border-bottom: var(--app-border) !important;
  border-radius: 0 !important;
}
@media (max-width: 850px) {
  body.-theme-karmly-light, body.-theme-karmly-dark, body.-theme-flexitime {
    --theme-formItem-container-padding: 15px 0;
  }
}
body.-theme-droppah {
  --theme-formItem-container-padding: 8px 0px;
  --theme-formItem-container-background: var(--app-color-black);
  --theme-formItem-container-borderRadius: var(--app-border-radius);
  --theme-formItem-container-border: none;
  --theme-formItem-marginBottom: 8px;
  --theme-formItem-horizontalPadding: 10px;
  --theme-formItem-inputContainer-padding: 0px 16px;
  --theme-formItem-inputContainer-borderTop: none;
  --theme-formItem-inputContainer-borderBottom: none;
  --theme-formItem-inputContainer-borderLeft: none;
  --theme-formItem-inputContainer-borderRight: none;
  --theme-formItem-inputContainer-borderRadius: var(--app-border-radius);
  --theme-formItem-inputContainer-background: var(--droppah-color-background-light);
  --theme-formItem-inputContainer-disabled-background: var(--droppah-color-background-light);
  --theme-formItem-inputContainer-readonly-background: var(--droppah-color-background-light);
  --theme-formItem-inputContainer-focus-borderTop: var(--theme-input-focus-border);
  --theme-formItem-inputContainer-focus-borderBottom: var(--theme-input-focus-border);
  --theme-formItem-inputContainer-focus-borderLeft: var(--theme-input-focus-border);
  --theme-formItem-inputContainer-focus-borderRight: var(--theme-input-focus-border);
  --theme-formItem-inputContainer-inputGroupAddon-backgroundColor: transparent;
  --theme-formItem-label-height: 34px;
  --theme-formItem-label-color: var(--app-color-white);
  --theme-formItem-label-fontWeight: var(--app-font-weight-bold);
  --theme-formItem-value-weight: var(--app-font-weight-normal);
  --theme-formItem-value-input-border: none;
  --theme-formItem-value-input-borderBottom: none;
  --theme-formItem-value-input-borderRadius: var(--app-border-radius);
  --theme-formItem-value-input-clipPath: inset(2px 1px -15px 1px);
  --theme-formItem-value-input-firstChild-paddingLeft: 0px;
  --theme-formItem-value-input-lastChild-paddingRight: 0px;
  --theme-formItem-value-inputGroupAddon-backgroundColor: transparent;
}
@media (max-width: 850px) {
  body.-theme-droppah .app-formItem-container > :last-child:not(.app-formItem-column),
body.-theme-droppah .app-formItem-column > :last-child:not(.app-formItem-column) {
    margin-bottom: 0;
  }
}

.app-approvalProject {
  width: 100%;
}
.app-approvalProject:not(:last-child) {
  margin-bottom: 20px;
  border-bottom: var(--app-border-light);
}
.app-approvalProject .app-approvalProject-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-bottom: 15px;
}
.app-approvalProject .app-approvalProject-header .app-approvalProject-projectName {
  font-size: var(--app-font-size-larger);
  margin-bottom: 5px;
}
.app-approvalProject .app-approvalProject-header .app-approvalProject-clientName {
  color: var(--app-color-grey-dark);
}

.app-approvalProject-unitGroups {
  padding: 25px 15px;
}
.app-approvalProject-unitGroups .app-approvalProject-unitGroup .app-approvalProject-unitGroup-header {
  display: flex;
  align-items: center;
  font-weight: var(--app-font-weight-normal);
  text-transform: capitalize;
  width: 100%;
}
.app-approvalProject-unitGroups .app-approvalProject-unitGroup .app-approvalProject-unitGroup-segments {
  width: 100%;
  padding: 10px 0;
}
.app-approvalProject-unitGroups .app-approvalProject-unitGroup .app-approvalProject-unitGroup-segments .app-approvalProject-unitGroup-segment {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 5px 0;
}
.app-approvalProject-unitGroups .app-approvalProject-unitGroup .app-approvalProject-unitGroup-segments .app-approvalProject-unitGroup-segment .-segment-date {
  width: 25%;
  display: flex;
  align-items: center;
}
.app-approvalProject-unitGroups .app-approvalProject-unitGroup .app-approvalProject-unitGroup-segments .app-approvalProject-unitGroup-segment .-segment-details {
  width: 50%;
  display: flex;
  align-items: center;
  min-height: 24px;
}
.app-approvalProject-unitGroups .app-approvalProject-unitGroup .app-approvalProject-unitGroup-segments .app-approvalProject-unitGroup-segment .-segment-details item-bullet {
  margin-right: 10px;
  height: auto;
  align-self: stretch;
}
.app-approvalProject-unitGroups .app-approvalProject-unitGroup .app-approvalProject-unitGroup-segments .app-approvalProject-unitGroup-segment .-segment-details .-segment-detailsInner {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.app-approvalProject-unitGroups .app-approvalProject-unitGroup .app-approvalProject-unitGroup-segments .app-approvalProject-unitGroup-segment .-segment-details .-segment-detailsInner > div {
  display: flex;
  align-items: center;
}
.app-approvalProject-unitGroups .app-approvalProject-unitGroup .app-approvalProject-unitGroup-segments .app-approvalProject-unitGroup-segment .-segment-details .-segment-detailsInner > div > :not(:first-child) {
  margin-left: 5px;
}
.app-approvalProject-unitGroups .app-approvalProject-unitGroup .app-approvalProject-unitGroup-segments .app-approvalProject-unitGroup-segment .-segment-details .-segment-detailsInner > div:nth-child(1) {
  font-weight: var(--app-font-weight-normal);
}
.app-approvalProject-unitGroups .app-approvalProject-unitGroup .app-approvalProject-unitGroup-segments .app-approvalProject-unitGroup-segment .-segment-details .-segment-detailsInner > div:nth-child(1) > i {
  position: relative;
  font-size: var(--app-font-size-normal);
  color: var(--app-color-grey-dark);
}
.app-approvalProject-unitGroups .app-approvalProject-unitGroup .app-approvalProject-unitGroup-segments .app-approvalProject-unitGroup-segment .-segment-details .-segment-detailsInner > div.-segment-error {
  color: var(--app-color-red);
}
.app-approvalProject-unitGroups .app-approvalProject-unitGroup .app-approvalProject-unitGroup-segments .app-approvalProject-unitGroup-segment .-segment-units {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.app-approvalProject-unitGroups .app-approvalProject-unitGroup .app-approvalProject-unitGroup-segments .app-approvalProject-unitGroup-segment .-segment-units segment-value {
  --unit-color: var(--app-color-grey-dark);
}
.app-approvalProject-unitGroups .app-approvalProject-unitGroup .app-approvalProject-unitGroup-footer {
  display: flex;
  align-items: center;
  font-weight: var(--app-font-weight-normal);
  justify-content: flex-end;
  width: 100%;
}
.app-approvalProject-unitGroups .app-approvalProject-unitGroup .app-approvalProject-unitGroup-footer segment-value {
  --unit-color: var(--app-color-grey-dark);
  --value-weight: 500;
}

.app-approvalProject-approvers,
.app-approvalProject-attachments {
  width: 100%;
  padding: 0 15px 15px 15px;
}
.app-approvalProject-approvers .app-approvalProject-approvers-header,
.app-approvalProject-approvers .app-approvalProject-attachments-header,
.app-approvalProject-attachments .app-approvalProject-approvers-header,
.app-approvalProject-attachments .app-approvalProject-attachments-header {
  width: 100%;
  display: flex;
  align-items: center;
  height: 40px;
}
.app-approvalProject-approvers .app-approvalProject-approvers-header > div,
.app-approvalProject-approvers .app-approvalProject-attachments-header > div,
.app-approvalProject-attachments .app-approvalProject-approvers-header > div,
.app-approvalProject-attachments .app-approvalProject-attachments-header > div {
  font-weight: var(--app-font-weight-normal);
}
.app-approvalProject-approvers .app-approvalProject-approvers-item,
.app-approvalProject-approvers .app-approvalProject-attachments-item,
.app-approvalProject-attachments .app-approvalProject-approvers-item,
.app-approvalProject-attachments .app-approvalProject-attachments-item {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 5px 0;
}
.app-approvalProject-approvers .app-approvalProject-approvers-empty,
.app-approvalProject-approvers .app-approvalProject-attachments-empty,
.app-approvalProject-attachments .app-approvalProject-approvers-empty,
.app-approvalProject-attachments .app-approvalProject-attachments-empty {
  text-align: center;
  width: 100%;
  margin: 20px 0;
  color: var(--app-color-grey-dark);
}

.app-approvalProject-approvers .app-approvalProject-approvers-item approval-user-status-icon {
  margin-right: 10px;
}
.app-approvalProject-approvers .app-approvalProject-approvers-item .-approver-details .-approver-reason {
  color: var(--app-color-grey-dark);
  display: flex;
  align-items: center;
}
.app-approvalProject-approvers .app-approvalProject-approvers-item .-approver-details .-approver-reason > i {
  margin-right: 5px;
}
.app-approvalProject-approvers .app-approvalProject-approvers-item .-approver-details .-approver-reason > div {
  padding-right: 25px;
}
.app-approvalProject-approvers .app-approvalProject-approvers-item .-approver-response {
  margin-left: auto;
  color: var(--app-color-grey-dark);
  flex: 0 0 auto;
}
.app-approvalProject-approvers .app-approvalProject-approvers-item .-approver-response.-declined {
  color: var(--app-color-red);
}
.app-approvalProject-approvers .app-approvalProject-approvers-item .-approver-response.-approved {
  color: var(--app-color-green);
}

.app-approvalProject-attachments .app-approvalProject-attachments-item .-attachment-name > a {
  height: 22px;
}
.app-approvalProject-attachments .app-approvalProject-attachments-item .-attachment-name > div {
  color: var(--app-color-grey-dark);
}
.app-approvalProject-attachments .app-approvalProject-attachments-item .-attachment-date {
  margin-left: auto;
  color: var(--app-color-grey-dark);
  flex: 0 0 auto;
}

.app-invoice-container {
  display: block;
  position: relative;
  width: 100%;
  padding: 50px;
  border-radius: var(--app-border-radius);
  box-shadow: var(--app-box-shadow-dark);
  min-height: 100px;
  background-color: var(--app-color-white);
  max-width: 1000px;
  margin: 0 auto;
}
@media (max-width: 850px) {
  .app-invoice-container {
    padding: 25px 0;
    border-radius: 0;
  }
  .app-invoice-container .app-invoice-header,
.app-invoice-container .app-invoice-subHeader,
.app-invoice-container .app-invoice-footer {
    padding: 0 10px;
  }
}
.app-invoice-container .app-invoice-header {
  width: 100%;
  min-height: 60px;
  margin-bottom: 5px;
}
.app-invoice-container .app-invoice-subHeader {
  width: 100%;
  min-height: 120px;
  margin-bottom: 20px;
}
.app-invoice-container .app-invoice-content .app-invoice-contentHeader > .-headerCol.-description,
.app-invoice-container .app-invoice-content .app-invoice-contentLines > .app-invoice-contentLine > .app-invoice-lineRow > .app-invoice-lineCol.-description {
  flex: 1 1 auto;
  width: 40%;
}
.app-invoice-container .app-invoice-content .app-invoice-contentHeader > .-headerCol.-quantity, .app-invoice-container .app-invoice-content .app-invoice-contentHeader > .-headerCol.-rate,
.app-invoice-container .app-invoice-content .app-invoice-contentLines > .app-invoice-contentLine > .app-invoice-lineRow > .app-invoice-lineCol.-quantity,
.app-invoice-container .app-invoice-content .app-invoice-contentLines > .app-invoice-contentLine > .app-invoice-lineRow > .app-invoice-lineCol.-rate {
  flex: 0 0 auto;
  padding-left: 15px;
  width: 110px;
  justify-content: flex-end;
}
.app-invoice-container .app-invoice-content .app-invoice-contentHeader > .-headerCol.-amount,
.app-invoice-container .app-invoice-content .app-invoice-contentLines > .app-invoice-contentLine > .app-invoice-lineRow > .app-invoice-lineCol.-amount {
  flex: 0 0 auto;
  padding-left: 15px;
  width: 120px;
  justify-content: flex-end;
}
.app-invoice-container .app-invoice-content .app-invoice-contentHeader {
  width: 100%;
  display: flex;
  align-items: center;
  height: 40px;
  border-bottom: 2px solid var(--app-color-dark);
}
.app-invoice-container .app-invoice-content .app-invoice-contentHeader .-headerCol {
  font-weight: var(--app-font-weight-normal);
  flex: 0 0 auto;
  display: flex;
  align-items: center;
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines {
  width: 100%;
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 70px;
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine:not(.-subtotalLine) {
  border-bottom: var(--app-border);
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine:hover .app-invoice-lineRow.-projectSelect {
  height: var(--invoice-lineRow-projectSelect-height-open);
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineIcon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  position: relative;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineIcon > i {
  font-size: 18px;
  color: var(--app-color-grey-very-dark);
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineIcon ~ .app-invoice-lineRow {
  padding-left: 20px;
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow {
  display: flex;
  align-items: center;
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow.-projectSelect {
  --invoice-lineRow-projectSelect-height-open: 24px;
  height: 0px;
  overflow: hidden;
  transition: height var(--app-transition);
  font-size: var(--app-font-size-small);
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow.-projectSelect.-lockedOpen {
  height: var(--invoice-lineRow-projectSelect-height-open) !important;
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow.-projectSelect.-lockedClosed {
  height: 0px !important;
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow.-projectSelect .app-invoice-lineCol {
  min-height: var(--invoice-lineRow-projectSelect-height-open);
  margin-bottom: 5px;
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow.-projectSelect .app-invoice-lineCol list-drop-down {
  --listDropDown-button-size: var(--invoice-lineRow-projectSelect-height-open);
  --listDropDown-button-iconSize: 14px;
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow.-projectSelect .app-invoice-lineCol list-drop-down .button-animatedIcon {
  margin-right: 3px;
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow.-projectSelect .app-invoice-lineCol .app-invoice-line-selectedProject {
  height: var(--invoice-lineRow-projectSelect-height-open);
  display: flex;
  align-items: center;
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow .app-invoice-lineCol {
  flex: 0 0 auto;
  min-height: 40px;
  display: flex;
  align-items: center;
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow .app-invoice-lineCol.-description .app-formItem-inputContainer {
  width: 100%;
  height: auto;
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow .app-invoice-lineCol.-description .app-formItem-inputContainer .rx-container-invoice-line {
  min-height: 34px;
  margin: 0;
  border: none !important;
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow .app-invoice-lineCol.-description .app-formItem-inputContainer .rx-container-invoice-line .rx-editor {
  padding-left: 0;
  padding-right: 0;
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow .app-invoice-lineCol.-description .app-formItem-inputContainer .rx-container-invoice-line .rx-editor > p:not(:first-child) {
  margin-top: 5px;
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow .app-invoice-lineCol.-quantity .app-formItem-inputContainer, .app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow .app-invoice-lineCol.-rate .app-formItem-inputContainer, .app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow .app-invoice-lineCol.-amount .app-formItem-inputContainer {
  width: 100%;
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow .app-invoice-lineCol.-quantity .app-formItem-inputContainer input, .app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow .app-invoice-lineCol.-rate .app-formItem-inputContainer input, .app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow .app-invoice-lineCol.-amount .app-formItem-inputContainer input {
  text-align: right;
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow .app-invoice-lineCol.-quantity .app-formItem-inputContainer .app-invoice-lineCol-value, .app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow .app-invoice-lineCol.-rate .app-formItem-inputContainer .app-invoice-lineCol-value, .app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow .app-invoice-lineCol.-amount .app-formItem-inputContainer .app-invoice-lineCol-value {
  margin-left: auto;
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow .app-invoice-lineCol textarea {
  height: var(--app-input-height);
  width: 100%;
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow .app-invoice-lineCol .app-invoice-lineCol-icon {
  min-width: 20px;
  font-size: var(--app-font-size-larger);
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow .app-invoice-lineCol .app-invoice-lineCol-inputContainer {
  height: 100%;
  display: flex;
  align-items: center;
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow .app-invoice-lineCol .app-invoice-lineCol-inputContainer > .input-group,
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow .app-invoice-lineCol .app-invoice-lineCol-inputContainer > input {
  width: 120px;
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow .app-invoice-lineCol .-label {
  font-weight: var(--app-font-weight-normal);
  margin-right: 10px;
  flex: 0 0 auto;
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow > .app-remove {
  position: absolute;
  right: -28px;
  top: 50%;
  transform: translateY(-50%);
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow .app-invoice-subtotal-header {
  width: 100%;
  padding: 15px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--app-color-dark);
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow .app-invoice-subtotal-header .app-invoice-subtotal-headerCol {
  display: flex;
  align-items: center;
  height: 40px;
  width: 50%;
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow .app-invoice-subtotal-header .app-invoice-subtotal-headerCol:last-child {
  justify-content: flex-end;
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow .app-invoice-subtotal-header .app-invoice-subtotal-headerCol textarea {
  width: 100%;
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow .app-invoice-subtotal-header .app-invoice-subtotal-headerCol .-label {
  flex: 0 0 auto;
  font-weight: var(--app-font-weight-normal);
  margin-right: 10px;
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow .app-invoice-subtotal-header .app-invoice-subtotal-headerCol .-filterDropdown,
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow .app-invoice-subtotal-header .app-invoice-subtotal-headerCol .-filterInput {
  flex: 0 0 180px;
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow .app-invoice-subtotal-header .app-invoice-subtotal-headerCol .-filterDropdown:not(:last-child),
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow .app-invoice-subtotal-header .app-invoice-subtotal-headerCol .-filterInput:not(:last-child) {
  margin-right: 10px;
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow .app-invoice-subtotal-contentLines {
  padding-left: 20px;
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow .app-invoice-subtotal-footer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow .app-invoice-subtotal-footer .app-invoice-subtotal-footer-left {
  display: flex;
  align-items: center;
  margin-right: auto;
  flex: 0 0 60%;
  padding: 10px 0 10px 20px;
  height: 54px;
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow .app-invoice-subtotal-footer .app-invoice-subtotal-footer-left list-drop-down {
  --listDropdown-button-minWidth: 50px;
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow .app-invoice-subtotal-footer .app-invoice-subtotal-footer-right {
  display: flex;
  align-items: center;
  margin-left: auto;
  flex: 0 0 40%;
  justify-content: flex-end;
  padding: 10px 0;
  border-bottom: 1px solid var(--app-color-dark);
  height: 55px;
  font-weight: var(--app-font-weight-normal);
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow .app-invoice-subtotal-footer .app-invoice-subtotal-footer-right .app-invoice-subtotal-footer-subtotalValue {
  flex: 0 0 120px;
  text-align: end;
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-invoice-lineRow .app-invoice-subtotal-footer .app-remove {
  margin-left: 8px;
}
.app-invoice-container .app-invoice-content .app-invoice-contentLines .app-invoice-contentLine .app-formItem-errorLabel {
  text-align: end;
}
.app-invoice-container .app-invoice-content .app-invoice-contentTotals {
  display: flex;
  margin-bottom: 10px;
}
.app-invoice-container .app-invoice-content .app-invoice-contentTotals .app-invoice-contentTotalsLeft {
  display: flex;
  margin-right: auto;
  flex: 0 0 60%;
  padding-top: 15px;
}
@media (max-width: 850px) {
  .app-invoice-container .app-invoice-content .app-invoice-contentTotals .app-invoice-contentTotalsLeft {
    flex: 0 0 30%;
    padding-left: 10px;
  }
}
.app-invoice-container .app-invoice-content .app-invoice-contentTotals .app-invoice-contentTotalsLeft list-drop-down {
  --listDropdown-button-minWidth: 50px;
}
.app-invoice-container .app-invoice-content .app-invoice-contentTotals .app-invoice-contentTotalsRight {
  display: flex;
  margin-left: auto;
  flex: 0 0 40%;
  flex-direction: column;
}
@media (max-width: 850px) {
  .app-invoice-container .app-invoice-content .app-invoice-contentTotals .app-invoice-contentTotalsRight {
    flex: 0 0 70%;
    padding-right: 20px;
  }
}
.app-invoice-container .app-invoice-content .app-invoice-contentTotals .app-invoice-contentTotalsRight .-contentTotals-subtotal,
.app-invoice-container .app-invoice-content .app-invoice-contentTotals .app-invoice-contentTotalsRight .-contentTotals-tax,
.app-invoice-container .app-invoice-content .app-invoice-contentTotals .app-invoice-contentTotalsRight .-contentTotals-total {
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: var(--app-font-weight-normal);
}
.app-invoice-container .app-invoice-content .app-invoice-contentTotals .app-invoice-contentTotalsRight .-contentTotals-subtotal > *,
.app-invoice-container .app-invoice-content .app-invoice-contentTotals .app-invoice-contentTotalsRight .-contentTotals-tax > *,
.app-invoice-container .app-invoice-content .app-invoice-contentTotals .app-invoice-contentTotalsRight .-contentTotals-total > * {
  flex: 0 0 50%;
  text-align: end;
}
.app-invoice-container .app-invoice-content .app-invoice-contentTotals .app-invoice-contentTotalsRight .-contentTotals-subtotal,
.app-invoice-container .app-invoice-content .app-invoice-contentTotals .app-invoice-contentTotalsRight .-contentTotals-tax {
  height: 50px;
}
@media (max-width: 850px) {
  .app-invoice-container .app-invoice-content .app-invoice-contentTotals .app-invoice-contentTotalsRight .-contentTotals-subtotal,
.app-invoice-container .app-invoice-content .app-invoice-contentTotals .app-invoice-contentTotalsRight .-contentTotals-tax {
    height: 40px;
  }
}
.app-invoice-container .app-invoice-content .app-invoice-contentTotals .app-invoice-contentTotalsRight .-contentTotals-subtotal:not(:last-child):first-child,
.app-invoice-container .app-invoice-content .app-invoice-contentTotals .app-invoice-contentTotalsRight .-contentTotals-tax:not(:last-child):first-child {
  margin-top: 10px;
}
.app-invoice-container .app-invoice-content .app-invoice-contentTotals .app-invoice-contentTotalsRight .-contentTotals-total {
  font-size: var(--app-font-size-larger);
  height: 80px;
}
@media (max-width: 850px) {
  .app-invoice-container .app-invoice-content .app-invoice-contentTotals .app-invoice-contentTotalsRight .-contentTotals-total {
    height: 60px;
  }
}
.app-invoice-container .app-invoice-content .app-invoice-contentTotals .app-invoice-contentTotalsRight .-contentTotals-total:not(:first-child) {
  border-top: 2px solid var(--app-color-dark);
  margin-top: 10px;
}
.app-invoice-container .app-invoice-footer {
  margin-bottom: 30px;
}
.app-invoice-container .app-invoice-addTimesheets {
  height: 80px;
  width: 100%;
  display: flex;
  border: var(--app-border);
  border-radius: var(--app-border-radius);
}
.app-invoice-container .app-invoice-addTimesheets .app-invoice-addTimesheets-icon {
  flex: 0 0 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: var(--app-color-grey-dark);
}
.app-invoice-container .app-invoice-addTimesheets .app-invoice-addTimesheets-checkbox {
  flex: 1 1 auto;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 30px;
  border-left: var(--app-border);
  background-color: var(--app-color-grey-very-light);
}

.app-reportTile-container {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.app-reportTile {
  min-width: 240px;
  height: 200px;
  flex: 0 1 auto;
  width: calc(20% - 20px);
  padding: 30px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  border: var(--app-border-light);
  border-radius: var(--app-border-radius);
  cursor: pointer;
  background-color: var(--app-color-white);
  transition: background-color var(--app-transition);
  box-shadow: var(--app-box-shadow);
}
.app-reportTile:hover {
  background-color: var(--app-color-grey-very-light);
}
.app-reportTile.-smallTile {
  min-width: 170px;
  height: 160px;
  padding: 20px 10px;
}
.app-reportTile.-smallTile report-thumbnail {
  --reportThumbnail-size: 50px;
}
.app-reportTile report-thumbnail {
  margin-bottom: 25px;
}
.app-reportTile .app-reportTile-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}
.app-reportTile .app-reportTile-details .app-reportTile-details-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
.app-reportTile .app-reportTile-details .app-reportTile-details-type {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  color: var(--app-color-grey-dark);
  text-transform: capitalize;
}

@media (max-width: 850px) {
  .app-reportTile-container {
    padding-left: 10px;
  }
  .app-reportTile {
    height: 180px;
    width: calc(50% - 10px);
    min-width: calc(50% - 10px);
    margin: 0 10px 10px 0;
    padding: 20px 15px;
  }
  .app-reportTile:not(:last-child) {
    border-bottom: var(--app-border);
  }
}
.app-list {
  --appList-itemCell-deleteWidth: 50px;
  --appList-scrollWidth: 16px;
  --appList-border: var(--theme-appList-border);
  --appList-border-radius: var(--app-border-radius);
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  width: 100%;
  max-height: 100%;
  flex: 0 1 auto;
}
.app-list.-noBorder {
  --appList-border: none;
  --appList-border-radius: none;
}
.app-list.-noBorder .app-list-empty {
  --theme-appList-empty-border: none;
  border-radius: none;
}
.app-list.-reverseScroll .app-list-scrollOuter,
.app-list.-reverseScroll cdk-virtual-scroll-viewport {
  margin-top: auto;
}
.app-list.-listTallerThanScrollContainer.-reverseScroll .app-list-scrollOuter .app-list-item:first-child,
.app-list.-listTallerThanScrollContainer.-reverseScroll cdk-virtual-scroll-viewport .app-list-item:first-child {
  border-top: var(--theme-appList-item-border);
}
.app-list.-listTallerThanScrollContainer:not(.-reverseScroll) .app-list-scrollOuter .app-list-item:last-child,
.app-list.-listTallerThanScrollContainer:not(.-reverseScroll) cdk-virtual-scroll-viewport .app-list-item:last-child {
  border-bottom: var(--theme-appList-item-border);
}
.app-list .app-list-scrollOuter {
  max-height: 100%;
  width: 100%;
  position: relative;
  overflow-y: var(--app-overflow-overlay);
  overflow-x: hidden;
  border: var(--appList-border);
  border-radius: var(--appList-border-radius);
}
.app-list .app-list-scrollOuter::-webkit-scrollbar-track {
  border-radius: var(--theme-scrollbar-width);
  background-color: var(--theme-color-scrollbar-background);
}
.app-list .app-list-scrollOuter::-webkit-scrollbar {
  width: var(--theme-scrollbar-width);
  border-radius: var(--theme-scrollbar-width);
  background-color: var(--theme-color-scrollbar-background);
}
.app-list .app-list-scrollOuter::-webkit-scrollbar-thumb {
  border-radius: var(--theme-scrollbar-width);
  background-color: var(--theme-color-scrollbar);
}
.app-list .app-list-scrollOuter .app-list-scrollInner {
  width: 100%;
  position: relative;
}
.app-list .app-list-scrollOuter .app-list-bottomSpinnerContainer {
  width: 100%;
  height: 60px;
  position: relative;
}
.app-list cdk-virtual-scroll-viewport {
  max-height: 100%;
  width: 100%;
  position: relative;
  overflow-y: var(--app-overflow-overlay);
  overflow-x: hidden;
}
.app-list cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper {
  max-width: 100%;
}

.app-list-item {
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: var(--theme-appList-item-border);
  transition: background-color 0.25s;
  background-color: var(--app-color-white);
}
.app-list-item:hover:not(.-header):not(.-itemSelectDisabled) {
  background-color: var(--app-color-grey-very-light);
}
.app-list-item.-header {
  border-bottom: none;
  background-color: var(--theme-appList-item-header-background);
}
.app-list-item.-header .app-list-itemCell {
  min-height: 39px;
  margin: 5px 0;
  font-weight: var(--app-font-weight-normal);
  transition: background-color var(--app-transition);
  border-radius: var(--app-border-radius);
  color: var(--app-color-grey-very-dark);
  user-select: none;
}
.app-list-item.-header .app-list-itemCell > i.ion-md-arrow-dropdown,
.app-list-item.-header .app-list-itemCell > i.ion-md-arrow-dropup {
  margin-top: 1px;
  font-size: var(--app-font-size-larger);
}
.app-list-item.-header .app-list-itemCell whats-this {
  margin-left: 0;
}
.app-list-item:not(.-itemSelectDisabled):not(.-header) {
  cursor: pointer;
}
.app-list-item:not(.-itemSelectDisabled).-header .app-list-itemCell:not(.-noSort) {
  cursor: pointer;
}
.app-list-item.-header:not(.-itemSelectDisabled) .app-list-itemCell:not(.-noSort):hover {
  background-color: var(--app-color-grey-light);
}
.app-list-item:last-child {
  border-bottom: none;
}

.app-list-itemRow {
  flex: 0 0 auto;
  min-width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.app-list-itemRow.-column {
  flex-direction: column;
  align-items: flex-start;
}
.app-list-itemRow.-expandable {
  height: 0;
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  opacity: 0;
  transition: max-height var(--app-transition), padding var(--app-transition), opacity var(--app-transition);
}
.app-list-itemRow.-expandable.-expanded {
  height: auto;
  max-height: 1000px;
  opacity: 1;
}

.app-list-itemCell {
  position: relative;
  padding: 8px 12px 7px 12px;
  height: 100%;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.app-list-itemCell:first-child {
  padding-left: 15px;
}
.app-list-itemCell:last-child {
  padding-right: 20px;
}
.app-list-itemCell.-highlighted > div,
.app-list-itemCell.-highlighted > span {
  color: var(--theme-color-link);
  font-weight: var(--app-font-weight-normal);
}
.app-list-itemCell.-column {
  flex-direction: column;
  align-items: flex-start;
}
.app-list-itemCell.-column > :not(:last-child) {
  margin-bottom: 3px;
}
.app-list-itemCell.-column > :not(:first-child) {
  margin-left: 0;
}
.app-list-itemCell.-alignRight {
  justify-content: flex-end;
  text-align: end;
}
.app-list-itemCell > span,
.app-list-itemCell > div {
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.app-list-itemCell > span:not(.input-group),
.app-list-itemCell > div:not(.input-group) {
  overflow: hidden;
}
.app-list-itemCell > i {
  color: var(--app-color-grey-dark);
  position: relative;
}
.app-list-itemCell .-itemCellText {
  display: flex;
}
.app-list-itemCell > segment-value {
  --unit-color: var(--app-color-grey-dark);
}
.app-list-itemCell > .app-list-personIcon {
  height: 20px;
  width: 20px;
  border-radius: 40px;
  background-color: var(--app-color-grey-light);
  margin-right: 10px;
  flex: 0 0 auto;
  text-align: center;
  font-size: var(--app-font-size-small);
  line-height: 20px;
  font-weight: var(--app-font-weight-normal);
  color: #333;
  overflow: hidden;
}
.app-list-itemCell > .app-list-personIcon img {
  width: 100%;
  height: 100%;
}
.app-list-itemCell > list-drop-down {
  --listDropDown-button-size: 1.5rem;
  --listDropDown-button-iconSize: 16px;
}
.app-list-itemCell > :not(span):not(:last-child) {
  margin-right: 8px;
}
.app-list-itemCell > span + :not(span) {
  margin-left: 8px;
}
.app-list-itemCell > .app-formItem {
  padding: 0 var(--theme-appList-itemCell-formItem-horitonzalPadding);
}
@media (max-width: 850px) {
  .app-list-itemCell > .app-formItem {
    padding-left: 0;
    padding-right: 0;
  }
}
.app-list-itemCell > .app-formItem .app-formItem-value {
  --app-formItem-height: 28px;
}
.app-list-itemCell > .app-formItem .app-formItem-value .-highlighted {
  font-weight: var(--app-font-weight-normal);
}
.app-list-itemCell > .app-formItem .app-formItem-value segment-value {
  --value-size: 1rem;
  --unit-color: var(--app-color-grey-dark);
}
.app-list-itemCell > .app-list-itemColour {
  width: 4px;
  flex: 0 0 auto;
  border-radius: 10px;
  margin-right: 7px;
  height: 22px;
}
.app-list-itemCell.-rowIcon {
  flex: 0 0 auto;
  width: var(--appList-itemCell-deleteWidth) !important;
  justify-content: center;
  align-items: center;
  touch-action: manipulation;
  position: relative;
}
.app-list-itemCell.-rowIcon.-disabled {
  pointer-events: none;
}
.app-list-itemCell.-rowIcon.-disabled > i {
  color: var(--app-color-grey-light);
}
.app-list-itemCell.-rowIcon > i {
  border-radius: 10px;
  transition: background-color var(--app-transition);
}
.app-list-itemCell.-rowIcon > :not(:last-child) {
  margin-right: 0;
}
.app-list-itemCell.-rowToggle {
  padding-left: 16px;
  padding-right: 0;
  flex: 0 0 auto;
  width: var(--appList-itemCell-deleteWidth) !important;
  justify-content: center;
  align-items: center;
  touch-action: manipulation;
  position: relative;
}
.app-list-itemCell.-rowToggle.-disabled {
  pointer-events: none;
}
.app-list-itemCell.-rowToggle.-disabled > i {
  color: var(--app-color-grey-light);
}
.app-list-itemCell.-rowToggle > i {
  border-radius: 10px;
  transition: background-color var(--app-transition);
}
.app-list-itemCell.-rowToggle > :not(:last-child) {
  margin-right: 0;
}
.app-list-itemCell.-rowDelete {
  flex: 0 0 auto;
  width: var(--appList-itemCell-deleteWidth) !important;
  justify-content: center;
  align-items: center;
  touch-action: manipulation;
  position: relative;
}
.app-list-itemCell.-rowDelete.-disabled {
  pointer-events: none;
}
.app-list-itemCell.-rowDelete.-disabled > i {
  color: var(--app-color-grey-light);
}
.app-list-itemCell.-rowDelete > i {
  border-radius: 10px;
  transition: background-color var(--app-transition);
}
.app-list-itemCell.-rowDelete > :not(:last-child) {
  margin-right: 0;
}
.app-list-itemCell.-rowDelete > i {
  color: var(--app-color-grey-dark);
}
.app-list-itemCell.-rowAdd {
  flex: 0 0 auto;
  width: var(--appList-itemCell-deleteWidth) !important;
  justify-content: center;
  align-items: center;
  touch-action: manipulation;
  position: relative;
  font-size: 20px;
}
.app-list-itemCell.-rowAdd.-disabled {
  pointer-events: none;
}
.app-list-itemCell.-rowAdd.-disabled > i {
  color: var(--app-color-grey-light);
}
.app-list-itemCell.-rowAdd > i {
  border-radius: 10px;
  transition: background-color var(--app-transition);
}
.app-list-itemCell.-rowAdd > :not(:last-child) {
  margin-right: 0;
}
.app-list-itemCell.-rowAdd > i {
  color: var(--theme-color-success);
}
.app-list-itemCell.-rowEdit {
  flex: 0 0 auto;
  width: var(--appList-itemCell-deleteWidth) !important;
  justify-content: center;
  align-items: center;
  touch-action: manipulation;
  position: relative;
  font-size: var(--app-font-size-large);
}
.app-list-itemCell.-rowEdit.-disabled {
  pointer-events: none;
}
.app-list-itemCell.-rowEdit.-disabled > i {
  color: var(--app-color-grey-light);
}
.app-list-itemCell.-rowEdit > i {
  border-radius: 10px;
  transition: background-color var(--app-transition);
}
.app-list-itemCell.-rowEdit > :not(:last-child) {
  margin-right: 0;
}
.app-list-itemCell.-rowEdit > i {
  color: var(--theme-color-link);
}
.app-list-itemCell.-rowArchived {
  flex: 0 0 auto;
  width: var(--appList-itemCell-deleteWidth) !important;
  justify-content: center;
  align-items: center;
  touch-action: manipulation;
  position: relative;
  font-size: var(--app-font-size-large);
  width: calc(var(--appList-itemCell-deleteWidth) - 1rem);
  padding: 0;
  padding-right: 12px;
}
.app-list-itemCell.-rowArchived.-disabled {
  pointer-events: none;
}
.app-list-itemCell.-rowArchived.-disabled > i {
  color: var(--app-color-grey-light);
}
.app-list-itemCell.-rowArchived > i {
  border-radius: 10px;
  transition: background-color var(--app-transition);
}
.app-list-itemCell.-rowArchived > :not(:last-child) {
  margin-right: 0;
}
.app-list-itemCell.-rowArchived > i {
  color: var(--app-color-grey);
}
.app-list-itemCell.-rowExpand {
  flex: 0 0 auto;
  width: var(--appList-itemCell-deleteWidth) !important;
  justify-content: center;
  align-items: center;
  touch-action: manipulation;
  position: relative;
  font-size: var(--app-font-size-large);
}
.app-list-itemCell.-rowExpand.-disabled {
  pointer-events: none;
}
.app-list-itemCell.-rowExpand.-disabled > i {
  color: var(--app-color-grey-light);
}
.app-list-itemCell.-rowExpand > i {
  border-radius: 10px;
  transition: background-color var(--app-transition);
}
.app-list-itemCell.-rowExpand > :not(:last-child) {
  margin-right: 0;
}
.app-list-itemCell.-rowExpand > i {
  color: var(--theme-color-link);
}
.app-list-itemCell.-rowLoading {
  flex: 0 0 auto;
  width: var(--appList-itemCell-deleteWidth) !important;
  justify-content: center;
  align-items: center;
  touch-action: manipulation;
  position: relative;
  cursor: default;
}
.app-list-itemCell.-rowLoading.-disabled {
  pointer-events: none;
}
.app-list-itemCell.-rowLoading.-disabled > i {
  color: var(--app-color-grey-light);
}
.app-list-itemCell.-rowLoading > i {
  border-radius: 10px;
  transition: background-color var(--app-transition);
}
.app-list-itemCell.-rowLoading > :not(:last-child) {
  margin-right: 0;
}
.app-list-itemCell.-rowLoading > spinner {
  --spinner-size: 20px;
}
.app-list-itemCell.-rowWarning {
  flex: 0 0 auto;
  width: var(--appList-itemCell-deleteWidth) !important;
  justify-content: center;
  align-items: center;
  touch-action: manipulation;
  position: relative;
  cursor: default;
}
.app-list-itemCell.-rowWarning.-disabled {
  pointer-events: none;
}
.app-list-itemCell.-rowWarning.-disabled > i {
  color: var(--app-color-grey-light);
}
.app-list-itemCell.-rowWarning > i {
  border-radius: 10px;
  transition: background-color var(--app-transition);
}
.app-list-itemCell.-rowWarning > :not(:last-child) {
  margin-right: 0;
}
.app-list-itemCell.-rowWarning > i {
  color: var(--app-color-yellow);
  font-size: var(--app-font-size-larger);
}
.app-list-itemCell.-rowAttachment, .app-list-itemCell.-rowMessage {
  flex: 0 0 auto;
  width: var(--appList-itemCell-deleteWidth) !important;
  justify-content: center;
  align-items: center;
  touch-action: manipulation;
  position: relative;
  cursor: default;
}
.app-list-itemCell.-rowAttachment.-disabled, .app-list-itemCell.-rowMessage.-disabled {
  pointer-events: none;
}
.app-list-itemCell.-rowAttachment.-disabled > i, .app-list-itemCell.-rowMessage.-disabled > i {
  color: var(--app-color-grey-light);
}
.app-list-itemCell.-rowAttachment > i, .app-list-itemCell.-rowMessage > i {
  border-radius: 10px;
  transition: background-color var(--app-transition);
}
.app-list-itemCell.-rowAttachment > :not(:last-child), .app-list-itemCell.-rowMessage > :not(:last-child) {
  margin-right: 0;
}
.app-list-itemCell.-rowAttachment > i, .app-list-itemCell.-rowMessage > i {
  color: var(--app-color-grey-dark);
  font-size: var(--app-font-size-larger);
}
.app-list-itemCell.-rowGo {
  flex: 0 0 auto;
  width: var(--appList-itemCell-deleteWidth) !important;
  justify-content: center;
  align-items: center;
  touch-action: manipulation;
  position: relative;
  cursor: pointer;
}
.app-list-itemCell.-rowGo.-disabled {
  pointer-events: none;
}
.app-list-itemCell.-rowGo.-disabled > i {
  color: var(--app-color-grey-light);
}
.app-list-itemCell.-rowGo > i {
  border-radius: 10px;
  transition: background-color var(--app-transition);
}
.app-list-itemCell.-rowGo > :not(:last-child) {
  margin-right: 0;
}
.app-list-itemCell.-rowGo > i {
  color: var(--app-color-grey-dark);
  font-size: var(--app-font-size-larger);
}

.app-list-itemDetail {
  display: flex;
  flex-direction: column;
}
.app-list-itemDetail > :not(:last-child) {
  margin-bottom: 5px;
}

.app-list-itemDetail-label {
  display: flex;
  align-items: center;
  font-size: var(--app-font-size-small);
}
.app-list-itemDetail-label > :not(span):not(:last-child) {
  margin-right: 8px;
}
.app-list-itemDetail-label > span + :not(span) {
  margin-left: 8px;
}
.app-list-itemDetail-label i {
  font-size: var(--app-font-size-large);
  position: relative;
  color: var(--app-color-grey-dark);
}

.app-list-itemDetail-value {
  display: flex;
  align-items: center;
  font-size: var(--app-font-size-normal);
}
.app-list-itemDetail-value > :not(span):not(:last-child) {
  margin-right: 8px;
}
.app-list-itemDetail-value > span + :not(span) {
  margin-left: 8px;
}
.app-list-itemDetail-value i {
  font-size: var(--app-font-size-large);
  position: relative;
  color: var(--app-color-grey-dark);
}
.app-list-itemDetail-value > span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.app-list-empty {
  --appList-listEmpty-padding: 30px;
  border-radius: var(--app-border-radius);
  padding: var(--appList-listEmpty-padding);
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  border: var(--theme-appList-empty-border);
  background-color: var(--app-color-white);
}
.app-list-empty > i {
  display: block;
  font-size: 3rem;
  margin-bottom: 5px;
  width: 100%;
}
.app-list-empty > .app-list-emptyImage {
  height: 50px;
  margin-bottom: 15px;
}
.app-list-empty > thumbnail-graphic {
  --thumbnailGraphic-height: 80px;
  margin-bottom: 15px;
}
.app-list-empty .app-list-emptyHeader {
  font-size: var(--app-font-size-larger);
  width: 100%;
  margin-bottom: 0;
  color: var(--app-color-dark);
  font-weight: var(--app-font-weight-normal);
  text-transform: capitalize;
  margin-bottom: 5px;
}
.app-list-empty .app-list-emptyDescription {
  font-size: var(--app-font-size-normal);
  width: 100%;
  color: var(--app-color-grey-dark);
}
.app-list-empty .app-list-emptyButton {
  margin-top: 15px;
  text-transform: capitalize;
}

body.-theme-invoxy {
  --theme-appList-border: var(--app-border-light);
  --theme-appList-item-border: var(--app-border-light);
  --theme-appList-empty-border: var(--app-border-dashed-light);
  --theme-appList-item-header-background: transparent;
  --theme-appList-itemCell-formItem-horitonzalPadding: 15px;
}
body.-theme-karmly-light, body.-theme-karmly-dark, body.-theme-flexitime {
  --theme-appList-border: var(--app-border);
  --theme-appList-item-border: var(--app-border);
  --theme-appList-empty-border: var(--app-border);
  --theme-appList-item-header-background: transparent;
  --theme-appList-itemCell-formItem-horitonzalPadding: 0;
}

.app-dashBlock {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  border-radius: var(--app-border-radius);
  border: var(--app-border);
  border-radius: var(--app-border-radius);
  background-color: var(--app-color-white);
}
.app-dashBlock .app-dashBlock-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 56px;
  border-bottom: var(--app-border);
  padding: 0 15px;
  flex: 0 0 auto;
}
.app-dashBlock .app-dashBlock-header.-dragHandle {
  cursor: move;
}
.app-dashBlock .app-dashBlock-header .app-dashBlock-headerLeft {
  display: flex;
  align-items: center;
}
.app-dashBlock .app-dashBlock-header .app-dashBlock-headerLeft > :not(:first-child) {
  margin-left: 10px;
}
.app-dashBlock .app-dashBlock-header .app-dashBlock-headerLeft report-thumbnail {
  --reportThumbnail-size: 40px;
}
.app-dashBlock .app-dashBlock-header .app-dashBlock-headerLeft .app-dashBlock-header-title {
  font-size: var(--app-font-size-large);
  font-weight: var(--app-font-weight-bolder);
}
.app-dashBlock .app-dashBlock-header .app-dashBlock-headerLeft button.app-dashBlock-header-title {
  padding: 0;
  min-width: 0;
  text-align: left;
  justify-content: flex-start;
  --button-iconSize: 16px;
  background-color: transparent !important;
}
.app-dashBlock .app-dashBlock-header .app-dashBlock-headerLeft .app-dashBlock-header-description {
  font-size: var(--app-font-size-normal);
  font-weight: var(--app-font-weight-normal);
}
.app-dashBlock .app-dashBlock-header .app-dashBlock-headerRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.app-dashBlock .app-dashBlock-header .app-dashBlock-headerRight > :not(:first-child) {
  margin-left: 15px;
}
.app-dashBlock .app-dashBlock-header .app-dashBlock-headerRight .app-remove {
  --appRemove-size: 26px;
}
.app-dashBlock .app-dashBlock-header .app-dashBlock-headerRight .app-formItem {
  align-items: flex-end;
}
.app-dashBlock .app-dashBlock-header .app-dashBlock-headerRight .app-formItem .app-formItem-value {
  font-size: var(--app-font-size-larger);
}
.app-dashBlock .app-dashBlock-content {
  width: 100%;
  overflow: hidden;
  position: relative;
  min-height: 0;
  flex: 1 1 auto;
}
@media (max-width: 850px) {
  .app-dashBlock {
    border-left: 0;
    border-right: 0;
    border-radius: 0;
  }
}

:host {
  --listDropDown-button-size: 34px;
  --listDropDown-button-iconSize: 20px;
  --listDropDown-button-fontSize: 1rem;
  --listDropDown-button-fontWeight: var(--app-font-weight-normal);
  --listDropDown-button-disabled-fontWeight: var(--app-font-weight-normal);
  --listDropDown-button-justifyContent: center;
  --listDropdown-button-minWidth: 100px;
  --listDropdown-button-icon-margin: 8px;
  position: relative;
  display: block;
  line-height: 20px;
  margin-right: 0;
  height: var(--listDropDown-button-size);
}
:host .-alignLeft {
  margin-right: var(--listDropdown-button-icon-margin) !important;
}
:host.-floatOnMobile {
  --button-height: var(--listDropDown-button-size);
}
@media (max-width: 850px) {
  :host.-floatOnMobile {
    --listDropDown-button-size: 44px;
  }
}
@media (max-width: 850px) {
  :host.-floatOnMobile {
    --button-height: 44px !important;
    margin: 0 !important;
    position: fixed;
    bottom: calc(15px + var(--app-navMenu-footerHeight) + env(safe-area-inset-bottom));
    border-radius: var(--app-border-radius);
    right: 20px;
    z-index: 1050;
  }
}
:host.-floatLeftOnMobile {
  --listDropDown-button-size: 44px;
  --button-height: var(--listDropDown-button-size);
}
@media (max-width: 850px) {
  :host.-floatLeftOnMobile {
    --listDropDown-button-size: 44px;
  }
}
@media (max-width: 850px) {
  :host.-floatLeftOnMobile {
    --button-height: 44px !important;
    margin: 0 !important;
    position: fixed;
    bottom: calc(15px + var(--app-navMenu-footerHeight) + env(safe-area-inset-bottom));
    border-radius: var(--app-border-radius);
    right: 20px;
    z-index: 1050;
  }
}
@media (max-width: 850px) {
  :host.-floatLeftOnMobile {
    margin: 0 !important;
    right: auto;
    left: 20px;
  }
}
:host.-buttonEllipsisDisabled .ldd-container > button {
  overflow-x: auto;
  overflow-y: hidden;
  text-overflow: unset;
  white-space: normal;
}
:host.-buttonEllipsisDisabled .ldd-container > button > span {
  overflow-x: auto;
  overflow-y: hidden;
  text-overflow: unset;
  white-space: normal;
}
:host .ldd-container {
  width: 100%;
}
:host .ldd-container button {
  --button-height: var(--listDropDown-button-size);
  --button-fontSize: var(--listDropDown-button-fontSize);
  --button-iconSize: var(--listDropDown-button-iconSize);
  font-weight: var(--listDropDown-button-fontWeight);
  justify-content: var(--listDropDown-button-justifyContent);
}
:host .ldd-container button > * {
  font-weight: var(--listDropDown-button-fontWeight);
}
:host .ldd-container button:disabled {
  font-weight: var(--listDropDown-button-disabled-fontWeight);
}
:host .ldd-container button:disabled > * {
  font-weight: var(--listDropDown-button-disabled-fontWeight);
}
:host .ldd-container button.-invalidField {
  border-right: 1px solid var(--app-color-red) !important;
}
:host .ldd-container button:not(.-type-icon):not(.-type-icon-round) {
  width: 100%;
  min-width: var(--listDropdown-button-minWidth);
}
:host .ldd-container button.-type-dropdown {
  --button-iconSize: 1.3rem;
}
:host .ldd-container button.-type-dropdown:disabled .button-icon {
  display: none;
}
:host .ldd-container button.-type-dropdown:disabled archived-icon {
  padding-top: 2px;
}
:host .ldd-container button.-type-dropdown:disabled .completedIcon {
  color: var(--app-color-green);
}
:host .ldd-container button.-type-dropdown:disabled tooltip-popover {
  pointer-events: all;
}
:host .ldd-container button.-type-dropdown > .button-icon.-alignRight {
  margin-left: auto;
}
:host .ldd-container button .-colorPrefix {
  margin: var(--listDropDown-icon-margin) 0;
  border-radius: 5px;
  width: 5px;
  height: calc(var(--listDropDown-button-size) - 18px);
}
:host .ldd-container button .-textPrefix {
  margin-right: 10px;
  width: auto;
  height: var(--listDropDown-button-size);
  line-height: var(--listDropDown-button-size);
  font-weight: var(--app-font-weight-bold);
}
:host .ldd-container button .-title {
  flex: 1 1 auto;
}
:host .ldd-container button .-title .-description {
  display: block;
  text-align: left;
  color: var(--app-color-grey-dark);
  font-size: var(--app-font-size-small);
  font-weight: var(--app-font-weight-normal);
}
:host .ldd-container button .-titlePlaceholder {
  color: var(--app-color-grey-dark) !important;
  font-weight: var(--app-font-weight-normal);
}

::ng-deep .ldd-listContainer {
  padding: 0;
  border: none;
  background-color: var(--theme-listDropdown-listContainer-backgroundColor);
  min-width: 220px;
  --listDropDown-iconSize: 18px;
}
::ng-deep .ldd-listContainer::-webkit-scrollbar-track {
  border-radius: var(--theme-scrollbar-width);
  background-color: var(--theme-color-scrollbar-background);
}
::ng-deep .ldd-listContainer::-webkit-scrollbar {
  width: var(--theme-scrollbar-width);
  border-radius: var(--theme-scrollbar-width);
  background-color: var(--theme-color-scrollbar-background);
}
::ng-deep .ldd-listContainer::-webkit-scrollbar-thumb {
  border-radius: var(--theme-scrollbar-width);
  background-color: var(--theme-color-scrollbar);
}
@media (max-width: 850px) {
  ::ng-deep .ldd-listContainer {
    --listDropDown-iconSize: 24px;
  }
}
::ng-deep .ldd-listContainer .ldd-listContainerInner {
  display: flex;
  flex-direction: column;
}
@media (min-width: 851px) {
  ::ng-deep .ldd-listContainer .ldd-listContainerInner {
    max-height: min(600px, 50vh);
  }
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-header {
  flex: 0 0 auto;
  width: 100%;
  overflow: hidden;
  padding: var(--theme-listDropdown-header-padding);
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-header .app-formItem-inputContainer {
  padding: var(--theme-listDropdown-header-inputContainer-padding);
  min-height: var(--theme-listDropdown-header-inputContainer-minHeight);
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-header .app-formItem-inputContainer app-search {
  flex: 1 1 auto;
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-header .ldd-back {
  --button-height: 30px;
  --button-iconSize: 14px;
  flex-shrink: 0;
  margin-right: 7px;
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-header .ldd-nestedListParent {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-shrink: 1;
  min-width: 0;
  font-size: var(--app-font-size-normal);
  font-weight: var(--app-font-weight-bold);
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-multiselect-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-multiselect-header .ldd-multiselect-count {
  color: var(--app-color-grey-dark);
  user-select: none;
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-multiselect-header .ldd-multiselect-toggleAll > button {
  --button-height: 20px;
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-multiselect-footer {
  padding: 10px 5px;
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-multiselect-footer > button {
  --button-height: 28px;
  width: 100%;
}
@media (max-width: 850px) {
  ::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-multiselect-footer {
    padding: 10px 10px;
  }
  ::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-multiselect-footer > button {
    --button-height: 40px;
  }
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-list {
  width: 100%;
  overflow-y: auto;
  flex: 1 1 auto;
  min-height: 0;
  padding: 10px 0;
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-divider {
  height: 1px;
  border-bottom: var(--app-border-light);
  margin: 10px 0;
  padding: 0;
  position: relative;
  user-select: none;
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-divider.-customDivider {
  height: 26px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  font-size: var(--app-font-size-small);
  color: var(--app-color-grey-dark);
  margin: 8px 0 5px 0;
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-divider.-customDivider item-bullet {
  --theme-itemBullet-dot-size: 8px;
  margin-right: 5px;
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-divider.-customDivider:first-child {
  margin-top: 0;
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-divider:first-child:not(.-customDivider), ::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-divider:last-child, ::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-divider + .ldd-divider:not(.-customDivider) {
  display: none;
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-item {
  height: auto;
  min-height: 34px;
  padding: 4px 5px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  color: var(--theme-listDropdown-item-color);
  background-color: var(--theme-listDropdown-item-backgroundColor);
  font-size: var(--app-font-size-normal);
  font-weight: var(--app-font-weight-normal);
  display: flex;
  justify-content: space-between;
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-item.-multiSelectItemToggled .ldd-itemInner {
  background-color: var(--theme-color-primary-translucent);
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-item.-itemSelected {
  background-color: var(--theme-listDropdown-item-selected-backgroundColor) !important;
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-item.-itemSelected .itemName {
  color: var(--theme-listDropdown-item-selected-color) !important;
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-item.-itemHighlighted {
  background-color: var(--theme-listDropdown-item-highlighted-backgroundColor) !important;
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-item.-itemDisabled {
  pointer-events: none;
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-item.-itemDisabled .itemName {
  color: var(--app-color-grey-dark) !important;
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-item.-itemDisabled tooltip-popover {
  pointer-events: all;
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-item.-itemDisabled:hover {
  background-color: transparent;
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-item.-dynamicItemCreator {
  color: var(--theme-color-link);
  font-weight: var(--app-font-weight-bold);
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-item .ldd-itemInner {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 20px;
  padding: 0 10px;
  border-radius: var(--app-border-radius);
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-item .ldd-itemLeft {
  flex: 1 1 auto;
  width: 100%;
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-item .ldd-itemRight {
  flex: 0 0 auto;
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-item .ldd-itemLeft,
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-item .ldd-itemRight {
  display: flex;
  align-items: center;
  min-height: 20px;
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-item .ldd-itemLeft item-bullet {
  margin: 3px 10px 3px 0;
  height: calc(100% - 6px);
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-item .ldd-itemLeft product-icon {
  margin-right: 10px;
  --productIcon-size: 18px;
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-item .ldd-itemLeft .itemIcon {
  font-size: var(--listDropDown-iconSize);
  color: var(--app-color-dark);
  margin-right: 10px;
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-item .ldd-itemLeft .ldd-itemLeftInner {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1 1 auto;
  position: relative;
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-item .ldd-itemLeft .ldd-itemLeftInner .itemName {
  max-width: 100%;
  font-size: var(--app-font-size-normal);
  font-weight: var(--app-font-weight-normal);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-item .ldd-itemLeft .ldd-itemLeftInner .itemDescription {
  color: var(--app-color-grey-dark);
  font-size: var(--app-font-size-small);
  line-height: 1.3rem;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-item .ldd-itemLeft .ldd-itemLeftInner .itemDescription .itemDescription-itemDescriptionBullet {
  width: 10px !important;
  height: 10px !important;
  margin-right: 6px;
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-item .ldd-itemRight i.ion-ios-arrow-forward {
  color: var(--app-color-grey-dark);
  font-size: var(--app-font-size-normal);
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-item .ldd-itemRight .itemIcon {
  font-size: var(--listDropDown-iconSize);
  color: var(--app-color-dark);
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-item .ldd-itemRight .itemIcon.-multiSelectToggledIcon {
  color: var(--theme-color-primary);
  font-size: 22px;
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-empty {
  line-height: 34px;
  padding: 0 15px;
  margin: 0 10px;
  position: relative;
  text-align: center;
  font-size: var(--app-font-size-normal);
  color: var(--app-color-grey);
}
::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-listDescription {
  text-align: center;
  color: var(--app-color-grey-dark);
  font-size: var(--app-font-size-small);
  font-weight: var(--app-font-weight-thin);
  margin: 5px 10px 10px 10px;
}
@media (max-width: 850px) {
  ::ng-deep .ldd-listContainer .ldd-listContainerInner {
    max-height: calc(100vh - 50px);
  }
  ::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-item {
    padding: 4px 10px;
  }
  ::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-item .ldd-itemInner {
    padding: 8px 15px;
  }
  ::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-item:hover, ::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-item:active, ::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-item:focus {
    background-color: transparent;
  }
  ::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-divider.-customDivider {
    padding: 0 20px;
  }
}
@media (min-width: 851px) {
  ::ng-deep .ldd-listContainer .ldd-listContainerInner .ldd-item:hover {
    background-color: var(--app-color-grey-light);
  }
}

.-user-icon {
  margin-right: 10px;
  height: 22px;
}

body.-theme-invoxy {
  --theme-listDropdown-header-padding: 10px;
  --theme-listDropdown-header-inputContainer-padding: 0;
  --theme-listDropdown-header-inputContainer-minHeight: 34px;
  --theme-listDropdown-listContainer-backgroundColor: var(--app-color-white);
  --theme-listDropdown-item-color: var(--app-color-dark);
  --theme-listDropdown-item-backgroundColor: transparent;
  --theme-listDropdown-item-selected-backgroundColor: var(--app-color-grey-light);
  --theme-listDropdown-item-highlighted-backgroundColor: var(--app-color-grey-light);
  --theme-listDropdown-item-selected-color: var(--theme-color-primary);
}
body.-theme-karmly-light, body.-theme-karmly-dark, body.-theme-flexitime {
  --theme-listDropdown-header-inputContainer-minHewight: 10px;
  --theme-listDropdown-header-inputContainer-padding: 10px;
  --theme-listDropdown-header-inputContainer-minHeight: 54px;
  --theme-listDropdown-listContainer-backgroundColor: var(--app-color-white);
  --theme-listDropdown-item-color: var(--app-color-dark);
  --theme-listDropdown-item-backgroundColor: transparent;
  --theme-listDropdown-item-selected-backgroundColor: var(--app-color-grey-light);
  --theme-listDropdown-item-highlighted-backgroundColor: var(--app-color-grey-light);
  --theme-listDropdown-item-selected-color: var(--theme-color-primary);
}
body.-theme-droppah {
  --theme-listDropdown-header-inputContainer-minHewight: 10px;
  --theme-listDropdown-header-inputContainer-padding: 10px;
  --theme-listDropdown-header-inputContainer-minHeight: 54px;
  --theme-listDropdown-listContainer-backgroundColor: var(--droppah-color-background-light);
  --theme-listDropdown-item-color: var(--app-color-white);
  --theme-listDropdown-item-backgroundColor: var(--app-color-black);
  --theme-listDropdown-item-selected-backgroundColor: var(--theme-color-primary);
  --theme-listDropdown-item-highlighted-backgroundColor: var(--app-color-grey-light);
  --theme-listDropdown-item-selected-color: var(--app-color-white);
}
body.-theme-droppah .ldd-listContainer .ldd-listContainerInner .ldd-list {
  padding: 0;
}
body.-theme-droppah .ldd-listContainer .ldd-listContainerInner .ldd-divider {
  border-bottom: none;
  margin: 8px 0;
}
body.-theme-droppah .ldd-listContainer .ldd-listContainerInner .ldd-item {
  min-height: var(--droppah-losenge-height);
  border-radius: var(--app-border-radius);
}
body.-theme-droppah .ldd-listContainer .ldd-listContainerInner .ldd-item + .ldd-item {
  margin-top: 6px;
}
body.-theme-droppah .ldd-listContainer .ldd-listContainerInner .ldd-item.-itemDisabled .itemName {
  color: var(--app-color-grey-very-dark) !important;
}
@media (max-width: 850px) {
  body.-theme-droppah .ldd-listContainer .ldd-listContainerInner .ldd-item:hover, body.-theme-droppah .ldd-listContainer .ldd-listContainerInner .ldd-item:active, body.-theme-droppah .ldd-listContainer .ldd-listContainerInner .ldd-item:focus {
    background-color: var(--app-color-black);
  }
}
@media (min-width: 851px) {
  body.-theme-droppah .ldd-listContainer .ldd-listContainerInner .ldd-item:hover {
    background-color: var(--theme-color-primary);
  }
}

html {
  height: 100%;
  width: 100%;
  font-size: var(--app-font-size-normal);
  font-weight: var(--app-font-weight-normal);
}

body {
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0;
  color: var(--app-color-dark);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--app-font-size-normal);
  font-weight: var(--app-font-weight-normal);
}
body *:focus {
  outline: none !important;
}

:root {
  min-height: 100%;
  width: 100%;
  position: relative;
}

::-webkit-scrollbar {
  width: 7px;
  background: #f9f9f9;
}

::-webkit-scrollbar-thumb {
  background-color: #dddddd;
  border-radius: 0 !important;
}

p {
  margin: 0 0 0 0;
}

input {
  border: var(--app-border);
  color: var(--app-color-dark);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input::placeholder {
  color: var(--app-color-grey) !important;
}

.form-control {
  height: var(--app-formItem-height);
  color: var(--app-color-dark);
  font-weight: var(--app-font-weight-normal);
}

input:disabled,
textarea:disabled,
.form-control:disabled {
  background-color: var(--theme-color-input-disabled-background);
  color: var(--theme-color-input-disabled-color);
}

input[readonly],
textarea[readonly],
.form-control[readonly] {
  background-color: var(--theme-color-input-readonly-background);
  color: var(--theme-color-input-readonly-color);
}

b,
strong {
  font-weight: var(--app-font-weight-bold);
}

input,
select,
textarea {
  font-weight: var(--app-font-weight-normal);
}
input:not(:disabled):not(input[readonly],
select[readonly],
textarea[readonly]):focus,
select:not(:disabled):not(input[readonly],
select[readonly],
textarea[readonly]):focus,
textarea:not(:disabled):not(input[readonly],
select[readonly],
textarea[readonly]):focus {
  border: var(--theme-input-focus-border);
  box-shadow: var(--theme-input-focus-boxShadow);
}

input[type=search]::-webkit-search-cancel-button {
  display: none;
}

li {
  list-style-type: none;
}

div.-bold,
span.-bold {
  font-weight: var(--app-font-weight-bold);
}

div.-label,
span.-label {
  color: var(--app-color-grey-dark);
  font-weight: var(--app-font-weight-normal);
}

div.-labelError,
span.-labelError {
  color: var(--app-color-red);
  font-weight: var(--app-font-weight-normal);
}

div.-highlighted,
span.-highlighted {
  color: var(--theme-color-link);
  font-weight: var(--app-font-weight-normal);
}

div.-capitalise,
span.-capitalise {
  text-transform: capitalize;
}

textarea,
textarea.form-control {
  width: 100%;
  min-height: 130px;
  max-height: 200px;
  padding: 10px 12px;
  background-color: transparent;
  border: var(--app-border);
  border-radius: 5px;
  transition: all 0.25s;
}
textarea:disabled,
textarea.form-control:disabled {
  border: var(--app-border-light);
}

.app-splash-logo {
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.app-warningPanel {
  background-color: var(--app-color-warning-background);
  border: 1px solid var(--app-color-warning-border);
  border-radius: var(--app-border-radius);
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.app-warningPanel .app-warningPanel-row {
  display: flex;
  align-items: center;
  text-align: center;
}
.app-warningPanel .app-warningPanel-row:not(:last-child) {
  margin-bottom: 15px;
}
.app-warningPanel .app-warningPanel-row > span {
  display: contents;
}

.app-footer {
  display: block;
  transition: all 0.25s;
  margin: 10px auto 30px auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.app-footer .app-footer-fromFlexitime {
  width: 90%;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app-footer .app-footer-fromFlexitime.-cursor {
  cursor: pointer;
}
.app-footer .app-footer-fromFlexitime > img {
  max-width: 130px;
  margin: 0 7px;
}

.app-animatedTick {
  --animatedTick-size: 60px;
  width: var(--animatedTick-size);
  height: var(--animatedTick-size);
}
.app-animatedTick img {
  max-width: var(--animatedTick-size);
  max-height: var(--animatedTick-size);
}

.app-buttonRow {
  min-height: 54px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  position: relative;
}
.app-buttonRow > .app-buttonRow-left {
  display: flex;
  align-items: center;
  position: relative;
  margin-right: auto;
  max-width: 100%;
}
.app-buttonRow > .app-buttonRow-left > :not(:first-child) {
  margin-left: 8px;
}
.app-buttonRow > .app-buttonRow-left > * {
  max-width: 100%;
}
.app-buttonRow > .app-buttonRow-right {
  display: flex;
  align-items: center;
  position: relative;
  margin-left: auto;
  max-width: 100%;
}
.app-buttonRow > .app-buttonRow-right > :not(:last-child) {
  margin-right: 8px;
}
.app-buttonRow > .app-buttonRow-right > * {
  max-width: 100%;
}
.app-buttonRow .app-buttonRow-title {
  font-size: var(--app-font-size-large);
  font-weight: var(--app-font-weight-normal);
}

.app-invalidInput {
  border-color: var(--app-color-red);
}

.pac-container {
  z-index: 1100;
}

.slim-popover button {
  min-width: 0 !important;
}
.slim-popover .slim-editor-utils-group {
  display: flex !important;
  justify-content: center !important;
}
.slim-popover .slim-editor-btn-group .slim-editor-btn {
  height: auto !important;
}

.rx-toolbar .rx-button-icon,
.rx-topbar .rx-button-icon {
  color: var(--theme-color-link);
}
.rx-toolbar .rx-button-icon > svg,
.rx-topbar .rx-button-icon > svg {
  fill: var(--theme-color-link) !important;
  stroke: var(--theme-color-link) !important;
}

.rx-toolbar-container {
  box-shadow: var(--app-box-shadow);
  z-index: 200 !important;
}

.rx-variable {
  background-color: var(--app-color-karmly-translucent) !important;
}

.rx-popup {
  z-index: 1100 !important;
}

@font-face {
  font-family: "Work Sans";
  src: url("/assets/fonts/Work_Sans/WorkSans-Light.ttf");
  font-weight: 200;
}
@font-face {
  font-family: "Work Sans";
  src: url("/assets/fonts/Work_Sans/WorkSans-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Work Sans";
  src: url("/assets/fonts/Work_Sans/WorkSans-SemiBold.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Work Sans";
  src: url("/assets/fonts/Work_Sans/WorkSans-Bold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Work Sans";
  src: url("/assets/fonts/Work_Sans/WorkSans-ExtraBold.ttf");
  font-weight: 800;
}
* {
  -webkit-font-smoothing: antialiased !important;
  font-family: "Work Sans", sans-serif !important;
}
// Components with styling on elements that're appended to the document body (eg. dropdowns)
@use '../lib/./lib-components/mega-search/mega-search.component.scss';

body {
  &.-theme-invoxy {
    --theme-appList-border: var(--app-border-light);
    --theme-appList-item-borderBottom: var(--app-border-light);
    --theme-appList-empty-border: none;
  }
  &.-theme-karmly-light,
  &.-theme-karmly-dark,
  &.-theme-flexitime {
    --theme-appList-border: var(--app-border);
    --theme-appList-item-borderBottom: var(--app-border);
    --theme-appList-empty-border: var(--app-border);
  }
}

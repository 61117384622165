// Button Colour Mixins /////////////////////////////
@mixin buttonDisabledTextColor {
  color: var(--app-color-grey-very-dark) !important;
  > i {
    color: var(--app-color-grey-very-dark) !important;
  }
}
@mixin buttonDisabledBackgroundColor {
  background-color: var(--app-color-grey-light) !important;
}
@mixin buttonDisabledBorderColor {
  border-color: transparent !important;
}
@mixin buttonDisabled {
  font-weight: var(--app-font-weight-normal);
  @include buttonDisabledTextColor;
  @include buttonDisabledBackgroundColor;
  @include buttonDisabledBorderColor;
}
@mixin buttonReadonly {
  pointer-events: none !important;
}

@mixin buttonStandard($text-color, $background-color, $background-color-hover) {
  color: $text-color !important;
  background-color: $background-color !important;
  font-weight: var(--app-font-weight-normal);

  &:active {
    color: $text-color !important;
    background-color: $background-color !important;
  }
  &:hover,
  &:focus {
    color: $text-color !important;
    background-color: $background-color !important;
  }
  &:disabled {
    @include buttonDisabled;
  }
  &.-readonly {
    @include buttonReadonly;
  }
}
@mixin buttonClear($text-color, $text-color-hover) {
  color: $text-color !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);

  &:active,
  &:focus {
    color: $text-color !important;
    background-color: transparent !important;
    font-weight: var(--app-font-weight-normal);
  }
  &:hover {
    color: $text-color-hover !important;
  }
  &:disabled {
    @include buttonDisabled;
  }
  &.-readonly {
    @include buttonReadonly;
  }
}
@mixin buttonClearGreyBorder($text-color, $text-color-hover) {
  @include buttonClear($text-color, $text-color-hover);
  border: var(--app-border);
  background-color: var(--app-color-white) !important;

  &:active {
    background-color: var(--app-color-grey-very-light) !important;
  }
  &:hover {
    background-color: var(--app-color-grey-very-light) !important;
  }
}
@mixin buttonHoverFill($text-color, $background-color-hover) {
  color: $text-color !important;
  background-color: transparent !important;
  font-weight: var(--app-font-weight-normal);

  &:active,
  &:focus {
    color: $text-color !important;
    background-color: transparent !important;
    font-weight: var(--app-font-weight-normal);
  }
  &:hover {
    color: $text-color !important;
    background-color: $background-color-hover !important;
  }
  &:disabled {
    @include buttonDisabled;
  }
  &.-readonly {
    @include buttonReadonly;
  }
}
@mixin buttonTranslucent($text-color, $background-color, $background-color-hover) {
  color: $text-color !important;
  background-color: $background-color !important;
  font-weight: var(--app-font-weight-normal);

  &:active,
  &:focus {
    color: $text-color !important;
    background-color: $background-color !important;
    font-weight: var(--app-font-weight-normal);
  }
  &:hover {
    color: $text-color !important;
    background-color: $background-color-hover !important;
  }
  &:disabled {
    @include buttonDisabled;
  }
  &.-readonly {
    @include buttonReadonly;
  }
}
@mixin buttonOutline($text-color, $background-color, $background-color-hover) {
  color: $text-color !important;
  background-color: $background-color !important;
  border: 1px solid $text-color;
  font-weight: var(--app-font-weight-normal);

  &:active,
  &:focus {
    color: $text-color !important;
    background-color: $background-color !important;
    border: 1px solid $text-color;
  }
  &:hover {
    color: $text-color !important;
    background-color: $background-color-hover !important;
    border: 1px solid $text-color;
  }
  &:disabled {
    @include buttonDisabled;
    border: var(--app-border-light) !important;
  }
  &.-readonly {
    @include buttonReadonly;
  }
}

// Button Animation Mixins /////////////////////////////
@mixin buttonIconAnimation-spin-inactive {
  > i.-iconInactive {
    opacity: 1;
    transform: rotate(0deg);
  }
  > i.-iconActive {
    opacity: 0;
    transform: rotate(-180deg);
  }
}
@mixin buttonIconAnimation-spin-active {
  > i.-iconInactive {
    opacity: 0;
    transform: rotate(180deg);
  }
  > i.-iconActive {
    opacity: 1;
    transform: rotate(0);
  }
}

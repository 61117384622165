@use './mixins.scss' as mixins;

@mixin -rowIcon {
  flex: 0 0 auto;
  width: var(--appList-itemCell-deleteWidth) !important;
  justify-content: center;
  align-items: center;
  touch-action: manipulation;
  position: relative;

  &.-disabled {
    pointer-events: none;

    > i {
      color: var(--app-color-grey-light);
    }
  }

  > i {
    border-radius: 10px;
    transition: background-color var(--app-transition);
  }
  > :not(:last-child) {
    margin-right: 0;
  }
}

@mixin app-list {
  --appList-itemCell-deleteWidth: 50px;
  --appList-scrollWidth: 16px;
  --appList-border: var(--theme-appList-border);
  --appList-border-radius: var(--app-border-radius);

  position: relative;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  width: 100%;
  max-height: 100%;
  flex: 0 1 auto;

  &.-noBorder {
    --appList-border: none;
    --appList-border-radius: none;

    .app-list-empty {
      --theme-appList-empty-border: none;
      border-radius: none;
    }
  }

  &.-reverseScroll {
    .app-list-scrollOuter,
    cdk-virtual-scroll-viewport {
      margin-top: auto;
    }
  }
  &.-listTallerThanScrollContainer {
    &.-reverseScroll {
      .app-list-scrollOuter,
      cdk-virtual-scroll-viewport {
        .app-list-item {
          &:first-child {
            border-top: var(--theme-appList-item-border);
          }
        }
      }
    }
    &:not(.-reverseScroll) {
      .app-list-scrollOuter,
      cdk-virtual-scroll-viewport {
        .app-list-item {
          &:last-child {
            border-bottom: var(--theme-appList-item-border);
          }
        }
      }
    }
  }

  .app-list-scrollOuter {
    @include mixins.verticalScrollBar;
    max-height: 100%;
    width: 100%;
    position: relative;
    overflow-y: var(--app-overflow-overlay);
    overflow-x: hidden;
    border: var(--appList-border);
    border-radius: var(--appList-border-radius);

    .app-list-scrollInner {
      width: 100%;
      position: relative;
    }
    .app-list-bottomSpinnerContainer {
      width: 100%;
      height: 60px;
      position: relative;
    }
  }

  cdk-virtual-scroll-viewport {
    max-height: 100%;
    width: 100%;
    position: relative;
    overflow-y: var(--app-overflow-overlay);
    overflow-x: hidden;

    .cdk-virtual-scroll-content-wrapper {
      max-width: 100%;
    }
  }
}

@mixin app-list-item {
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: var(--theme-appList-item-border);
  transition: background-color 0.25s;
  background-color: var(--app-color-white);

  &:hover {
    &:not(.-header):not(.-itemSelectDisabled) {
      background-color: var(--app-color-grey-very-light);
    }
  }

  &.-header {
    border-bottom: none;
    background-color: var(--theme-appList-item-header-background);

    .app-list-itemCell {
      min-height: 39px;
      margin: 5px 0;
      font-weight: var(--app-font-weight-normal);
      transition: background-color var(--app-transition);
      border-radius: var(--app-border-radius);
      color: var(--app-color-grey-very-dark);
      user-select: none;

      > i.ion-md-arrow-dropdown,
      > i.ion-md-arrow-dropup {
        margin-top: 1px;
        font-size: var(--app-font-size-larger);
      }
      whats-this {
        margin-left: 0;
      }
    }
  }
  &:not(.-itemSelectDisabled) {
    &:not(.-header) {
      cursor: pointer;
    }
    &.-header {
      .app-list-itemCell:not(.-noSort) {
        cursor: pointer;
      }
    }
  }
  &.-header:not(.-itemSelectDisabled) {
    .app-list-itemCell:not(.-noSort) {
      &:hover {
        background-color: var(--app-color-grey-light);
      }
    }
  }
  &:last-child {
    border-bottom: none;
  }
}

@mixin app-list-itemRow {
  flex: 0 0 auto;
  min-width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;

  &.-column {
    flex-direction: column;
    align-items: flex-start;
  }
  &.-expandable {
    height: 0;
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
    transition: max-height var(--app-transition), padding var(--app-transition), opacity var(--app-transition);

    &.-expanded {
      height: auto;
      max-height: 1000px;
      opacity: 1;
    }
  }
}

@mixin app-list-itemCell {
  position: relative;
  padding: 8px 12px 7px 12px;
  height: 100%;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:first-child {
    padding-left: 15px;
  }
  &:last-child {
    padding-right: 20px;
  }

  &.-highlighted {
    > div,
    > span {
      color: var(--theme-color-link);
      font-weight: var(--app-font-weight-normal);
    }
  }
  &.-column {
    flex-direction: column;
    align-items: flex-start;

    > :not(:last-child) {
      margin-bottom: 3px;
    }
    > :not(:first-child) {
      margin-left: 0;
    }
  }
  &.-alignRight {
    justify-content: flex-end;
    text-align: end;
  }

  > span,
  > div {
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:not(.input-group) {
      overflow: hidden;
    }
  }
  > i {
    color: var(--app-color-grey-dark);
    position: relative;
  }

  .-itemCellText {
    display: flex;
  }

  > segment-value {
    --unit-color: var(--app-color-grey-dark);
  }
  > .app-list-personIcon {
    height: 20px;
    width: 20px;
    border-radius: 40px;
    background-color: var(--app-color-grey-light);
    margin-right: 10px;
    flex: 0 0 auto;
    text-align: center;
    font-size: var(--app-font-size-small);
    line-height: 20px;
    font-weight: var(--app-font-weight-normal);
    color: #333;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }
  > list-drop-down {
    --listDropDown-button-size: 1.5rem;
    --listDropDown-button-iconSize: 16px;
  }
  > :not(span):not(:last-child) {
    margin-right: 8px;
  }
  > span + :not(span) {
    margin-left: 8px;
  }
  > .app-formItem {
    padding: 0 var(--theme-appList-itemCell-formItem-horitonzalPadding);

    @include mixins.is-mobile {
      padding-left: 0;
      padding-right: 0;
    }

    .app-formItem-value {
      --app-formItem-height: 28px;

      .-highlighted {
        font-weight: var(--app-font-weight-normal);
      }

      segment-value {
        --value-size: 1rem;
        --unit-color: var(--app-color-grey-dark);
      }
    }
  }

  > .app-list-itemColour {
    width: 4px;
    flex: 0 0 auto;
    border-radius: 10px;
    margin-right: 7px;
    height: 22px;
  }
  &.-rowIcon {
    @include -rowIcon;
  }
  &.-rowToggle {
    padding-left: 16px;
    padding-right: 0;
    @include -rowIcon;
  }
  &.-rowDelete {
    @include -rowIcon;

    > i {
      color: var(--app-color-grey-dark);
    }
  }
  &.-rowAdd {
    @include -rowIcon;
    font-size: 20px;

    > i {
      color: var(--theme-color-success);
    }
  }
  &.-rowEdit {
    @include -rowIcon;
    font-size: var(--app-font-size-large);

    > i {
      color: var(--theme-color-link);
    }
  }
  &.-rowArchived {
    @include -rowIcon;
    font-size: var(--app-font-size-large);
    width: calc(var(--appList-itemCell-deleteWidth) - 1rem);
    padding: 0;
    padding-right: 12px;

    > i {
      color: var(--app-color-grey);
    }
  }
  &.-rowExpand {
    @include -rowIcon;
    font-size: var(--app-font-size-large);

    > i {
      color: var(--theme-color-link);
    }
  }
  &.-rowLoading {
    @include -rowIcon;
    cursor: default;

    > spinner {
      --spinner-size: 20px;
    }
  }
  &.-rowWarning {
    @include -rowIcon;
    cursor: default;

    > i {
      color: var(--app-color-yellow);
      font-size: var(--app-font-size-larger);
    }
  }
  &.-rowAttachment,
  &.-rowMessage {
    @include -rowIcon;
    cursor: default;

    > i {
      color: var(--app-color-grey-dark);
      font-size: var(--app-font-size-larger);
    }
  }
  &.-rowGo {
    @include -rowIcon;
    cursor: pointer;

    > i {
      color: var(--app-color-grey-dark);
      font-size: var(--app-font-size-larger);
    }
  }
}

@mixin app-list-itemDetail {
  display: flex;
  flex-direction: column;

  > :not(:last-child) {
    margin-bottom: 5px;
  }
}

@mixin _app-list-itemDetail-labelOrItem {
  display: flex;
  align-items: center;

  > :not(span):not(:last-child) {
    margin-right: 8px;
  }
  > span + :not(span) {
    margin-left: 8px;
  }
  i {
    font-size: var(--app-font-size-large);
    position: relative;
    color: var(--app-color-grey-dark);
  }
}

@mixin app-list-itemDetail-label {
  @include _app-list-itemDetail-labelOrItem;
  font-size: var(--app-font-size-small);
}

@mixin app-list-itemDetail-value {
  @include _app-list-itemDetail-labelOrItem;
  font-size: var(--app-font-size-normal);

  > span {
    @include mixins.overflowEllipsis;
  }
}

@mixin app-list-empty {
  --appList-listEmpty-padding: 30px;

  border-radius: var(--app-border-radius);
  padding: var(--appList-listEmpty-padding);
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  border: var(--theme-appList-empty-border);
  background-color: var(--app-color-white);

  > i {
    display: block;
    font-size: 3rem;
    margin-bottom: 5px;
    width: 100%;
  }
  > .app-list-emptyImage {
    height: 50px;
    margin-bottom: 15px;
  }
  > thumbnail-graphic {
    --thumbnailGraphic-height: 80px;
    margin-bottom: 15px;
  }

  .app-list-emptyHeader {
    font-size: var(--app-font-size-larger);
    width: 100%;
    margin-bottom: 0;
    color: var(--app-color-dark);
    font-weight: var(--app-font-weight-normal);
    text-transform: capitalize;
    margin-bottom: 5px;
  }
  .app-list-emptyDescription {
    font-size: var(--app-font-size-normal);
    width: 100%;
    color: var(--app-color-grey-dark);
  }
  .app-list-emptyButton {
    margin-top: 15px;
    text-transform: capitalize;
  }
}

.app-list {
  @include app-list;
}
.app-list-item {
  @include app-list-item;
}
.app-list-itemRow {
  @include app-list-itemRow;
}
.app-list-itemCell {
  @include app-list-itemCell;
}
.app-list-itemDetail {
  @include app-list-itemDetail;
}
.app-list-itemDetail-label {
  @include app-list-itemDetail-label;
}
.app-list-itemDetail-value {
  @include app-list-itemDetail-value;
}
.app-list-empty {
  @include app-list-empty;
}

body {
  &.-theme-invoxy {
    --theme-appList-border: var(--app-border-light);
    --theme-appList-item-border: var(--app-border-light);
    --theme-appList-empty-border: var(--app-border-dashed-light);

    --theme-appList-item-header-background: transparent;

    --theme-appList-itemCell-formItem-horitonzalPadding: 15px;
  }
  &.-theme-karmly-light,
  &.-theme-karmly-dark,
  &.-theme-flexitime {
    --theme-appList-border: var(--app-border);
    --theme-appList-item-border: var(--app-border);
    --theme-appList-empty-border: var(--app-border);

    --theme-appList-item-header-background: transparent;

    --theme-appList-itemCell-formItem-horitonzalPadding: 0;
  }
}

@use "./mixins.scss" as mixins;

.app-summaryContainer {
  width: 100%;
  display: flex;

  @include mixins.is-mobile {
    flex-wrap: wrap;

    .app-summaryItem {
      padding: 15px;
      width: 100%;

      &:not(:first-child) {
        margin-left: 0;
      }
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}

.app-summaryItem {
  flex: 1 1 auto;
  background-color: var(--app-color-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 15px 25px;
  height: 80px;
  transition: box-shadow var(--app-transition);

  &.-hoverable {
    cursor: pointer;

    &:hover {
      box-shadow: var(--app-box-shadow-dark);
    }
  }

  &.-warning {
    color: var(--app-color-red);

    .app-summaryItem-label {
      color: var(--app-color-red);
    }
  }

  .app-summaryItem-label {
    color: var(--app-color-grey-very-dark);
    display: flex;
    align-items: center;

    .-alignRight {
      margin-left: auto;
    }
  }
  .app-summaryItem-value {
    display: flex;
    // align-items: flex-end;
    align-items: center;
    font-size: var(--theme-summaryItem-value-fontSize);
    line-height: var(--theme-summaryItem-value-fontSize);
    height: 34px;

    .-label {
      font-size: var(--app-font-size-larger);
      line-height: 20px;
    }
    .-valueSmall {
      font-size: var(--theme-summaryItem-value-fontSize-small);
      line-height: var(--theme-summaryItem-value-fontSize-small);
    }
  }
  .app-summaryitem-selectIcon {
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--app-color-grey-dark);
  }
}



.app-window,
.app-navContent {
  .app-summaryContainer {
    margin-bottom: 15px;
  }
}

body {
  &.-theme-invoxy {
    --theme-summaryItem-value-fontSize: 26px;
    --theme-summaryItem-value-fontSize-small: 18px;
  }
  &.-theme-karmly-light,
  &.-theme-karmly-dark,
  &.-theme-flexitime {
    --theme-summaryItem-value-fontSize: 20px;
    --theme-summaryItem-value-fontSize-small: 16px;
  }
}

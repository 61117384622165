@use '../mixins.scss' as mixins;
@use './button-colors.scss' as buttonColors;
@use './button-types.scss' as buttonTypes;
@use './button-mixins.scss' as buttonMixins;

body {
  button,
  a {
    --button-height: 34px;
    --button-iconSize: 20px;
    --button-fontSize: 1rem;
    --button-minWidth: 100px;
    --button-padding: 15px;

    // @include mixins.is-mobile {
    //   --button-height: 44px;
    // }

    @include mixins.user-select-disabled;
    border-radius: var(--app-border-radius);
    font-size: var(--button-fontSize);
    height: var(--button-height);
    min-width: var(--button-minWidth);
    max-width: 100%;
    color: var(--app-color-dark);
    border-radius: var(--app-border-radius);
    border: 1px solid transparent;
    text-transform: none;
    font-weight: var(--app-font-weight-normal);
    // box-shadow: none !important;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 var(--button-padding);
    cursor: pointer;
    touch-action: manipulation;
    position: relative;
    white-space: nowrap;

    // TODO: create global custom button component which avoid
    // issues with transitions on initial element render
    // transition: background-color var(--app-transition), color var(--app-transition);

    @include mixins.is-mobile {
      padding: 0 10px;
      min-width: 80px;
    }

    > i,
    > i.ion,
    > .button-animatedIcon > i {
      font-size: var(--button-iconSize);
      flex: 0 1 auto;
      transition: color var(--app-transition);
    }
    // Container used around icons for containing icon animations
    > .button-animatedIcon {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: var(--button-iconSize);
      height: var(--button-iconSize);

      > i {
        transition: transform var(--app-transition-longer), opacity var(--app-transition-longer);
      }

      &.-buttonIconAnimation-spinWhenActivated,
      &.-buttonIconAnimation-spinOnHover {
        @include buttonMixins.buttonIconAnimation-spin-inactive;
      }
      &.-buttonIconAnimation-spinWhenActivated {
        &.-activated {
          @include buttonMixins.buttonIconAnimation-spin-active;
        }
      }
      &.-buttonIconAnimation-spinOnHover {
        &:not(:disabled):hover {
          @include buttonMixins.buttonIconAnimation-spin-active;
        }
      }
    }
    > span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: normal;
      transition: color var(--app-transition);
    }
    > item-bullet {
      max-height: calc(100% - 10px);
      margin-top: 5px;
      margin-bottom: 5px;
    }
    &.-centerAlign {
      text-align: center;
    }
    &.-centerTextAndIcon {
      > .-title {
        flex: 0 1 auto !important;
      }
    }
    &:disabled {
      pointer-events: none;

      tooltip-popover {
        pointer-events: all;
      }
    }
    &.btn {
      border: none;
    }
    &[ngbDropdownToggle] {
      &::after {
        display: none !important;
      }
    }

    > :not(:last-child) {
      &:not(tooltip-popover) {
        margin-right: 8px;
      }
    }
  }

  a {
    @include buttonTypes.button-type-text;
    @include buttonColors.button-color-link-clear;
  }
}

body {
  &.-theme-droppah {
    button,
    a {
      --button-height: var(--droppah-losenge-height-small);
    }
  }
}

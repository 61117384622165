.app-edit-content {
  border-radius: var(--app-border-radius);
  background-color: var(--theme-editContent-background);
  border: var(--app-border-light);
  padding: var(--theme-editContent-padding);
  margin: 0 0 20px 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  &.-loading {
    opacity: 0.7;
    pointer-events: none;
  }
  &.-cols {
    flex-direction: row;

    .app-edit-contentCol {
      display: flex;
      flex-direction: column;
      width: 50%;
      flex: 0 0 auto;
    }
  }
}

.app-edit-row {
  width: 100%;
  min-height: 50px;
  padding: 8px 0;
  display: flex;

  .-divider {
    width: 100%;
    height: 1px;
    border-top: 1px solid #efefef;
    margin: 20px 0;
  }

  .app-edit-col {
    display: flex;
    min-height: 34px;
    float: none;

    &.-column {
      flex-direction: column;
      align-items: flex-start;

      > :not(:first-child) {
        margin-left: 0;
      }
    }
    &.-alignRight {
      justify-content: flex-end;
    }
    &.-alignVertical {
      display: block;

      > :not(:first-child) {
        margin-left: 0;
      }
    }

    .app-edit-colHeader {
      height: 34px;
      display: flex;
      align-items: center;

      &.-colHeaderLarge {
        font-size: var(--app-font-size-large);
        font-weight: var(--app-font-weight-bold);
      }
    }
    .app-edit-colValue {
      height: 34px;
      display: flex;
      align-items: center;
      padding: 0.375rem 0.75rem;

      &.-bold {
        font-weight: var(--app-font-weight-normal);
      }
    }

    > label {
      margin-bottom: 0;
      line-height: 20px;
    }
    > :not(:first-child) {
      margin-left: 10px;
    }
    // RedactorX Container
    .rx-container {
      margin-left: 0;
      width: 100%;
      border: 1px solid #ccc;
      border-radius: 2px;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    }

    .btn-link {
      padding: 0;
    }
    pending-field {
      height: 34px;
      position: absolute;
      right: 25px;
      z-index: 10;
    }
    > input {
      width: 100%;
      position: relative;
    }
    date-picker {
      width: 100%;
    }
    time-input {
      display: block;
      width: 140px;
    }
    list-drop-down {
      width: 100%;

      button {
        width: 100%;
      }
    }
    .form-group {
      margin-bottom: 0;
    }
    textarea {
      background-color: var(--app-color-white);
      min-height: 100px;

      &:disabled {
        background-color: var(--theme-color-input-disabled-background);
      }
    }
  }
}

body {
  &.-theme-invoxy {
    --theme-editContent-background: var(--app-color-grey-very-light);
    --theme-editContent-padding: 30px;
  }
  &.-theme-karmly-light {
    --theme-editContent-background: var(--app-color-white);
    --theme-editContent-padding: 30px 15px;
  }
  &.-theme-karmly-dark {
    --theme-editContent-background: var(--app-color-grey-very-light);
    --theme-editContent-padding: 30px 15px;
  }
}
